import React from 'react'
import { Container, Typography, Icon } from 'components'

function AnalogLine({ waitTime, waitingPeople, smallBox }) {
  return (
    <>
      <Container
        width="100%"
        padding="0.5em"
        flexWrap="nowrap"
        alignItems="center"
      >
        <Icon icon="clock" fontSize="2" marginRight="1em" />
        <Typography
          fontWeight="bold"
          fontSize={smallBox ? `1` : `2`}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {`${waitTime} ${smallBox ? `min` : `min para ser atendido`}`}
        </Typography>
      </Container>
      <Container
        width="100%"
        padding="0.5em"
        flexWrap="nowrap"
        alignItems="center"
      >
        <Icon icon="user" fontSize="2" marginRight="1em" />
        <Typography
          fontWeight="bold"
          fontSize={smallBox ? `1` : `2`}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {`${waitingPeople} ${smallBox ? `pers` : `personas esperando`}`}
        </Typography>
      </Container>
    </>
  )
}

export default AnalogLine
