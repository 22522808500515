import React from 'react'
import { Container, Typography, Img, Icon } from 'components'
import { useTime } from 'context'

function Card({ title, children }) {
  return (
    <>
      <Container
        width="100%"
        backgroundColor="tertiary.4"
        padding="3"
        borderRadius="1rem 1rem 0 0"
      >
        <Typography
          width="100%"
          color="white"
          fontSize={{ _: 5, lg: 6 }}
          textAlign="center"
        >
          {title}
        </Typography>
      </Container>
      <Container
        width="100%"
        flexGrow={1}
        backgroundColor="white"
        borderRadius="0 0 1rem 1rem"
        flexDirection="column"
        justifyContent="space-around"
        padding="2"
      >
        {children}
      </Container>
    </>
  )
}

function TV({ logo, config }) {
  const time = useTime()
  const {
    mainTitle,
    staffTitle,
    staff,
    timesTitle,
    times,
    chiefTitle,
    chief,
    waitTimeTitle,
    waitTimes,
    extraInstruction,
  } = config
  return (
    <Container backgroundColor="gray.2@0.25" height="100vh">
      <Container
        backgroundColor="white"
        maxHeight="10vh"
        width="100%"
        paddingY="2"
        paddingX={{ _: 4, lg: 5 }}
        withShadow
        justifyContent="space-between"
        alignItems="center"
      >
        <Img width="15%" src={logo} />
        <Typography color="tertiary.4" fontSize={{ _: 6, lg: 7 }}>
          {mainTitle}
        </Typography>
        <Typography color="tertiary.4" fontSize={{ _: 6, lg: 7 }}>
          {time.format(`HH:mm:ss`)}
        </Typography>
      </Container>
      <Container
        width="100%"
        alignItems="flex-start"
        height="90vh"
        paddingX={{ _: 4, lg: 5 }}
        paddingY={{ _: 1, lg: 3 }}
      >
        <Container width="100%" height="95%" justifyContent="space-evenly">
          <Container
            flex={1}
            flexWrap="no-wrap"
            height="100%"
            flexDirection="column"
            padding="3"
          >
            <Card title={staffTitle}>
              {staff.map((staff, categoryIndex) => (
                <>
                  <Typography
                    color="tertiary.4"
                    fontSize={{ _: 3, lg: 4 }}
                    width="100%"
                    textAlign="center"
                    fontWeight="bolder"
                    marginY="2"
                    key={categoryIndex}
                  >
                    {staff.label}
                  </Typography>
                  {staff.items.map((item, staffIndex) => (
                    <Typography
                      key={staffIndex}
                      fontSize={{ _: 3, lg: 4 }}
                      width="100%"
                      textAlign="center"
                      marginY="2"
                    >
                      {item}
                    </Typography>
                  ))}
                </>
              ))}
            </Card>
          </Container>
          <Container
            flex={1}
            flexWrap="no-wrap"
            flexDirection="column"
            height="100%"
            padding="3"
          >
            <Container
              flexGrow={4}
              width="100%"
              flexDirection="column"
              marginBottom="3"
            >
              <Card title={timesTitle}>
                {times.map((time, index) => (
                  <Container width="100%" marginY="2" key={index}>
                    <Typography
                      fontSize={{ _: 6, lg: 7 }}
                      fontWeight="bolder"
                      width="100%"
                      textAlign="center"
                    >
                      {time.time}
                    </Typography>
                    <Typography
                      fontSize={{ _: 4, lg: 5 }}
                      width="100%"
                      textAlign="center"
                      color="tertiary.4"
                    >
                      {time.label}
                    </Typography>
                  </Container>
                ))}
              </Card>
            </Container>
            <Container flexGrow={1} width="100%" flexDirection="column">
              <Card title={chiefTitle}>
                <Typography
                  fontSize={{ _: 5, lg: 6 }}
                  width="100%"
                  textAlign="center"
                  marginY="2"
                >
                  {chief}
                </Typography>
              </Card>
            </Container>
          </Container>
          <Container
            flex={1}
            flexWrap="no-wrap"
            height="100%"
            flexDirection="column"
            padding="3"
          >
            <Card title={waitTimeTitle}>
              {waitTimes.map((time, index) => (
                <Container
                  width="100%"
                  marginY="2"
                  key={index}
                  alignItems="center"
                  flexWrap="no-wrap"
                  paddingX="3"
                >
                  <Icon
                    icon="circle"
                    fontSize={{ _: 7, lg: 8 }}
                    color={time.color}
                    marginRight="3"
                  />
                  <Container>
                    <Typography
                      fontSize={{ _: 6, lg: 7 }}
                      color={time.color}
                      fontWeight="bolder"
                      width="100%"
                    >
                      {time.time}
                    </Typography>
                    <Typography
                      fontSize={{ _: 5, lg: 6 }}
                      color={time.color}
                      width="100%"
                    >
                      {time.label}
                    </Typography>
                  </Container>
                </Container>
              ))}
            </Card>
          </Container>
        </Container>
        <Typography color="gray.2" fontSize={{ _: 5, lg: 6 }}>
          {extraInstruction}
        </Typography>
      </Container>
    </Container>
  )
}

export default TV
