import axios from 'axios'
import {
  PRINT_FILA_BASE_URL,
  PRINT_CHECKIN_BASE_URL,
  PRINT_BONUS_BASE_URL,
  PRINT_H2H_BASE_URL,
  PRINT_AUTOPAGO_ZONE_BASE_URL,
  PRINT_PROVIDERVOUCHER_BASE_URL,
  PRINT_INTEGRATED_BASE_URL,
  PRINT_AUTOPAGO_BASE_URL,
  PRINT_NEW_CHECKIN_BASE_URL,
  PRINT_HOSPITAL_PUERTO_MONTT_BASE_URL,
} from 'config/constants'

const urlByApp = {
  fila: PRINT_FILA_BASE_URL,
  checkin: PRINT_CHECKIN_BASE_URL,
  bonus: PRINT_BONUS_BASE_URL,
  h2h: PRINT_H2H_BASE_URL,
  autopago: PRINT_AUTOPAGO_BASE_URL,
  autopagoZone: PRINT_AUTOPAGO_ZONE_BASE_URL,
  voucherWithProvider: PRINT_PROVIDERVOUCHER_BASE_URL,
  integrated: PRINT_INTEGRATED_BASE_URL,
  newCheckin: PRINT_NEW_CHECKIN_BASE_URL,
  hospitalPuertoMontt: PRINT_HOSPITAL_PUERTO_MONTT_BASE_URL,
}

export function print({ withPrevisionData = false, app = `fila`, ...ticket }) {
  let retry = 3 //Intenta solo una vez
  async function tryToPrint(resolve, reject) {
    try {
      const { previsionTicket } = ticket
      if (previsionTicket) {
        ticket.prevision = previsionTicket
      }
      const result = await axios.post(
        `${urlByApp[app]}${
          withPrevisionData || previsionTicket || ticket?.positiveError
            ? `WithPrevision`
            : ``
        }`,
        ticket,
      )
      resolve(result)
    } catch (e) {
      if (retry == 3) {
        reject({
          message: `No se ha podido imprimir el ticket. Consulte con su RUT`,
        })
      } else {
        setTimeout(async () => {
          retry += 1
          await tryToPrint(resolve, reject)
        }, 1000)
      }
    }
  }
  return new Promise(tryToPrint)
}
