import React, { useEffect } from 'react'
import { useStateWithMerge } from 'hooks'
import { useHistory } from 'react-router-dom'
import { capitalizeFirstLetter } from 'utils'
import { Container, Typography, Button, Icon, Input, Img } from 'components'
import createKeyboardConfig, {
  keyboardModes,
  callModesById,
} from 'config/keyboard'
import { useUnit } from 'context'
import Keyboard from '../../Keyboard'

const inputsConfig = [
  {
    placeholder: `Nombre`,
    name: `firstName`,
  },
  {
    placeholder: `Apellido`,
    name: `lastName`,
  },
]

// eslint-disable-next-line max-lines-per-function
function RequestName({ goBack, unit, flushInterval }) {
  const history = useHistory()
  const { logo } = useUnit()
  const [state, setState] = useStateWithMerge({
    blockGoBack: false,
    firstName: ``,
    lastName: ``,
    focusedInput: `firstName`,
  })

  useEffect(() => {
    if (history && !history.location.state) {
      goBack()
    }
  }, [])

  const { requestNameConfig, disableGoBack } =
    history && history.location.state ? history.location.state : {}

  useEffect(() => {
    if (disableGoBack == true) {
      setState({ blockGoBack: true })
      setTimeout(() => flushInterval(), 500)
    }
  }, [disableGoBack])

  async function handleConfirm() {
    const { firstName, lastName } = state
    if (firstName && lastName) {
      return history.push({
        pathname: `/totem/${unit.id}/print`,
        state: {
          ...history.location.state,
          additionalPatientData: {
            patientFirstName: firstName,
            patientLastName: lastName,
          },
        },
      })
    }
  }

  //Manejo del teclado
  const handleButtonClick = (e) => {
    const key = e.target.name
    const value = state[state.focusedInput] + key
    setState({
      [state.focusedInput]: capitalizeFirstLetter(value.toLowerCase()),
    })
    e.target.blur()
  }

  const handleDeleteInput = (e) => {
    if (state[state.focusedInput].length > 0) {
      setState({
        [state.focusedInput]: state[state.focusedInput].slice(0, -1),
      })
    }
    if (e) {
      e.target.blur()
    }
  }

  const { layoutConfig } = createKeyboardConfig(
    callModesById.byRequestData[keyboardModes.ABC_KEYBOARD],
    {
      handleButtonClick,
      handleDeleteInput,
    },
  )

  const { blockGoBack, firstName, lastName } = state

  const disabledBtn = firstName.length === 0 || lastName.length === 0
  return (
    <Container width="100%" justifyContent="center" alignItems="center">
      <Container
        width={{ _: `40em` }}
        height={{ _: `53em`, md: `60em` }}
        fontSize={1}
        backgroundColor="white"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        shadow="0px 3px 4px -4px rgba(0, 0, 0, 0.8)"
      >
        <Container
          width="100%"
          justifyContent="center"
          shadow="0px 3px 4px rgba(0,0,0, 0.12)"
          paddingY="3"
        >
          <Img src={logo} maxWidth="40%" />
        </Container>
        <Container paddingY="3" paddingX="3">
          {blockGoBack == false ? (
            <Button
              backgroundColor="transparent"
              paddingY="2"
              paddingR="4"
              onClick={goBack}
              alignSelf="flex-start"
            >
              <Icon icon="arrowLeft" color="gray.3" fontSize="5" />
            </Button>
          ) : null}

          <Container width="100%" justifyContent="center" alignItems="center">
            <Container
              width="100%"
              justifyContent="center"
              alignItems="center"
              marginBottom="4"
            >
              <Container
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  fontSize="5"
                  fontWeight={2}
                  color="black"
                  textAlign="center"
                >
                  {requestNameConfig && requestNameConfig.title
                    ? requestNameConfig.title
                    : ``}
                </Typography>
              </Container>
              <Container
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Typography fontSize="4" fontWeight={0} color="black">
                  Ingrese su nombre y apellido
                </Typography>
              </Container>
            </Container>
            <Container
              width="100%"
              justifyContent="center"
              alignItems="center"
              marginBottom="4"
            >
              {inputsConfig.map((input, index) => (
                <Container
                  key={index}
                  width="80%"
                  marginY="1vh"
                  flexDirection="row"
                  flexWrap="no-wrap"
                  backgroundColor="white"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="5px"
                  padding="0.5em"
                  border="1px solid"
                  height={{ _: `6vh` }}
                  borderColor="gray.2"
                >
                  <Input
                    {...input}
                    value={state[input.name]}
                    width="100%"
                    fontSize="4"
                    border="none"
                    readonly="readonly"
                    onClick={() => setState({ focusedInput: input.name })}
                  />
                </Container>
              ))}
            </Container>

            <Container
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(30em, 1fr))"
              gridGap={3}
              width="100%"
              marginY="2"
              maxHeight="60vh"
            >
              <Keyboard
                isQwerty={true}
                config={layoutConfig}
                columns={20}
                marginTop={{ _: 1, md: 0 }}
                marginLeft={{ _: 0 }}
              />
            </Container>
            <Container width="100%" justifyContent="center" alignItems="center">
              <Button
                width="80%"
                backgroundColor={disabledBtn ? `tertiary.5` : `primary.4`}
                disabled={disabledBtn}
                color="white"
                fontSize="5"
                padding={3}
                borderRadius="12px"
                hoverProps={{
                  backgroundColor: disabledBtn ? `tertiary.5` : `primary.3`,
                }}
                onClick={() => handleConfirm()}
                marginTop="3"
              >
                <Typography
                  color={disabledBtn ? `black@0.25` : `white`}
                  fontSize="4"
                  fontWeight="3"
                >
                  Siguiente
                </Typography>
              </Button>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}

export default RequestName
