import React, { useEffect } from 'react'
import { Container, Typography, Button, Icon } from 'components'
import moment from 'moment-timezone'
import { useAttentions, useAuth, useTime } from 'context'
import { formatSeconds } from 'utils'
import { useStateWithMerge } from 'hooks'
import API from 'config/api'
import NormalEndButton from '../panel/CallPanel/ActionsRow/EndButton'

function getInitialState(currentAttention = null) {
  return {
    canFinish: currentAttention != null,
    canRecall: currentAttention != null,
    canMarkAbsent: currentAttention != null,
    loading: true,
    error: null,
  }
}

function CurrentAttentionDisplay({ currentAttention }) {
  const currentTime = useTime()
  return (
    <Typography fontSize="4" color="white">
      {currentAttention
        ? formatSeconds(
            currentTime.diff(moment(currentAttention.initDate), `s`),
          )
        : `-`}
    </Typography>
  )
}
function CallButton({
  attentions,
  setState,
  moduleId,
  canCall,
  withCallByManyModules,
  attentionsByModule,
}) {
  const {
    user: { id: userId },
  } = useAuth()

  async function onClick() {
    try {
      const attentionIdToCall = withCallByManyModules
        ? attentionsByModule[0]
        : attentions[0]

      await API.callAttention({
        userId,
        moduleId,
        attentionId: attentionIdToCall,
      })

      setState({
        canRecall: true,
        canFinish: true,
        canMarkAbsent: true,
        canCall: false,
      })
    } catch (e) {
      //console.error(e)
    }
  }

  const isAvailable = attentions.length > 0

  const disabled = withCallByManyModules ? !canCall : !isAvailable

  return (
    <Button
      width="39%"
      backgroundColor={disabled ? `gray.0` : `primary.8`}
      padding="0.5em"
      fontSize="4"
      marginLeft="0.5em"
      color={disabled ? `gray.3` : `white`}
      disabled={disabled}
      onClick={onClick}
    >
      Llamar
    </Button>
  )
}
function RecallButton({ attentionId, setState, canRecall }) {
  async function onClick() {
    try {
      await API.recallAttention({ attentionId })
      setState({ canRecall: false, canFinish: false, canMarkAbsent: false })
      setTimeout(
        () =>
          setState({ canRecall: true, canFinish: true, canMarkAbsent: true }),
        5000,
      )
    } catch (e) {
      //console.error(e)
    }
  }
  return (
    <Button
      width="29%"
      backgroundColor={canRecall ? `primary.8` : `gray.0`}
      padding="0.5em"
      fontSize="4"
      color={canRecall ? `white` : `gray.3`}
      marginRight="0.5em"
      disabled={!canRecall}
      onClick={onClick}
    >
      Re-llamar
    </Button>
  )
}

function MarkAsAbsentButton({
  attentionId,
  setState,
  setCurrentAttention,
  canMarkAbsent,
  moduleId,
}) {
  async function onClick() {
    try {
      await API.absentAttention({ attentionId })
      setState(getInitialState(), false)
      setCurrentAttention({ moduleId })
    } catch (e) {
      //console.error(e)
    }
  }
  return (
    <Button
      width="39%"
      backgroundColor={canMarkAbsent ? `primary.8` : `gray.0`}
      padding="0.5em"
      fontSize="4"
      marginLeft="0.5em"
      color={canMarkAbsent ? `white` : `gray.3`}
      disabled={!canMarkAbsent}
      onClick={onClick}
    >
      Ausente
    </Button>
  )
}

function CallPanel({
  description,
  identifier,
  id: moduleId,
  canCall = true,
  withCallByManyModules = false,
  attentionsByModule = [],
  currentUnit,
}) {
  const {
    currentAttentionByModule,
    setCurrentAttention,
    auxiliaryLines,
    attentions,
  } = useAttentions()

  const currentAttention = currentAttentionByModule[moduleId]
  const [state, setState] = useStateWithMerge(getInitialState(currentAttention))

  const { canFinish, canRecall, canMarkAbsent } = state

  useEffect(() => {
    setState(getInitialState(currentAttention))
  }, [currentAttention])

  return (
    <Container
      width="100%"
      padding="2"
      border="1px solid"
      borderColor="primary.2"
      marginY="1"
      borderRadius="5px"
      height="7em"
    >
      <Typography width="12%" fontSize="3" fontWeight="bold" color="primary.2">
        {`${description} ${identifier}`.toUpperCase()}
      </Typography>
      <Container width="44%">
        <Container
          backgroundColor="primary.2"
          paddingX="3"
          paddingY="1"
          justifyContent="space-between"
          marginRight="1"
          height="5em"
          width="49%"
          alignItems="flex-end"
        >
          <CurrentAttentionDisplay currentAttention={currentAttention} />
          <Container
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="5" color="white">
              {currentAttention ? currentAttention.displayName : `-`}
            </Typography>
            <Typography fontSize="1" color="white" fontStyle="italic">
              <Icon icon="user" color="white" marginRight="5px" />
              {currentAttention ? `Atendiendo` : `Esperando atención`}
            </Typography>
          </Container>
        </Container>
        <Container
          width="49%"
          height="5em"
          backgroundColor="primary.2"
          paddingX="3"
          paddingY="1"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Typography color="white" fontSize="4">
            Rut:{` `}
            {currentAttention
              ? currentAttention.patientDocument || currentAttention.patientRut
              : `-`}
          </Typography>
          <Typography color="white" fontSize="4">
            <Icon icon="clock" fontSize="3" color="white" marginRight="5px" />
            {currentAttention
              ? formatSeconds(currentAttention.waitTime * 60)
              : `-`}
          </Typography>
        </Container>
      </Container>
      <Container width="44%" marginTop="0.5em" alignItems="stretch">
        <RecallButton
          attentionId={currentAttention && currentAttention.id}
          setState={setState}
          canRecall={canRecall}
        />
        <NormalEndButton
          attentionId={currentAttention && currentAttention.id}
          suggestedRefs={
            currentAttention ? currentAttention.suggestedLines : []
          }
          editTicketPatient={() => null}
          planTicketJourney={() => null}
          currentAttention={currentAttention}
          auxiliaryLines={auxiliaryLines}
          enabled={canFinish}
          currentUnit={currentUnit}
        />
        {currentAttention == null ? (
          <CallButton
            moduleId={moduleId}
            attentions={attentions}
            setState={setState}
            canCall={canCall}
            withCallByManyModules={withCallByManyModules}
            attentionsByModule={attentionsByModule}
          />
        ) : (
          <MarkAsAbsentButton
            moduleId={moduleId}
            attentionId={currentAttention.id}
            setState={setState}
            setCurrentAttention={setCurrentAttention}
            canMarkAbsent={canMarkAbsent}
          />
        )}
      </Container>
    </Container>
  )
}

export default CallPanel
