import React from 'react'
import { format } from 'rut.js'
import { useStateWithMerge } from 'hooks'
import { Container, Typography, Button, Input } from 'components'

function getInitialState(patient) {
  return {
    patientRut: patient.patientRut || null,
    patientFirstName: patient.patientFirstName || null,
    patientLastName: patient.patientLastName || null,
    patientMaternalLastName: patient.patientMaternalLastName || null,
    patientMail: patient.patientMail || null,
    patientDocument: patient.patientDocument || null,
    documentType: patient.patientDocument ? `patientDocument` : `patientRut`,
  }
}
function EditPatient({ onClose, onConfirm, patient }) {
  const [state, setState] = useStateWithMerge(getInitialState(patient))
  const {
    patientFirstName,
    patientLastName,
    patientMail,
    documentType,
    patientMaternalLastName,
  } = state
  function setField(value, field) {
    return setState({ [field]: value })
  }
  const canSubmit =
    state[documentType] != null && state[documentType].length > 0
  function confirm() {
    if (canSubmit) {
      onConfirm(state)
    }
  }

  return (
    <>
      <Typography fontSize="5">Datos de paciente</Typography>
      <Container border="0.5px solid" borderColor="gray.0" />
      <Typography color="primary.4" fontWeight="bold" fontSize="2">
        RUT/Pasaporte
      </Typography>
      <Input
        type="radio"
        value={documentType}
        onChange={(documentType) => setField(documentType, `documentType`)}
        options={[
          { label: `RUT`, value: `patientRut` },
          { label: `Pasaporte`, value: `patientDocument` },
        ]}
      />
      <Input
        placeholder="Escriba aquí"
        width="100%"
        fontSize="2"
        margin="0.5em 0"
        value={state[documentType]}
        name={documentType}
        onChange={(doc) =>
          setField(
            documentType === `patientRut` ? format(doc) : doc,
            documentType,
          )
        }
      />
      <Typography color="primary.4" fontWeight="bold" fontSize="2">
        Nombre
      </Typography>
      <Input
        placeholder="Escriba aquí"
        width="100%"
        fontSize="2"
        margin="0.5em 0"
        value={patientFirstName}
        name="patientFirstName"
        onChange={setField}
      />
      <Typography color="primary.4" fontWeight="bold" fontSize="2">
        Apellido
      </Typography>
      <Input
        placeholder="Escriba aquí"
        width="100%"
        fontSize="2"
        margin="0.5em 0"
        value={patientLastName}
        name="patientLastName"
        onChange={setField}
      />
      <Typography color="primary.4" fontWeight="bold" fontSize="2">
        Apellido Materno
      </Typography>
      <Input
        placeholder="Escriba aquí"
        width="100%"
        fontSize="2"
        margin="0.5em 0"
        value={patientMaternalLastName}
        name="patientMaternalLastName"
        onChange={setField}
      />
      <Typography color="primary.4" fontWeight="bold" fontSize="2">
        Correo
      </Typography>
      <Input
        placeholder="Escriba aquí"
        width="100%"
        fontSize="2"
        margin="0.5em 0"
        value={patientMail}
        name="patientMail"
        onChange={setField}
      />
      <Container border="0.5px solid" borderColor="gray.0" />
      <Container marginTop="2" alignItems="center" justifyContent="flex-end">
        <Button onClick={onClose}>
          <Typography textDecoration="underline">Cancelar</Typography>
        </Button>
        <Button
          onClick={confirm}
          marginLeft="2"
          borderRadius="3px"
          paddingY="1"
          paddingX="2"
          backgroundColor={canSubmit ? `primary.2` : `gray.2`}
        >
          Cambiar
        </Button>
      </Container>
    </>
  )
}

export default EditPatient
