import React, { useEffect } from 'react'
import notification from 'antd/es/notification'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { useStateWithMerge } from 'hooks'
import { Container, Typography, Button, Icon, Img } from 'components'
import createKeyboardConfig, {
  keyboardModes,
  callModesById,
} from 'config/keyboard'
import { useUnit } from 'context'
import Error from '../IdBeforeLines/Schedules/Error'
import Keyboard from '../Keyboard'
import ValidationCodeInput from './ValidationCodeInput'

const initialState = { blockGoBack: false, validationCode: `` }

function ValidationCode({ goBack, unit, flushInterval }) {
  const history = useHistory()
  const { logo } = useUnit()

  useEffect(() => {
    if (history?.location?.state == null) {
      goBack()
    }
  }, [])

  const {
    schedules = [],
    title,
    disableGoBack,
    patientData,
    error = null,
  } = history?.location?.state ?? {}

  const [state, setState] = useStateWithMerge(initialState)
  const { blockGoBack, validationCode } = state

  useEffect(() => {
    if (disableGoBack == true) {
      setState({ blockGoBack: true })
      setTimeout(() => flushInterval(), 500)
    }
  }, [disableGoBack])

  const goToLines = () =>
    history.push({ pathname: `/totem/${unit.id}/lines`, state: patientData })

  const handleConfirm = (schedule) => {
    if (schedule?.validationCodes?.includes(validationCode)) {
      history.push({
        pathname: `/totem/${unit.id}/print`,
        state: {
          ...schedule,
          ...patientData,
          integrationId: validationCode,
          label: schedule?.labelName,
          hasExpressPickUp: true,
        },
      })
    } else {
      notification.error({
        message: `Código inválido. Intente nuevamente.`,
        duration: 3,
      })
    }
  }

  //Manejo del teclado
  const handleButtonClick = (e) => {
    if (validationCode.length < 6) {
      const key = e.target.name
      setState({ validationCode: validationCode + key })

      e.target.blur()
    }
  }

  const handleDeleteInput = (e) => {
    if (validationCode.length > 0) {
      setState({ validationCode: validationCode.slice(0, -1) })
    }

    if (e) {
      e.target.blur()
    }
  }

  const { layoutConfig } = createKeyboardConfig(
    callModesById.byRipleyOrder[keyboardModes.NUMERIC_KEYBOARD],
    {
      handleButtonClick,
      handleDeleteInput,
    },
  )

  return (
    <Container
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Container
        width={{ _: `40em` }}
        justifyContent="space-around"
        flexWrap="no-wrap"
        alignItems="center"
        flexDirection="column"
        padding="3"
        position="relative"
      >
        <Container width="100%" flexDirection="row" justifyContent="flex-start">
          {blockGoBack == false ? (
            <Button
              backgroundColor="transparent"
              paddingY="2"
              paddingR="4"
              onClick={goBack}
              alignSelf="flex-start"
            >
              <Icon icon="arrowLeft" color="gray.3" fontSize="6" />
            </Button>
          ) : null}
        </Container>
        {error != null ? (
          <Error
            unitName={unit.name}
            goToLines={goToLines}
            {...error}
            country={unit.country}
          />
        ) : (
          <Container width="100%" justifyContent="center" alignItems="center">
            <Container
              width="100%"
              boxSizing="border-box"
              justifyContent="center"
              backgroundColor="transparent"
            >
              <Img src={logo} maxWidth="80%" marginBottom="16px" />
            </Container>
            <Typography
              fontSize="5"
              fontWeight={2}
              textAlign="center"
              lineHeight="1.2"
            >
              {title}
            </Typography>
            <Container
              justifyContent="center"
              width="100%"
              marginTop="2"
              marginBottom="4"
            >
              <Typography fontSize="4" textAlign="center" lineHeight="1.2">
                Ingrese el Código de Retiro recibido en su email
              </Typography>
            </Container>
            <ValidationCodeInput validationCode={validationCode} />
            <Container
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(30em, 1fr))"
              gridGap={3}
              width="100%"
              marginTop="4"
              marginBottom="2"
              maxHeight="60vh"
            >
              <Keyboard
                isQwerty={false}
                config={layoutConfig}
                columns={3}
                marginTop={{ _: 1, md: 0 }}
                marginLeft={{ _: 0 }}
                isMedium
              />
              {schedules?.map((schedule) => (
                <Button
                  key={schedule.id}
                  backgroundColor="primary.4"
                  color="white"
                  fontSize="5"
                  padding={3}
                  borderRadius="15px"
                  withShadow
                  hoverProps={{
                    backgroundColor: `primary.3`,
                  }}
                  onClick={() => handleConfirm(schedule)}
                  marginTop="3"
                >
                  <Typography color="white" fontSize="4" fontWeight="3">
                    Continuar
                  </Typography>
                  {schedule?.scheduledDate || schedule?.reservationDate ? (
                    <Typography
                      color="white"
                      fontWeight="0"
                      fontSize="3"
                      marginTop="1"
                    >
                      {moment(
                        schedule?.scheduledDate || schedule?.reservationDate,
                      ).format(`h:mm A`)}
                    </Typography>
                  ) : null}
                </Button>
              ))}
            </Container>
          </Container>
        )}
      </Container>
    </Container>
  )
}

export default ValidationCode
