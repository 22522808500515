import React, { useContext, useEffect } from 'react'
import API from 'config/api'
import moment from 'moment-timezone'
import notification from 'antd/es/notification'
import { useStateWithMerge, useIO } from 'hooks'
import { decomposeRut } from 'utils'
import { useAuth } from './AuthContext'

const admissionistEvents = {
  addAttention: `addAttention`,
  callAttention: `callAttention`,
  endAttention: `endAttention`,
  removeAttention: `removeAttention`,
}

const AttentionsContext = React.createContext()
//METODO QUE TOMA EL ESTADO ACTUAL DEL ADMISIONISTA
// Y CALCULA SI TIENE QUE MOVERSE DE FILA O NO
function getNextProfile({ lines, linesById, profile }) {
  const { linesConfig, currentLineId, currentAttentionCount } = profile
  let nextLineId = currentLineId
  const filteredLines = lines.filter((id) => {
    return linesConfig[id]
  })
  if (filteredLines.length > 0) {
    const currentLineIndex = filteredLines.indexOf(currentLineId)
    if (currentLineId == null || currentLineIndex < 0) {
      nextLineId = filteredLines[0]
    } else {
      const lineLimit = linesConfig[currentLineId]
      const shouldChangeLine =
        filteredLines.length > 1 &&
        (currentAttentionCount >= lineLimit ||
          linesById[currentLineId].attentions.length === 0)
      if (shouldChangeLine) {
        const orderedLines = [
          ...filteredLines.slice(currentLineIndex + 1),
          ...filteredLines.slice(0, currentLineIndex),
        ]
        orderedLines.some((lineId) => {
          if (linesById[lineId].attentions.length > 0) {
            nextLineId = lineId
            return true
          }
          return false
        })
      }
    }
    profile.currentLineId = nextLineId
    profile.currentAttentionCount =
      currentLineId != nextLineId ? 0 : currentAttentionCount
  }

  return profile
}
//SACAR CUANDO SE IMPLEMENTE ALGORITMO DE TIEMPO CONSISTENTE
const waitPerAttention = 5
function sortAttentions(attentionA, attentionB) {
  const {
    attentionPriority: attentionPriorityA,
    scheduledDate: scheduledDateA,
  } = attentionA
  const {
    attentionPriority: attentionPriorityB,
    scheduledDate: scheduledDateB,
  } = attentionB
  if (attentionPriorityA !== attentionPriorityB) {
    return attentionPriorityA - attentionPriorityB
  } else if (scheduledDateA != null && scheduledDateB != null) {
    return (
      moment(scheduledDateA).format(`X`) - moment(scheduledDateB).format(`X`)
    )
  } else {
    return (
      moment(attentionA.getDate).format(`X`) -
      moment(attentionB.getDate).format(`X`)
    )
  }
}
function getNextLinesById({ prevLinesById, lineId, attentionId, diff = 1 }) {
  const nextLinesById = { ...prevLinesById }
  const nextWaitingPeople = Math.max(
    nextLinesById[lineId].waitingPeople + diff,
    0,
  )
  let attentions = nextLinesById[lineId].attentions
  if (diff > 0) {
    attentions.push(attentionId)
  } else {
    attentions = attentions.filter((id) => id != attentionId)
  }
  nextLinesById[lineId] = {
    ...nextLinesById[lineId],
    attentions,
    waitingPeople: nextWaitingPeople,
    waitTime: nextWaitingPeople * waitPerAttention,
  }
  return nextLinesById
}
function handleAddAttention({
  prevState,
  setState,
  incomingAttention,
  availableAdmissionists,
  userId,
}) {
  const notPriority =
    availableAdmissionists != null &&
    availableAdmissionists.length > 0 &&
    availableAdmissionists[0] != userId
  if (notPriority) {
    const nextAvailableAdmissionists = availableAdmissionists.slice(1)
    setTimeout(() => {
      setState((prevState) => {
        if (prevState.pendingAttentions[incomingAttention.id]) {
          return incomingEventHandler({
            setState,
            prevState,
            incomingAttention,
            availableAdmissionists: nextAvailableAdmissionists,
            userId,
            event: admissionistEvents.addAttention,
          })
        }
        return prevState
      })
    }, 5000)
    const payload = {
      pendingAttentions: {
        ...prevState.pendingAttentions,
        [incomingAttention.id]: true,
      },
    }
    return payload
  }
  const { lines, linesById, attentions, attentionsById } = prevState
  if (!lines.includes(incomingAttention.lineId) || notPriority) {
    return prevState
  }
  const payload = {}
  payload.attentionsById = {
    ...attentionsById,
    [incomingAttention.id]: incomingAttention,
  }
  payload.attentions = [...attentions, incomingAttention.id].sort((idA, idB) =>
    sortAttentions(payload.attentionsById[idA], payload.attentionsById[idB]),
  )
  payload.linesById = getNextLinesById({
    prevLinesById: linesById,
    lineId: incomingAttention.lineId,
    attentionId: incomingAttention.id,
  })
  return payload
}

function handleCallAttention({
  prevState,
  incomingAttention,
  modules,
  userId,
  withCallByManyModules = false,
}) {
  const {
    lines,
    linesById,
    attentions,
    attentionsById,
    currentAttention,
    currentAttentionByModule,
  } = prevState

  const payload = {
    pendingAttentions: {
      ...prevState.pendingAttentions,
    },
    currentAttentionByTicket: {
      ...prevState.currentAttentionByTicket,
      [incomingAttention.ticketId]: incomingAttention.id,
    },
  }

  delete payload.pendingAttentions[incomingAttention.id]

  if (lines.includes(incomingAttention.lineId)) {
    payload.attentionsById = {
      ...attentionsById,
    }
    if (incomingAttention.attentionTypeId !== 9) {
      delete payload.attentionsById[incomingAttention.id]
      payload.attentions = attentions.filter(
        (attentionId) => attentionId != incomingAttention.id,
      )
      payload.linesById = getNextLinesById({
        prevLinesById: linesById,
        lineId: incomingAttention.lineId,
        attentionId: incomingAttention.id,
        diff: -1,
      })
    } else {
      payload.attentionsById[incomingAttention.id] = incomingAttention
    }
  }

  if (incomingAttention.userId === userId && currentAttention == null) {
    payload.currentAttention = incomingAttention
  }

  const currentAttentionToValidate = withCallByManyModules
    ? currentAttentionByModule[incomingAttention.moduleId]
    : currentAttention

  if (
    modules.includes(incomingAttention.moduleId) &&
    currentAttentionToValidate == null
  ) {
    payload.currentAttentionByModule = {
      ...currentAttentionByModule,
      [incomingAttention.moduleId]: incomingAttention,
    }
  }

  return payload
}

function handleEndAttention({ prevState, incomingAttention, modules }) {
  const {
    currentAttention,
    currentAttentionByModule,
    attentionsById,
    currentAttentionByTicket,
  } = prevState
  const { id, moduleId, ticketId, attentionTypeId, endDate } = incomingAttention
  const payload = { currentAttentionByTicket: { ...currentAttentionByTicket } }
  if (currentAttention?.id == id) {
    payload.currentAttention = null
  }
  if (modules.includes(moduleId)) {
    payload.currentAttentionByModule = {
      ...currentAttentionByModule,
    }
    delete payload.currentAttentionByModule[moduleId]
  }

  if (attentionsById[id]?.accessType === 6) {
    payload.attentionsById = {
      ...attentionsById,
    }
    payload.attentionsById[id] = {
      availableAdmissionists: null,
      ...payload.attentionsById[id],
      attentionTypeId,
      endDate,
    }
  }
  delete payload.currentAttentionByTicket[ticketId]
  return payload
}
function handleRemoveAttention({ prevState, incomingAttention }) {
  const { attentionsById, attentions, linesById } = prevState
  const { lineId, id } = attentionsById[incomingAttention.id] ?? {}
  if (lineId == null) {
    return prevState
  }
  const payload = {}
  payload.attentionsById = {
    ...attentionsById,
  }

  delete attentionsById[incomingAttention.id]
  payload.attentions = attentions.filter(
    (attentionId) => attentionId != incomingAttention.id,
  )
  payload.linesById = getNextLinesById({
    prevLinesById: linesById,
    lineId,
    attentionId: id,
    diff: -1,
  })

  return payload
}

const emitPayload = `admissionist`
const subscribeEvent = `attentionChanges`
const secondsToReconnect = 8

const handlerByEvent = {
  [admissionistEvents.addAttention]: handleAddAttention,
  [admissionistEvents.callAttention]: handleCallAttention,
  [admissionistEvents.endAttention]: handleEndAttention,
  [admissionistEvents.removeAttention]: handleRemoveAttention,
}

function incomingEventHandler({
  event,
  incomingAttention,
  availableAdmissionists,
  prevState,
  setState,
  userId,
  modules,
  withCallByManyModules = false,
}) {
  const handler = handlerByEvent[event]
  if (!handler) {
    return prevState
  }
  const nextState = handler({
    prevState,
    setState,
    userId,
    modules,
    incomingAttention,
    withCallByManyModules,
    availableAdmissionists,
  })
  if (prevState.profile != null) {
    const profile = {
      ...prevState.profile,
    }
    if (event === admissionistEvents.callAttention) {
      profile.currentAttentionCount += 1
    }
    nextState.profile = getNextProfile({
      lines: prevState.lines,
      linesById: nextState.linesById || prevState.linesById,
      profile,
    })
  }
  return nextState
}
const getInitialState = ({ modules = [], modulesById = {} }) => ({
  lines: [],
  linesById: {},
  userLines: [],
  linesByModule: {},
  currentLineId: 0,
  attentions: [],
  attentionsById: {},
  currentAttention: null,
  currentAttentionByModule: {},
  currentAttentionByTicket: {},
  pendingAttentions: {},
  //TODO: Move modules and modulesById fetching to attentionContext
  modules,
  modulesById,
  activeModuleId: modules.length > 0 ? modules[0] : null,
  motiveId: null,
  motives: [],
  motivesById: {},
  auxiliaryLines: {
    units: [],
    currentUnitLines: [],
  },
  auxiliaryModules: [],
  requestInQueue: null,
  stockId: null,
  profile: null,
})
function AttentionsProvider({ children }) {
  const {
    user: { id: userId },
    modules,
    modulesById,
    currentUnit,
  } = useAuth()
  const [state, setState] = useStateWithMerge(
    getInitialState({ modules, modulesById }),
  )
  const { currentAttention, attentionsById, attentions, motives, motivesById } =
    state
  const [rut, dv] = decomposeRut(currentAttention?.patientRut)
  async function getCurrentAttentionStock() {
    try {
      const { stock } = await API.getStock({
        rut,
        dv,
        document: null,
        unitId: currentUnit?.id,
        isAvailable: true,
      })
      if (stock != null) {
        setStockId(stock.id)
      }
    } catch (e) {
      return e
    }
  }
  async function getMotives({ unitId, lineId }) {
    try {
      const { motives, motivesById } = await API.getMotives({ unitId, lineId })
      setState({ motives, motivesById })
    } catch (error) {
      notification.error({ message: `No se pudo cargar motivos de atención` })
    }
  }
  useEffect(() => {
    if (currentUnit?.hasStock && currentAttention?.stockId == null) {
      getCurrentAttentionStock()
    }
    if (currentAttention?.lineId && currentUnit?.id) {
      getMotives({
        unitId: currentUnit.id,
        lineId: currentAttention.lineId,
      })
    } else {
      const cleanedMotivesById = { ...motivesById }
      const cleanedMotives = motives.filter((motiveId) => {
        const motive = motivesById[motiveId]
        if (motive.lineId) {
          delete cleanedMotivesById[motive.id]
          return false
        }
        return true
      })
      setState({
        motives: cleanedMotives,
        motivesById: cleanedMotivesById,
        motiveId: null,
      })
    }
  }, [currentAttention])
  function changeCurrentLine(currentLineId) {
    return setState({ currentLineId })
  }
  function mapInitialData(data) {
    return setState((prevState) => {
      if (data.profile != null) {
        if (
          prevState.profile == null ||
          prevState.profile.id != data.profile.id
        ) {
          data.profile = getNextProfile({
            lines: data.lines,
            linesById: data.linesById,
            profile: data.profile,
          })
        } else {
          data.profile = {
            ...prevState.profile,
            name: data.profile.name,
            linesConfig: data.profile.linesConfig,
          }
        }
      }
      return data
    })
  }
  function setCurrentAttention({ currentAttention = null, moduleId }) {
    return setState((prevState) => {
      const currentAttentionByModule = {
        ...prevState.currentAttentionByModule,
        [moduleId]: currentAttention,
      }
      return {
        currentAttention,
        currentAttentionByModule,
      }
    })
  }
  function setActiveModuleId(activeModuleId) {
    setState({ activeModuleId })
  }
  function setRequestInQueue(requestInQueue) {
    return setState({
      requestInQueue,
    })
  }
  function setStockId(stockId) {
    setState({ stockId })
  }
  function setMotiveId(motiveId) {
    setState({ motiveId })
  }
  function filterAttentionsByProfessional(value) {
    if (!value) {
      return
    }
    const newAttentions = attentions.filter((attentionId) => {
      const attention = attentionsById[attentionId]
      if (
        attention.professionalName &&
        attention.professionalName.includes(value)
      ) {
        return true
      }
      return false
    })
    setState({ attentions: newAttentions })
  }
  async function onReconnect() {
    try {
      const userLines = await API.getUserLines({
        admissionistId: userId,
        modules,
      })
      setState(userLines)
      notification.success({ message: `Conexión reestablecida` })
    } catch (e) {
      const error =
        typeof e === `string` ? e : `No se ha podido reestablecer la conexión`
      notification.error({ message: error })
    }
  }
  useIO({
    emitPayload,
    subscribeEvent,
    subscribeEventHandler: ({
      event,
      incomingAttention,
      withCallByManyModules,
      availableAdmissionists,
    }) => {
      setState((prevState) =>
        incomingEventHandler({
          event,
          incomingAttention,
          availableAdmissionists,
          prevState,
          userId,
          modules,
          setState,
          withCallByManyModules,
        }),
      )
    },
    onReconnectHandler: (event) => {
      if (event !== `io client disconnect`) {
        notification.error({ message: `Se ha perdido la conexión` })
        setTimeout(() => {
          onReconnect()
        }, secondsToReconnect * 1000)
      }
    },
  })
  const currentLineTypeId =
    state.linesById[state.currentLineId] != null
      ? state.linesById[state.currentLineId].lineTypeId
      : 1
  const value = {
    ...state,
    currentLineTypeId,
    changeCurrentLine,
    mapInitialData,
    setCurrentAttention,
    setRequestInQueue,
    setActiveModuleId,
    setStockId,
    setMotiveId,
    filterAttentionsByProfessional,
  }
  return (
    <AttentionsContext.Provider value={value}>
      {children}
    </AttentionsContext.Provider>
  )
}

function useAttentions() {
  const context = useContext(AttentionsContext)
  if (!context) {
    throw new Error(`useAttentions must be used within a AttentionsProvider`)
  }
  return context
}

export { useAttentions, AttentionsProvider }
