import React, { useEffect } from 'react'
import Modal from 'antd/es/modal'
import API from 'config/api'
import { LOGO_KEIRON } from 'assets'
import { Container, Input, Button, Img, Typography } from 'components'
import { useStateWithMerge } from 'hooks'
import { useAuth, useUnit } from 'context'

function getInitialState(units) {
  return {
    email: ``,
    password: ``,
    error: null,
    modules: [],
    selectedModules: [],
    hasForceDefaultIp: false,
    unit: units[0],
  }
}

function forceKick(userToKick, onKick, onAbort) {
  Modal.confirm({
    title: `Hay un usuario atendiendo en este modulo.`,
    content: `Si desea continuar con el inicio de sesión, se le cerrara la sesión a dicho usuario y se dará por finalizada la atención, ¿Desea continuar?`,
    okText: `Sí.`,
    okType: `danger`,
    cancelText: `No.`,
    onOk: onKick,
    onCancel: onAbort,
  })
}

// eslint-disable-next-line max-lines-per-function
const Login = () => {
  const { logIn } = useAuth()
  const { units, unitsById } = useUnit()
  const [state, setState] = useStateWithMerge(getInitialState(units))
  const {
    email,
    password,
    modules,
    selectedModules,
    unit,
    error,
    hasForceDefaultIp,
  } = state
  useEffect(() => {
    async function getModules() {
      try {
        const { modules, preselectedModule, hasForceDefaultIp } =
          await API.getModules(unit)
        setState({
          modules,
          selectedModules:
            preselectedModule != null ? [preselectedModule.id] : [],
          hasForceDefaultIp,
        })
      } catch (e) {
        setState({
          error: `No se pudo cargar los modulos, recargue la pagina.`,
        })
      }
    }
    if (unit != null) {
      getModules()
    }
  }, [unit])

  function setField(value, field) {
    setState({ [field]: value })
  }
  async function handleLogin() {
    try {
      const { userToKick = null, ...payload } = await API.login({
        unitId: unit,
        email,
        password,
        modules: selectedModules,
      })

      if (userToKick == null) {
        return logIn(payload)
      } else {
        return forceKick(
          userToKick,
          async () => {
            const payload = await API.login({
              unitId: unit,
              email,
              password,
              modules: selectedModules,
              forceLogin: true,
            })

            return logIn(payload)
          },
          () =>
            setState({
              error: `Modulo atendiendo`,
            }),
        )
      }
    } catch (e) {
      const error =
        typeof e == `string` ? e : `Ha ocurrido un error inesperado, reintente.`
      return setState({
        error,
      })
    }
  }

  const canSubmit =
    unit != null && selectedModules.length > 0 && email && password
  return (
    <Container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      background="1"
    >
      <Img src={LOGO_KEIRON} alt="logo saltala" width="25%" />
      <Typography color="primary.3" fontSize="3" margin="2">
        Ingresa tus datos para iniciar sesión
      </Typography>
      <Input
        showArrow
        width="33%"
        color="primary.3"
        backgroundColor="transparent"
        border="1px solid"
        borderColor="primary.3"
        margin="2"
        placeholder="Unidad"
        label="Seleccione unidad"
        type="select"
        name="unit"
        onChange={setField}
        value={unit}
        options={units.map((unitId) => ({
          label: unitsById[unitId].name,
          value: unitId,
        }))}
      />
      <Input
        showArrow
        mode="multiple"
        width="33%"
        color="primary.3"
        backgroundColor="transparent"
        border="1px solid"
        borderColor="primary.3"
        margin="2"
        placeholder="Modulo"
        label="Seleccione modulo"
        type="select"
        name="selectedModules"
        onChange={setField}
        disabled={hasForceDefaultIp}
        value={selectedModules}
        options={modules.map((module) => ({
          label:
            module.namingConfig?.admissionistPanel?.text ??
            `${module.description} ${module.identifier}`,
          value: module.id,
        }))}
      />
      <Input
        width="33%"
        color="primary.3"
        backgroundColor="transparent"
        border="1px solid"
        borderColor="primary.3"
        margin="2"
        padding="2"
        type="text"
        name="email"
        placeholder="Usuario"
        label="Ingrese su correo"
        value={email}
        onChange={setField}
      />
      <Input
        width="33%"
        color="primary.3"
        backgroundColor="transparent"
        margin="2"
        padding="2"
        border="1px solid"
        borderColor="primary.3"
        type="password"
        name="password"
        placeholder="Contraseña"
        label="Ingrese su contraseña"
        value={password}
        onChange={setField}
      />
      <Button
        onClick={handleLogin}
        width="33%"
        backgroundColor={canSubmit ? `primary.3` : `gray.2`}
        color="white"
        padding="3"
        fontSize="3"
        disabled={!canSubmit}
      >
        Iniciar sesión
      </Button>
      {error && (
        <Typography color="error" marginTop="2" fontSize="2">
          {error}
        </Typography>
      )}
    </Container>
  )
}

export default Login
