import React, { useEffect } from 'react'
import { Button, Typography, Container, MaterialIcon } from 'components'
import { useStateWithMerge } from 'hooks'

function IconContainer({ icon }) {
  const containerProps = {
    borderRadius: `50%`,
    width: `35px`,
    height: `35px`,
    backgroundColor: `#1E5E97`,
    marginX: `1`,
    justifyContent: `center`,
    alignItems: `center`,
  }

  return (
    <Container {...containerProps}>
      <MaterialIcon icon={icon} fontSize={icon == `baby` ? 3 : 4} />
    </Container>
  )
}

function IconList({ icons }) {
  const [state, setState] = useStateWithMerge({
    disabled: false,
    pregnant: false,
    children: false,
    elder: false,
  })
  const { disabled, pregnant, children, elder } = state

  useEffect(() => {
    setState({ ...icons })
  }, [])

  return (
    <Container width="100%" justifyContent="center">
      {disabled == true ? <IconContainer icon="accessible" /> : null}
      {pregnant == true ? <IconContainer icon="pregnantWoman" /> : null}
      {children == true ? <IconContainer icon="baby" /> : null}
      {elder == true ? <IconContainer icon="elderly" /> : null}
    </Container>
  )
}

function LineButton({ children, icons, ...options }) {
  const styleProps = {
    width: `100%`,
    backgroundColor: `primary.4`,
    color: `white`,
    fontSize: `5`,
    padding: 3,
    borderRadius: `15px`,
    minHeight: { _: `8vh`, md: `10vh` },
    withShadow: true,
    maxWidth: `100%`,
    height: `fit-content`,
    hoverProps: {
      backgroundColor: `primary.3`,
      withShadow: false,
    },
  }

  return (
    <Button {...styleProps} {...options}>
      <Typography
        textOverflow="ellipsis"
        color="white"
        overflow="hidden"
        fontSize={5}
        maxHeight="auto"
        textAlign="center"
        maxWidth="100%"
      >
        {children}
      </Typography>
      {icons != null ? (
        <Container width="100%" justifyContent="center">
          <IconList icons={icons} />
        </Container>
      ) : null}
    </Button>
  )
}

export default LineButton
