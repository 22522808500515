/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import API from 'config/api'
import { useStateWithMerge } from 'hooks'
import { CONFIG_TV_KEY } from 'config/constants'
import { Container, Typography, Input, Img, Button, Icon } from 'components'
import { LOGO_KEIRON } from 'assets'
import notification from 'antd/lib/notification'
import { useUnit } from 'context'

const getInitialState = () => ({
  lineOptions: [],
  config: JSON.parse(localStorage.getItem(CONFIG_TV_KEY)) || null,
  isLoading: false,
})
function Config() {
  const [state, setState] = useStateWithMerge(getInitialState())
  const { config, lineOptions, isLoading } = state
  const { localActiveUnits, unitsById } = useUnit()
  const isMultiLines = unitsById[localActiveUnits[0]].monitorId == 4

  useEffect(() => {
    async function getLines() {
      setState({ isLoading: true })
      try {
        let { lines } = await API.getTotemLines({
          returnUnfiltered: true,
          unitId: localActiveUnits[0],
          showAllUnits: true,
        })
        const lineIds = []
        lines = lines.map((line) => {
          lineIds.push(line.id)
          return { value: line.id, label: line.name }
        })
        let config = state.config
        if (!config) {
          config = {
            totalAttentions: 9,
            groups: [{ lines: lineIds, quantity: 9 }],
            noSound: false,
          }
          localStorage.setItem(CONFIG_TV_KEY, JSON.stringify(config))
        }
        setState({ lineOptions: lines, config })
      } catch (e) {
        const error =
          typeof e === `string` ? e : `Ha ocurrido un problema, recargue`
        notification.error({ message: error })
      } finally {
        setState({ isLoading: false })
      }
    }
    getLines()
  }, [])
  function handleChange(value, name) {
    setState({
      config: {
        ...config,
        [name]: value,
      },
    })
  }
  function handleGroupChange({ value, name, index }) {
    const newConfig = { ...config }
    const groupToChange = newConfig.groups[index]
    groupToChange[name] = value
    setState({ config })
  }
  function deleteGroup(index) {
    let newConfig = { ...config }
    const groups = newConfig.groups.filter((_, i) => index !== i)
    newConfig = {
      ...newConfig,
      groups,
    }
    setState({ config: newConfig })
  }
  function addGroup() {
    const newGroup = { lines: [], quantity: 0 }
    setState({
      config: {
        ...config,
        groups: [...config.groups, newGroup],
      },
    })
  }
  function saveConfig() {
    localStorage.setItem(CONFIG_TV_KEY, JSON.stringify(state.config))
    notification.success({ message: `Configuracion guardada` })
  }
  if (!config || isLoading) {
    return `Cargando...`
  }
  const hasManyGroups = config.groups.length > 1
  return (
    <Container
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Container
        maxHeight="80vh"
        height="90%"
        padding="5%"
        width="80%"
        overflowY="auto"
      >
        <Container
          width="100%"
          height="30%"
          marginBottom="5%"
          justifyContent="center"
          alignItems="center"
        >
          <Img src={LOGO_KEIRON} />
        </Container>
        <Input
          label="TV Sin Sonido:"
          type="checkbox"
          width="100%"
          name="noSound"
          value={config.noSound}
          onChange={handleChange}
          marginBottom="3"
        />
        {!isMultiLines ? (
          <Input
            label="Cantidad total de atenciones en panel:"
            type="number"
            width="100%"
            min={0}
            max={9}
            name="totalAttentions"
            value={config.totalAttentions}
            onChange={handleChange}
            marginBottom="3"
          />
        ) : null}
        {config.groups.map((group, index, groups) => {
          const generalQuantity = groups.reduce(
            (acc, current) => acc + current.quantity,
            0,
          )
          const maxQuantity =
            config.totalAttentions - generalQuantity + group.quantity
          return (
            <Container
              position="relative"
              width="100%"
              justifyContent="space-between"
              key={index}
            >
              {hasManyGroups ? (
                <Container width="100%" marginBottom="2">
                  <Typography>Grupo {index + 1}</Typography>
                  {index > 0 ? (
                    <Button
                      onClick={() => deleteGroup(index)}
                      backgroundColor="transparent"
                      color="error"
                    >
                      <Icon icon="trash" />
                      Eliminar grupo
                    </Button>
                  ) : null}
                </Container>
              ) : null}
              <Input
                width="45%"
                onChange={(value, name) =>
                  handleGroupChange({ value, name, index })
                }
                type="text"
                label="Nombre:"
                name="name"
                value={group.name}
              />

              <Input
                width="45%"
                onChange={(value, name) =>
                  handleGroupChange({ value, name, index })
                }
                type="text"
                label="Descripción:"
                name="description"
                value={group.description}
              />
              <Input
                width="50%"
                onChange={(value, name) =>
                  handleGroupChange({ value, name, index })
                }
                label="Filas:"
                type="select"
                mode="multiple"
                name="lines"
                value={group.lines}
                options={lineOptions}
              />
              <Input
                max={!isMultiLines ? maxQuantity : 6}
                min={0}
                width="45%"
                onChange={(value, name) =>
                  handleGroupChange({ value, name, index })
                }
                type="number"
                label="Cantidad:"
                name="quantity"
                value={group.quantity}
              />
              {hasManyGroups && (
                <Container
                  borderBottom="1px solid"
                  borderColor="gray.0"
                  width="100%"
                  marginY="2"
                />
              )}
            </Container>
          )
        })}
        <Button
          marginTop="2"
          backgroundColor={
            isMultiLines && config.groups.length == 4 ? `gray.1` : `primary.2`
          }
          padding="2"
          onClick={addGroup}
          disabled={isMultiLines && config.groups.length == 4}
        >
          <Icon marginRight="1" icon="plusCircle" />
          Agregar grupo
        </Button>
      </Container>
      <Button
        width="60%"
        padding="3"
        backgroundColor="primary.3"
        fontSize="4"
        onClick={saveConfig}
      >
        <Icon icon="checkCircle" marginRight="2" />
        Guardar
      </Button>
    </Container>
  )
}

export default Config
