import React from 'react'
import API from 'config/api'
import { Button } from 'components'
import { notification } from 'antd'

function CallButtonNormal({
  userId,
  canCall,
  nextAttentionId,
  activeModuleId,
  setIsCalling,
}) {
  async function callAttention() {
    try {
      await API.callAttention({
        userId,
        moduleId: activeModuleId,
        attentionId: nextAttentionId,
      })
      setIsCalling(true)
      setTimeout(() => setIsCalling(false), 5000)
    } catch (e) {
      let error = `No se ha podido realizar el llamado, reintente.`
      if (e && e.error && typeof e.error === `string`) {
        error = e.error
      }
      return notification.error({ message: error, duration: 30 })
    }
  }
  return (
    <Button
      flex={3}
      marginLeft="0.5em"
      backgroundColor={canCall ? `primary.8` : `gray.0`}
      padding="0.5em"
      position="relative"
      fontSize="4"
      color={canCall ? `white` : `gray.3`}
      disabled={!canCall}
      onClick={callAttention}
    >
      Llamar
    </Button>
  )
}

export default CallButtonNormal
