const axios = require(`axios`)

function initAxiosInstance(baseURL, responseKey = `data`) {
  const axiosInstance = axios.create({
    baseURL,
    validateStatus() {
      return true
    },
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      const {
        data: { [responseKey]: data, error, success },
      } = response
      if (success) {
        return data
      } else {
        return Promise.reject(error)
      }
    },
    (error) => {
      return Promise.reject(error)
    },
  )
  return axiosInstance
}

module.exports = { initAxiosInstance }
