import API from 'config/api'
import { useEffect } from 'react'

const intervalTimeInMinutes = 2

function useStatsPing({ category }) {
  async function storeCategoryStats() {
    try {
      await API.storeCategoryStats({ category })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }
  useEffect(() => {
    const intervalRef = setInterval(
      storeCategoryStats,
      60000 * intervalTimeInMinutes,
    )

    storeCategoryStats()

    return () => clearInterval(intervalRef)
  }, [])
}

export default useStatsPing
