import axios from 'axios'
import {
  AUTOPAGO_RECORD_ID_KEY,
  GET_IP_URL,
  TOTEM_IP_KEY,
} from 'config/constants'
import { useStateWithMerge } from 'hooks'
import React, { useContext, useEffect, createContext } from 'react'

const AutopagoContext = createContext()

const getIpTotem = async (handleSetTotemIp) => {
  try {
    const {
      data: { ip },
    } = await axios.get(GET_IP_URL)
    handleSetTotemIp(ip)
  } catch {
    // Si no es posible conseguir la IP, se seguira el flujo correctamente
  }
}
function AutopagoProvider({ children, isAutopago = false }) {
  const initialState = {
    autopagoRecordId:
      parseInt(localStorage.getItem(AUTOPAGO_RECORD_ID_KEY), 10) || undefined,
    totemIp: localStorage.getItem(TOTEM_IP_KEY),
  }
  const [state, setState] = useStateWithMerge(initialState)
  const { totemIp, autopagoRecordId } = state

  const handleSetAutopagoRecordId = (id) => {
    if (id) {
      localStorage.setItem(AUTOPAGO_RECORD_ID_KEY, id)
      setState({ autopagoRecordId: id })
    } else {
      localStorage.removeItem(AUTOPAGO_RECORD_ID_KEY)
      setState({ autopagoRecordId: undefined })
    }
  }

  const handleSetTotemIp = (ip) => {
    localStorage.setItem(TOTEM_IP_KEY, ip)
    setState({ totemIp: ip })
  }

  const autopagoProviderValue = {
    autopagoRecordId,
    totemIp,
    handleSetAutopagoRecordId,
  }

  const value = isAutopago ? autopagoProviderValue : {}

  useEffect(() => {
    if (!totemIp) {
      getIpTotem(handleSetTotemIp)
    }
  }, [])

  return (
    <AutopagoContext.Provider value={value}>
      {children}
    </AutopagoContext.Provider>
  )
}

function useAutopago() {
  const context = useContext(AutopagoContext)
  if (!context) {
    throw new Error(`useAutopago must be used within a AutopagoProvider`)
  }
  return context
}

export { AutopagoProvider, useAutopago }
