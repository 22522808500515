import React, { useReducer, useContext, useEffect } from 'react'
import API from 'config/api'
import { ADMISSIONIST_TOKEN_KEY } from 'config/constants'
import { Container, Icon } from 'components'
import { useUnit } from './UnitContext'

const AuthContext = React.createContext()
const initialState = {
  user: null,
  currentActivityType: null,
  activityTypes: [],
  activityTypesById: {},
  //TODO: Move modules and modulesById fetching to attentionContext
  modules: [],
  modulesById: {},
  isLoading: true,
}

const actionTypes = {
  LOG_IN: `LOG_IN`,
  LOG_OUT: `LOG_OUT`,
  SET_ACTIVITY_TYPE_DATA: `SET_ACTIVITY_TYPE_DATA`,
  CHANGE_ACTIVITY_TYPE: `CHANGE_ACTIVITY_TYPE`,
}
function reducer(state, action) {
  switch (action.type) {
    case actionTypes.LOG_IN: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    }
    case actionTypes.LOG_OUT: {
      return {
        ...state,
        user: null,
        isLoading: false,
      }
    }
    case actionTypes.SET_ACTIVITY_TYPE_DATA: {
      return {
        ...state,
        ...action.data,
      }
    }
    case actionTypes.CHANGE_ACTIVITY_TYPE: {
      return {
        ...state,
        currentActivityType: action.currentActivityType,
      }
    }

    default: {
      return state
    }
  }
}
function AuthProvider({ children }) {
  const { unitsById } = useUnit()
  const [{ isLoading, ...state }, dispatch] = useReducer(reducer, initialState)
  async function getUserData() {
    try {
      if (!localStorage.getItem(ADMISSIONIST_TOKEN_KEY)) {
        throw new Error(`No se encontró token`)
      }
      const payload = await API.getUserData()
      dispatch({
        type: actionTypes.LOG_IN,
        payload,
      })
    } catch (error) {
      dispatch({ type: actionTypes.LOG_OUT })
      localStorage.removeItem(ADMISSIONIST_TOKEN_KEY)
    }
  }
  useEffect(() => {
    getUserData()
  }, [])

  function logIn({ token }) {
    localStorage.setItem(ADMISSIONIST_TOKEN_KEY, `Bearer ${token}`)
    return getUserData()
  }
  function logOut() {
    localStorage.removeItem(ADMISSIONIST_TOKEN_KEY)
    return dispatch({ type: actionTypes.LOG_OUT })
  }
  function setActivityTypes(data) {
    return dispatch({ type: actionTypes.SET_ACTIVITY_TYPE_DATA, data })
  }
  function changeCurrentActivityType(currentActivityType) {
    return dispatch({
      type: actionTypes.CHANGE_ACTIVITY_TYPE,
      currentActivityType,
    })
  }
  const isUserAvailable =
    state.currentActivityType != null &&
    state.activityTypes.length > 0 &&
    state.activityTypesById?.[state.currentActivityType]?.available

  const currentUnit = unitsById?.[state.user?.unitId]
  const value = {
    ...state,
    currentUnit,
    isUserAvailable,
    logIn,
    logOut,
    setActivityTypes,
    changeCurrentActivityType,
  }
  return (
    <AuthContext.Provider value={value}>
      {isLoading ? (
        <Container
          width="100vw"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Icon icon="spinner" spin fontSize="7" />
        </Container>
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
