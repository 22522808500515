import { useState, useEffect, useRef } from 'react'
import { INACTIVITY_TIMEOUT_IN_SECONDS } from 'config/constants'
import useIsMounted from './useIsMounted'
const defaultHandler = () =>
  // eslint-disable-next-line no-console
  console.error(`NO HA SIDO ESPECIFICADO UNA FUNCION PARA EJECUTARSE`)

function useInactivityHandler({
  timeout = INACTIVITY_TIMEOUT_IN_SECONDS,
  inactivityHandler = defaultHandler,
  resetDependencies = [],
}) {
  const [_, setElapsedTime] = useState(0)
  const timeInterval = useRef(null)
  const isMounted = useIsMounted()

  function resetTimer() {
    setElapsedTime(0)
  }

  function flushInterval() {
    clearInterval(timeInterval.current)
  }
  function mountListeners() {
    resetTimer()
    window.addEventListener(`click`, resetTimer)
    window.addEventListener(`mousemove`, resetTimer)
    window.addEventListener(`keyup`, resetTimer)
  }

  function unmountListeners() {
    window.removeEventListener(`click`, resetTimer)
    window.removeEventListener(`mousemove`, resetTimer)
    window.removeEventListener(`keyup`, resetTimer)
  }

  useEffect(() => {
    mountListeners()
    timeInterval.current = setInterval(() => {
      setElapsedTime((elapsedTime) => {
        if (elapsedTime >= timeout) {
          inactivityHandler?.()
        }
        if (isMounted.current) {
          return elapsedTime + 1
        }
      })
    }, 1000)
    return () => {
      flushInterval()
      unmountListeners()
    }
  }, resetDependencies)
  return flushInterval
}

export default useInactivityHandler
