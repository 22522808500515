import React, { useState } from 'react'
import styled from 'styled-components'
import {
  compose,
  space,
  color,
  layout,
  border,
  position,
  typography,
  flexbox,
  grid,
} from 'styled-system'
import { backgroundGradient, colorsWithOpacity } from 'utils'
import Icon from './Icon'

const ButtonWrapper = styled.button`
  transition: color 400ms ease;
  transition: background-color 400ms ease;
  ${compose(
    color,
    space,
    border,
    layout,
    position,
    flexbox,
    typography,
    backgroundGradient,
    grid,
    colorsWithOpacity,
  )}
  ${({ withShadow = false, disabled = false, shadow = `` }) => `
  ${withShadow ? `box-shadow: 0px 5px 8px 1px rgba(0,0,0,0.3) !important;` : ``}
  ${disabled ? `cursor:not-allowed` : `  cursor: pointer`};
  ${
    shadow?.length > 0
      ? `-webkit-box-shadow: ${shadow}; box-shadow: ${shadow};`
      : ``
  }
  `}
  &:hover {
    transition: color 400ms ease;
    transition: background-color 400ms ease;
    ${({ hoverProps, ...props }) =>
      compose(
        layout,
        space,
        flexbox,
        border,
        colorsWithOpacity,
      )({ ...props, ...hoverProps })}
  }
`

ButtonWrapper.defaultProps = {
  color: `white`,
  border: `none`,
  maxWidth: `100%`,
}

const Button = ({
  children,
  loaderSize = `4`,
  loaderColor = `white`,
  disabled,
  onClick = () => null,
  ...props
}) => {
  const [isLoading, setLoading] = useState(false)
  function handleClick(e) {
    if (!disabled && !isLoading) {
      const handlerResponse = onClick(e)
      if (handlerResponse?.then != null) {
        setLoading(true)
        handlerResponse
          .then(() => setLoading(false))
          .catch(() => setLoading(false))
      }
    }
  }
  const aditionalProps = isLoading
    ? { justifyContent: `center`, flexDirection: `row`, alignItems: `center` }
    : {}
  return (
    <ButtonWrapper
      {...props}
      {...aditionalProps}
      disabled={disabled || isLoading}
      variant={disabled ? `disabled` : null}
      onClick={handleClick}
    >
      {isLoading ? (
        <Icon icon="spinner" spin color={loaderColor} fontSize={loaderSize} />
      ) : (
        children
      )}
    </ButtonWrapper>
  )
}

export default Button
