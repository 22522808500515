import { useEffect, useRef } from 'react'
import moment from 'moment-timezone'

function usePolling({
  pollingFn,
  pollingTimeInSeconds = 60,
  dependencies = [],
  active = true,
}) {
  const interval = useRef(null)
  const lastPoll = useRef(null)
  function cancelPolling() {
    clearInterval(interval.current)
  }

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current)
    }
    if (active) {
      pollingFn()
      lastPoll.current = moment()
      interval.current = setInterval(() => {
        pollingFn(lastPoll.current)
        lastPoll.current = moment()
      }, pollingTimeInSeconds * 1000)
    }
    return () => clearInterval(interval.current)
  }, dependencies)
  return cancelPolling
}

export default usePolling
