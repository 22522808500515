/* eslint-disable no-plusplus */
import React, { useEffect } from 'react'
import { Container, Typography, Img } from 'components'
import { calcFontSize, getEmptyAttention } from 'utils'
import { useStateWithMerge } from 'hooks'

function getFilledAttentions({ attentions, attentionsById, config }) {
  const filledAttentions = []
  if (config) {
    const { groups, totalAttentions } = config
    const attentionsToShow = []
    groups.forEach((group) => {
      const filteredAttentions = attentions.filter((attentionId) =>
        group.lines.includes(attentionsById[attentionId].lineId),
      )
      for (let i = 0; i < group.quantity; i++) {
        const attentionId = filteredAttentions[i]
        if (attentionId) {
          attentionsToShow.push(attentionId)
        }
      }
    })
    for (let i = 0; i < totalAttentions; i++) {
      const attentionId = attentionsToShow[i]
      filledAttentions.push(
        attentionId != null
          ? attentionsById[attentionId]
          : getEmptyAttention(i),
      )
    }
  } else {
    for (let i = 0; i < 7; i++) {
      const attentionId = attentions[i]
      filledAttentions.push(
        attentionId != null
          ? attentionsById[attentionId]
          : getEmptyAttention(i),
      )
    }
  }
  return filledAttentions
}

function LastTicketsPanel({
  lastCalledAttentions,
  attentionsById,
  width,
  config,
  isNextCallsMode,
  xAxisUnit,
  yAxisUnit,
  isVertical,
}) {
  const filledAttentions = getFilledAttentions({
    attentions: lastCalledAttentions,
    attentionsById,
    config,
  })

  return (
    <Container
      width={{ md: width, _: `100%` }}
      justifyContent="center"
      zIndex="-1"
      height={`100${yAxisUnit}`}
      minWidth="600px"
      paddingX={isVertical ? `2.5${xAxisUnit}` : `1${xAxisUnit}`}
      paddingY={`1${xAxisUnit}`}
    >
      <Container
        height="16%"
        backgroundColor="primary.4"
        width="100%"
        justifyContent="center"
        alignItems="center"
        borderTopRightRadius={`1${xAxisUnit}`}
        borderTopLeftRadius={`1${xAxisUnit}`}
      >
        <Typography
          color="white"
          fontSize={`2.5${xAxisUnit}`}
          fontWeight="bold"
          paddingTop={`0.7${xAxisUnit}`}
        >
          {isNextCallsMode ? `PRÓXIMOS` : `ÚLTIMOS`} LLAMADOS
        </Typography>
        <Container width="100%" paddingBottom={`0.3${xAxisUnit}`}>
          <Typography
            width="50%"
            fontSize={`2.3${xAxisUnit}`}
            paddingLeft={isVertical ? `4${xAxisUnit}` : `3${xAxisUnit}`}
            color="white"
          >
            # / nombre
          </Typography>
          {isNextCallsMode ? null : (
            <Typography
              fontSize={`2.3${xAxisUnit}`}
              paddingLeft={isVertical ? `4${xAxisUnit}` : `3${xAxisUnit}`}
              color="white"
            >
              Módulo
            </Typography>
          )}
        </Container>
      </Container>
      <Container
        width="100%"
        height="84%"
        overflowY="hidden"
        borderBottomRightRadius={`1${xAxisUnit}`}
        borderBottomLeftRadius={`1${xAxisUnit}`}
      >
        {filledAttentions.map((attention, index) => {
          const {
            moduleShortIdentifier,
            longIdentifier,
            shortIdentifier,
            id,
            showLongIdentifier,
          } = attention

          let currentIdentifier = showLongIdentifier
            ? longIdentifier
            : shortIdentifier
          if (currentIdentifier?.includes(`PAGOWEB`)) {
            currentIdentifier = currentIdentifier.replace(`PAGOWEB`, `PWEB`)
          }
          return (
            <Container
              width="100%"
              height="14.3%"
              backgroundColor={index % 2 === 0 ? `gray.5` : `white`}
              key={id}
              flexWrap="no-wrap"
              alignItems="center"
            >
              <Typography
                width={isNextCallsMode ? `100%` : `50%`}
                color="tertiary.7"
                fontWeight="bold"
                fontSize={`3.5${xAxisUnit}`}
                paddingLeft={isVertical ? `4${xAxisUnit}` : `3${xAxisUnit}`}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {currentIdentifier}
              </Typography>
              {isNextCallsMode ? null : (
                <Typography
                  color="tertiary.7"
                  fontWeight="600"
                  fontSize={`3.5${xAxisUnit}`}
                  paddingLeft={isVertical ? `4${xAxisUnit}` : `3${xAxisUnit}`}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {moduleShortIdentifier}
                </Typography>
              )}
            </Container>
          )
        })}
      </Container>
    </Container>
  )
}

function ActualTicketPanel({
  currentAttention,
  width,
  logo,
  xAxisUnit,
  yAxisUnit,
  isVertical,
}) {
  return (
    <Container
      paddingLeft={isVertical ? `2.5${xAxisUnit}` : `0.5${xAxisUnit}`}
      paddingRight={isVertical ? `2.5${xAxisUnit}` : `4.5${xAxisUnit}`}
      alignItems={isVertical ? `center` : `flex-start`}
      width={{ md: width, _: `100%` }}
      minWidth="600px"
      flexDirection="column"
    >
      <Img src={logo} height={`13${xAxisUnit}`} />
      {currentAttention ? (
        <>
          <Container
            backgroundColor="primary.4"
            width="100%"
            padding="3"
            justifyContent="center"
            alignItems="center"
            borderTopRightRadius={`1${xAxisUnit}`}
            borderTopLeftRadius={`1${xAxisUnit}`}
            marginBottom={`3.3${xAxisUnit}`}
            marginLeft={isVertical ? `0` : `2.5${xAxisUnit}`}
          >
            <Typography
              textAlign="center"
              lineHeight="1.2"
              color="white"
              fontSize={calcFontSize(
                currentAttention.moduleLongIdentifier,
                false,
              )}
              fontWeight="900"
            >
              {`${currentAttention.moduleLongIdentifier}`}
            </Typography>
          </Container>
          <Container
            width="100%"
            justifyContent="center"
            marginLeft={isVertical ? `0` : `2.5${xAxisUnit}`}
          >
            <Typography
              color="primary.4"
              fontSize={calcFontSize(currentAttention.longIdentifier)}
              fontWeight="900"
              lineHeight="1.1"
            >
              {currentAttention.longIdentifier}
            </Typography>
          </Container>
        </>
      ) : (
        <Typography
          color="primary.4"
          fontSize={`13${yAxisUnit}`}
          fontWeight={3}
          textAlign="center"
          paddingX="2"
          paddingTop={!isVertical ? `3${xAxisUnit}` : `0`}
        >
          Esperando llamados
        </Typography>
      )}
    </Container>
  )
}

const Footer = ({ isVertical, xAxisUnit }) => (
  <Container
    position="absolute"
    bottom="0"
    width="100%"
    paddingBottom={isVertical ? `1${xAxisUnit}` : `0.5${xAxisUnit}`}
    paddingLeft={isVertical ? `0` : `1${xAxisUnit}`}
    justifyContent={isVertical ? `center` : `flex-start`}
  >
    <Typography color="gray.2" fontSize={`1.3${xAxisUnit}`}>
      Con tecnología <strong>Keirón</strong>
    </Typography>
  </Container>
)

const initialState = {
  xAxisUnit: `vh`,
  yAxisUnit: `vw`,
  isVertical: false,
}
function TV({
  logo,
  attentionsById,
  lastCalledAttentions,
  currentAttention,
  unit: { monitorId },
  config,
  isNextCallsMode,
}) {
  const [state, setState] = useStateWithMerge(initialState)

  useEffect(() => {
    function handleOrientationChange() {
      const isVertical = window.innerHeight > window.innerWidth
      setState({
        xAxisUnit: isVertical ? `vh` : `vw`,
        yAxisUnit: isVertical ? `vw` : `vh`,
        isVertical,
      })
    }

    handleOrientationChange()

    window.addEventListener(`resize`, handleOrientationChange)

    return () => {
      window.removeEventListener(`resize`, handleOrientationChange)
    }
  }, [])
  return (
    <Container height="100vh" width="100%" flexWrap="wrap">
      <Container height="100vh" width="100%" flexWrap="wrap">
        <ActualTicketPanel
          width="51%"
          currentAttention={currentAttention}
          logo={logo}
          {...state}
        />

        <LastTicketsPanel
          width="49%"
          lastCalledAttentions={lastCalledAttentions}
          attentionsById={attentionsById}
          config={config}
          monitorId={monitorId}
          isNextCallsMode={isNextCallsMode}
          {...state}
        />
      </Container>
      <Footer {...state} />
    </Container>
  )
}

export default TV
