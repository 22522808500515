import React from 'react'
import {
  MdAccessible as accessible,
  MdExpand as expand,
  MdCheckCircleOutline as checkCircleOutline,
  MdErrorOutline as errorOutline,
  MdPeople as people,
  MdSchedule as schedule,
  MdAdsClick as adsClick,
  MdCalendarToday as calendarToday,
  MdAccessTime as accessTime,
  MdAccountCircle as accountCircle,
  MdCheckCircle as checkCircle,
  MdError as error,
  MdFingerprint as fingerprint,
  MdLanguage as language,
  MdHourglassEmpty as hourglassEmpty,
  MdOutlineCreditCard as outlineCreditCard,
  MdPregnantWoman as pregnantWoman,
  MdElderly as elderly,
  MdMail as mail,
  MdOutlineEmail as outlineEmail,
  MdVisibilityOff as visibilityOff,
  MdVisibility as visibility,
  MdSupervisorAccount as supervisorAccount,
  MdDoneAll as doneAll,
  MdInfoOutline as infoOutline,
  MdWarningAmber as warningAmber,
} from 'react-icons/md'
import {
  FaBaby as baby,
  FaRegCalendarTimes as calendarError,
  FaRegCalendarCheck as calendarCheck,
} from 'react-icons/fa'
import { ImWhatsapp as whatsapp, ImPhone as phone } from 'react-icons/im'
import { TbWorldOff as conectionError } from 'react-icons/tb'
import styled from 'styled-components'

import {
  compose,
  color,
  typography,
  space,
  position,
  border,
} from 'styled-system'

const icons = {
  accessible,
  expand,
  checkCircleOutline,
  errorOutline,
  people,
  schedule,
  adsClick,
  whatsapp,
  phone,
  calendarToday,
  accessTime,
  accountCircle,
  checkCircle,
  error,
  fingerprint,
  language,
  hourglassEmpty,
  outlineCreditCard,
  pregnantWoman,
  elderly,
  mail,
  outlineEmail,
  visibilityOff,
  visibility,
  baby,
  calendarError,
  conectionError,
  calendarCheck,
  supervisorAccount,
  doneAll,
  infoOutline,
  warningAmber,
}

function IconWrapper({ icon, ...props }) {
  const Component = icons[icon]
  if (!Component) {
    return null
  }
  const Icon = styled(Component)`
    ${compose(color, typography, space, position, border)}
    ${({ onClick = null, cursor = onClick != null ? `pointer` : `` }) =>
      `${cursor != null ? `cursor: ${cursor}` : ``}`}
  `
  return <Icon {...props} />
}

export default IconWrapper
