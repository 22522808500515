import React, { useEffect } from 'react'
import Icon from 'components/MaterialIcon'
import { sale } from 'utils/pos'
import { useHistory } from 'react-router-dom'
import { POS_TYPES } from 'config/constants'
import { useUnit } from 'context'
import API from 'config/api'
import { useAutopago } from 'context/AutopagoContext'
import Feedbacks from './Feedbacks'
import { genericErrorRedirect, redirectWithoutQueuing } from './Redirection'

const handlePOSError = async (scheduleData) => {
  try {
    const autopagoRecordId = await API.savePOSError(scheduleData)
    return autopagoRecordId
  } catch (error) {
    return undefined
  }
}

const Payment = ({ unit, flushInterval }) => {
  const history = useHistory()
  const {
    division: { corporationId },
  } = useUnit()
  const {
    autopagoRecordId: lastAutopagoRecordId,
    totemIp,
    handleSetAutopagoRecordId,
  } = useAutopago()
  const historyState = history?.location?.state ?? {}
  const {
    scheduleData,
    scheduleData: { integrationId, patientRut, patientRutDv },
    data,
    type,
    validateAdmissionResponse,
    posType,
  } = historyState

  useEffect(() => {
    const handleSale = async () => {
      try {
        setTimeout(() => flushInterval(), 500)
        const paymentResponse = await sale({
          type,
          amount:
            validateAdmissionResponse?.copaymentTotal ??
            scheduleData.privateTotal,
          posType,
          corporationId,
          integrationId,
          patientRut,
          patientRutDv,
          financerCode: data?.financerCode,
        })
        if (paymentResponse.success) {
          history.push({
            pathname: `/totem/${unit.id}/fin`,
            state: { ...historyState, paymentResponse },
          })
        } else {
          throw new Error(`No se pudo confirmar el pago`)
        }
      } catch (error) {
        const autopagoRecordId = await handlePOSError({
          ...data,
          ...scheduleData,
          autopagoRecordId: lastAutopagoRecordId,
          totemIp,
          errorDescription: error,
        })
        handleSetAutopagoRecordId?.(autopagoRecordId)
        genericErrorRedirect({
          unitId: unit.id,
          scheduleData,
          history,
          useOnlyFirstLevel: true,
          data: historyState,
          pathToRedirect: `/totem/${unit.id}/${
            posType === POS_TYPES.H2H ? `tipopago` : `pagar`
          }`,
          title: `Ups!`,
          subtitle: `Tenemos problemas para completar su solicitud`,
          iconSetting: {
            icon: <Icon icon="language" />,
            iconColor: `primary.4`,
          },
          textToExit: `Pagar en caja`,
          upperIconSetting: {
            upperIcon: <Icon icon="error" />,
            upperIconColor: `error.3`,
          },
          toExitFn: scheduleData?.hasNotQueuing
            ? () =>
                redirectWithoutQueuing({
                  history,
                  icon: <Icon icon="calendarCheck" />,
                  iconColor: `green.0`,
                  title: `Listo!`,
                  subtitle: `Su cita ha sido agendada.
                Acérquese a pagar en caja.`,
                })
            : undefined,
        })
      }
    }
    handleSale()
  }, [])
  return (
    <Feedbacks
      icon={<Icon icon="outlineCreditCard" />}
      iconColor="primary.4"
      upperIconColor="green.0"
      title="Opere su tarjeta"
    />
  )
}

export default Payment
