import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import LineButton from '../LineButton'

function LineItem({
  id,
  name,
  isDisabled,
  isRutRequired,
  unitId,
  icons,
  requestName = null,
  ...props
}) {
  const location = useLocation()
  const history = useHistory()
  const hasRut = !!location.state

  async function handleLineClick() {
    if (requestName) {
      history.push({
        pathname: `/totem/${unitId}/requestName`,
        state: { lineId: id, ...location.state },
      })
    } else {
      history.push({
        pathname: `/totem/${unitId}/print`,
        state: { lineId: id, ...location.state },
      })
    }
  }
  if (!hasRut && isRutRequired) {
    return null
  }
  return (
    <LineButton
      handleLineClick={handleLineClick}
      name={name}
      isDisabled={isDisabled}
      icons={icons}
      {...props}
    />
  )
}

export default LineItem
