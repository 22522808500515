import React, { useState } from 'react'
import { Container, Typography, Button, Input } from 'components'
import API from 'config/api'
import notification from 'antd/es/notification'

export default function SpecialAttention({
  requestInQueue,
  setRequestInQueue,
  userId,
  activeModuleId,
  isUserAvailable,
  currentAttention,
  callTypeId,
  currentLines,
  moduleType,
}) {
  const [ticketIdentifier, setTicketIdentifier] = useState(``)
  const canCall =
    isUserAvailable &&
    (!currentAttention ||
      (callTypeId === 2 && moduleType === 1 && !requestInQueue))
  const canSubmit = canCall && ticketIdentifier.length >= 2

  async function callSpecialAttention() {
    try {
      const lineIds = currentLines.reduce(
        (prev, current) => (current.id == 0 ? prev : [...prev, current.id]),
        [],
      )
      const { id, attentionTypeId } = await API.findAttentionByTicketIdentifier(
        {
          ticketIdentifier,
          lineIds,
        },
      )
      const request =
        attentionTypeId === 2 ? API.reopenAttention : API.callAttention
      const payload = {
        userId,
        moduleId: activeModuleId,
        attentionId: id,
        isAhead: attentionTypeId === 1,
      }
      if (callTypeId === 2 && currentAttention) {
        // Si es autollamado se guarda la funcion y payload
        // para ser llamada una vez termine de atender al numero actual
        notification.success({
          message: `Atención excepcional será llamada una vez finalice la atención actual`,
        })
        setRequestInQueue({ request, payload })
      } else {
        // Para el flujo normal, solo se ejecuta la request
        await request(payload)
      }
    } catch (e) {
      const error = typeof e === `string` ? e : `Ocurrio un error inesperado.`
      notification.error({ message: error, duration: 30 })
    }
  }
  React.useEffect(() => {
    setTicketIdentifier(``)
  }, [currentAttention])
  return (
    <Container flexDirection="column">
      <Typography color="primary.4" fontWeight="bold" fontSize="2">
        ATENDER NÚMEROS EXCEPCIONALES
      </Typography>
      <Input
        placeholder="Escriba aquí"
        width="100%"
        fontSize="2"
        margin="0.5em 0"
        disabled={!canCall}
        value={ticketIdentifier}
        onChange={(ticketIdentifier) =>
          setTicketIdentifier(ticketIdentifier.toUpperCase())
        }
      />
      <Typography
        color="primary.4"
        textAlign="center"
        width="100%"
        fontSize="2"
        fontStyle="italic"
        padding="1em 0"
      >
        Ingrese número de atención
      </Typography>
      <Button
        onClick={callSpecialAttention}
        backgroundColor={canSubmit ? `primary.8` : `gray.0`}
        color={canSubmit ? `white` : `gray.3`}
        padding="0.5em"
        fontSize="4"
        width="100%"
        textAlign="center"
        disabled={!canSubmit}
      >
        AÑADIR
      </Button>
    </Container>
  )
}
