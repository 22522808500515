import React, { useState, useEffect } from 'react'
import notification from 'antd/es/notification'
import API from 'config/api'
import Modal from 'antd/es/modal'
import { capitalizeFirstLetter } from 'utils'
import { useTime, useAuth, useAttentions } from 'context'
import { Container, Typography, Input, Icon } from 'components'

function Header({ title }) {
  const {
    user: { id: userId, firstName, lastName, moduleType },
    activityTypes,
    activityTypesById,
    currentActivityType,
    changeCurrentActivityType,
  } = useAuth()
  const { modules, modulesById, activeModuleId, profile } = useAttentions()
  const [activityTypeId, setActivityTypeId] = useState(currentActivityType)
  useEffect(() => setActivityTypeId(currentActivityType), [currentActivityType])
  async function changeActivityType(activityTypeId) {
    try {
      await API.updateActivityType({
        userId,
        activityTypeId,
        modules,
      })
      setActivityTypeId(activityTypeId)
      changeCurrentActivityType(activityTypeId)
    } catch (e) {
      const error = typeof e === `string` ? e : `Ocurrio un error inesperado.`
      notification.error({ message: error })
    }
  }

  function handleChangeActivity(activityId) {
    const activityType = activityTypesById[activityId]
    Modal.confirm({
      title: `Confirmar cambio de actividad: ${activityType.description}`,
      content: `Si cambia el estado ${
        activityType.available ? `` : `NO`
      } podrá atender. ¿Desea continuar?`,
      okText: `Si.`,
      okType: activityType.available ? `primary` : `danger`,
      cancelText: `No.`,
      onOk: () => changeActivityType(activityId),
    })
  }
  const activeModule = modulesById[activeModuleId]
  const moduleNameInInitialPanel =
    activeModule?.namingConfig?.admissionistPanel?.text ??
    `${activeModule?.description} ${activeModule?.identifier}`

  return (
    <Container
      position="sticky"
      display="flex"
      justifyContent="flex-end"
      width="100%"
    >
      <Container
        justifyContent="space-between"
        alignItems="center"
        padding="0.5em 2em"
        margin="0"
        width="100%"
        backgroundColor="primary.8"
        zIndex={2}
      >
        {moduleType === 2 ? (
          <Container alignItems="center">
            <Typography
              color="white"
              fontSize="5"
              fontWeight="bold"
              marginRight="3"
            >
              Panel Inicial
            </Typography>
            <CurrentTime color="white" />
          </Container>
        ) : (
          <Container alignItems="center" flex={1}>
            <Typography color="white" fontSize="4" fontWeight="bold">
              {moduleNameInInitialPanel}:
            </Typography>
            <Input
              type="select"
              width="30%"
              margin="0 0.5em"
              onChange={handleChangeActivity}
              value={activityTypeId}
              options={activityTypes.map((type) => ({
                value: type,
                label: activityTypesById[type].description,
              }))}
            />
          </Container>
        )}
        <Container alignItems="center">
          <Icon icon="user" color="white" fontSize="4" marginRight="5px" />
          <Typography fontSize="4" color="white">
            {`${firstName} ${lastName} ${
              profile != null ? `  - ${profile.name}` : ``
            }`}
          </Typography>
        </Container>
      </Container>
      {moduleType === 2 && (
        <Container
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          padding="0.5em 2em"
        >
          <Typography fontSize="6" color="primary.2">
            {title}
          </Typography>
          <CurrentTime />
        </Container>
      )}
    </Container>
  )
}

function CurrentTime({ color = `primary.4` }) {
  const currentTime = useTime()
  return (
    <Typography fontWeight="bold" color={color} fontSize="3">
      {capitalizeFirstLetter(
        currentTime.format(`dddd DD [ de ] MMMM YYYY, HH:mm:ss  [hrs.]`),
      )}
    </Typography>
  )
}

export default Header
