import React, { useEffect } from 'react'
import { Container, Typography, Input, Table } from 'components'
import { useAuth } from 'context'
import { useStateWithMerge } from 'hooks'
import notification from 'antd/es/notification'
import moment from 'moment-timezone'
import API from 'config/api'

function getInitialState(professionals) {
  const professionalRut =
    professionals?.length > 0 ? professionals[0].rut : null
  const isLoading = professionals?.length > 0
  return {
    professionalRut,
    medicalCenterId: ``,
    dataSourceForTable: [],
    rawAppointments: [],
    centersById: {},
    centerIds: [],
    isLoading,
  }
}

const columns = [
  {
    title: `Hora programada`,
    key: `appointmentDate`,
    render: (appointment) =>
      moment(appointment.appointmentDate).format(`HH:mm`),
    sorter: (a, b) => a.appointmentDate.localeCompare(b.appointmentDate),
    defaultSortOrder: `ascend`,
  },
  { title: `Rut Paciente`, dataIndex: `patientRut`, key: `patientRut` },
  { title: `Nombre Paciente`, dataIndex: `patientName`, key: `patientName` },
  { title: `Estado`, dataIndex: `status`, key: `status` },
  {
    title: `Hora llamada`,
    key: `initDate`,
    render: (appointment) =>
      appointment.initDate ? moment(appointment.initDate).format(`HH:mm`) : `-`,
  },
  {
    title: `Hora finalización`,
    key: `endDate`,
    render: (appointment) =>
      appointment.endDate ? moment(appointment.endDate).format(`HH:mm`) : `-`,
  },
]

function Appointments() {
  const { currentUnit } = useAuth()
  const [state, setState] = useStateWithMerge(
    getInitialState(currentUnit?.professionals),
  )
  const {
    professionalRut,
    dataSourceForTable,
    isLoading,
    centersById,
    centerIds,
    medicalCenterId,
    rawAppointments,
  } = state

  useEffect(() => {
    async function getData() {
      setState({ isLoading: true })
      try {
        const rawAppointments = await API.getAppointments({ professionalRut })
        const { appointments, centersById, centerIds } = rawAppointments

        setState({
          dataSourceForTable: appointments,
          rawAppointments: appointments,
          centersById,
          centerIds,
          medicalCenterId: centerIds[0],
        })
      } catch (e) {
        notification.error(`Fallo la búsqueda de citas`)
      } finally {
        setState({ isLoading: false })
      }
    }
    if (professionalRut) {
      getData()
    }
  }, [professionalRut])

  function onMedicalCenterChange(medicalCenterId) {
    const dataSourceForTable = rawAppointments.filter(
      (appointment) => appointment.centerId == medicalCenterId,
    )
    return setState({ medicalCenterId, dataSourceForTable })
  }

  return (
    <Container width="100%" flexDirection="column" padding={1}>
      <Container
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        marginBottom="12px"
      >
        <Container width="20%">
          <Typography
            fontSize="5"
            color="primary.2"
            fontWeight="600"
            marginBottom={3}
          >
            AGENDA
          </Typography>
        </Container>
        {centersById && centerIds.length > 0 ? (
          <Container alignItems="center" width="30%">
            <Typography
              fontWeight="2"
              color="primary.2"
              fontSize={1}
              marginRight="0.5em"
            >
              Seleccione un centro:
            </Typography>
            <Input
              width="100%"
              type="select"
              fontSize="2"
              value={medicalCenterId}
              disabled={isLoading}
              onChange={(centerId) => {
                onMedicalCenterChange(centerId)
              }}
              options={centerIds.map((centerId) => ({
                label: centersById[centerId].name,
                value: centersById[centerId].id,
              }))}
            />
          </Container>
        ) : null}
        <Container alignItems="center" width="30%">
          <Typography
            fontWeight="2"
            color="primary.2"
            fontSize={1}
            marginRight="0.5em"
          >
            Seleccione un profesional:
          </Typography>
          <Input
            width="100%"
            type="select"
            fontSize="2"
            value={professionalRut}
            disabled={isLoading}
            onChange={(value) => {
              setState({ professionalRut: value })
            }}
            options={currentUnit?.professionals.map((professional) => ({
              label: professional.name,
              value: professional.rut,
            }))}
          />
        </Container>
      </Container>
      <Table
        bordered
        columns={columns}
        dataSource={dataSourceForTable}
        scroll={{ x: 500 }}
        fontSize="13px"
        headerFontSize="13px"
        headerWeight="bold"
        size="small"
        oddBackgroundColor="white"
        loading={isLoading}
      />
    </Container>
  )
}

export default Appointments
