// DEPRECAR cuando se haga funcionalidad
function replaceLineName(ticket) {
  if (ticket && [1123].includes(ticket.lineId)) {
    ticket.lineName = `Te Ayudo`
  }
  if (
    ticket &&
    [
      626, 627, 650, 652, 1063, 1059, 1134, 1064, 1065, 1110, 1112, 1118, 1122,
      1109, 1111, 1113, 1114, 1115, 1117, 1144, 1145,
    ].includes(ticket.lineId)
  ) {
    ticket.lineName = `Recepción 1`
  }
  if (ticket && [628, 651, 654].includes(ticket.lineId)) {
    ticket.lineName = `Recepción 2`
  }
  if (ticket && [1119, 1120, 1121, 1127].includes(ticket.lineId)) {
    ticket.lineName = `Recepción 3`
  }
  if (
    ticket &&
    [1136, 1128, 1129, 1130, 1131, 1132, 1133, 1124, 1125, 1060, 1135].includes(
      ticket.lineId,
    )
  ) {
    ticket.lineName = `Recepción 5`
  }
  if (ticket && [1346, 1347, 1348, 1349].includes(ticket.lineId)) {
    ticket.lineName = `Piso 3`
  }
  if (ticket && [1343, 1344, 1345].includes(ticket.lineId)) {
    ticket.lineName = `Piso 2`
  }
  if (
    ticket &&
    [1336, 1337, 1338, 1350, 1351, 1352, 1353].includes(ticket.lineId)
  ) {
    ticket.lineName = `Piso 1`
  }
  return ticket
}

export { replaceLineName }
