import React from 'react'
import { Container, Button, Icon } from 'components'

function Keyboard({
  config,
  columns,
  isQwerty,
  isMedium = false,
  isEmail = false,
  ...props
}) {
  function getColumn(key, icon) {
    if (!isQwerty) {
      return 0
    } else if ((icon && !isEmail) || key === `@`) {
      return `span 3`
    } else if (icon && isEmail) {
      return `span 5`
    } else if (key === `Z` && !isEmail) {
      return `3/5`
    } else {
      return `span 2`
    }
  }
  function getHeight() {
    if (isQwerty || isMedium) {
      return { _: `5vh`, md: `8vh` }
    }
    return { _: `6vh`, md: `10vh` }
  }

  function getColorByChars(key) {
    const listChars = [`-`, `_`, `.`, `@`]
    return listChars.includes(key) || Number.isInteger(parseInt(key, 10))
  }

  function getBackground({ key, icon, isEmail }) {
    if (icon) {
      return `error.3`
    }
    if (getColorByChars(key) && isEmail) {
      return `primary.4@0.2`
    }
    return `primary.4`
  }

  function getShadow(icon) {
    let props = {
      withShadow: true,
    }
    if (icon && isEmail) {
      props = {
        shadow: `0 4px 8px 1px rgba(208, 208, 208, 1)`,
      }
    }
    if (!icon && isEmail) {
      props = {}
    }
    return props
  }

  return (
    <Container
      width="100%"
      justifyContent="center"
      height="auto"
      display="grid"
      gridTemplateColumns={`repeat(${columns}, 1fr)`}
      gridGap={{
        _: isQwerty ? `1vw` : `2vw`,
        md: isQwerty ? `1vw` : `1.5vw`,
      }}
      {...props}
    >
      {config.map(({ icon, onClick, key }) => {
        if (key === ` `) {
          return <Container key="empty-space" />
        }
        const text = icon ? null : key
        return (
          <Button
            key={key}
            backgroundColor={getBackground({ key: text, icon, isEmail })}
            height={getHeight(key)}
            padding="1"
            borderRadius={isEmail ? `8px` : `5px`}
            gridColumn={getColumn(key, icon)}
            fontSize={isQwerty || isMedium ? 6 : 7}
            fontWeight={isEmail ? `600` : `500`}
            name={text}
            onClick={onClick}
            color={isEmail && getColorByChars(text) ? `primary.4` : undefined}
            hoverProps={{ backgroundColor: `white`, color: `gray.1` }}
            {...getShadow(icon)}
          >
            {icon ? <Icon icon={icon} width="3rem" /> : text}
          </Button>
        )
      })}
    </Container>
  )
}

export default Keyboard
