import { validateEmail } from 'utils'

function handleGetEmailInvalid({ email, submitActivated }) {
  return submitActivated && !validateEmail(email)
}

export const getTitle = (typeInput) => {
  if (typeInput == `firstName`) {
    return `Ingrese su nombre (sin apellidos)`
  }
  if (typeInput == `lastName`) {
    return `Ingrese su apellido paterno`
  }
  return `Ingrese su correo`
}

export const getTitleButton = (typeInput) => {
  if (typeInput == `firstName`) {
    return `Continuar`
  }
  if (typeInput == `lastName`) {
    return `Continuar`
  }
  if (typeInput == `email`) {
    return `Confirmar Email`
  }
  return `Enviar por correo`
}

export const getTitleError = (typeInput) => {
  if (typeInput == `firstName`) {
    return `Ingrese su nombre`
  }
  if (typeInput == `lastName`) {
    return `Ingrese su apellido`
  }
  return `Email incorrecto`
}

function handleGetInputNamesInvalid({ email, submitActivated }) {
  return submitActivated && email?.trim()?.length === 0
}

export function handleInvalidByType({
  email,
  submitActivated,
  typeInputIntegratedUnit,
}) {
  if (
    typeInputIntegratedUnit == `firstName` ||
    typeInputIntegratedUnit == `lastName`
  ) {
    return handleGetInputNamesInvalid({ email, submitActivated })
  } else {
    return handleGetEmailInvalid({ email, submitActivated })
  }
}

export const propsByInputValueType = {
  firstName: {
    nextPath: `ingresarApellido`,
    nextInputValueType: `lastName`,
    currentPath: `ingresarNombre`,
    prevPath: `listaDeHoras`,
  },
  lastName: {
    nextPath: `ingresarEmail`,
    nextInputValueType: `email`,
    currentPath: `ingresarApellido`,
    prevPath: `ingresarNombre`,
    prevTypeInputIntegratedUnit: `firstName`,
  },
  email: {
    currentPath: `ingresarEmail`,
    prevPath: `ingresarApellido`,
    prevTypeInputIntegratedUnit: `lastName`,
  },
}
export const textInputsList = [`firstName`, `lastName`]
export const hasPrevPath = [...textInputsList, `email`]

export const goBackByPath = ({ locationState, history, unitId }) => {
  const {
    schedules,
    patientData,
    selectedSchedule,
    typeInputIntegratedUnit,
    posType,
  } = locationState
  if (hasPrevPath.includes(typeInputIntegratedUnit)) {
    const { prevPath: lastPath, prevTypeInputIntegratedUnit } =
      propsByInputValueType[typeInputIntegratedUnit]
    let state
    if (typeInputIntegratedUnit === `firstName`) {
      state = { schedules, patientData, posType }
    } else {
      state = {
        selectedSchedule,
        patientData,
        typeInputIntegratedUnit: prevTypeInputIntegratedUnit,
        prevPath: lastPath,
        schedules,
        posType,
      }
    }
    history.push({
      pathname: `/totem/${unitId}/${lastPath}`,
      state,
    })
  }
}
