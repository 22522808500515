import { Button, Container, Icon, Typography } from 'components'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import { capitalizeFirstLetter } from 'utils'

const GenericButton = ({ onClick, title }) => {
  return (
    <Button
      shadow="0px 5px 5px 1px rgba(0, 0, 0, .1)"
      backgroundColor="primary.4"
      color="white"
      fontSize="2vh"
      padding="1.2vh"
      borderRadius="0.75vh"
      margin="1vh 0"
      width="100%"
      onClick={onClick}
    >
      {moment(title).format(`LT`)}
    </Button>
  )
}

const ScheduleList = ({ unit, goBack }) => {
  const location = useLocation()
  const history = useHistory()
  const locationState = location?.state ?? {}
  const { schedules, patientData, posType } = locationState

  const handleScheduleSelection = (schedule) => {
    return history.push({
      pathname: `/totem/${unit.id}/ingresarNombre`,
      state: {
        selectedSchedule: schedule,
        patientData,
        typeInputIntegratedUnit: `firstName`,
        prevPath: `listaDeHoras`,
        schedules,
        posType,
      },
    })
  }
  return (
    <Container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      margin="5"
    >
      <Container width="100%">
        <Button backgroundColor="transparent" paddingY="3" onClick={goBack}>
          <Icon icon="arrowLeft" color="gray.3" fontSize="6" />
        </Button>
      </Container>
      <Container
        justifyContent="center"
        alignItems="center"
        paddingBottom="4.5vh"
      >
        <Typography fontSize="3vh" fontWeight="700" margin="1vh 0">
          Selecciona una hora
        </Typography>
        <Typography fontSize="2.5vh" fontWeight="700" margin="1vh 0">
          Atención de urgencia
        </Typography>
      </Container>

      <Container height="40vh" overflow="scroll" justifyContent="center">
        {Object.keys(schedules).map((key) => (
          <Container
            key={`scheduleList-${key}`}
            justifyContent="center"
            width="100%"
            paddingX="2vh"
          >
            <Typography fontSize="2vh" fontWeight="700" margin="1vh 0">
              {capitalizeFirstLetter(moment(key).format(`dddd D [de] MMMM`))}
            </Typography>
            {schedules[key].map((schedule) => (
              <GenericButton
                key={schedule.startDate}
                title={schedule.startDate}
                onClick={() => handleScheduleSelection(schedule)}
              />
            ))}
          </Container>
        ))}
      </Container>
    </Container>
  )
}

export default ScheduleList
