import React from 'react'
import { Container, Button, Icon } from 'components'

function Keyboard({ config, columns }) {
  return (
    <Container
      width="100%"
      display="grid"
      height="100%"
      gridTemplateColumns={`repeat(${columns}, 1fr)`}
      gridGap="1rem"
      marginTop="3px"
    >
      {config.map(({ icon, onClick, key }) => {
        const text = icon ? null : key
        return (
          <Button
            key={key}
            backgroundColor="primary.4"
            color="white"
            height="90%"
            borderRadius="6%"
            withShadow
            fontSize="6"
            fontWeight="500"
            paddingY="6%"
            hoverProps={{
              backgroundColor: `primary.3`,
            }}
            name={text}
            onClick={onClick}
          >
            {icon ? <Icon icon={icon} width="3rem" /> : text}
          </Button>
        )
      })}
    </Container>
  )
}

export default Keyboard
