/* eslint-disable no-plusplus */
import React from 'react'
import { Container, Typography, Img, Icon } from 'components'
import { LOGO_KEIRON } from 'assets'
import { getEmptyAttention } from 'utils'
import { useUnit } from 'context'

function getFilledAttentions({ attentions, attentionsById, group }) {
  const filledAttentions = []
  const filteredAttentions = attentions.filter((attentionId) =>
    group.lines.includes(attentionsById[attentionId].lineId),
  )
  for (let i = 0; i < group.quantity; i++) {
    const attentionId = filteredAttentions[i]

    filledAttentions.push(
      attentionId != null ? attentionsById[attentionId] : getEmptyAttention(i),
    )
  }
  return filledAttentions
}

function AttentionStatusBadge({ initDate, endDate, maxWidthWithStatusColumn }) {
  let attentionEnded = false

  if (!initDate && !endDate) {
    return null
  }

  if (endDate) {
    attentionEnded = true
  }

  return (
    <Container
      width="100%"
      maxWidth={maxWidthWithStatusColumn}
      backgroundColor={attentionEnded ? `gray.6` : `primary.11`}
      borderRadius="24px"
      padding="1"
    >
      <Typography
        color={attentionEnded ? `primary.10` : `secundary.2`}
        fontWeight="bold"
        fontSize={{ _: 2, sm: 2, md: 4, lg: 4 }}
        overflow="hidden"
        textOverflow="ellipsis"
        textAlign="center"
        width="100%"
      >
        {attentionEnded && <Icon icon="check" fontSize="2" marginRight="1" />}
        {attentionEnded ? `Atendido` : `En atención`}
      </Typography>
    </Container>
  )
}

function HeaderItem({ columnMaxWidth, textToRender }) {
  return (
    <Typography
      textAlign="center"
      maxWidth={columnMaxWidth}
      width="100%"
      fontSize={{ _: 2, sm: 4, md: 5, lg: 6 }}
      color="white"
    >
      {textToRender}
    </Typography>
  )
}

function LineGroup({
  attentionsById,
  attentions,
  group,
  config,
  isNextCallsMode,
}) {
  //TODO: cambiar a configuración del template
  const unit = useUnit()
  const corporationId =
    unit?.division?.corporation?.id ?? unit?.division?.corporationId
  const ticketColumnHeader = corporationId === 20 ? `N. Cliente` : `N. Paciente`
  const showAttentionStatus = config?.showAttentionStatus && !isNextCallsMode

  const rowsPaddingX = showAttentionStatus ? `5%` : `10%`
  const maxWidthWithoutStatusColumn = `45%`
  const maxWidthWithStatusColumn = `30%`
  const columnMaxWidth = showAttentionStatus
    ? maxWidthWithStatusColumn
    : maxWidthWithoutStatusColumn

  const filledAttentions = getFilledAttentions({
    attentions,
    attentionsById,
    config,
    group,
  })

  return (
    <Container
      width={`${96 / config.groups.length}%`}
      maxHeight="100%"
      justifyContent="center"
      marginLeft="1%"
    >
      {/* Group name and description */}
      <Container width="100%" justifyContent="center" alignItems="center">
        <Container
          flexDirection="column"
          backgroundColor="primary.4"
          borderRadius="15px 15px 0px 0px"
          width="100%"
          alignItems="center"
        >
          <Typography
            color="white"
            fontSize={{ _: 4, sm: 5, md: 6, lg: 7 }}
            fontWeight="bold"
          >
            {group.name || ` - `}
          </Typography>
          <Typography color="white" fontSize={{ _: 3, sm: 4, md: 5, lg: 6 }}>
            {group.description || ` - `}
          </Typography>
        </Container>
        <Container
          width="100%"
          backgroundColor="primary.3"
          borderRadius="0px 0px 15px 15px"
          justifyContent="space-between"
          paddingX={rowsPaddingX}
          marginBottom="3%"
        >
          {showAttentionStatus ? (
            <HeaderItem columnMaxWidth={columnMaxWidth} textToRender="Estado" />
          ) : null}
          <HeaderItem
            columnMaxWidth={isNextCallsMode ? `100%` : columnMaxWidth}
            textToRender={ticketColumnHeader}
          />
          {isNextCallsMode ? null : (
            <HeaderItem columnMaxWidth={columnMaxWidth} textToRender="Módulo" />
          )}
        </Container>
      </Container>
      <Container width="100%">
        {filledAttentions.map((attention, index) => {
          const {
            moduleShortIdentifier,
            longIdentifier,
            shortIdentifier,
            id,
            showLongIdentifier,
            initDate,
            endDate,
          } = attention

          const currentIdentifier = showLongIdentifier
            ? longIdentifier
            : shortIdentifier

          return (
            <Container
              width="100%"
              paddingX={rowsPaddingX}
              height="10vh"
              backgroundColor={index % 2 === 0 ? `gray.0` : `white`}
              key={id}
              justifyContent="space-between"
              alignItems="center"
            >
              {showAttentionStatus ? (
                <AttentionStatusBadge
                  initDate={initDate}
                  endDate={endDate}
                  maxWidthWithStatusColumn={maxWidthWithStatusColumn}
                />
              ) : null}
              <Typography
                maxWidth={isNextCallsMode ? `100%` : columnMaxWidth}
                color="primary.4"
                fontWeight="bold"
                fontSize={{ _: 4, sm: 5, md: 6, lg: 7 }}
                overflow="hidden"
                textOverflow="ellipsis"
                textAlign="center"
                width="100%"
              >
                {currentIdentifier}
              </Typography>
              {isNextCallsMode ? null : (
                <Typography
                  maxWidth={columnMaxWidth}
                  color="primary.4"
                  fontWeight="500"
                  fontSize={{ _: 4, sm: 5, md: 6, lg: 7 }}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  textAlign="center"
                  width="100%"
                >
                  {moduleShortIdentifier}
                </Typography>
              )}
            </Container>
          )
        })}
      </Container>
    </Container>
  )
}

function MultiGroupTV({
  logo,
  attentionsById,
  attentions,
  config,
  isNextCallsMode,
}) {
  return (
    <Container
      height="100vh"
      width="100%"
      flexWrap="nowrap"
      flexDirection="column"
    >
      {/* Header Container */}
      <Container
        height="15%"
        justifyContent="space-between"
        width="98%"
        marginTop="2%"
        marginLeft="1%"
        paddingLeft="40px"
        paddingRight="40px"
      >
        <Img width="15%" src={logo} marginLeft="3" marginTop="3" />
        <Img width="10%" src={LOGO_KEIRON} marginRight="3" />
      </Container>

      <Container flexDirection="row" width="98%" marginLeft="1%">
        {config.groups.map((group) => (
          <LineGroup
            key={group.name}
            attentions={attentions}
            attentionsById={attentionsById}
            group={group}
            config={config}
            isNextCallsMode={isNextCallsMode}
          />
        ))}
      </Container>
    </Container>
  )
}

export default MultiGroupTV
