import React, { useEffect } from 'react'
import { useStateWithMerge } from 'hooks'
import { Container, Typography } from 'components'
import API from 'config/api'
import { notification, message } from 'antd'
import { useUnit } from 'context'
import Icon from 'components/MaterialIcon'
import { useAutopago } from 'context/AutopagoContext'
import Identification from '../index'
import { genericErrorRedirect } from '../../../payment/Redirection'
import { notAutopagoGenericErrorRedirect } from '../Redirection'

const isEmptyObject = (obj) => Object.keys(obj).length === 0

const initialState = {
  isCalling: false,
  ticket: null,
  patientData: null,
  isTicketCalling: false,
  schedules: null,
  extraInfo: null,
  canAskWithoutRut: true,
  isValidatingRut: false,
}

function IdBeforeLines({ history, unit, flushInterval, goBack }) {
  const [state, setState] = useStateWithMerge(initialState)
  const {
    logo,
    division: { corporationId },
    units,
    unitsById,
    isAutopagoActive: isAutopagoFront,
    isPrescriptionScanActive: isPrescriptionScan,
  } = useUnit()
  const isAutopagoBack = unitsById[units[0]].isAutopago
  const isAutopago = isAutopagoFront && isAutopagoBack
  const { handleSetAutopagoRecordId, totemIp } = useAutopago()

  useEffect(() => {
    if (goBack == null) {
      flushInterval()
    }
  })
  useEffect(() => {
    async function getCanAskWithoutRut() {
      try {
        const { lines } = await API.getTotemLines({
          returnUnfiltered: true,
          unitId: unit.id,
        })
        const canAskWithoutRut = lines.some(
          (line) => line.isRutRequired == false,
        )
        setState({ canAskWithoutRut })
      } catch (e) {
        const error =
          typeof e === `string` ? e : `Error al cargar filas, reintenta.`
        message.error(error)
      }
    }
    getCanAskWithoutRut()
    handleSetAutopagoRecordId?.()
  }, [])
  const { isCalling, canAskWithoutRut, isValidatingRut } = state
  const goToLines = (patientData) =>
    history.push({ pathname: `/totem/${unit.id}/lines`, state: patientData })

  //Busqueda de cita
  async function getSchedule(patientData) {
    setState({ isCalling: true, isValidatingRut: true })
    try {
      if (isEmptyObject(patientData)) {
        return goToLines()
      }
      let autopagoRecordId
      if (isAutopago) {
        autopagoRecordId = await API.saveAutopagoRecord({
          ...patientData,
          unitId: unit.id,
          totemIp,
        })
        handleSetAutopagoRecordId?.(autopagoRecordId)
      }
      const scheduleInfo = await API.getSchedule({
        ...patientData,
        unitId: unit.id,
        isAutopagoFrontend: isAutopago,
        corporationId,
        unitEarlyMinutes: unit.earlyMinutes,
        unitLateMinutes: unit.lateMinutes,
        totemIp,
        autopagoRecordId,
      })
      if (isPrescriptionScan && scheduleInfo?.goToIdBeforeLines) {
        return notAutopagoGenericErrorRedirect({
          unitID: unit.id,
          data: {},
          hasToExit: false,
          history,
          title:
            scheduleInfo?.notificationMessage ??
            `Nº de receta ingresado no es válido.`,
          iconPaddingTop: `3rem`,
          iconSetting: {
            icon: <Icon icon="infoOutline" />,
            iconColor: `error.3`,
          },
          toExitFn: () =>
            history.push({
              pathname: `/totem/units/patient`,
              state: {
                goToIdBeforeLines: scheduleInfo?.goToIdBeforeLines,
              },
            }),
          textToExit: `Volver a intentarlo`,
          styleToExit: {
            backgroundColor: `primary.4`,
            color: `white`,
            shadow: `0px 5px 5px 1px rgba(0, 0, 0, .1)`,
          },
        })
      }
      if (scheduleInfo?.hasAmbulatorySchedules && scheduleInfo?.schedules) {
        return history.push({
          pathname: `/totem/${unit.id}/listaDeHoras`,
          state: {
            ...scheduleInfo,
            patientData,
            corporationId,
          },
        })
      }
      if (
        scheduleInfo?.hasAmbulatorySchedules &&
        scheduleInfo?.schedules == null
      ) {
        return genericErrorRedirect({
          unitID: unit.id,
          redirect: `/totem`,
          timeout: 4000,
          data: {},
          hasToExit: false,
          history,
          title: `Lo sentimos, no hay citas disponibles`,
          iconPaddingTop: `3rem`,
          iconSetting: {
            icon: <Icon icon="calendarError" />,
            iconColor: `error.3`,
          },
        })
      }
      if (scheduleInfo?.hasAmbulatorySchedules && !scheduleInfo?.schedules) {
        return genericErrorRedirect({
          unitID: unit.id,
          redirect: `/totem`,
          timeout: 4000,
          data: {},
          hasToExit: false,
          history,
          title: `Error de conexión`,
          subtitle: `No es posible completar su solicitud en este momento.`,
          iconPaddingTop: `3rem`,
          iconSetting: {
            icon: <Icon icon="language" />,
            iconColor: `primary.4`,
          },
          upperIconSetting: {
            upperIcon: <Icon icon="error" />,
            upperIconColor: `error.3`,
          },
        })
      }

      if (scheduleInfo?.goToLines) {
        return goToLines({ ...patientData, ...scheduleInfo })
      }
      if (!scheduleInfo) {
        return goToLines(patientData)
      }
      if (scheduleInfo.hasExpressPickup) {
        // Se debe seguir el flujo de RE
        return history.push({
          pathname: `/totem/${unit.id}/confirmation`,
          state: { ...scheduleInfo, patientData },
        })
      }

      history.push({
        pathname: `/totem/${unit.id}/schedules`,
        state: { ...scheduleInfo, patientData },
      })
    } catch (e) {
      const error =
        typeof e === `string` ? e : `Ha ocurrido un error, reintente`
      return notification.error({ message: error })
    } finally {
      setState({ isCalling: false, patientData, isValidatingRut: false })
    }
  }

  return (
    <Identification
      goBack={goBack}
      onConfirm={getSchedule}
      isCalling={isCalling}
      logo={logo}
      unit={unit}
      canAskWithoutRut={canAskWithoutRut}
      isValidatingRut={isValidatingRut}
      {...unit}
    >
      <Container width="100%" justifyContent="center">
        <Typography
          textAlign="center"
          color="primary.6"
          fontSize={unit?.name?.length > 30 ? 6 : 7}
          fontWeight="900"
        >
          {unit ? unit.name : null}
        </Typography>
      </Container>
    </Identification>
  )
}

export default IdBeforeLines
