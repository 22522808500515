import React from 'react'
import { Modal } from 'components'
import EditPatient from './Patient'
import ScheduleAttention from './ScheduleAttention'
import PlanTicketJourney from './PlanTicketJourney'
import Stock from './StockAdmissionistModal'
import StockTableModal from './StockTableModal'

export const modalTypes = {
  editPatient: `editPatient`,
  scheduleAttentions: `scheduleAttentions`,
  planTicketJourney: `planTicketJourney`,
  stockAdmissionistModal: `stockAdmissionistModal`,
  stockTableModal: `stockTableModal`,
}
function ModalWrapper({ openModal, onClose, ...props }) {
  switch (openModal) {
    case modalTypes.stockAdmissionistModal: {
      return (
        <Modal
          isVisible
          onClose={onClose}
          title={`Sesiones ${props.patientName ?? ``}`}
        >
          <Stock onClose={onClose} {...props} />
        </Modal>
      )
    }
    case modalTypes.stockTableModal: {
      return (
        <Modal
          isVisible
          onClose={onClose}
          title={`Sesiones ${props.patientName ?? ``}`}
        >
          <StockTableModal onClose={onClose} {...props} />
        </Modal>
      )
    }
    case modalTypes.editPatient: {
      return (
        <Modal isVisible onClose={onClose} title="Editar datos de paciente">
          <EditPatient onClose={onClose} {...props} />
        </Modal>
      )
    }
    case modalTypes.scheduleAttentions: {
      return (
        <Modal
          isVisible
          onClose={onClose}
          title={`Programar atención: ${props.ticket}`}
        >
          <ScheduleAttention onClose={onClose} {...props} />
        </Modal>
      )
    }
    case modalTypes.planTicketJourney: {
      return (
        <Modal
          isVisible
          onClose={onClose}
          title={`Programar atención: ${props.ticket}`}
        >
          <PlanTicketJourney onClose={onClose} {...props} />
        </Modal>
      )
    }
    default: {
      return null
    }
  }
}

export default ModalWrapper
