import React, { useState, useEffect } from 'react'
import { useStatsPing } from 'hooks'
import { Container, Icon } from 'components'
import { useAttentions, useAuth } from 'context'
import API from 'config/api'
import Resume from './Resume'
import CallPanel from './CallPanel'

function getCurrentLines({ lines, linesById, currentLineId }) {
  return lines.map((line) => ({
    ...linesById[line],
    isActive: currentLineId === line,
  }))
}

function MultiPanel() {
  useStatsPing({ category: 1 })
  const [isLoading, setIsLoading] = useState(true)
  const { mapInitialData, modules, modulesById, ...attentionsState } =
    useAttentions()
  const { user, currentUnit } = useAuth()

  // moduleType 2 = Usuario con llamado N-N
  // linewSourceId 2 = Usuario con llamado N-N por modulos
  const withCallByManyModules =
    user?.linesSourceId === 2 && user?.moduleType === 2

  useEffect(() => {
    async function getInitialData() {
      try {
        const getUserLinesPayload = { admissionistId: user.id }

        if (withCallByManyModules) {
          getUserLinesPayload.modules = modules
        }

        const [userLines, auxiliaryLines] = await Promise.all([
          API.getUserLines(getUserLinesPayload),
          API.getLines(user.unitId),
        ])

        // Aqui se setea el state del context en base a la respuesta del API
        mapInitialData({ ...userLines, auxiliaryLines })
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }
    getInitialData()
  }, [])

  const currentLines = getCurrentLines(attentionsState)

  if (isLoading) {
    return (
      <Container
        width="100%"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Icon icon="spinner" fontSize="6" color="primary.2" spin />
      </Container>
    )
  }

  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems="stretch"
      flexWrap="nowrap"
    >
      <Resume currentLines={currentLines} />
      <Container
        flexDirection="column"
        flexWrap="nowrap"
        height="68vh"
        overflowY="auto"
      >
        {modules.map((moduleId) => {
          const { linesByModule = {}, linesById = {} } = attentionsState
          let moduleCanCall = !withCallByManyModules

          const attentionsByModule = []
          const lines = linesByModule[moduleId] ?? []

          lines.forEach((moduleLineId) => {
            const { attentions } = linesById[moduleLineId]

            attentionsByModule.push(...attentions)

            if (attentions?.length > 0) {
              moduleCanCall = true
            }
          })

          return (
            <CallPanel
              key={moduleId}
              withCallByManyModules={withCallByManyModules}
              canCall={moduleCanCall}
              attentionsByModule={attentionsByModule}
              currentUnit={currentUnit}
              {...modulesById[moduleId]}
            />
          )
        })}
      </Container>
    </Container>
  )
}

export default MultiPanel
