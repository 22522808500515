import { useEffect, useState } from 'react'
import useDebounce from './useDebounce'
import useStateWithMerge from './useStateWithMerge'

const isEmptyObj = (obj) => Object.keys(obj).length === 0

const getInitialState = () => ({
  pagination: { page: 1, pageSize: 10 },
  order: [],
  actualOrder: {},
})
function useTableManager({
  onSearch,
  filterText,
  initialColumns = [],
  searchOnChange = [],
  extraFilters = {},
  rebuildColumnsOnChange = [],
}) {
  const [state, setState] = useStateWithMerge(getInitialState)
  const [columns, setColumns] = useState([])
  const debouncedSearchItem = useDebounce(filterText, 2000)

  const { pagination, order, actualOrder } = state
  useEffect(() => {
    //Se reinicia la paginación y orders
    retrySearch()
  }, [debouncedSearchItem, ...searchOnChange, ...Object.values(extraFilters)])

  //  Se mapean las columnas cada vez que sortea una columna
  //  Es OBLIGATORIO que se defina un key en la columna
  // para que funcione apropiadamente
  useEffect(() => {
    const columns = initialColumns.map((column) => {
      const extraProps = {}
      if (column.sorter) {
        extraProps.sortOrder =
          actualOrder.columnKey === column.key && actualOrder.order
      }
      return {
        ...column,
        ...extraProps,
      }
    })
    setColumns(columns)
  }, [actualOrder, ...rebuildColumnsOnChange])

  // Los filtros de las tablas no los solemos usar (2do parametro)
  function onTableChange(nextPagination, filtersTable, sorter) {
    const directions = {
      ascend: `ASC`,
      descend: `DESC`,
    }
    let field = null
    if (!isEmptyObj(sorter)) {
      field = sorter.column ? sorter.column.sorterPath : sorter.field
      if (field) {
        setState({
          order: [[...field.split(`.`), directions[sorter.order]]],
          actualOrder: sorter,
        })
      } else {
        setState({
          order: [],
          actualOrder: {},
        })
      }
    }
    if (!isEmptyObj(nextPagination)) {
      setState({
        pagination: {
          page: nextPagination.current,
          pageSize: nextPagination.pageSize,
        },
      })
    }
    onSearch({
      pagination: {
        page: nextPagination.current,
        pageSize: nextPagination.pageSize,
      },
      order: field
        ? [[...field.split(`.`), directions[sorter.order]]]
        : undefined,
      filter: debouncedSearchItem,
      extraFilters,
    })
  }

  // Función para gatillar una búsqueda
  // puede usarse para un retry
  function retrySearch() {
    setState(getInitialState)
    onSearch({
      pagination,
      order,
      filter: debouncedSearchItem,
      extraFilters,
    })
  }
  return { onTableChange, pagination, columns, retrySearch }
}

export default useTableManager
