import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Container, Typography } from 'components'
import { useOnlineStatus } from 'context'
import { SERVER_HOST } from 'config/constants'
import YouTube from 'react-youtube'
import { useWindowSize } from 'hooks'

const formatVideoUrl = (videoName) => `${SERVER_HOST}/assets/${videoName}`

function Video({ unit }) {
  const [source, setSource] = useState(formatVideoUrl(unit.videoName))
  const videoRef = useRef(null)
  useEffect(() => {
    setSource(formatVideoUrl(unit.videoName))
    videoRef.current.load()
  }, [unit.videoName])

  return (
    <Container
      backgroundColor="gray.0"
      height="36.5625vw"
      width="65vw"
      justifyContent="center"
      alignItems="center"
    >
      <video
        width="100%"
        height="auto"
        ref={videoRef}
        autoPlay
        loop
        muted
        // se dejo en camelcase esta prop
        // porque sino genera error de que la prop no existe
        playsInline
        className="cover"
        key={source}
      >
        <source src={source} />
      </video>
    </Container>
  )
}

function VideoWrapper({
  unit,
  currentAttention,
  videoUrl,
  fullScreen = false,
}) {
  const externalVideoUrl = videoUrl ?? unit.externalVideoUrl
  const [youtubeError, setYotubeError] = useState(true)
  const size = useWindowSize()
  const { isOnline, wasOnlineOnMount } = useOnlineStatus()
  useEffect(() => {
    if (isOnline) {
      setYotubeError(false)
    }
  }, [isOnline])
  const youtubeOptions = useMemo(() => {
    let height = size.height
    if (!fullScreen) {
      height = height - 200
    }
    const youtubeOptions = {
      height,
      width: `100%`,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        mute: 1,
        listType: `playlist`,
        loop: 1,
        playsInline: 1,
      },
    }
    //Compatible con playlist o video
    if (externalVideoUrl && externalVideoUrl.includes(`list=`)) {
      const listId = externalVideoUrl.split(`list=`)[1]
      youtubeOptions.playerVars.list = listId
    } else if (externalVideoUrl && externalVideoUrl.includes(`v=`)) {
      const videoId = externalVideoUrl.split(`v=`)[1]
      youtubeOptions.playerVars.playlist = videoId
    }
    return youtubeOptions
  }, [size, externalVideoUrl])
  const onReady = (event) => {
    event.target.mute()
    event.target.playVideo()
  }
  const onError = (event) => {
    if (!event.target) {
      // Error de internet al inciar TV
      return setYotubeError(true)
    }
    return event.target.nextVideo()
  }
  if (!externalVideoUrl || (!wasOnlineOnMount && youtubeError)) {
    return <Video unit={unit} currentAttention={currentAttention} />
  }
  if (externalVideoUrl && !youtubeError) {
    return (
      <Container display="block" width="100%">
        <YouTube opts={youtubeOptions} onReady={onReady} onError={onError} />
      </Container>
    )
  }

  return <Typography>Cargando video...</Typography>
}
export default VideoWrapper
