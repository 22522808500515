import React from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Icon, Link } from 'components'
import { useDisabled } from 'context'
import LineInfo from '../LineInfo'
import Identification from '../index'

function IdAfterLines({ location, history, unit, goBack }) {
  const { isDisabled } = useDisabled()

  return (
    <Identification goBack={goBack} unit={unit} {...unit}>
      <Container width="100%" justifyContent="center">
        <Link
          width={[`90%`, `100%`]}
          justifyContent="flex-start"
          onClick={() => history.goBack()}
        >
          <Icon color="gray.3" fontSize="6" icon="arrowLeft" />
        </Link>
        <LineInfo
          currentLine={location.state ? location.state : null}
          isDisabled={isDisabled}
          marginBottom={unit.allowPassport ? 0 : `2`}
        />
      </Container>
    </Identification>
  )
}

export default withRouter(IdAfterLines)
