import styled from 'styled-components'
import { compose, background, color } from 'styled-system'
import AntdProgress from 'antd/es/progress'

const Progress = styled(AntdProgress)`
  &.ant-progress-line {
    position: absolute !important;
    left: 0;
    bottom: -7px;
  }
  & .ant-progress-bg {
    ${compose(background, color)}
  }
`

Progress.defaultProps = {
  backgroundColor: `primary.2`,
}

export default Progress
