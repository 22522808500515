import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { TimeProvider, AttentionsProvider, useAuth } from 'context'
import Layout from './layout'
import Panel from './panel'
import Login from './Login'
import Attentions from './Attentions'
import MultiPanel from './MultiPanel'
import Stock from './Stock'
import Appointments from './Appointments'

const getRoutes = (moduleType, hasStock, hasAppointments) => {
  const routes = [
    {
      key: 1,
      path: `panel`,
      title: `Panel Inicial`,
      icon: `user`,
      component: moduleType === 2 ? MultiPanel : Panel,
    },
    {
      key: 2,
      path: `attentions`,
      title: `Buscar`,
      icon: `search`,
      component: Attentions,
    },
  ]
  if (hasStock) {
    routes.push({
      key: 3,
      path: `stock`,
      title: `Stock`,
      icon: `userClock`,
      component: Stock,
    })
  }
  if (hasAppointments) {
    routes.push({
      key: 4,
      path: `appointments`,
      title: `Agenda`,
      icon: `calendar`,
      component: Appointments,
    })
  }
  return routes
}

function Admissionist({ match }) {
  const { user, currentUnit } = useAuth()
  const hasStock = currentUnit?.hasStock
  const hasAppointments = !!currentUnit?.professionals
  const routes = getRoutes(user && user.moduleType, hasStock, hasAppointments)
  if (user == null) {
    return (
      <>
        <Redirect to="/admisionista" />
        <Login />
      </>
    )
  }
  return (
    <TimeProvider>
      <AttentionsProvider>
        <Layout routes={routes}>
          <Switch>
            {routes.map(({ path, component }) => (
              <Route
                key={path}
                path={`${match.url}/${path}`}
                component={component}
              />
            ))}
            <Redirect to={`${match.url}/${routes[0].path}`} />
          </Switch>
        </Layout>
      </AttentionsProvider>
    </TimeProvider>
  )
}

export default Admissionist
