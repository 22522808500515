import React from 'react'
import {
  CONFIG_BY_UNIT_KEY,
  PRINT_SERVER_HOST_KEY,
  CHECKIN_ACTIVE_KEY,
  IGNORE_SCHEDULE_KEY,
  AUTOPAGO_ACTIVE_KEY,
  PRESCRIPTION_SCAN_ACTIVE_KEY,
} from 'config/constants'
import { Container, Typography, Input, Img, Button } from 'components'
import { useUnit } from 'context'
import { useStateWithMerge } from 'hooks'
import { getNewUnitConfig } from 'utils'
import { LOGO_TOTEM } from 'assets'
import Unit from './Unit'

function getInitialState({
  units,
  initialCheckinActive,
  initialAutopagoActive,
  initialPrescriptionScanActive,
}) {
  const configByUnit =
    JSON.parse(localStorage.getItem(CONFIG_BY_UNIT_KEY)) || {}
  const ignoreSchedule =
    JSON.parse(localStorage.getItem(IGNORE_SCHEDULE_KEY)) || false
  units.forEach((unitId, index) => {
    configByUnit[unitId] =
      configByUnit[unitId] || getNewUnitConfig({ order: index })
  })

  localStorage.setItem(CONFIG_BY_UNIT_KEY, JSON.stringify(configByUnit))
  return {
    configByUnit,
    printServerHost: localStorage.getItem(PRINT_SERVER_HOST_KEY),
    isCheckinActive: initialCheckinActive,
    isAutopagoActive: initialAutopagoActive,
    isPrescriptionScanActive: initialPrescriptionScanActive,
    unitId: ``,
    password: ``,
    ignoreSchedule,
    error: null,
  }
}
// eslint-disable-next-line max-lines-per-function
function Config() {
  const {
    units,
    unitsById,
    unitLogin,
    checkinHost,
    isCheckinActive: initialCheckinActive,
    isAutopagoActive: initialAutopagoActive,
    isPrescriptionScanActive: initialPrescriptionScanActive,
  } = useUnit()
  const [state, setState] = useStateWithMerge(() =>
    getInitialState({
      units,
      initialCheckinActive,
      initialAutopagoActive,
      initialPrescriptionScanActive,
    }),
  )
  const {
    configByUnit,
    printServerHost,
    isCheckinActive,
    isAutopagoActive,
    isPrescriptionScanActive,
    ignoreSchedule,
    unitId,
    password,
    error,
  } = state

  function setCheckinActive() {
    setState((prevState) => {
      const nextCheckinActive = !prevState.isCheckinActive
      localStorage.setItem(CHECKIN_ACTIVE_KEY, nextCheckinActive)
      return { isCheckinActive: nextCheckinActive }
    })
  }
  function setAutopagoActive() {
    setState((prevState) => {
      const nextAutopagoActive = !prevState.isAutopagoActive
      localStorage.setItem(AUTOPAGO_ACTIVE_KEY, nextAutopagoActive)
      return { isAutopagoActive: nextAutopagoActive }
    })
  }
  function setPrescriptionScanActive() {
    setState((prevState) => {
      const nextPrescriptionScanActive = !prevState.isPrescriptionScanActive
      localStorage.setItem(
        PRESCRIPTION_SCAN_ACTIVE_KEY,
        nextPrescriptionScanActive,
      )
      return { isPrescriptionScanActive: nextPrescriptionScanActive }
    })
  }
  function setPrintServerHost(newPrintServerHost) {
    localStorage.setItem(PRINT_SERVER_HOST_KEY, newPrintServerHost)
    setState({ printServerHost: newPrintServerHost })
  }
  function setIgnoreSchedule(ignoreSchedule) {
    localStorage.setItem(IGNORE_SCHEDULE_KEY, ignoreSchedule)
    setState({ ignoreSchedule })
  }
  function changeUnitConfig(unitId, newConfig) {
    setState((prevState) => {
      const nextConfigByUnit = { ...prevState.configByUnit }
      nextConfigByUnit[unitId] = newConfig
      localStorage.setItem(CONFIG_BY_UNIT_KEY, JSON.stringify(nextConfigByUnit))
      return {
        configByUnit: nextConfigByUnit,
      }
    })
  }
  async function loginUnit() {
    setState({ error: null })
    try {
      const unit = await unitLogin(unitId, password)
      changeUnitConfig(unit.id, getNewUnitConfig({ order: units.length }))
      setState({ error: null, unitId: ``, password: `` })
    } catch (e) {
      setState({ error: e })
    }
  }
  return (
    <Container
      minHeight="100vh"
      padding="3em"
      alignItems="center"
      flexDirection="column"
    >
      <Container
        width="100%"
        height="30%"
        justifyContent="center"
        alignItems="center"
      >
        <Img src={LOGO_TOTEM} />
      </Container>
      <Container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        size="100%"
        padding="1% 10%"
      >
        <Typography color="primary.4" fontSize={6} fontWeight="bold">
          Configuración de totem para este equipo
        </Typography>
        <Typography
          color="primary.4"
          fontSize={5}
          fontWeight="100"
          padding="2%"
        >
          {`Setea host del server de impresión (default: 'http://localhost:5001')`}
        </Typography>
        <Input
          width="100%"
          padding={2}
          fontSize={5}
          value={printServerHost}
          onChange={setPrintServerHost}
        />
        <Typography
          color="primary.4"
          fontSize={5}
          fontWeight="100"
          padding="2%"
        >
          Activar Autopago
        </Typography>
        <Input
          padding={2}
          fontSize={5}
          value={isAutopagoActive}
          type="checkbox"
          onChange={setAutopagoActive}
        />
        <Typography
          color="primary.4"
          fontSize={5}
          fontWeight="100"
          padding="2%"
        >
          Activar flujo con receta medica
        </Typography>
        <Input
          padding={2}
          fontSize={5}
          value={isPrescriptionScanActive}
          type="checkbox"
          onChange={setPrescriptionScanActive}
        />
        <Typography
          color="primary.4"
          fontSize={5}
          fontWeight="100"
          padding="2%"
        >
          Activar integración checkin
        </Typography>
        <Input
          padding={2}
          fontSize={5}
          value={isCheckinActive}
          type="checkbox"
          onChange={setCheckinActive}
        />
        <Typography
          color="primary.4"
          fontSize={5}
          fontWeight="100"
          padding="2%"
        >
          URL de integración checkin
        </Typography>
        <Input
          width="100%"
          padding={2}
          fontSize={5}
          value={checkinHost}
          disabled
          onChange={setCheckinActive}
        />
        <Typography
          color="primary.4"
          fontSize={5}
          fontWeight="100"
          padding="2%"
        >
          Ignorar horarios por configuración (modo instalación).
        </Typography>
        <Input
          padding={2}
          fontSize={5}
          value={ignoreSchedule}
          type="checkbox"
          onChange={setIgnoreSchedule}
        />
        <Typography color="primary.4" fontSize={6} fontWeight="bold">
          Unidades
        </Typography>
        {units.map((unitId) => (
          <Unit
            key={unitId}
            changeUnitConfig={changeUnitConfig}
            config={configByUnit[unitId] || {}}
            id={unitId}
            name={unitsById[unitId].name}
          />
        ))}
        <Container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          withShadow
          padding="1em"
          marginY="1em"
        >
          <Typography
            color="primary.4"
            fontSize={5}
            fontWeight="bolder"
            padding="2%"
          >
            Iniciar sesión en nueva unidad
          </Typography>
          <Typography
            color="primary.4"
            fontSize={5}
            fontWeight="100"
            padding="2%"
          >
            ID Sucursal
          </Typography>
          <Input
            width="80%"
            padding={2}
            fontSize={5}
            value={unitId}
            onChange={(unitId) => setState({ unitId })}
            required
          />
          <Typography
            color="primary.4"
            fontSize={5}
            fontWeight="100"
            padding="2%"
            marginTop="2%"
          >
            Código de acceso
          </Typography>
          <Input
            placeholder="Ingresa contraseña"
            width="80%"
            name="password"
            type="password"
            value={password}
            padding={3}
            fontSize={4}
            password
            onChange={(password) => setState({ password })}
            required
          />
          <Button
            backgroundColor="primary.4"
            borderRadius=".8rem"
            marginY="0.5em"
            fontSize="5"
            padding="2%"
            width="80%"
            fontWeight="bold"
            onClick={loginUnit}
          >
            Acceder
          </Button>
          {error != null ? (
            <Typography
              color="error"
              fontSize={5}
              fontWeight="bolder"
              padding="2%"
              marginTop="2%"
            >
              {error}
            </Typography>
          ) : null}
        </Container>
      </Container>
    </Container>
  )
}

export default Config
