import { Container, MaterialIcon } from 'components'
import React, { useEffect } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import Feedbacks from './Feedbacks'

export const redirectWithoutQueuing = ({
  history,
  unitId,
  scheduleData,
  icon,
  iconColor,
  title,
  subtitle,
}) => {
  return history.push({
    pathname: `/totem/${unitId}/sinEncolamiento`,
    state: {
      redirect: `/totem`,
      timeout: 12000,
      scheduleData,
      props: {
        icon,
        iconColor,
        iconPaddingTop: `3rem`,
        title,
        subtitle,
      },
    },
  })
}

const getTitle = ({ documentsFlow }) => {
  if (documentsFlow.flow === `print`) {
    return `Listo!`
  }
  if (documentsFlow.flow === `email` && documentsFlow.status) {
    return `Correo enviado exitosamente`
  }
  if (documentsFlow.flow === `email` && !documentsFlow.status) {
    return `No se ha podido enviar correo`
  }
}

const getSubtitle = ({ documentsFlow, paymentResponse, bonusResponse }) => {
  if (documentsFlow.flow === `email` && documentsFlow.status) {
    return `Espere por su atención.`
  }

  if (documentsFlow.flow === `email` && !documentsFlow.status) {
    return `Retire sus documentos y espere por su atención.`
  }

  if (documentsFlow.flow === `print` && paymentResponse && bonusResponse) {
    return `Retire su bono, comprobante y espere por su atención.`
  }

  if (documentsFlow.flow === `print` && paymentResponse && !bonusResponse) {
    return `Retire su comprobante y espere por su atención.`
  }

  if (documentsFlow.flow === `print` && !paymentResponse && bonusResponse) {
    return `Retire su bono y espere por su atención.`
  }
}

const getIcon = ({ documentsFlow }) => {
  if (documentsFlow.flow === `email` && !documentsFlow.status) {
    return {
      icon: <MaterialIcon icon="outlineEmail" />,
      iconColor: `primary.4`,
      upperIcon: <MaterialIcon icon="error" />,
      upperIconColor: `error.3`,
      extraMidContent: <Container paddingY="2vh" />, // padding bottom exit card
    }
  }
  return {
    icon: <MaterialIcon icon="checkCircleOutline" />,
    extraMidContent: <Container paddingY="0.6vh" />, // padding bottom exit card
  }
}

export const toExit = ({
  history,
  scheduleData,
  isAutopagoFinished = false,
  action = `TO_PAYMENT`,
  sendToPrint = true,
  paymentResponse,
  bonusResponse,
  documentsFlow = { flow: `print` },
}) => {
  const { unitId, ...state } = scheduleData
  let title = getTitle({ documentsFlow })
  let subtitle = getSubtitle({ documentsFlow, paymentResponse, bonusResponse })
  let icon = getIcon({ documentsFlow })

  history.push({
    pathname: `/totem/${unitId}/print`,
    state: {
      ...state,
      action,
      isAutopagoFinished,
      sendToPrint,
      paymentResponse,
      title,
      subtitle,
      icon,
    },
  })
}

const getToExitFn = ({ hasToExit, history, scheduleData, toExitFn }) => {
  if (toExitFn) {
    return toExitFn
  }
  if (hasToExit) {
    return () => toExit({ history, scheduleData })
  }
  return undefined
}

export const genericErrorRedirect = ({
  unitId,
  scheduleData,
  data = {},
  pathToRedirect,
  history,
  iconSetting = {
    icon: <MdErrorOutline />,
    iconColor: `error.3`,
  },
  upperIconSetting = {},
  title = `Error de conexión`,
  subtitle,
  textToExit = `Hacer trámite en caja`,
  textToRetry = `Volver a intentarlo`,
  styleToExit,
  styleToRetry,
  hasToExit = true,
  iconPaddingTop = `9rem`,
  iconPaddingBottom = `3rem`,
  redirect = undefined,
  timeout = undefined,
  useOnlyFirstLevel = false,
  toExitFn = undefined,
  titleFontSize,
}) => {
  const toRetry = () => {
    const state = useOnlyFirstLevel
      ? { scheduleData, ...data }
      : { scheduleData, data }
    history.push({
      pathname: pathToRedirect,
      state,
    })
  }

  history.push({
    pathname: `/totem/${unitId}/redirigiendo`,
    state: {
      scheduleData,
      data,
      redirect,
      timeout,
      props: {
        ...iconSetting,
        ...upperIconSetting,
        iconPaddingTop,
        iconPaddingBottom,
        title,
        subtitle,
        toRetry: pathToRedirect !== undefined ? toRetry : undefined,
        toExit: getToExitFn({ hasToExit, history, scheduleData, toExitFn }),
        textToExit,
        textToRetry,
        styleToExit,
        titleFontSize,
        styleToRetry,
      },
    },
  })
}

const Redirection = () => {
  const history = useHistory()
  const { data, redirect, timeout, props } = history?.location?.state ?? {}

  useEffect(() => {
    if (redirect) {
      setTimeout(
        () => {
          history.push({ pathname: redirect, state: data })
        },
        timeout ? timeout : 2000,
      )
    }
  }, [])

  return <Feedbacks {...props} />
}

export default Redirection
