import React from 'react'
import { notification } from 'antd'
import { Container, Button, Input } from 'components'
import API from 'config/api'

export default function TransferToModule({
  currentAttention,
  activeModuleId,
  auxiliaryModules,
}) {
  const [moduleToTransfer, setModuleToTransfer] = React.useState(null)
  const modulesOptions = React.useMemo(
    () =>
      auxiliaryModules
        .filter((module) => module.id != activeModuleId)
        .map((module) => ({
          value: module.id,
          label: `${module.description} ${module.identifier}`,
        })),
    [activeModuleId, auxiliaryModules],
  )
  const canTransferToModule =
    moduleToTransfer != null && currentAttention != null

  async function transferAttention() {
    if (canTransferToModule) {
      try {
        await API.transferAttentionToModule({
          moduleId: moduleToTransfer,
          attentionId: currentAttention.id,
        })
        setModuleToTransfer(null)
      } catch (e) {
        const error =
          typeof e === `string` ? e : `No se ha podido derivar el ticket`
        notification.error({ message: error })
      }
    }
  }
  return (
    <Container flexDirection="column">
      <Input
        placeholder="Escriba aquí"
        width="100%"
        fontSize="2"
        margin="0.5em 0"
        type="select"
        options={modulesOptions}
        value={moduleToTransfer}
        onChange={setModuleToTransfer}
      />
      <Button
        onClick={transferAttention}
        backgroundColor={canTransferToModule ? `primary.8` : `gray.0`}
        color={canTransferToModule ? `white` : `gray.3`}
        marginY="2"
        padding="0.5em"
        fontSize="4"
        width="100%"
        textAlign="center"
        disabled={!canTransferToModule}
      >
        DERIVAR A OTRO MODULO
      </Button>
    </Container>
  )
}
