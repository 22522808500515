/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import API from 'config/api'
import { useAuth, useAttentions } from 'context'
import { useStateWithMerge } from 'hooks'
import { clean, format, validate } from 'rut.js'

import { Container, Typography, Button, Input, Icon } from 'components'
import notification from 'antd/es/notification'

function decomposeRut(patientRut) {
  if (!patientRut) {
    return []
  }
  const [rawRut, dv] = format(patientRut).split(`-`)
  return [parseInt(clean(rawRut), 10), dv]
}
const getInitialState = ({
  patientName,
  patientRut,
  document,
  isAvailable,
}) => ({
  stockTypes: [],
  stockTypeId: 1,
  isLoading: true,
  isEditing: false,
  amount: 0,
  isAvailable,
  patientRut,
  document,
  patientName,
})
function StockAdmissionistModal(props) {
  const {
    user: { id: userId },
    currentUnit: { id: unitId },
  } = useAuth()
  const { setStockId } = useAttentions()
  const [state, setState] = useStateWithMerge(() => getInitialState(props))
  const {
    isLoading,
    amount,
    stock,
    isLapsed,
    isEditing,
    isAvailable,
    patientName,
    patientRut,
    document,
    attentionId,
    stockTypes,
    stockTypeId,
  } = state

  async function getStockTypes() {
    try {
      const stockTypes = await API.getStockTypes()
      return setState({ stockTypes })
    } catch (error) {
      console.log(`error`)
    }
  }
  const getOptions = () => {
    const options = []
    stockTypes.forEach((stockType) => {
      const option = {
        label: stockType.name,
        value: stockType.id,
      }
      options.push(option)
    })
    return options
  }
  function setStockType(option) {
    setState({ stockTypeId: option })
  }

  async function getStock(stockTypeId) {
    try {
      const [rut, dv] = decomposeRut(patientRut)
      const { isLapsed, stock } = await API.getStock({
        rut,
        dv,
        document,
        unitId,
        isAvailable,
        stockTypeId,
      })
      setState({
        isAvailable: stock ? stock.isAvailable : true,
        amount: stock ? stock.amount : null,
        stock,
        isLapsed,
        isLoading: false,
      })
    } catch (e) {
      const error =
        typeof e === `string` ? e : `Ha ocurrido un problema, reintente...`
      notification.error({ message: error })
    }
  }
  useEffect(() => {
    getStockTypes()
  }, [])

  useEffect(() => {
    getStock(stockTypeId)
  }, [stockTypeId])

  async function createStock() {
    const isRutValid = validate(clean(patientRut))
    try {
      if (!isRutValid) {
        throw Error(`El rut es inválido`)
      }
      const [rut, dv] = decomposeRut(patientRut)
      const payload = {
        rut,
        dv,
        document,
        amount,
        unitId,
        createdById: userId,
        patientName,
        attentionId,
        stockTypeId,
      }
      const newStock = await API.createStock(payload)
      setStockId(newStock.id)
      setState({ stock: { ...newStock, attentions: [] }, isLapsed: false })
      notification.success({ message: `Stock creado` })
    } catch (e) {
      const error =
        typeof e === `string` ? e : `Ha ocurrido un problema, reintente...`
      if (e.message) {
        notification.error({ message: e.message })
      } else {
        notification.error({ message: error })
      }
    }
  }

  async function editStock() {
    try {
      await API.editStock({
        ...stock,
        amount,
        isAvailable,
        patientName,
        updatedById: userId,
        stockTypeId,
      })
      notification.success({ message: `Stock editado` })
      setState((prevState) => ({
        isEditing: false,
        stock: { ...prevState.stock, amount },
      }))
    } catch (e) {
      const error =
        typeof e === `string` ? e : `Ha ocurrido un problema, reintente...`
      notification.error({ message: error })
    }
  }

  function setField(value, field) {
    return setState({ [field]: value })
  }

  return (
    <Container flexDirection="column" isLoading={isLoading}>
      <Input
        label="Tipo de Stock"
        name="stockType"
        value={stockTypeId}
        onChange={(option) => setState({ stockTypeId: option })}
        options={getOptions()}
        type="select"
        marginY="2"
        width={182}
      />
      {!stock || isLapsed || !stock?.isAvailable ? (
        <>
          <Typography fontSize="4">Usuario no posee stock vigente</Typography>
          <FormStock
            disabled={amount <= 0 || !patientRut || !patientName}
            setField={setField}
            onConfirm={createStock}
            getOptions={getOptions}
            stockTypeId={stockTypeId}
            setStockType={setStockType}
            {...state}
          />
        </>
      ) : (
        <>
          <Container alignItems="baseline">
            <Typography fontSize="5" color="primary.2">
              Sesiones realizadas {stock.attentions.length}/{stock.amount}
            </Typography>
            <Icon
              fontSize="4"
              marginX="2"
              icon="edit"
              color="primary.2"
              onClick={() =>
                setState((prevState) => ({ isEditing: !prevState.isEditing }))
              }
            />
            {isAvailable ? null : (
              <Typography fontSize="3" fontStyle="italic" color="gray.3">
                (Deshabilitado)
              </Typography>
            )}
          </Container>
          {isEditing ? (
            <FormStock
              pastAttentions={stock.attentions.length}
              disabled={!patientRut && !document}
              setField={setField}
              onConfirm={editStock}
              getOptions={getOptions}
              stockTypeId={stockTypeId}
              setStockType={setStockType}
              {...state}
            />
          ) : null}
          <Typography fontSize="4">Detalle:</Typography>
          <Container flexDirection="column">
            {stock.attentions.length === 0 ? (
              <Typography>No hay información aún</Typography>
            ) : (
              stock.attentions.map((attention) => {
                const {
                  id,
                  getDate,
                  line: { name: lineName },
                } = attention
                return (
                  <Container
                    key={id}
                    backgroundColor="gray.0@0.4"
                    padding="2"
                    borderRadius="10px"
                    marginBottom="2"
                  >
                    <Typography fontSize="3" marginRight="4">
                      Cita: {moment(getDate).format(`LLLL [hrs.]`)}
                    </Typography>
                    <Typography fontSize="3">Servicio: {lineName}</Typography>
                  </Container>
                )
              })
            )}
          </Container>
        </>
      )}
    </Container>
  )
}

function FormStock({
  amount,
  setField,
  disabled,
  onConfirm,
  pastAttentions,
  isAvailable,
  patientRut,
  patientName,
}) {
  const isEditing = pastAttentions != null

  return (
    <Container flexDirection="column" width="100%">
      <Input
        label="RUT del paciente"
        name="patientRut"
        value={patientRut ? format(patientRut) : null}
        disabled
        onChange={setField}
        type="text"
      />
      <Input
        label="Nombre del paciente"
        name="patientName"
        value={patientName}
        onChange={setField}
        type="text"
        marginY="2"
      />
      <Container alignItems="flex-end" marginBottom="2">
        <Input
          label="Nueva cantidad"
          name="amount"
          value={amount}
          onChange={setField}
          type="number"
          min={pastAttentions || 0}
        />
        <Button
          marginLeft="4"
          paddingY="0.35rem"
          paddingX="3"
          backgroundColor="primary.2"
          disabled={disabled}
          onClick={onConfirm}
        >
          {isEditing ? `Editar` : `Crear`}
        </Button>
      </Container>
      {isEditing ? (
        <Container>
          <Typography marginRight="3">Stock disponible:</Typography>
          <Input
            name="isAvailable"
            onChange={setField}
            value={isAvailable}
            type="checkbox"
          />
        </Container>
      ) : null}
    </Container>
  )
}

export default StockAdmissionistModal
