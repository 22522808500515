import styled from 'styled-components'
import {
  compose,
  system,
  layout,
  space,
  color,
  border,
  flexbox,
  typography,
} from 'styled-system'
import { Link } from 'react-router-dom'
import { setProperty } from 'utils'
const LinkStyled = styled(Link)`
  ${compose(
    layout,
    space,
    flexbox,
    color,
    typography,
    system({ textDecoration: true }),
    border,
  )}
  ${({ withShadow = false, overflowY = null, onClick = null }) => `
  ${setProperty(`overflow-y`, overflowY)}
  ${withShadow ? `box-shadow: 1px 3px 9px rgba(0,0,0,0.5) !important;` : ``}
  ${onClick == null ? `` : `  cursor: pointer`};
  `}

  & > * {
    ${compose(color, typography, system({ textDecoration: true }))}
  }

  &:hover {
    ${({ hoverProps, ...props }) =>
      compose(
        layout,
        space,
        flexbox,
        color,
        border,
      )({ ...props, ...hoverProps })}
    & > * {
      ${({ hoverProps, ...props }) =>
        compose(
          color,
          typography,
          system({ textDecoration: true }),
        )({ ...props, ...hoverProps })}
    }
  }
`
LinkStyled.defaultProps = {
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  width: `100%`,
  padding: `0.8em`,
  marginBottom: `1em`,
  hoverProps: null,
  maxWidth: `100%`,
}

export default LinkStyled
