/* eslint-disable max-len */
import React, { useState } from 'react'
import { Container } from 'components'
import CallButton from './CallButton'
import EndButton from './EndButton'
import RecallButton from './RecallButton'
import AbsentButton from './AbsentButton'

export default function ActionsRow({
  currentLines,
  currentAttentions,
  editTicketPatient,
  planTicketJourney,
  auxiliaryLines,
  currentAttention,
  isAccessTypeRestricted,
  user,
  currentUnit,
  isUserAvailable,
}) {
  const [isRecalling, setIsRecalling] = useState(false)
  const [isCalling, setIsCalling] = useState(false)

  const canPerformAction =
    currentAttention != null && !isRecalling && !isCalling
  return (
    <Container width="100%" marginTop="0.5em" alignItems="stretch">
      <RecallButton
        attentionId={currentAttention && currentAttention.id}
        isRecalling={isRecalling}
        setIsRecalling={setIsRecalling}
        enabled={canPerformAction && currentAttention.accessType != 7} //Si es ticket de messagebird (kitchencenter) no se permite rellamado
      />
      <EndButton
        attentionId={currentAttention && currentAttention.id}
        suggestedRefs={currentAttention ? currentAttention.suggestedLines : []}
        editTicketPatient={editTicketPatient}
        planTicketJourney={planTicketJourney}
        currentAttention={currentAttention}
        auxiliaryLines={auxiliaryLines}
        enabled={canPerformAction}
        isAccessTypeRestricted={isAccessTypeRestricted}
        currentUnit={currentUnit}
      />
      {currentAttention == null ? (
        <CallButton
          currentAttentions={currentAttentions}
          currentLines={currentLines}
          setIsCalling={setIsCalling}
          user={user}
          currentUnit={currentUnit}
          isUserAvailable={isUserAvailable}
        />
      ) : (
        <AbsentButton
          currentAttention={currentAttention}
          enabled={canPerformAction}
          isAccessTypeRestricted={isAccessTypeRestricted}
          currentUnit={currentUnit}
        />
      )}
    </Container>
  )
}
