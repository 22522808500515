import React, { useMemo } from 'react'
import { Button, Container, Input, Modal, Typography } from 'components'
import API from 'config/api'
import { useStateWithMerge } from 'hooks'
import { useAttentions, useAuth } from 'context'
import notification from 'antd/es/notification'
import { getPropsByConditionToButtons } from 'utils'
const { clean, format, validate } = require(`rut.js`)

const initialState = {
  rut: ``,
  openModal: false,
  selectedLine: null,
}

function ExceptionalButton({ currentLines, width }) {
  const [state, setState] = useStateWithMerge(initialState)
  const { user, currentUnit, isUserAvailable } = useAuth()
  const { rut, openModal, selectedLine } = state
  const {
    setRequestInQueue,
    activeModuleId,
    currentAttention,
    requestInQueue,
  } = useAttentions()
  const { callTypeId } = currentUnit
  const canSubmit = validate(rut) && selectedLine != null
  async function onSubmit() {
    try {
      const [patientRut, patientRutDv] = rut.replaceAll(`.`, ``).split(`-`)
      const { attentionId } = await API.addTicket({
        patientRut,
        patientRutDv,
        lineId: selectedLine,
        isExceptional: true,
        userId: user.id,
        unitId: user.unitId,
      })
      const request = API.callAttention
      const payload = {
        userId: user.id,
        moduleId: activeModuleId,
        attentionId,
        isAhead: true,
      }
      if (callTypeId === 2 && currentAttention) {
        // Si es autollamado se guarda la funcion y payload
        // para ser llamada una vez termine de atender al numero actual
        notification.success({
          message: `Atención excepcional será llamada una vez finalice la atención actual`,
        })
        setRequestInQueue({ request, payload })
      } else {
        // Para el flujo normal, solo se ejecuta la request
        await request(payload)
      }
      setState({ openModal: false, selectedLine: null, rut: `` })
    } catch (e) {
      const error = typeof e === `string` ? e : `Ocurrio un error inesperado.`
      notification.error({ message: error, duration: 30 })
    }
  }

  const linesOptions = useMemo(() => {
    const options = []
    currentLines.forEach((line) => {
      if (line.id != 0) {
        options.push({ label: line.name, value: line.id })
      }
    })
    return options
  }, [currentLines])
  const canOpenModal =
    isUserAvailable &&
    (!currentAttention ||
      (callTypeId === 2 && user.moduleType === 1 && !requestInQueue))
  return (
    <Container width={width}>
      <Button
        flex={2}
        disabled={!canOpenModal}
        {...getPropsByConditionToButtons(canOpenModal)}
        paddingY="1"
        paddingX="2"
        fontSize="1"
        onClick={() => setState({ openModal: true })}
      >
        Ingreso excepcional
      </Button>
      <Modal
        isVisible={openModal}
        title="Ingreso excepcional"
        width="500px"
        onClose={() =>
          setState({ openModal: false, selectedLine: null, rut: `` })
        }
      >
        <Container>
          <Typography
            padding="2%"
            color="primary.4"
            fontSize={2}
            fontWeight="100"
            paddingTop="0px"
          >
            Rut:
          </Typography>
          <Input
            placeholder="Ingrese rut"
            value={rut}
            onChange={(value) =>
              setState({ rut: value.length ? format(clean(value)) : `` })
            }
            width="100%"
            fontSize="2"
            margin="0.5em 0"
          />
        </Container>
        <Container>
          <Typography
            color="primary.4"
            fontSize={2}
            fontWeight="100"
            padding="2%"
          >
            Fila:
          </Typography>
          <Input
            type="select"
            value={selectedLine}
            options={linesOptions}
            fontSize="2"
            margin="0.5em 0"
            onChange={(selectedLine) => setState({ selectedLine })}
            width="100%"
          />
        </Container>
        <Container justifyContent="flex-end">
          <Button
            marginTop="20px"
            paddingY="2"
            paddingX="4"
            backgroundColor={canSubmit ? `primary.2` : `gray.0`}
            color={canSubmit ? `white` : `gray.3`}
            disabled={!canSubmit}
            onClick={onSubmit}
          >
            Aceptar
          </Button>
        </Container>
      </Modal>
    </Container>
  )
}

export default ExceptionalButton
