import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import API from 'config/api'
import Icon from 'components/MaterialIcon'
import { readFingerprint } from 'utils/fingerprint'
import { useAutopago } from 'context/AutopagoContext'
import Feedbacks from './Feedbacks'
import { genericErrorRedirect } from './Redirection'

const handleSaveFingerprintError = async (scheduleData) => {
  try {
    const autopagoRecordId = await API.saveFingerprintError(scheduleData)
    return autopagoRecordId
  } catch (error) {
    return undefined
  }
}

function Fingerprint({ unit, flushInterval }) {
  const history = useHistory()
  const { scheduleData, data, posType } = history?.location?.state ?? {}
  const {
    autopagoRecordId: lastAutopagoRecordId,
    totemIp,
    handleSetAutopagoRecordId,
  } = useAutopago()
  const scanFingerPrint = async () => {
    let fingerprintResponse
    try {
      setTimeout(() => flushInterval(), 500)
      fingerprintResponse = await readFingerprint(
        data?.accompanistRut?.replaceAll(`.`, ``) ?? data?.patientRut,
      )
    } catch (error) {
      const autopagoRecordId = await handleSaveFingerprintError({
        ...data,
        ...scheduleData,
        autopagoRecordId: lastAutopagoRecordId,
        totemIp,
        errorDescription: error,
      })
      handleSetAutopagoRecordId?.(autopagoRecordId)
      genericErrorRedirect({
        unitId: unit.id,
        scheduleData,
        data: {
          data,
          posType,
        },
        useOnlyFirstLevel: true,
        pathToRedirect: `/totem/${unit.id}/huella`,
        title: `Ups!`,
        subtitle: `Tenemos problemas para completar su solicitud`,
        iconSetting: {
          icon: <Icon icon="language" />,
          iconColor: `primary.4`,
        },
        upperIconSetting: {
          upperIcon: <Icon icon="error" />,
          upperIconColor: `error.3`,
        },
        history,
      })
    }
    try {
      const admissionPayload = {
        ...scheduleData,
        ...data,
        preBonusId: data.appointment.preBonusId,
        auditingId: fingerprintResponse.result.parsedResponse.auditingId,
        readerCode: fingerprintResponse.result.parsedResponse.readerCode,
        autopagoRecordId: lastAutopagoRecordId,
        totemIp,
      }
      const admissionResponse = await API.validateImedAdmission(
        admissionPayload,
      )
      history.push({
        pathname: `/totem/${unit.id}/redirigiendo`,
        state: {
          data: {
            ...history?.location?.state,
            scheduleData,
            validateAdmissionResponse: admissionResponse.parsedResponse,
            appointmentData: data,
            fingerprintResponse: fingerprintResponse?.result?.parsedResponse,
            posType,
          },
          redirect: `/totem/${unit.id}/copago`,
          props: {
            icon: <Icon icon="fingerprint" />,
            iconColor: `primary.4`,
            iconPaddingTop: `3.5rem`,
            upperIcon: <Icon icon="checkCircle" />,
            upperIconColor: `green.0`,
            title: `Huella ingresada exitosamente`,
          },
        },
      })
    } catch (e) {
      const { autopagoRecordId: newAutopagoRecordId } = e
      const autopagoRecordId = newAutopagoRecordId || lastAutopagoRecordId
      handleSetAutopagoRecordId?.(autopagoRecordId)
      genericErrorRedirect({
        unitId: unit.id,
        scheduleData,
        history,
        title: `Lo sentimos`,
        subtitle: `No se puede realizar su solicitud. Retire su ticket y espere su atención en caja.`,
        redirect: `/totem/${unit.id}/print`,
        timeout: 4000,
        data: { ...scheduleData, action: `TO_PAYMENT` },
        hasToExit: false,
        iconSetting: {
          icon: <Icon icon="errorOutline" />,
          iconColor: `primary.4`,
        },
      })
    }
  }

  useEffect(() => {
    scanFingerPrint()
  }, [])

  return (
    <Feedbacks
      icon={<Icon icon="fingerprint" />}
      iconColor="primary.4"
      iconPaddingTop="3.5rem"
      title="Ponga el dedo indicado en el lector de huella…"
    />
  )
}

export default Fingerprint
