import { Button, Container, Typography, Icon } from 'components'
import React from 'react'
import { useHistory } from 'react-router-dom'

const PaymentType = ({ unit }) => {
  const history = useHistory()
  const state = history?.location?.state ?? {}
  const redirectToPayment = (type) => {
    history.push({
      pathname: `/totem/${unit.id}/pagar`,
      state: {
        ...state,
        type,
      },
    })
  }
  const goBack = () => {
    history.push({
      pathname: `/totem/${unit.id}/copago`,
      state: {
        ...state,
      },
    })
  }
  return (
    <Container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      padding="0 9.375vw"
    >
      <Container width="100%">
        <Button backgroundColor="transparent" paddingY="3" onClick={goBack}>
          <Icon icon="arrowLeft" color="gray.3" fontSize="6" />
        </Button>
      </Container>
      <Typography fontSize="6vw" fontWeight="700" margin="1vh 0">
        Elije tu medio de pago
      </Typography>
      <Button
        data-testid="debitButton"
        shadow="0px 5px 5px 1px rgba(0, 0, 0, .1)"
        backgroundColor="primary.4"
        color="white"
        fontSize="5vw"
        padding={3}
        borderRadius="1.5vw"
        height="12vh"
        margin="1.5vh 0"
        width="100%"
        onClick={() => redirectToPayment(`debit`)}
      >
        Débito
      </Button>
      <Button
        data-testid="creditButton"
        shadow="0px 5px 5px 1px rgba(0, 0, 0, .1)"
        backgroundColor="primary.4"
        color="white"
        fontSize="5vw"
        padding={3}
        borderRadius="1.5vw"
        height="12vh"
        margin="1.5vh 0"
        width="100%"
        onClick={() => redirectToPayment(`credit`)}
      >
        Crédito
      </Button>
    </Container>
  )
}

export default PaymentType
