import { clean, format } from 'rut.js'

export function formatSeconds(seconds = 0) {
  if (!seconds) {
    return `-`
  }
  return seconds > 3600
    ? new Date(seconds * 1000).toISOString().substr(11, 8)
    : new Date(seconds * 1000).toISOString().substr(14, 5)
}
export function capitalizeFirstLetter(word) {
  return word.replace(/\w/, (l) => l.toUpperCase())
}

export function normalizeString(rawText) {
  return rawText
    .toLowerCase()
    .normalize(`NFD`)
    .replace(/[\u0300-\u036f]/g, ``)
}

export function removeIndexFromArray(array, indexToRemove) {
  return array.filter((_, index) => index != indexToRemove)
}

export function getEmptyAttention(i) {
  return {
    id: `${i}-${Date.now()}`,
    shortIdentifier: ``,
    moduleShortIdentifier: ``,
    longIdentifier: ``,
    moduleLongIdentifier: ``,
  }
}

export function getNewUnitConfig(newUnit = {}) {
  return {
    isActive: false,
    visibleLines: [],
    showOnlyVirtualTicket: false,
    order: 0,
    ...newUnit,
  }
}

export function decomposeRut(patientRut) {
  if (!patientRut) {
    return []
  }
  const [rawRut, dv] = format(patientRut).split(`-`)
  return [parseInt(clean(rawRut), 10), dv]
}
export function isNumber(str) {
  if (typeof str != `string`) {
    return false
  }
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export const parseToPesosCL = Intl.NumberFormat(`es-CL`, {
  style: `currency`,
  currency: `CLP`,
})

export const handleEmailMask = (email) => {
  const emailSplited = email.split(`@`)
  const emailCharsHidden = emailSplited[0]
    .split(``)
    .map((char, index, arr) =>
      index === 0 || index === 1 || index === arr.length - 1 ? char : `*`,
    )
  return `${emailCharsHidden.join(``)}@${emailSplited[1]}`
}

export function validateEmail(email) {
  if (!email) {
    return false
  }
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  )
  return pattern.test(email.trim())
}
export const getPropsByConditionToButtons = (condition) => ({
  backgroundColor: condition ? `white` : `gray.0`,
  color: condition ? `primary.8` : `gray.3`,
  border: `1px`,
  borderStyle: `solid`,
  borderColor: condition ? `primary.8` : `transparent`,
  borderRadius: `0.2rem`,
})
