import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { print } from 'utils'
import { useHistory } from 'react-router-dom'
import { Container, Button, Typography, Icon, MaterialIcon } from 'components'
import ContactChannel from './ContactChannel'

function ScheduleError({
  title,
  message,
  sendToPrint = true,
  continueToServices = false,
  goToLines,
  unitName,
  country,
}) {
  const history = useHistory()
  const {
    mutate: printError,
    isLoading: isPrinting,
    isSuccess: isSuccessPrinted,
  } = useMutation((ticket) => print(ticket))
  useEffect(() => {
    if (sendToPrint) {
      printError({ errorMessage: message, unitName })
    }
  }, [])
  function handleConfirm() {
    if (continueToServices) {
      goToLines()
    } else {
      history.goBack()
    }
  }
  return (
    <>
      <Container
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
        width="100%"
        height="50%"
      >
        <MaterialIcon
          icon="errorOutline"
          googleIcon
          fontSize="10"
          color="error.3"
        />
        <Typography
          fontSize="6"
          textAlign="center"
          fontWeight={2}
          color="gray.4"
          width="100%"
        >
          {title}
        </Typography>
        <Typography
          fontSize="2"
          textAlign="center"
          fontWeight={0}
          color="gray.4"
          width="100%"
        >
          {message}
        </Typography>
      </Container>
      {country === `PE` ? <ContactChannel contacts={null} /> : null}
      <Button
        disabled={isPrinting}
        shadow="0px 4px 8px 1px #D0D0D0"
        backgroundColor="primary.4"
        width="100%"
        borderRadius="12px"
        paddingY="3"
        onClick={handleConfirm}
      >
        <Typography color="white" fontSize="5">
          Entendido
        </Typography>
      </Button>
      {sendToPrint ? (
        <Container alignItems="center" paddingY="2">
          <Icon
            marginRight="2"
            color={isPrinting ? `primary.4` : `warning`}
            icon={isPrinting ? `circleNotch` : `warning`}
            spin={isPrinting}
          />
          <Typography>
            {isPrinting
              ? `Imprimiendo ticket...`
              : isSuccessPrinted
              ? `Retire su ticket`
              : `No se ha podido imprimir su ticket`}
          </Typography>
        </Container>
      ) : null}
    </>
  )
}

export default ScheduleError
