import React from 'react'
import { withRouter } from 'react-router-dom'
import { Container } from 'components'
import SideBar from './SideBar'
import Header from './Header'

function Layout({ children, routes, location: { pathname }, ...props }) {
  return (
    <Container width="100%" flexWrap="no-wrap" {...props} height="100vh">
      <Container
        width="14%"
        height="100%"
        paddingLeft="1em"
        alignContent="flex-start"
        backgroundColor="primary.8"
        zIndex={3}
      >
        <SideBar routes={routes} activeRoute={pathname} />
      </Container>
      <Container
        width="86%"
        flexDirection="column"
        height="100%"
        flexWrap="no-wrap"
      >
        <Header
          title={(routes.find((route) => route.path === pathname) || {}).title}
        />
        <Container width="100%" padding="1em 2em" overflowY="auto">
          {children}
        </Container>
      </Container>
    </Container>
  )
}

export default withRouter(Layout)
