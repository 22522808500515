import React, { useEffect, useState } from 'react'
import { notification } from 'antd'
import { Container, Input } from 'components'
import { useAttentions, useAuth } from 'context'
import { useIO, useStateWithMerge, usePolling, useStatsPing } from 'hooks'
import API from 'config/api'
import CallPanel from './CallPanel'
import SpecialActions from './SpecialActions'
import Line from './Line'
import Tickets from './Tickets'
//SACAR CUANDO SE IMPLEMENTE ALGORITMO DE TIEMPO CONSISTENTE
const waitPerAttention = 5
function getLinesSourceInitialState({
  moduleLines = [],
  userLines = [],
  user: { linesSourceId = 2 },
}) {
  //Por defecto sea la que esta configurada
  let linesSource = linesSourceId
  if (linesSourceId > 2) {
    //Si es libre eleccion usar la modalidad que tenga mas filas
    linesSource = moduleLines.length > userLines.length ? 2 : 1
  }
  return {
    linesSource,
    linesSources: [
      {
        value: 1,
        label: `Filas por admisionista`,
        disabled: linesSourceId === 2,
      },
      {
        value: 2,
        label: `Filas por modulo`,
        disabled: linesSourceId === 1,
      },
    ],
  }
}

//Dependiendo de si es por fila o por modulo se calcula la prioridad por config
//Si no tiene una prioridad para ese admisionista o modulo se usa la de la fila
function getPriorityAndAttentions({
  line: { priorityOrderByLineSource, attentions },
  activeModuleId,
  linesSource,
}) {
  const customPriority =
    linesSource === 2
      ? priorityOrderByLineSource[2][activeModuleId]
      : priorityOrderByLineSource[1]
  const priority = customPriority ?? priorityOrderByLineSource[0]
  return [priority, attentions.length]
}
function getCurrentLines({
  userLines = [],
  moduleLines = [],
  linesById,
  currentLineId,
  attentions,
  attentionsById,
  linesSource,
  linePriorityModeId,
  activeModuleId,
  profile,
}) {
  const lines = linesSource === 2 ? moduleLines : userLines
  let currentAttentions = []
  let currentLines = []
  if (lines.length > 0) {
    if (linePriorityModeId === 2) {
      let maxPriorityId = null
      lines.forEach((lineId) => {
        const [priority, attentionsNumber] = getPriorityAndAttentions({
          line: linesById[lineId],
          activeModuleId,
          linesSource,
        })
        linesById[lineId].priority = priority
        if (maxPriorityId == null) {
          //Si es la primera fila se setea ella como la prioritaria
          maxPriorityId = lineId
        } else {
          const [prevPriority, prevAttentionsNumber] = getPriorityAndAttentions(
            {
              line: linesById[maxPriorityId],
              activeModuleId,
              linesSource,
            },
          )
          if (
            //Si la prioridad de la fila actual es la misma que la anterior
            //La fila prioritaria es la que tenga más atenciones en la cola
            (priority === prevPriority &&
              attentionsNumber > prevAttentionsNumber) ||
            //Si la prioridad actual es mayor que la anterior se setea esta
            //Si tiene al menos una atención en cola O si la anterior tenia 0
            (priority < prevPriority &&
              (attentionsNumber > 0 || prevAttentionsNumber == 0)) ||
            //Si la prioridad actual es mayor que la anterior se setea esta
            //SOLO si la anterior tenia 0 atenciones Y esta tiene al menos una
            (priority > prevPriority &&
              attentionsNumber > 0 &&
              prevAttentionsNumber == 0)
          ) {
            maxPriorityId = lineId
          }
        }
      })
      currentAttentions = attentions.filter((attentionId) => {
        const { lineId } = attentionsById[attentionId]
        return maxPriorityId === lineId
      })
      currentLines = lines.map((line) => ({
        ...linesById[line],
        isActive: maxPriorityId === line,
        hasDelay: maxPriorityId === line,
      }))
    } else if (linePriorityModeId === 4 && profile != null) {
      lines.forEach((id) => {
        const line = linesById[id]
        if (profile.linesConfig[id]) {
          line.isActive = profile.currentLineId === id
          line.hasDelay = profile.currentLineId === id
          currentLines.push(line)
        }
      })
      currentAttentions = attentions.filter((attentionId) => {
        const { lineId } = attentionsById[attentionId]
        return profile.currentLineId === lineId
      })
    } else {
      let totalAttentions = 0
      currentAttentions = attentions.filter((attentionId) => {
        const { lineId } = attentionsById[attentionId]
        if (lines.includes(lineId)) {
          const isAnalogLine = linesById[lineId].lineTypeId === 3
          if (!isAnalogLine) {
            totalAttentions += 1
          }
          return (
            (currentLineId === 0 && !isAnalogLine) || currentLineId === lineId
          )
        } else {
          return false
        }
      })
      currentLines = [
        {
          id: 0,
          name: `Todos`,
          waitingPeople: totalAttentions,
          waitTime: totalAttentions * waitPerAttention,
          isActive: currentLineId === 0,
          hasDelay: currentLineId === 0,
          lineTypeId: 1,
        },
        ...lines.map((line) => ({
          ...linesById[line],
          isActive: currentLineId === line,
          hasDelay: currentLineId === line,
        })),
      ]
    }
  }

  const mappedCurrentAttentions = currentAttentions.map(
    (attentionId) => attentionsById[attentionId],
  )

  return {
    currentLines,
    currentAttentions,
    mappedCurrentAttentions,
  }
}
// eslint-disable-next-line max-lines-per-function
function Panel() {
  useStatsPing({ category: 1 })
  const [isLoading, setIsLoading] = useState(true)
  const {
    changeCurrentLine,
    mapInitialData,
    userLines,
    linesByModule,
    activeModuleId,
    linesById,
    currentLineId,
    currentLineTypeId,
    attentions,
    attentionsById,
    setRequestInQueue,
    currentAttention,
    currentAttentionByTicket,
    requestInQueue,
    filterAttentionsByProfessional,
    profile,
  } = useAttentions()
  const moduleLines = linesByModule[activeModuleId]
  const {
    user,
    currentUnit: { linePriorityModeId, restrictLinesSwitch, hasStock },
    modules,
    setActivityTypes,
    logOut,
  } = useAuth()
  const [{ linesSource, linesSources }, setLinesSource] = useStateWithMerge(
    () => getLinesSourceInitialState({ moduleLines, userLines, user }),
  )
  const [professionalFilter, setProfesionalFilter] = useState(null)
  const { currentLines, currentAttentions, mappedCurrentAttentions } =
    getCurrentLines({
      userLines,
      linesById,
      currentLineId,
      attentions,
      attentionsById,
      linesSource,
      moduleLines,
      linePriorityModeId,
      activeModuleId,
      profile,
    })
  useIO({
    emitPayload: user.id,
    subscribeEvent: `kick`,
    subscribeEventHandler: () => {
      notification.error({ message: `Su sesión ha terminado` })
      logOut()
    },
  })

  useEffect(() => {
    async function getInitialData() {
      try {
        const [
          userLines,
          activityTypes,
          auxiliaryLines,
          motives,
          currentActivityType,
          { modules: auxiliaryModules },
        ] = await Promise.all([
          API.getUserLines({ admissionistId: user.id, modules }),
          API.getActivityTypes(user.unitId),
          API.getLines(user.unitId),
          API.getMotives({ unitId: user.unitId }),
          API.getCurrentActivityType(user.id),
          API.getModules(user.unitId),
        ])
        if (linePriorityModeId === 4) {
          const profile = await API.getComposedData()
          userLines.profile = profile
        }
        mapInitialData({
          auxiliaryLines,
          auxiliaryModules,
          ...motives,
          ...userLines,
        })
        setActivityTypes({ ...activityTypes, currentActivityType })
        filterAttentionsByProfessional(professionalFilter)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }
    getInitialData()
  }, [professionalFilter])
  useEffect(() => {
    async function callException() {
      if (requestInQueue && !currentAttention) {
        try {
          const { request, payload } = requestInQueue
          await request(payload)
        } catch (e) {
          let error = `No se ha podido realizar el llamado, reintente.`
          if (e && e.error && typeof e.error === `string`) {
            error = e.error
          }
          notification.error({ message: error, duration: 30 })
        } finally {
          setRequestInQueue(null)
        }
      }
    }
    callException()
  }, [currentAttention])
  //TODO-START: CAMBIAR TODO EL ENFOQUE DE POLLING A UNO MEJOR
  async function fetchUserLines() {
    try {
      const userLines = await API.getUserLines({
        admissionistId: user.id,
        modules,
      })
      if (linePriorityModeId === 4) {
        const nextProfile = await API.getComposedData()
        userLines.profile = nextProfile
      }
      mapInitialData(userLines)
      if (professionalFilter) {
        filterAttentionsByProfessional(professionalFilter)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }
  usePolling({ pollingFn: fetchUserLines, pollingTimeInSeconds: 60 })

  useIO({
    emitPayload: `admissionist`,
    subscribeEvent: `refreshQueue`,
    subscribeEventHandler: fetchUserLines,
  })
  //TODO-END: CAMBIAR TODO EL ENFOQUE DE POLLING A UNO MEJOR
  useEffect(() => {
    setLinesSource(getLinesSourceInitialState({ moduleLines, userLines, user }))
    if (professionalFilter) {
      filterAttentionsByProfessional(professionalFilter)
    }
  }, [moduleLines, userLines])
  const professionalNameFilters = {}
  const professionalNameOptions = mappedCurrentAttentions
    .filter((attention) => {
      if (!professionalNameFilters[attention.professionalName]) {
        professionalNameFilters[attention.professionalName] = true
        return true
      }
      return false
    })
    .map((attention) => ({
      label: attention.professionalName,
      value: attention.professionalName,
    }))

  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems="stretch"
      isLoading={isLoading}
      loaderSize="6"
      loaderColor="primary.8"
    >
      <Container width="100%" alignItems="stretch">
        <CallPanel
          currentAttentions={currentAttentions}
          currentLines={currentLines}
          hasStock={hasStock}
        />
        <SpecialActions currentLines={currentLines} />
      </Container>
      <Container width="100%" marginTop="1em">
        <Input
          value={linesSource}
          options={linesSources}
          onChange={(linesSource) => {
            changeCurrentLine(0)
            setLinesSource({ linesSource })
          }}
          type="radioButton"
          color="primary.8"
          borderColor="primary.8"
          fontWeight="bold"
        />
      </Container>
      <Container
        width="100%"
        flexDirection="column"
        borderTop="3px solid"
        borderColor="primary.8"
        padding="0.5em 1em"
        withShadow
      >
        <Container width="100%" marginTop="10px" marginBottom="10px">
          {professionalNameOptions.length > 0 ||
          (mappedCurrentAttentions.length == 0 && professionalFilter) ? (
            <Input
              type="select"
              width="20%"
              options={professionalNameOptions}
              onChange={(value) => {
                setProfesionalFilter(value)
              }}
              value={professionalFilter}
              allowClear
            />
          ) : null}
        </Container>
        <Container
          width="100%"
          overflowX="auto"
          overflowY="hidden"
          flexWrap="nowrap"
          alignItems="stretch"
        >
          {currentLines.map((line) => (
            <Line
              {...line}
              unitId={user.unitId}
              restrictLinesSwitch={
                restrictLinesSwitch || [2, 4].includes(linePriorityModeId)
              }
              changeCurrentLine={changeCurrentLine}
              key={line.id}
              smallBox={currentLines.length > 5}
            />
          ))}
        </Container>
        <Tickets
          attentions={mappedCurrentAttentions}
          lineTypeId={currentLineTypeId}
          currentAttentionByTicket={currentAttentionByTicket}
        />
      </Container>
    </Container>
  )
}

export default Panel
