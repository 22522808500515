import React from 'react'
import moment from 'moment-timezone'
import { notification } from 'antd'
import Clipboard from 'react-clipboard.js'
import { Container, Typography, Icon } from 'components'
import { useTime } from 'context'
import { formatSeconds } from 'utils'

// eslint-disable-next-line complexity
export default function CurrentAttention({ currentAttention }) {
  const currentTime = useTime()

  const document = currentAttention
    ? currentAttention.patientDocument || currentAttention.patientRut
    : null
  return (
    <Container width="100%" marginTop="0.5em" alignItems="stretch">
      <Container
        flexDirection="column"
        flex={1}
        backgroundColor="primary.8"
        padding="1em"
        marginRight="1em"
      >
        <Typography fontSize="4" color="white">
          {currentAttention
            ? formatSeconds(
                currentTime.diff(moment(currentAttention.initDate), `s`),
              )
            : `-`}
        </Typography>
        <Typography fontSize="7" color="white">
          {currentAttention ? currentAttention.displayName : `-`}
        </Typography>
        <Typography fontSize="1" color="white" fontStyle="italic">
          <Icon icon="user" color="white" marginRight="5px" />
          {currentAttention ? `Atendiendo` : `Esperando atención`}
        </Typography>
      </Container>
      <Container
        position="relative"
        flexDirection="column"
        flex={1}
        backgroundColor="primary.8"
        padding="1em"
        justifyContent="space-evenly"
      >
        <Container width="100%" alignItems="center">
          <Typography color="white" fontSize="4">
            {currentAttention && currentAttention.patientFirstName
              ? `${currentAttention.patientFirstName || ``} ${
                  currentAttention.patientLastName || ``
                }`
              : `-`}
          </Typography>
          {currentAttention && currentAttention.label ? (
            <Typography color="white" fontSize="2" marginLeft="3">
              <Icon icon="exclamationCircle" marginRight="1" />
              {currentAttention.label}
            </Typography>
          ) : null}
        </Container>
        <Container width="100%" flexDirection="flex-start" alignItems="center">
          <Typography color="white" fontSize="4">
            {currentAttention && document ? document : `Sin Rut/Pasaporte`}
          </Typography>
          {document ? (
            <Clipboard
              component="icon"
              button-href="#"
              data-clipboard-text={`${document.replace(/\./g, ``)}`}
            >
              <Icon
                fontSize="2"
                icon="copy"
                marginLeft="1"
                color="white"
                onClick={() =>
                  notification.success({
                    message: `Rut copiado exitosamente`,
                  })
                }
              />
            </Clipboard>
          ) : null}
        </Container>
        <Typography color="white" fontSize="4">
          <Icon icon="clock" fontSize="5" color="white" marginRight="5px" />
          {currentAttention
            ? formatSeconds(currentAttention.waitTime * 60)
            : `-`}
        </Typography>
      </Container>
    </Container>
  )
}
