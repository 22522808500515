import axios from 'axios'
import { READ_FINGERPRINT_URL } from 'config/constants'

export async function readFingerprint(rut) {
  // export async function readFingerprint() {
  let response
  try {
    response = await axios.post(READ_FINGERPRINT_URL, { rut })
    // response = {
    //   data: {
    //     result: {
    //       rawResponse: {
    //         ParamsGet: {
    //           erc: 0,
    //           ercText: ``,
    //           Erc: 0,
    //           ErcDesc: `La verificaciÃ³n fue APROBADA`,
    //           NroAudit: `BONO-C1HG-YCCU-MKJH`,
    //           CodLector: `{02EF5C51-3D52-974D-891E-AD48DB185ABB}`,
    //         },
    // eslint-disable-next-line max-len
    //         signature: `4EB4BF546AE7547C0F9B71B856EEF7F10FEF208C2CC336B1FBF8A0C6F9C601759F5EA6B02931BCA9A3F686308A832ACCCD1CA248523F25EBA224BE287077F064D6CC1434DC3C9B8448717FF70D2307B861406A59E0BD65CFD8013650F5DE65DD0EC20FDEAFBAFAD419391C52375898A3E412797683BFB9969D45183F087DF3AB74D12A89274AA35C34695B9EAAEAF2A358713DD8FE6A3E3C315C7CC75074EA2016D283A18B06939FB20CE7AFE16F124C327ABE8FC851155193180A3E7C643BB7FF7F42124856E6123AC09883E60A5DEBDD88B2F9FEEFD2FE4AC1035D06A6FBD919AE0DEEDE3C6F4AB749C2ACFE458EA788F8C0DC38E607922DEBD837F86E8A51`,
    //         token: `0.623693905260205`,
    //         VersionMB: ` VersiÃ³n 4.5 Build 7389.50442`,
    //       },
    //       parsedResponse: {
    //         readerCode: `02EF5C51-3D52-974D-891E-AD48DB185ABB`,
    //         errorCode: 0,
    //         message: `La verificaciÃ³n fue APROBADA`,
    //         auditingId: `BONO-C1HG-YCCU-MKJH`,
    //         version: ` VersiÃ³n 4.5 Build 7389.50442`,
    // eslint-disable-next-line max-len
    //         signature: `4EB4BF546AE7547C0F9B71B856EEF7F10FEF208C2CC336B1FBF8A0C6F9C601759F5EA6B02931BCA9A3F686308A832ACCCD1CA248523F25EBA224BE287077F064D6CC1434DC3C9B8448717FF70D2307B861406A59E0BD65CFD8013650F5DE65DD0EC20FDEAFBAFAD419391C52375898A3E412797683BFB9969D45183F087DF3AB74D12A89274AA35C34695B9EAAEAF2A358713DD8FE6A3E3C315C7CC75074EA2016D283A18B06939FB20CE7AFE16F124C327ABE8FC851155193180A3E7C643BB7FF7F42124856E6123AC09883E60A5DEBDD88B2F9FEEFD2FE4AC1035D06A6FBD919AE0DEEDE3C6F4AB749C2ACFE458EA788F8C0DC38E607922DEBD837F86E8A51`,
    //         token: `0.623693905260205`,
    //       },
    //     },
    //   },
    // }
    if (response.data.result.parsedResponse.errorCode != 0) {
      return Promise.reject({
        success: false,
        error: response.parsedResponse.message,
      })
    }
  } catch (error) {
    return Promise.reject({ success: false, error: `Unexpected error` })
  }
  return new Promise((resolve) =>
    setTimeout(() => resolve(response.data), 2000),
  )
}
