import React from 'react'
import { Container, Typography, Icon } from 'components'
import NormalLine from './NormalLine'
import AnalogLine from './AnalogLine'
const componentByLineTypeId = {
  1: NormalLine,
  2: NormalLine,
  3: AnalogLine,
}
function Line(props) {
  const {
    id,
    name,
    isActive = false,
    changeCurrentLine,
    smallBox,
    lineTypeId = 1,
    restrictLinesSwitch,
  } = props

  const Content = componentByLineTypeId[lineTypeId] || NormalLine
  return (
    <Container
      width={smallBox ? `10%` : `20%`}
      flex="0 0 auto"
      flexDirection="column"
      alignItems="center"
      padding="0.5em"
    >
      <Container
        width="100%"
        flexDirection="column"
        border={isActive ? `2px solid` : null}
        alignItems="center"
        borderColor="primary.8"
        shadow="0px 5px 8px 1px rgba(0,0,0,0.2)"
        position="relative"
        disabled={restrictLinesSwitch}
        onClick={() => (restrictLinesSwitch ? null : changeCurrentLine(id))}
        borderRadius="0.25rem"
      >
        <Container
          width="100%"
          padding="0.5em"
          shadow="0px 5px 8px 1px rgba(0,0,0,0.1)"
          justifyContent="center"
          alignItems="center"
          height="3em"
          backgroundColor="white"
          textOverflow="ellipsis"
        >
          <Typography
            fontWeight="bold"
            fontSize={smallBox ? `0` : `2`}
            lineHeight="1"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {name.length >= 35
              ? `${name.toUpperCase().slice(0, 35)}...`
              : name.toUpperCase()}
          </Typography>
        </Container>
        <Content {...props} />
      </Container>
      {isActive && (
        <Icon
          icon="caretDown"
          color="primary.8"
          fontSize="50px"
          marginTop="-20px"
          style={{
            '-webkit-filter': `drop-shadow( 0px 3px 5px rgba(0,0,0,0.3))`,
            filter: `drop-shadow( 0px 3px 5px rgba(0,0,0,0.3))`,
          }}
        />
      )}
    </Container>
  )
}

export default Line
