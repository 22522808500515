import React, { useState, useMemo, useCallback } from 'react'
import API from 'config/api'
import { Button, Container, Typography, Icon, Input, Modal } from 'components'
import { useStateWithMerge, useOnClickOutside } from 'hooks'
import { useAttentions } from 'context'
import notification from 'antd/es/notification'
import MultiTransferModal from './MultiTransferModal'

function EndButton({
  attentionId,
  suggestedRefs = [],
  email,
  auxiliaryLines,
  currentAttention = {},
  editTicketPatient,
  planTicketJourney,
  enabled,
  currentUnit,
}) {
  const [showList, setShowList] = useState(false)
  const [isTransfering, setIsTransfering] = useState(false)
  const [multiTransferModal, setMultiTransferModal] = useState(false)
  const { stockId, setStockId, motiveId } = useAttentions()
  const isAnalog = currentAttention?.attentionTypeId === 9
  const canPlanTicketJourney =
    currentAttention?.canPlanTicketJourney && !isAnalog
  const hasJourney = currentAttention?.hasJourney
  const requireId =
    currentAttention?.allowEditingPatient &&
    !currentAttention?.patientRut &&
    !currentAttention?.patientDocument

  const canOnlyFinish =
    currentAttention && [5, 11].includes(currentAttention.accessType)

  function wrapAction(action) {
    if (canOnlyFinish) {
      return async () => {
        setMultiTransferModal(false)
        endAttention()
      }
    } else if (requireId) {
      return () => {
        setMultiTransferModal(false)
        editTicketPatient(
          action,
          suggestedRefs.length === 1 ? suggestedRefs[0] : null,
        )
      }
    } else {
      // Si solo hay una fila sugerida para derivar
      // Se deriva de inmediato
      return async () => {
        setMultiTransferModal(false)
        action(suggestedRefs.length === 1 ? suggestedRefs[0] : null)
      }
    }
  }
  async function endAttention() {
    try {
      if (currentUnit.isMotiveAttentionRequired && motiveId == null) {
        throw Error(
          `Debe seleccionar un motivo de atención para finalizar la atención`,
        )
      }
      await API.endAttention({ attentionId, email, motiveId })
    } catch (e) {
      const error =
        typeof e?.message == `string`
          ? e?.message
          : `Ha ocurrido un error, por favor reintente.`
      notification.warn({ message: error })
    } finally {
      setShowList(false)
    }
  }
  async function transferAttention(lineId) {
    setIsTransfering(true)
    try {
      await API.transferAttentionToLine({
        lineId,
        attentionId,
        email,
        motiveId,
        stockId,
      })
      setStockId(null)
    } catch (e) {
      //console.error(e)
    } finally {
      setIsTransfering(false)
      setShowList(false)
    }
  }
  async function handleClick(lineToRef) {
    if (canPlanTicketJourney && !hasJourney) {
      planTicketJourney(transferAttention)
    } else if (lineToRef) {
      transferAttention(lineToRef)
    } else if (suggestedRefs.length > 1) {
      setShowList(true)
    } else {
      endAttention()
    }
  }
  function getLabel() {
    const defaultValue = { label: `Finalizar`, showFinish: false }
    if (canOnlyFinish) {
      return defaultValue
    }
    if (canPlanTicketJourney && !hasJourney) {
      return { label: `Planificar ticket`, showFinish: true }
    } else if (suggestedRefs.length > 0) {
      return { label: `Derivar`, showFinish: true }
    }
    return defaultValue
  }

  const { label, showFinish } = getLabel()
  const closeSuggestionList = useCallback(() => setShowList(false), [])
  return (
    <>
      <Container flex={3} alignItems="stretch" position="relative">
        <Button
          flex={2}
          backgroundColor={enabled ? `primary.8` : `gray.0`}
          color={enabled ? `white` : `gray.3`}
          padding="0.5em"
          fontSize="4"
          onClick={
            currentUnit?.hasMultiTransfer && currentAttention?.accessType === 11
              ? () => setMultiTransferModal(true)
              : wrapAction(handleClick)
          }
          disabled={!enabled}
        >
          {label}
        </Button>
        {!canOnlyFinish ? (
          <Button
            disabled={!enabled}
            flex={0.3}
            height="100%"
            backgroundColor={enabled ? `primary.9` : `gray.1`}
            justifyContent="center"
            alignItems="center"
            onClick={() => setShowList((prevState) => !prevState)}
          >
            <Icon icon="caretDown" />
          </Button>
        ) : null}
        {showList ? (
          <RefSuggestions
            isTransfering={isTransfering}
            transferAttention={transferAttention}
            suggestedRefs={suggestedRefs}
            auxiliaryLines={auxiliaryLines}
            currentLineId={currentAttention ? currentAttention.lineId : null}
            hasJourney={hasJourney}
            onClose={closeSuggestionList}
            showFinish={showFinish}
            endAttention={endAttention}
          />
        ) : null}
      </Container>
      <Modal
        isVisible={multiTransferModal}
        onClose={() => setMultiTransferModal(false)}
        closeColor="gray"
      >
        <MultiTransferModal
          patientRut={currentAttention?.patientRut}
          ticketId={currentAttention?.ticketId}
          wrapAction={wrapAction(handleClick)}
        />
      </Modal>
    </>
  )
}

const getInitialState = ({ suggestedRefs, auxiliaryLines }) => {
  const suggestedLines = []
  const otherLines = auxiliaryLines.currentUnitLines.filter((line) => {
    if (!suggestedRefs.includes(line.id)) {
      return true
    }
    suggestedLines.push(line)
    return false
  })
  return {
    suggestedLines,
    otherLines,
    searchText: ``,
    collapsedUnits: auxiliaryLines.units.map((unit) => unit.id),
  }
}

function RefSuggestions({
  isTransfering,
  suggestedRefs,
  hasJourney,
  auxiliaryLines,
  transferAttention,
  currentLineId,
  showFinish,
  endAttention,
  onClose,
}) {
  const [state, setState] = useStateWithMerge(
    getInitialState({ suggestedRefs, auxiliaryLines, currentLineId }),
  )
  const suggestionsRef = useOnClickOutside(onClose)
  const { suggestedLines, otherLines, searchText, collapsedUnits } = state
  const [filteredLines, filteredOtherUnits] = useMemo(() => {
    const filteredLines = otherLines.filter((line) =>
      line.name.toUpperCase().includes(searchText.toUpperCase()),
    )
    const filteredOtherUnits = []
    auxiliaryLines.units.forEach((unit) => {
      const unitFilteredLines = unit.lines.filter((line) =>
        line.name.toUpperCase().includes(searchText.toUpperCase()),
      )
      if (unitFilteredLines.length > 0) {
        filteredOtherUnits.push({ ...unit, lines: unitFilteredLines })
      }
    })
    return [filteredLines, filteredOtherUnits]
  }, [searchText])
  return (
    <Container
      ref={suggestionsRef}
      position="absolute"
      top={50}
      zIndex="10"
      backgroundColor="white"
      width="100%"
      border="1px solid"
      borderColor="gray.2@0.5"
      left="0"
    >
      {showFinish ? (
        <Button
          disabled={isTransfering}
          onClick={endAttention}
          width="100%"
          padding="0.5em"
          justifyContent="center"
          alignItems="center"
          borderBottom="3px solid"
          borderColor="gray.0@0.5"
          color="gray.3"
          marginBottom="1"
          hoverProps={{
            backgroundColor: `gray.0`,
            color: `gray.2`,
          }}
        >
          Finalizar atención
        </Button>
      ) : null}
      <Typography fontSize="2" fontStyle="italic" color="gray.3" margin="2">
        Derivar atención a:
      </Typography>
      {hasJourney ? (
        <Typography fontSize="1" fontStyle="italic" color="error" margin="2">
          * NOTA: Este ticket viene con un viaje planificado, asi que se sugiere
          no cambiar la fila a derivar.
        </Typography>
      ) : null}
      {suggestedLines.map((line) => (
        <Item
          disabled={isTransfering}
          key={line.id}
          onClick={async () => transferAttention(line.id)}
          suggedted
          {...line}
        />
      ))}
      <Container
        width="100%"
        borderColor="gray.2@0.5"
        marginX="2"
        borderBottom="1px solid"
        marginY="1"
      />
      <Input
        value={searchText}
        onChange={(searchText) => setState({ searchText })}
        placeholder="Buscar otra fila"
        width="100%"
        margin="2"
        addonAfter={<Icon icon="search" />}
      />
      <Container
        width="100%"
        maxHeight="30vh"
        overflowY="auto"
        justifyContent="center"
        alignItems="center"
      >
        {filteredLines.length === 0 ? (
          <Typography fontSize="1" fontStyle="italic" color="gray.3" margin="2">
            No hay filas
          </Typography>
        ) : (
          filteredLines.map((line) => (
            <Item
              disabled={isTransfering}
              key={line.id}
              onClick={async () => transferAttention(line.id)}
              {...line}
            />
          ))
        )}
        {filteredOtherUnits.length > 0 ? (
          <>
            <Typography
              fontSize="3"
              fontWeight="bolder"
              margin="2"
              width="100%"
              textAlign="center"
              border="1px solid gray"
              borderWidth="1px 0px"
            >
              Derivar a otras unidades
            </Typography>
            {filteredOtherUnits.map((unit) => {
              const isCollapsed = collapsedUnits.includes(unit.id)
              return (
                <>
                  <Container
                    width="100%"
                    backgroundColor="primary.1@0.3"
                    padding="1"
                    flewWrap="no-wrap"
                  >
                    <Typography
                      fontSize="2"
                      fontWeight="bolder"
                      textDecoration="underline"
                      textAlign="center"
                      width="90%"
                    >
                      {unit.name}
                    </Typography>
                    <Icon
                      fontSize="3"
                      icon={isCollapsed ? `caretDown` : `caretUp`}
                      onClick={() =>
                        setState((prevState) => {
                          const nextCollapsedUnits = isCollapsed
                            ? prevState.collapsedUnits.filter(
                                (id) => id != unit.id,
                              )
                            : [...prevState.collapsedUnits, unit.id]
                          return { collapsedUnits: nextCollapsedUnits }
                        })
                      }
                    />
                  </Container>
                  {!isCollapsed
                    ? unit.lines.map((line) => (
                        <Item
                          disabled={isTransfering}
                          key={line.id}
                          onClick={async () => transferAttention(line.id)}
                          {...line}
                        />
                      ))
                    : null}
                </>
              )
            })}
          </>
        ) : null}
      </Container>
    </Container>
  )
}

function Item({ name, suggedted = false, onClick, disabled }) {
  return (
    <Button
      width="100%"
      color={suggedted ? `primary.3` : `gray.2`}
      justifyContent="space-between"
      alignItems="baseline"
      padding="2"
      marginY="1"
      disabled={disabled}
      onClick={onClick}
      borderRadius="5px"
      backgroundColor={suggedted ? `primary.0` : `white`}
      hoverProps={{
        backgroundColor: suggedted ? `primary.2` : `gray.0`,
        color: suggedted ? `white` : `gray.4`,
      }}
    >
      {name}
      {suggedted ? <Icon icon="checkCircle" /> : null}
    </Button>
  )
}

export default EndButton
