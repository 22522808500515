import React from 'react'
import moment from 'moment-timezone'
import { Container, Button, Typography, Icon } from 'components'
import { useAuth, useAttentions } from 'context'
import API from 'config/api'
export default function AnalogTicket({
  id,
  getDate,
  initDate,
  endDate,
  displayName,
  attentionTypeId,
  setTicketToDelete,
}) {
  const {
    user: { id: userId },
  } = useAuth()
  const { activeModuleId: moduleId, currentAttention } = useAttentions()
  //NO MOSTRARLE EN LA LISTA LA ATENCION ACTUAL AL ADMISIONISTA QUE LA ATIENDE
  if (currentAttention?.id === id) {
    return null
  }
  async function callAnalogAttention() {
    try {
      await API.callAnalogAttention({ attentionId: id, userId, moduleId })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
  const canCall =
    ((initDate == null && endDate == null) ||
      (initDate != null && endDate != null)) &&
    currentAttention == null
  return (
    <Container width="20%" padding="0 0.5em" marginBottom="3">
      <Container
        flexDirection="column"
        alignItems="flex-start"
        backgroundColor="gray.0"
        width="100%"
        padding="0.5em"
        borderRadius="0.5em"
        position="relative"
        withShadow
      >
        <Typography color="primary.4" fontSize="5" fontWeight="bold">
          {displayName}
        </Typography>
        <Typography color="primary.4" fontSize="1" marginRight="3">
          {`Generado: ${moment(getDate).format(`LT A`)}`}
        </Typography>
        {initDate != null ? (
          <Typography color="primary.4" fontSize="1" marginRight="3">
            {attentionTypeId === 2
              ? `Marcado ausente.`
              : `Atendido: ${moment(initDate).format(`LT A`)} - 
          ${endDate == null ? `En curso` : moment(endDate).format(`LT A`)}`}
          </Typography>
        ) : null}
        <Button
          width="100%"
          padding="1"
          marginY="1"
          backgroundColor={canCall ? `secundary.0` : `gray.3`}
          alignSelf="center"
          onClick={callAnalogAttention}
          disabled={!canCall}
        >
          Llamar
        </Button>
        <Icon
          icon="close"
          color="primary.4"
          fontSize="1"
          position="absolute"
          top="2"
          right="2"
          onClick={setTicketToDelete}
        />
      </Container>
    </Container>
  )
}
