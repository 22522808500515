import React, { useState } from 'react'
import notification from 'antd/es/notification'
import { LOGO } from 'assets'
import { Container, Typography, Icon, Img, Link } from 'components'
import { useAuth, useAttentions } from 'context'
import API from 'config/api'
import CloseSession from '../CloseSession'

function isAttending(currentAttentionByModule) {
  return Object.values(currentAttentionByModule).some(
    (attention) => !!attention,
  )
}

function SideBar({ routes, activeRoute }) {
  const [logOutModal, setLogOutModal] = useState(false)
  const { logOut, user, modules, currentUnit } = useAuth()
  const { id, versionDescription } = currentUnit

  const { currentAttentionByModule } = useAttentions()
  async function logOutHandler() {
    if (isAttending(currentAttentionByModule)) {
      notification.error({
        message: `No puedes cerrar sesión mientras estás atendiendo`,
      })
    } else {
      try {
        const { id: userId } = user
        await API.logout({
          userId,
          modules,
        })
        logOut()
      } catch (e) {
        const error = typeof e === `string` ? e : `Ocurrio un error inesperado.`
        notification.error({ message: error })
      }
    }
  }
  return (
    <Container
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Container>
        <Img src={LOGO} alt="logo" marginTop="1em" />
        <Container marginTop="3em">
          {routes.map(({ key, path, title, icon }) => {
            const isActive = `/admisionista/${path}` === activeRoute
            return (
              <Link
                key={key}
                to={path}
                backgroundColor={isActive ? `white` : `primary.8`}
                color={isActive ? `primary.8` : `white`}
                fontSize="3"
                borderRadius="200px 10px 10px 200px"
                hoverProps={{
                  backgroundColor: isActive ? `white` : `primary.8`,
                  color: isActive ? `primary.8` : `white`,
                  borderRadius: `200px 10px 10px 200px`,
                }}
              >
                <Icon icon={icon} />
                <Typography marginLeft="0.5em">{title}</Typography>
              </Link>
            )
          })}
          <Link
            color="white"
            fontSize="3"
            onClick={() => setLogOutModal(true)}
            hoverProps={{
              backgroundColor: `primary.8`,
              color: `white`,
              borderRadius: `200px 10px 10px 200px`,
            }}
          >
            <Typography>Cerrar sesión</Typography>
          </Link>
        </Container>
      </Container>
      <Container
        flexDirection="column"
        alignItems="center"
        width="100%"
        marginBottom="3em"
      >
        <Typography color="white" fontSize="3" fontWeight="bold">
          ID: {id}
        </Typography>
        <Typography color="white" fontSize="3" fontWeight="bold">
          Versión: {versionDescription}
        </Typography>
      </Container>
      {logOutModal && (
        <CloseSession
          onClose={() => setLogOutModal(false)}
          logOut={logOutHandler}
        />
      )}
    </Container>
  )
}
export default SideBar
