import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDisabled } from 'context'
import API from 'config/api'
import notification from 'antd/es/notification'
import { Container } from 'components'
import Lines from '../index'
import VirtualTicketButton from '../VirtualTicketButton'
import ListItem from './LineItem'

function LinesBeforeId({ unit, goBack, flushInterval, ...props }) {
  const {
    id: unitId,
    visibleLines: linesToFilter,
    ignoreSchedule = false,
  } = unit
  const { isDisabled } = useDisabled()
  const [currentLineCategory, setCurrentLineCategory] = useState(null)
  useEffect(() => {
    if (goBack == null) {
      flushInterval()
    }
  })
  const {
    data: { lines, hasSchedule },
    isLoading,
    refetch: refetchLines,
  } = useQuery(
    [`totemLines`, linesToFilter, unitId, ignoreSchedule],
    () =>
      API.getTotemLines({
        linesToFilter,
        unitId,
        ignoreSchedule,
      }),
    {
      refetchInterval: 20000,
      refetchIntervalInBackground: true,
      retry: 3,
      retryDelay: 800,
      initialData: { lines: [], hasSchedule: false },
      onError() {
        const key = `lines-${Date.now()}`
        notification.error({
          key,
          message: `No se ha podido establecer conexión con servidor`,
          description: `Presione o cierra este mensaje para reintentar`,
          duration: 15, //seconds
          onClick() {
            refetchLines()
            notification.close(key)
          },
        })
      },
      onSuccess({ lines }) {
        if (
          lines[currentLineCategory] &&
          lines[currentLineCategory].lines == null
        ) {
          setCurrentLineCategory(null)
        }
      },
    },
  )
  const visibleLines = useMemo(() => {
    return lines[currentLineCategory] && lines[currentLineCategory].lines
      ? lines[currentLineCategory].lines
      : lines
  }, [lines, currentLineCategory])

  return (
    <Lines
      isLoading={isLoading}
      isGroup={currentLineCategory != null}
      hasVisibleLines={visibleLines?.length > 0}
      onGroupBack={() => setCurrentLineCategory(null)}
      unit={unit}
      {...props}
    >
      <Container
        justifyContent={isDisabled ? `flex-start` : `center`}
        alignItems="center"
        display="grid"
        gridGap="2vw"
        gridTemplateColumns={{
          md: `repeat(2, 1fr)`,
          _: isDisabled ? `repeat(2, 1fr)` : `1fr`,
        }}
        width="80%"
        padding={3}
      >
        {hasSchedule && currentLineCategory == null ? (
          <VirtualTicketButton unit={unit} />
        ) : null}
        {visibleLines.map((line, index) => (
          <ListItem
            key={line.id}
            {...line}
            setCurrentLineCategory={() => setCurrentLineCategory(index)}
            isDisabled={isDisabled}
          />
        ))}
      </Container>
    </Lines>
  )
}

export default LinesBeforeId
