import React, { useState } from 'react'
import { useUnit } from 'context'
import { Container, Typography, Button, Input, Img } from 'components'
import { LOGO_TOTEM } from 'assets'

const Login = () => {
  const [unitId, setUnit] = useState(``)
  const [password, setPassword] = useState(``)
  const [error, setError] = useState(null)
  const { unitLogin } = useUnit()
  async function login() {
    setError(null)
    try {
      await unitLogin(unitId, password)
    } catch (e) {
      const error =
        typeof e == `string` ? e : `Ha ocurrido un error inesperado, reintente.`
      setError(error)
    }
  }
  return (
    <Container minHeight="100vh" alignItems="center" justifyContent="center">
      <Container height="90%" padding="5%" width="80%">
        <Container
          width="100%"
          height="30%"
          marginBottom="5%"
          justifyContent="center"
          alignItems="center"
        >
          <Img src={LOGO_TOTEM} />
        </Container>
        <Container
          width="100%"
          height="30%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            color="primary.4"
            fontSize={6}
            fontWeight="bold"
            marginBottom="1%"
          >
            Ingreso al totem
          </Typography>
        </Container>
        <Container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          size="100%"
          padding="3% 10%"
        >
          <Typography
            color="primary.4"
            fontSize={5}
            fontWeight="100"
            padding="2%"
          >
            ID Sucursal
          </Typography>
          <Input
            width="100%"
            padding={2}
            fontSize={5}
            value={unitId}
            onChange={(unitId) => setUnit(unitId)}
            required
          />
          <Typography
            color="primary.4"
            fontSize={5}
            fontWeight="100"
            padding="2%"
            marginTop="2%"
          >
            Código de acceso
          </Typography>
          <Input
            placeholder="Ingresa contraseña"
            width="100%"
            name="password"
            type="password"
            value={password}
            padding={3}
            fontSize={4}
            password
            onChange={(password) => setPassword(password)}
            required
          />
          <Button
            backgroundColor="primary.4"
            borderRadius=".8rem"
            marginTop="10%"
            fontSize="5"
            padding="2%"
            width="80%"
            fontWeight="bold"
            onClick={login}
          >
            Acceder
          </Button>
          {error != null ? (
            <Typography
              color="error"
              fontSize={5}
              fontWeight="bolder"
              padding="2%"
              marginTop="2%"
            >
              {error}
            </Typography>
          ) : null}
        </Container>
      </Container>
    </Container>
  )
}

export default Login
