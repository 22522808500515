/* eslint-disable no-console */
import React, { useState } from 'react'
import moment from 'moment-timezone'
import API from 'config/apiCheckin'
import { Container, Button, Typography, Icon } from 'components'
import { useInactivityHandler } from 'hooks'
import { useUnit } from 'context'
import { appointmentMessage } from 'utils/message'
import NotificationModal from './NotificationModal'
import Ticket from './Ticket'

function AppointmentButton({ key, appointment, arrive, disabled = true }) {
  return (
    <Button
      disabled={disabled}
      key={key}
      width="100%"
      padding="4"
      withShadow
      fontSize="2"
      backgroundColor={disabled ? `gray.1` : `primary.4`}
      color="white"
      borderRadius="10px"
      marginBottom="3"
      onClick={() => arrive(appointment)}
    >
      <Container width="100%" justifyContent="space-between">
        <Container width="35%" justifyContent="space-between">
          <Typography fontWeight="2" color={disabled ? `gray.4` : `white`}>
            {`${appointment.specialty.name} - ${moment(
              appointment.scheduledTime,
            ).format(`HH:mm`)} hrs`}
          </Typography>
          {disabled ? (
            <Typography fontWeight="0" fontStyle="italic" color="gray.4">
              {appointmentMessage(appointment)}
            </Typography>
          ) : (
            <Typography fontWeight="0" fontStyle="italic" color="white">
              Disponible
            </Typography>
          )}
        </Container>
        {disabled ? null : <Icon icon="chevronRight" />}
      </Container>
    </Button>
  )
}
// eslint-disable-next-line max-lines-per-function
function Appointments({ goBack, history }) {
  const [ticket, setTicket] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const { checkinHost } = useUnit()
  const flushInterval = useInactivityHandler({
    timeout: 10,
    inactivityHandler: goBack,
  })
  if (history.location.state == null) {
    goBack()
    return null
  }
  const { patient, appointments } = history.location.state

  async function arrive(appointment) {
    setLoading(true)
    try {
      const { hasToPay, id: appointmentId } = appointment
      const steps = hasToPay
        ? [{ stepId: 1, initTime: moment(), endTime: moment() }]
        : [
            { stepId: 1, initTime: moment(), endTime: moment() },
            { stepId: 2, initTime: moment(), endTime: moment() },
          ]
      const updatedAppointment = await API.updateAppointment({
        checkinHost,
        steps,
        appointmentId,
        events: [`updateAppointment`],
      })

      const currentStep =
        appointment.steps && appointment.steps.length > 0
          ? appointment.steps
              .reverse()
              .find((step) => step.AppointmentStep.initTime !== null)
          : null
      const newTicket = {
        ...appointment,
        hasToPay:
          updatedAppointment.hasToPay == null
            ? hasToPay
            : updatedAppointment.hasToPay,
        currentStep,
        patient,
      }
      flushInterval()
      setLoading(false)
      return setTicket(newTicket)
    } catch (e) {
      setErrorMessage(
        `Ocurrió un error al intentar procesar la cita. Por favor diríjase a admisión.`,
      )
      setLoading(false)
    }
  }

  return (
    <Container padding="4" width="100%">
      {ticket !== null || errorMessage ? (
        <NotificationModal isVisible onClose={goBack} message={errorMessage}>
          <Ticket ticket={ticket} />
        </NotificationModal>
      ) : null}

      <Container width="100%" flexDirection="column" alignItems="center">
        <Container
          marginY={3}
          width="60%"
          backgroundColor="green.1"
          justifyContent="center"
          padding="3"
          borderRadius="10px "
        >
          <Icon
            color="green.2"
            fontSize={5}
            icon="userClock"
            marginRight="1%"
          />
          <Typography color="green.2" fontSize={5} fontWeight="1">
            Citas disponibles
          </Typography>
        </Container>
        <Container
          flexDirection="column"
          alignItems="center"
          maxHeight="60vh"
          width="60%"
          overflowY="auto"
          flexWrap="no-wrap"
        >
          {appointments.availableAppointments &&
            appointments.availableAppointments.map((appointment, key) => {
              return (
                <AppointmentButton
                  key={key}
                  appointment={appointment}
                  disabled={loading}
                  arrive={arrive}
                />
              )
            })}
        </Container>
        {appointments.unavailableAppointments.length ? (
          <>
            <Container
              border="0.5px solid"
              borderColor="primary.4"
              width="60%"
              marginBottom="2%"
              marginTop="2%"
            />
            <Container
              marginY={3}
              width="60%"
              backgroundColor="error.1"
              justifyContent="center"
              padding="3"
              borderRadius="10px "
            >
              <Icon
                color="error.2"
                fontSize={5}
                icon="userClock"
                marginRight="1%"
              />
              <Typography color="error.2" fontSize={5} fontWeight="1">
                Citas no disponibles
              </Typography>
            </Container>
            <Container>
              <Typography color="primary.4" fontSize={5} marginBottom="3%">
                Diríjase a Admisión para ver si puede ser atendido(a)
              </Typography>
            </Container>
            <Container
              flexDirection="column"
              alignItems="center"
              maxHeight="60vh"
              width="60%"
              overflowY="auto"
              flexWrap="no-wrap"
            >
              {appointments.unavailableAppointments
                ? appointments.unavailableAppointments.map(
                    (appointment, key) => {
                      return (
                        <AppointmentButton
                          key={key}
                          appointment={appointment}
                        />
                      )
                    },
                  )
                : null}
            </Container>
            {` `}
          </>
        ) : null}
      </Container>
    </Container>
  )
}

export default Appointments
