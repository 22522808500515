import React from 'react'
import { Container, Typography, Icon, MaterialIcon } from 'components'

function LineInfo({ currentLine, isDisabled, ...props }) {
  return (
    <Container
      justifyContent="center"
      width="100%"
      padding={isDisabled ? `1vh` : `2vh`}
      borderRadius="8px"
      marginX={4}
      {...props}
    >
      {currentLine ? (
        <>
          <Container width="100%" justifyContent="center" marginBottom="2%">
            <Typography
              fontSize="5"
              fontWeight="bold"
              color="primary.6"
              textAlign="center"
            >
              {currentLine.name.toUpperCase()}
            </Typography>
          </Container>
          <Container
            justifyContent="space-around"
            alignItems="center"
            width="100%"
            height="100%"
            flexWrap="no-wrap"
          >
            <Container
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="no-wrap"
              width="50%"
              boxSizing="border-box"
            >
              <Container
                flexDirection="row"
                alignItems="center"
                flexWrap="no-wrap"
              >
                <MaterialIcon
                  icon="people"
                  fontSize="7"
                  color="primary.4"
                  marginBottom="5%"
                  paddingRight="0.5rem"
                />
                <Typography
                  fontSize="7"
                  fontWeight="bold"
                  color="primary.6"
                  marginBottom="5%"
                >
                  {currentLine.waitingPeople}
                </Typography>
              </Container>
              <Typography
                fontSize="4"
                color="primary.6"
                textAlign="start"
                paddingX="0.5em"
                lineHeight="1"
              >
                Personas en la fila
              </Typography>
            </Container>
            <Container
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="50%"
              height="100%"
              boxSizing="border-box"
              flexWrap="no-wrap"
              marginLeft="1vw"
            >
              <Container
                flexDirection="row"
                alignItems="center"
                flexWrap="no-wrap"
              >
                <MaterialIcon
                  icon="schedule"
                  fontSize="7"
                  color="primary.4"
                  marginBottom="5%"
                  paddingRight="0.5rem"
                />
                <Typography
                  fontSize="7"
                  fontWeight="bold"
                  color="primary.6"
                  marginBottom="5%"
                >
                  {currentLine.waitTime}
                </Typography>
              </Container>
              <Typography
                fontSize="4"
                color="primary.6"
                textAlign="start"
                paddingX="0.5em"
                lineHeight="1"
              >
                Minutos para ser atendido
              </Typography>
            </Container>
          </Container>
        </>
      ) : (
        <Icon icon="spinner" spin color="white" />
      )}
    </Container>
  )
}

export default LineInfo
