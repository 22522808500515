import React, { useState, useCallback } from 'react'
import API from 'config/api'
import { Button, Container, Icon } from 'components'
import { notification, Popover } from 'antd'
import { useOnClickOutside } from 'hooks'
import moment from 'moment-timezone'

function MarkAsAbsentButton({
  currentAttention,
  enabled,
  isAccessTypeRestricted,
  currentUnit,
}) {
  const [isOpenMenu, setOpenMenu] = useState(false)
  const closeMenu = useCallback(() => setOpenMenu(false), [])
  const menuRef = useOnClickOutside(closeMenu)
  const { id: attentionId, ticketId, lineId } = currentAttention
  async function onClick() {
    try {
      await API.absentAttention({ attentionId })
    } catch (e) {
      const error =
        typeof e === `string`
          ? e
          : `No se ha podido marcar ausente la atención, reintente.`
      notification.error({ message: error })
    }
  }

  async function scheduleAttention() {
    try {
      await API.scheduleAttentions([
        {
          ticketId,
          lineId,
          getDate: moment().add(5, `minute`),
          attentionTypeId: 8, // Ausente reprogramado
        },
      ])
      await onClick()
      setOpenMenu(false)
    } catch (e) {
      const error =
        typeof e === `string` ? e : `No se ha podido reagendar atención`
      notification.error({ message: error })
    }
  }
  return (
    <Container flex={3} position="relative">
      {currentUnit.hasAbsentConfirmation ? (
        <Popover
          content={
            <Container width="100%" justifyContent="center">
              <Button backgroundColor="primary.8" onClick={onClick}>
                Sí, confirmar
              </Button>
            </Container>
          }
          title="¿Confirma que desea marcar como ausente?"
          trigger="click"
        >
          <Button
            flex={2}
            padding="0.5em"
            fontSize="4"
            marginLeft="0.5em"
            backgroundColor={enabled ? `primary.8` : `gray.0`}
            color={enabled ? `white` : `gray.3`}
            disabled={!enabled}
          >
            {enabled ? `Ausente` : `Llamando...`}
          </Button>
        </Popover>
      ) : (
        <Button
          flex={2}
          padding="0.5em"
          fontSize="4"
          marginLeft="0.5em"
          backgroundColor={enabled ? `primary.8` : `gray.0`}
          color={enabled ? `white` : `gray.3`}
          disabled={!enabled}
          onClick={onClick}
        >
          {enabled ? `Ausente` : `Llamando...`}
        </Button>
      )}
      {!isAccessTypeRestricted ? (
        <Button
          flex={0.3}
          height="100%"
          justifyContent="center"
          alignItems="center"
          backgroundColor={enabled ? `primary.9` : `gray.1`}
          disabled={!enabled}
          onClick={() => setOpenMenu(!isOpenMenu)}
        >
          <Icon icon="caretDown" />
        </Button>
      ) : null}
      {isOpenMenu ? (
        <Container
          withShadow
          ref={menuRef}
          position="absolute"
          top={50}
          zIndex="10"
          backgroundColor="white"
          width="96%"
          border="1px solid"
          borderColor="gray.2@0.5"
          left="0.75em"
        >
          <Button
            backgroundColor="white"
            width="100%"
            padding="2"
            hoverProps={{ backgroundColor: `primary.8`, color: `white` }}
            color="gray.3"
            fontStyle="italic"
            fontSize="2"
            onClick={scheduleAttention}
          >
            Volver a la cola en 5 minutos
            <Icon marginLeft="1" icon="clock" />
          </Button>
        </Container>
      ) : null}
    </Container>
  )
}

export default MarkAsAbsentButton
