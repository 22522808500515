import { useState, useEffect } from 'react'

// Hook for listening to targetKeys in targetKeyCodes array
function useKeyListener(targetKeyCodes) {
  const initialState = {
    keyPressed: false,
    key: null,
  }

  const [state, setState] = useState(initialState)

  useEffect(() => {
    function upHandler({ keyCode, key }) {
      if (targetKeyCodes.includes(keyCode)) {
        setState({ keyPressed: true, key })
      }
    }
    window.addEventListener(`keydown`, upHandler)

    return () => {
      window.removeEventListener(`keydown`, upHandler)
    }
  }, [])

  if (state.keyPressed) {
    return state
  }

  return null
}

export default useKeyListener
