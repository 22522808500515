import React, { useState } from 'react'
import styled from 'styled-components'
import {
  compose,
  space,
  color,
  flex,
  layout,
  border,
  position,
  typography,
} from 'styled-system'
import Dropdown from 'antd/es/dropdown'
import Menu from 'antd/es/menu'

const Button = styled(Dropdown.Button)`
  display: flex;
  ${space}
  & .ant-btn:first-child {
    flex-grow: 1;
  }
  & .ant-btn {
    ${compose(color, border, typography)}
  }
  ${compose(space, color, layout, border, position, flex, typography)}
`

Button.defaultProps = {
  color: `white`,
  border: `none`,
  maxWidth: `100%`,
}

function ChildActions({ actions, setVisibility }) {
  return (
    <Menu>
      {actions.map((action) => (
        <Menu.Item
          onClick={() => {
            setVisibility(false)
            action.onClick()
          }}
          toggle
          key={action.id}
        >
          {action.title}
        </Menu.Item>
      ))}
    </Menu>
  )
}

function ButtonWrapper({
  children,
  onClick = () => null,
  childActions = [],
  ...props
}) {
  const [isVisible, setVisibility] = useState(false)
  return (
    <Button
      trigger={[`click`]}
      {...props}
      onClick={onClick}
      overlay={() => (
        <ChildActions actions={childActions} setVisibility={setVisibility} />
      )}
      visible={isVisible}
      onVisibleChange={setVisibility}
    >
      {children}
    </Button>
  )
}

export default ButtonWrapper
