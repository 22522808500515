/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react'
import { Container, Typography, Img, Icon } from 'components'
import API from 'config/api'
import { useStateWithMerge } from 'hooks'

function getFilledAttentions({ attentions, attentionsById, lineId }) {
  const filledAttentions = []
  attentions.forEach((attentionId) => {
    if (attentionsById[attentionId].lineId == lineId) {
      filledAttentions.push(attentionsById[attentionId])
    }
  })
  return filledAttentions
}

function Line(props) {
  const [availableTickets, setAvailableTickets] = useState(null)
  const { attentions, attentionsById, lineId, lineName, ticketLimit, tickets } =
    props

  const filledAttentions = getFilledAttentions({
    attentions,
    attentionsById,
    lineId,
  })

  useEffect(() => {
    function getTicketsByLine() {
      const lineTickets = tickets.filter(
        (ticket) => ticket.startingLineId === lineId,
      )
      const availableTickets = ticketLimit - lineTickets.length
      setAvailableTickets(availableTickets)
    }
    getTicketsByLine()
  }, [tickets])

  return (
    <Container
      width="100%"
      maxHeight="100%"
      height="15%"
      justifyContent="space-around"
      alignItems="center"
      marginLeft="1%"
    >
      <Container
        width="75%"
        height="100%"
        backgroundColor="white"
        alignItems="center"
        borderRadius="10px"
      >
        <Typography width="60%" fontSize="6" paddingLeft="4" color="black@0.85">
          {lineName}
        </Typography>
        <Typography
          width="20%"
          fontSize="6"
          fontWeight="bold"
          color="black@0.85"
        >
          {filledAttentions[0]?.shortIdentifier}
        </Typography>
        <Typography
          width="20%"
          fontSize="6"
          fontWeight="bold"
          color="black@0.85"
        >
          {filledAttentions[0]?.moduleShortIdentifier}
        </Typography>
      </Container>
      <Container
        width="20%"
        backgroundColor="white"
        height="100%"
        alignItems="center"
        justifyContent="center"
        borderRadius="10px"
      >
        <Typography
          fontSize="6"
          color={availableTickets == 0 ? `error.3` : `black@0.85`}
        >
          {`${availableTickets} / ${ticketLimit}`}
        </Typography>
      </Container>
    </Container>
  )
}

function getInitialState() {
  return {
    lines: [],
    tickets: [],
  }
}

function MultiLineTV(props) {
  const [state, setState] = useStateWithMerge(getInitialState)
  const { lines, tickets } = state

  const unitId = props.unit.id

  useEffect(() => {
    async function getTotemLines(unitId) {
      const { currentUnitLines } = await API.getLines(unitId)
      const filteredLines = currentUnitLines.filter((line) =>
        props.config.groups[0].lines.includes(line.id),
      )
      return setState({ lines: filteredLines })
    }
    getTotemLines(unitId)
  }, [])

  useEffect(() => {
    async function getTickets(unitId) {
      const tickets = await API.getTickets(unitId)
      setState({ tickets })
    }
    getTickets(unitId)
  }, [props.currentAttention])

  return (
    <Container height="100vh" width="100vw" backgroundColor="tertiary.5">
      <Container
        height="100%"
        width="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Container
          width="100%"
          backgroundColor="tertiary.6"
          minHeight="10vh"
          maxHeight="12vh"
        >
          <Img width="15%" src={props.logo} marginLeft="3" marginY="3" />
        </Container>
        <Container flexDirection="column" width="98%" height="73vh">
          <Container width="100%" paddingX="3" height="5%" marginBottom="1">
            <Typography
              width="45%"
              paddingLeft="4"
              fontSize={{ _: 2, sm: 3, md: 4, lg: 5 }}
              color="black@0.45"
              fontWeight="bold"
            >
              FILA
            </Typography>
            <Typography
              width="19%"
              fontSize={{ _: 2, sm: 3, md: 4, lg: 5 }}
              color="black@0.45"
              fontWeight="bold"
            >
              LLAMANDO A
            </Typography>
            <Typography
              width="20%"
              fontSize={{ _: 2, sm: 3, md: 4, lg: 5 }}
              color="black@0.45"
              fontWeight="bold"
            >
              MODULO
            </Typography>
            <Typography
              width=""
              fontSize={{ _: 2, sm: 3, md: 4, lg: 5 }}
              color="black@0.45"
              fontWeight="bold"
            >
              HORAS DISPONIBLES
            </Typography>
          </Container>
          <Container
            flexDirection="column"
            justifyContent="space-around"
            height="90%"
            width="100%"
          >
            {lines.map((line, index) => (
              <Line
                key={index}
                lineId={line.id}
                lineName={line.name}
                ticketLimit={line.ticketsPerDayLimit}
                attentions={props.attentions}
                attentionsById={props.attentionsById}
                tickets={tickets}
              />
            ))}
          </Container>
        </Container>
        <Container
          width="100%"
          height="10vh"
          justifyContent="flex-end"
          alignItems="center"
          backgroundColor="tertiary.6"
          paddingRight="5"
        >
          <Container>
            <Icon
              fontSize={{ _: 5, lg: 6 }}
              color="tertiary.7"
              icon="facebook"
              marginRight="2"
            />
            <Typography fontSize={{ _: 2, lg: 3 }} color="tertiary.7">
              {props.config.facebookUrl}
            </Typography>
          </Container>
          <Container marginLeft="4">
            <Icon
              fontSize={{ _: 5, lg: 6 }}
              color="tertiary.7"
              icon="twitter"
              marginRight="2"
            />
            <Typography fontSize={{ _: 2, lg: 3 }} color="tertiary.7">
              {props.config.twitterUrl}
            </Typography>
          </Container>
          <Container marginLeft="4">
            <Icon
              fontSize={{ _: 5, lg: 6 }}
              color="tertiary.7"
              icon="instagram"
              marginRight="2"
            />
            <Typography fontSize={{ _: 2, lg: 3 }} color="tertiary.7">
              {props.config.instagramUrl}
            </Typography>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}

export default MultiLineTV
