/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { Modal, Container, Typography, Button } from 'components'
export default function NotificationModal({
  onClose,
  isVisible,
  message,
  children,
}) {
  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, 5000)
  }, [])
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      borderRadius="30px"
      width="65%"
    >
      <Container flexDirection="column" alignItems="center" width="100%">
        {message == null ? (
          children
        ) : (
          <>
            <Typography
              width="80%"
              textAlign="center"
              color="primary.3"
              fontSize={6}
              fontWeight="3"
            >
              {message}
            </Typography>
            <Button
              backgroundColor="primary.3"
              width="40%"
              borderRadius=".8rem"
              fontSize="5"
              fontWeight="bold"
              marginTop="2em"
              height="15%"
              padding={[`5%`, `3%`, `5%`, `3%`]}
              withShadow
              onClick={() => onClose()}
            >
              Entendido
            </Button>
          </>
        )}
      </Container>
    </Modal>
  )
}
