import React, { useEffect, useState } from 'react'
import API from 'config/api'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import { Container, Typography, Icon } from 'components'
import { print } from 'utils'
import Feedbacks, { TemporalFeedback } from 'screens/payment/Feedbacks'
import { LOADER_SPINNER } from 'assets'
import { useAutopago } from 'context/AutopagoContext'
import { useUnit } from 'context'
import { replaceLineName } from './utils'

import ErrorMessage from './Error'
import NoPrintTicket from './NoPrint'
import SuccessMessage from './Success'

const handleAutopago = ({
  title,
  subtitle,
  icon,
  isSuccessPrinted,
  isPrintingError,
  goBack,
  isError,
  ticketInfo,
  ticketError,
  hasExpressPickUp,
  isAutopago,
  patientRut,
  patientRutDv,
}) => {
  if (isSuccessPrinted && !isError) {
    return (
      <Feedbacks
        goBack={goBack}
        iconColor="green.0"
        iconPaddingTop="6.6rem"
        iconPaddingBottom="3.2rem"
        title={title}
        subtitle={subtitle}
        {...icon}
      />
    )
  }
  if (isError) {
    return (
      <Feedbacks
        iconColor="green.0"
        iconPaddingTop="6.6rem"
        iconPaddingBottom="3.2rem"
        title={
          <ErrorMessage
            isPrintingError={isPrintingError}
            ticket={ticketInfo?.ticket}
            goBack={goBack}
            ticketError={ticketError}
            hasExpressPickUp={hasExpressPickUp}
            ticketInfo={{ ...ticketInfo, action: `TO_ATTENTION` }}
            isAutopago={isAutopago}
            patientRut={patientRut}
            patientRutDv={patientRutDv}
          />
        }
        {...icon}
      />
    )
  }
  return (
    <Feedbacks
      iconColor="primary.4"
      iconPaddingTop="6.6rem"
      iconPaddingBottom="3.2rem"
      icon={<img src={LOADER_SPINNER} width="30%" height="30%" />}
      title="Estamos generando su ticket..."
    />
  )
}

const handleAutopagoZones = ({
  title,
  subtitle,
  icon,
  isSuccessPrinted,
  isPrintingError,
  goBack,
  isError,
  ticketInfo,
  ticketError,
  hasExpressPickUp,
  hasAutopagoZone,
}) => {
  if (isSuccessPrinted && !isError) {
    return (
      <Feedbacks
        goBack={goBack}
        iconColor="green.0"
        iconPaddingTop="6.6rem"
        iconPaddingBottom="3.2rem"
        title={title}
        subtitle={subtitle}
        {...icon}
      />
    )
  }
  if (isError) {
    return (
      <TemporalFeedback
        timeout={3}
        prevComponent={
          <Feedbacks
            iconColor="green.0"
            iconPaddingTop="6.6rem"
            iconPaddingBottom="3.2rem"
            title={title}
            subtitle={subtitle}
            {...icon}
          />
        }
        postComponent={
          <ErrorMessage
            isPrintingError={isPrintingError}
            ticket={ticketInfo?.ticket}
            goBack={goBack}
            ticketError={ticketError}
            hasExpressPickUp={hasExpressPickUp}
            ticketInfo={{ ...ticketInfo, action: `TO_ATTENTION` }}
            hasAutopagoZone={hasAutopagoZone}
          />
        }
      />
    )
  }
  return (
    <Feedbacks
      iconColor="primary.4"
      iconPaddingTop="6.6rem"
      iconPaddingBottom="3.2rem"
      icon={<img src={LOADER_SPINNER} width="30%" height="30%" />}
      title="Estamos generando su ticket..."
    />
  )
}

const heightByCase = ({
  hasAutopagoZone,
  isAutopago,
  isAddingTicket,
  isPrinting,
  isSuccessPrinted,
  isError,
  ticketInfo,
}) => {
  if (isAutopago && !isAddingTicket && !isPrinting && !isSuccessPrinted) {
    return `62em`
  }
  if (hasAutopagoZone && !isAddingTicket && !isPrinting && !isSuccessPrinted) {
    return `45em`
  }

  // Checkin integration
  if (isSuccessPrinted && ticketInfo?.cardHeight) {
    return ticketInfo.cardHeight
  }

  if (isError && ticketInfo?.errorCardHeight) {
    return ticketInfo?.errorCardHeight
  }
  return `40em`
}

function Print({ goBack, unit, flushInterval }) {
  const location = useLocation()
  const {
    division: { timezoneId },
  } = useUnit()
  const { autopagoRecordId, totemIp } = useAutopago()
  // hasExpressPickUp solo es true cuando en la vista ValidationCode
  // se inicia correctamente el flujo de Retiro Express
  const {
    isReservation,
    sendToPrint = true,
    hasExpressPickUp = false,
    isAutopagoFinished,
    previsionTicket,
    hasAutopagoZone,
    isAutopago,
    patientRut,
    patientRutDv,
    icon,
    isTicketWithVoucher,
  } = location?.state ?? {}

  const [showPositiveError, setShowPositiveError] = useState(false)
  const {
    mutate: printTicket,
    isLoading: isPrinting,
    isError: isPrintingError,
    isSuccess: isSuccessPrinted,
  } = useMutation((ticket) => print(ticket))

  const {
    data: ticketInfo,
    mutate: addTicket,
    isLoading: isAddingTicket,
    isError: isTicketError,
    error: ticketError,
  } = useMutation(
    (body) => {
      const payload = {
        ...body,
        unitId: unit.id,
        previsionTicket,
        autopagoRecordId,
        totemIp,
      }
      if (isReservation) {
        return API.executeReservation(payload)
      }
      return API.addTicket(payload)
    },
    {
      onSuccess(ticket) {
        if (sendToPrint && !ticket.preventPrintTicket) {
          let formattedTicket = replaceLineName(ticket)
          if (isTicketWithVoucher) {
            formattedTicket = { ...formattedTicket, app: `fila` }
          }

          printTicket({ ...formattedTicket, timezoneId })
        }
        if (ticket.positiveError && ticket.preventPrintTicket) {
          setShowPositiveError(true)
        }
      },
      onMutate: flushInterval,
    },
  )

  useEffect(() => {
    if (!location?.state) {
      return goBack()
    }
    addTicket(location.state)
  }, [])
  const schedule = location?.state ?? {}
  const isError = isPrintingError || isTicketError || showPositiveError

  if (isAutopagoFinished) {
    const { title, subtitle } = location?.state ?? {}
    if (hasAutopagoZone) {
      return handleAutopagoZones({
        title,
        subtitle,
        icon,
        isSuccessPrinted,
        isPrintingError,
        goBack,
        isError,
        ticketInfo,
        ticketError,
        hasExpressPickUp,
        hasAutopagoZone,
      })
    } else {
      return handleAutopago({
        title,
        subtitle,
        icon,
        isSuccessPrinted,
        isPrintingError,
        goBack,
        isError,
        ticketInfo,
        ticketError,
        hasExpressPickUp,
        isAutopago,
        patientRut,
        patientRutDv,
      })
    }
  }

  return (
    <Container width="100%" justifyContent="center" alignItems="center">
      <Container
        justifySelf="center"
        width={{ _: `83%` }}
        height={{
          _: heightByCase({
            hasAutopagoZone,
            isAutopago,
            isAddingTicket,
            isPrinting,
            isSuccessPrinted,
            isError,
            ticketInfo,
          }),
        }}
        backgroundColor="white"
        justifyContent={isError ? `space-around` : `center`}
        flexWrap="no-wrap"
        alignItems="center"
        flexDirection="column"
        shadow="0px 5px 5px 1px rgba(0, 0, 0, .1)"
        padding="8.6% 6.6% 6%"
        position="relative"
        borderRadius="2%"
      >
        {isError ? (
          <ErrorMessage
            isPrintingError={isPrintingError}
            ticket={ticketInfo?.ticket}
            goBack={goBack}
            ticketError={ticketError}
            hasExpressPickUp={hasExpressPickUp}
            ticketInfo={ticketInfo}
            hasAutopagoZone={hasAutopagoZone}
            isAutopago={isAutopago}
            patientRut={patientRut}
            patientRutDv={patientRutDv}
            isNotFeedback={true}
            {...icon}
          />
        ) : null}
        {isAddingTicket || isPrinting ? (
          <Container
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Icon icon="circleNotch" spin fontSize="9" />
            <Typography fontSize="5" lineHeight="1">
              Estamos generando su ticket...
            </Typography>
          </Container>
        ) : null}
        {sendToPrint == false ? (
          <NoPrintTicket goBack={goBack} {...schedule} />
        ) : null}
        {isSuccessPrinted ? (
          <SuccessMessage
            goBack={goBack}
            hasExpressPickUp={hasExpressPickUp}
            ticketInfo={ticketInfo}
          />
        ) : null}
      </Container>
    </Container>
  )
}
export default Print
