import React from 'react'
import { Container } from 'components'
import HTMLParser from 'html-react-parser'
function LayoutImageOrText({ totemLayout, bottom }) {
  const { layoutTypeId, informationConfig, imageUrl, isActive } = totemLayout
  const isLayoutWithImage = layoutTypeId === 2
  if (!isActive || (isLayoutWithImage && !imageUrl) || layoutTypeId == 1) {
    return null
  }
  return (
    <Container
      flexDirection="column"
      background={!isLayoutWithImage ? informationConfig?.color : null}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundImage={isLayoutWithImage ? `url(${imageUrl})` : null}
      width="100%"
      minHeight={{ _: `19vh`, md: `15%`, lg: `25%` }}
      maxHeight="25%"
      fontSize="5"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      position="absolute"
      bottom={bottom}
    >
      {layoutTypeId == 3 ? HTMLParser(informationConfig?.text) : null}
    </Container>
  )
}
export default LayoutImageOrText
