import React from 'react'
import { Container, Typography, Button, Icon } from 'components'
import moment from 'moment-timezone'
import { capitalizeFirstLetter } from 'utils'
import MaterialIcon from 'components/MaterialIcon'
import Error from './Error'

const InfoCard = ({ name, icon, value, ...props }) => (
  <Container width="100%" flexDirection="column" {...props}>
    <Typography color="primary.4" fontSize="5" marginBottom="1">
      <Container alignItems="center">
        {icon}
        {name}
      </Container>
    </Typography>
    {value}
  </Container>
)

function ScheduleCard({ onClick, ...schedule }) {
  const {
    scheduledDate,
    reservationDate,
    professionalName,
    services,
    buttonMessage,
    index,
  } = schedule
  return (
    <Container
      background="white"
      borderRadius="1rem"
      shadow="0px 5px 8px 1px RGBA(0, 0, 0, 0.12)"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {schedule?.paymentStatus === `P` ? (
        <Container
          position="absolute"
          top="0"
          right="2rem"
          backgroundColor="green.0"
          padding="0.3rem 1.5rem 0.3rem 1rem"
          borderRadius="0 0 0.75rem 0.75rem"
          alignItems="center"
        >
          <MaterialIcon
            icon="checkCircleOutline"
            fontSize="2rem"
            color="white"
          />
          <Typography color="white" fontSize="1.75rem" marginLeft="0.5rem">
            Pagada
          </Typography>
        </Container>
      ) : null}
      <Container
        flexDirection="column"
        justifyContent="center"
        width="100%"
        padding="3rem 2.25rem 2.25rem"
      >
        {services ? (
          <InfoCard
            name={services?.length > 1 ? `Prestaciones` : `Prestación`}
            icon={
              <MaterialIcon
                icon="calendarToday"
                style={{ marginRight: `1rem` }}
              />
            }
            value={services?.map(({ primaryCode, name }, index) => (
              <Typography
                key={`service-${primaryCode}-${index}`}
                fontSize="7"
                fontWeight="400"
              >
                {capitalizeFirstLetter(name?.toLowerCase())}
              </Typography>
            ))}
            marginBottom="2.625rem"
          />
        ) : null}
        {scheduledDate || reservationDate ? (
          <InfoCard
            name="Fecha y hora"
            icon={
              <MaterialIcon icon="accessTime" style={{ marginRight: `1rem` }} />
            }
            value={
              <Typography fontSize="6" fontWeight="400">
                {capitalizeFirstLetter(
                  moment(scheduledDate || reservationDate).format(
                    `dddd D MMMM YYYY, HH:mm`,
                  ),
                )}
              </Typography>
            }
            marginBottom="2.625rem"
          />
        ) : null}
        {professionalName ? (
          <InfoCard
            name="Profesional"
            icon={
              <MaterialIcon
                icon="accountCircle"
                style={{ marginRight: `1rem` }}
              />
            }
            value={
              <Typography fontSize="6" fontWeight="400">
                {professionalName
                  .split(` `)
                  .map(
                    (word) => `${capitalizeFirstLetter(word.toLowerCase())} `,
                  )}
              </Typography>
            }
          />
        ) : null}
        <Button
          data-testid={`button-${index}`}
          width="100%"
          backgroundColor="primary.4"
          color="white"
          fontSize="6"
          marginTop="3rem"
          padding={3}
          borderRadius="15px"
          withShadow
          hoverProps={{
            backgroundColor: `primary.3`,
          }}
          onClick={() => onClick(schedule)}
        >
          {buttonMessage}
        </Button>
      </Container>
    </Container>
  )
}

function SchedulesWithAutopago({
  goBack,
  unit,
  goToLines,
  error,
  schedules,
  handleConfirm,
  title,
}) {
  return (
    <Container flexDirection="column" alignItems="center" width="100%">
      <Container justifyContent="start" width="100%">
        <Button
          backgroundColor="transparent"
          paddingY="3"
          paddingX="4"
          onClick={goBack}
        >
          <Icon icon="arrowLeft" color="gray.3" fontSize="6" />
        </Button>
      </Container>
      {error != null ? (
        <Container
          width={{ _: `40em` }}
          height={{ _: `37em` }}
          backgroundColor="white"
          justifyContent="space-evenly"
          flexWrap="no-wrap"
          alignItems="center"
          flexDirection="column"
          shadow="0px 3px 4px -4px rgba(0, 0, 0, 0.8)"
          paddingTop="5"
          paddingBottom="0"
          paddingX="3"
          position="relative"
        >
          <Error
            unitName={unit.name}
            goToLines={goToLines}
            {...error}
            country={unit.country}
          />
        </Container>
      ) : (
        <>
          <Typography fontSize="6" color="gray.4" fontWeight="1">
            {title}
          </Typography>
          <Container
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(44.3em, 1fr))"
            gridGap={4}
            gridAutoColumns="minmax(44.3em, 1fr)"
            width={schedules.length ? `95%` : `87%`}
            marginY="2"
            overflowX="auto"
            gridAutoFlow="column"
            padding="2rem 0.5rem"
            alignItems="center"
          >
            {schedules?.map((schedule, index) => (
              <ScheduleCard
                key={`schedule-${schedule.id}-${index}`}
                index={index}
                {...schedule}
                onClick={handleConfirm}
              />
            ))}
          </Container>
          <Button backgroundColor="transparent" onClick={goToLines}>
            <Typography
              fontSize="5"
              textDecoration="underline"
              color="primary.4"
            >
              Ir al menú principal
            </Typography>
          </Button>
        </>
      )}
    </Container>
  )
}

export default SchedulesWithAutopago
