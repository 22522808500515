import React, { useState, useCallback } from 'react'
import API from 'config/api'
import { Container, Typography, Button, Icon } from 'components'
import { notification } from 'antd'
import { useOnClickOutside } from 'hooks'

function CallButton({
  userId,
  canCall,
  nextAttentionId,
  activeModuleId,
  modules,
  modulesById,
  setActiveModuleId,
  setIsCalling,
}) {
  const [moduleList, setModuleList] = useState(false)
  const closeModuleList = useCallback(() => setModuleList(false), [])
  const listRef = useOnClickOutside(closeModuleList)
  async function callAttention() {
    try {
      await API.callAttention({
        userId,
        moduleId: activeModuleId,
        attentionId: nextAttentionId,
      })
      setIsCalling(true)
      setTimeout(() => setIsCalling(false), 5000)
    } catch (e) {
      let error = `No se ha podido realizar el llamado, reintente.`
      if (e && e.error && typeof e.error === `string`) {
        error = e.error
      } else if (typeof e === `string`) {
        error = e
      }
      return notification.error({ message: error, duration: 30 })
    }
  }
  const moduleName = `${modulesById[activeModuleId].description} ${modulesById[activeModuleId].identifier}`
  return (
    <Container
      marginLeft="0.5em"
      flex={3}
      alignItems="stretch"
      position="relative"
    >
      <Button
        flex={2}
        backgroundColor={canCall ? `primary.2` : `gray.0`}
        padding="0.5em"
        position="relative"
        fontSize="4"
        color={canCall ? `white` : `gray.3`}
        disabled={!canCall}
        onClick={callAttention}
      >
        Llamar {moduleName}
      </Button>
      <Button
        flex={0.3}
        height="100%"
        backgroundColor={canCall ? `primary.3` : `gray.3`}
        justifyContent="center"
        alignItems="center"
        onClick={() => setModuleList(!moduleList)}
      >
        <Icon icon="caretDown" />
      </Button>
      {moduleList ? (
        <Container
          ref={listRef}
          position="absolute"
          top={50}
          zIndex="10"
          backgroundColor="white"
          width="100%"
          border="1px solid"
          borderColor="gray.2@0.5"
          left="0"
          withShadow
        >
          <Typography fontSize="2" fontStyle="italic" color="gray.3" margin="2">
            Llamar como
          </Typography>
          {modules.map((moduleId) => {
            const { description, identifier } = modulesById[moduleId]
            return (
              <Container
                disabled={!canCall}
                backgroundColor={canCall ? `white` : `gray.0`}
                key={moduleId}
                width="100%"
                padding="2"
                onClick={() => {
                  if (canCall) {
                    setActiveModuleId(moduleId)
                    setModuleList(false)
                  }
                }}
                hoverProps={{ backgroundColor: `gray.0` }}
              >{`${description} ${identifier}`}</Container>
            )
          })}
        </Container>
      ) : null}
    </Container>
  )
}

export default CallButton
