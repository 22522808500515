import { Container, MaterialIcon, Typography } from 'components'
import React from 'react'

const contactsPE = [
  { icon: `whatsapp`, contact: `+469 419 55900` },
  { icon: `phone`, contact: `(01) 611-5757` },
  { icon: `adsClick`, contact: `ripley.com.pe` },
]

function ContactChannel({ contacts, ...props }) {
  const renderContacts = contacts || contactsPE

  return (
    <Container margin="2" {...props}>
      {renderContacts.map((c) => {
        return (
          <Container
            margin="1"
            backgroundColor="gray.0"
            borderRadius="50px"
            padding="1"
            paddingX="2"
            alignItems="center"
            key={c.contact}
          >
            <MaterialIcon icon={c.icon} marginX="1" />
            <Typography fontWeight="900">{c.contact}</Typography>
          </Container>
        )
      })}
    </Container>
  )
}

export default ContactChannel
