import React from 'react'
import { Container, Table, Typography, Input } from 'components'
import API from 'config/api'
import moment from 'moment-timezone'
import { format } from 'rut.js'
import { useTableManager, useStateWithMerge, useStatsPing } from 'hooks'
import { useAuth } from 'context'

const initialColumns = [
  { title: `ID`, dataIndex: `id`, key: `id`, width: 50 },
  { title: `TICKET`, dataIndex: `ticket`, key: `ticket`, width: 65 },
  {
    title: `SOLICITADO`,
    dataIndex: `getDate`,
    key: `getDate`,
    width: 100,
    render: (item) => moment(item).format(`HH:mm:ss`),
  },
  {
    title: `INICIO DE ATENCIÓN`,
    dataIndex: `initDate`,
    key: `initDate`,
    render: (item) =>
      moment(item).isValid() ? moment(item).format(`HH:mm:ss`) : `-`,
  },
  {
    title: `FIN DE ATENCIÓN`,
    dataIndex: `endDate`,
    key: `endDate`,
    render: (item) =>
      moment(item).isValid() ? moment(item).format(`HH:mm:ss`) : `-`,
  },
  { title: `USUARIO`, dataIndex: `user`, key: `user` },
  { title: `MÓDULO`, dataIndex: `module`, key: `module` },
  { title: `FILA`, dataIndex: `line`, key: `line` },
  {
    title: `RUT/PASAPORTE PACIENTE`,
    key: `patientRut`,
    render: ({ patientRut: rut, patientDocument }) =>
      patientDocument ? patientDocument : format(rut),
  },

  { title: `MOTIVO`, dataIndex: `motive`, key: `motive` },
  {
    title: `TIPO DE ATENCIÓN`,
    key: `attentionType`,
    render: (item) => {
      return `${item.attentionType}${
        item.ticketAccessType == 8 ? ` (Excepcional)` : ``
      }`
    },
  },
]

const getInitialState = {
  attentions: [],
  totalAttentions: 0,
  error: null,
  filterText: ``,
  filteredAttentions: [],
}

function Attentions() {
  const { user } = useAuth()
  useStatsPing({ category: 1 })
  const [state, setState] = useStateWithMerge(getInitialState)
  const { attentions, totalAttentions, filterText } = state

  const { columns, pagination, onTableChange } = useTableManager({
    filterText,
    onSearch: getAttentions,
    initialColumns,
  })

  async function getAttentions({ pagination: { page, pageSize } }) {
    try {
      const { attentions, totalAttentions } = await API.getAttentions({
        page,
        pageSize,
        filter: filterText,
        unitId: user.unitId,
      })
      setState({ attentions, totalAttentions })
    } catch (e) {
      const error = typeof e === `string` ? e : `Error al cargar atenciones`
      setState({
        error,
      })
    }
  }
  return (
    <Container width="100%" flexDirection="column" padding={1}>
      <Container
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
      >
        <Typography
          fontSize="5"
          color="primary.2"
          fontWeight="600"
          marginBottom={3}
        >
          ÚLTIMOS TICKETS
        </Typography>
        <Container alignItems="center">
          <Typography
            fontWeight="2"
            color="primary.2"
            fontSize={1}
            marginRight="0.5em"
          >
            Filtrar:
          </Typography>
          <Input
            borderRadius="4px"
            fontStyle="italic"
            fontWeight="0"
            value={filterText}
            onChange={(filterText) => setState({ filterText })}
          />
        </Container>
      </Container>
      <Table
        bordered
        columns={columns}
        dataSource={attentions}
        onChange={onTableChange}
        pagination={{
          showSizeChanger: true,
          pageSize: pagination.pageSize,
          current: pagination.page,
          total: totalAttentions,
        }}
        scroll={{ x: 500 }}
        fontSize="13px"
        headerFontSize="13px"
        headerWeight="bold"
        size="small"
      />
    </Container>
  )
}

export default Attentions
