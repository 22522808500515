import styled from 'styled-components'
import {
  compose,
  background,
  space,
  color,
  border,
  typography,
  system,
  layout,
} from 'styled-system'
import Input from 'antd/es/input'
import Select from 'antd/es/select'
import AntdRadio from 'antd/es/radio'
import AntdCheckbox from 'antd/es/checkbox'
import AntdTimePicker from 'antd/es/time-picker'
import AntdDatePicker from 'antd/es/date-picker'
import AntdInputNumber from 'antd/es/input-number'

const fontSize = system({
  fontSize: {
    property: `fontSize`,
    scale: `fontSizes`,
  },
})

export const CustomSelect = styled(Select)`
  width: 100%;
  height: auto;
  & .ant-select-selection.ant-select-selection--single,
  & .ant-select-selection.ant-select-selection--multiple {
    width: 100%;
    height: auto;
    border-radius: 0;
    line-height: 1;
    ${compose(background, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
`
export const CustomInput = styled(Input)`
  &.ant-input {
    width: 100% !important;
    height: auto;
    border-radius: 0;
    line-height: 1;
    ${compose(background, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
  &.ant-input.scan:focus {
    box-shadow: none;
  }
`

export const InputPassword = styled(Input.Password)`
  &.ant-input-password .ant-input {
    width: 100% !important;
    height: auto;
    border-radius: 0;
    line-height: 1;
    ${compose(background, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
`

export const RadioGroup = styled(AntdRadio.Group)`
  & label.ant-radio-button-wrapper {
    box-shadow: none !important;
    ${compose(background, space, typography)}
    &.ant-radio-button-wrapper-checked, &:hover {
      ${compose(border, color)}
    }
  }
`

export const Radio = styled(AntdRadio)``

export const RadioButton = styled(AntdRadio.Button)``

export const TimePicker = styled(AntdTimePicker)`
  &.ant-time-picker {
    width: 100% !important;
  }
`
export const DatePicker = styled(AntdDatePicker)`
  &.ant-calendar-picker {
    width: 100% !important;
    & .ant-calendar-picker-input.ant-input {
      ${color}
      & ::placeholder {
        ${color}
      }
    }
  }
`
DatePicker.defaultProps = {
  color: `gray.1`,
}

export const InputNumber = styled(AntdInputNumber)`
  &.ant-input-number {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(background, layout, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
  & .ant-input-number-input {
    ${fontSize}
  }
`

InputNumber.defaultProps = {
  fontSize: `1`,
}

export const Checkbox = styled(AntdCheckbox)`
  width: 100% !important;
  & .ant-checkbox-checked .ant-checkbox-inner {
    ${compose(
      system({
        boxColor: {
          property: `backgroundColor`,
          scale: `colors`,
        },
      }),
    )}
  }
  & .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    ${compose(
      system({
        checkColor: {
          property: `borderColor`,
          scale: `colors`,
        },
      }),
    )}
  }
`

Checkbox.defaultProps = {
  backgroundColor: `primary.2`,
}
