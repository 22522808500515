import React, { useEffect, useState } from 'react'

import { Container, Typography } from 'components'

const ValidationCodeInput = ({ validationCode }) => {
  const [validationCodes, setValidationCodes] = useState([])

  /**
   * Se genera un array de 6 elementos para renderizar los cuadros
   * donde se renderizan cada uno de los digitos del codigo de validacion
   */
  const getFormattedCode = () => {
    const newValue = Array.from({ length: 6 }, (_, i) =>
      validationCode[i] ? validationCode[i] : ``,
    )
    setValidationCodes(newValue)
  }

  useEffect(() => {
    getFormattedCode()
  }, [validationCode])

  return (
    <Container
      width="100%"
      marginY="1vh"
      flexWrap="no-wrap"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="5px"
      borderWidth="1px"
    >
      {validationCodes.map((value, index) => (
        <Container
          key={`${index}-code-digit`}
          width={60}
          height={60}
          marginY="1vh"
          flexWrap="no-wrap"
          backgroundColor="white"
          justifyContent="center"
          alignItems="center"
          borderRadius="5px"
          padding="0.5em"
          border="1px solid"
          borderColor="gray.1"
        >
          <Typography fontSize="5" fontWeight={2} textAlign="center">
            {value}
          </Typography>
        </Container>
      ))}
    </Container>
  )
}

export default ValidationCodeInput
