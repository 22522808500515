import React from 'react'
import { useInactivityHandler } from 'hooks'
import { Container, Typography, Button, MaterialIcon } from 'components'

function NoPrintTicket({ additionalPatientData = {}, goBack }) {
  useInactivityHandler({ timeout: 10, inactivityHandler: goBack })
  return (
    <Container
      flexDirection="column"
      justifyContent="space-between"
      alignContent="center"
      height="100%"
      width="85%"
      paddingTop="5"
    >
      <Container
        flexDirection="column"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        height="70%"
      >
        <MaterialIcon icon="checkCircleOutline" color="green.0" fontSize="9" />
        <Typography fontSize="6" fontWeight={0} color="gray.4">
          {`Gracias ${additionalPatientData?.patientFirstName ?? ``}`}
        </Typography>
        <Container width="100%">
          <Typography
            fontSize="5"
            textAlign="center"
            fontWeight={2}
            color="gray.4"
            width="100%"
          >
            Revisa tu nombre en la pantalla de atención.
          </Typography>
          <Typography
            fontSize="5"
            fontWeight={2}
            textAlign="center"
            color="gray.4"
            width="100%"
          >
            ¡Muy pronto te atenderemos!
          </Typography>
        </Container>
      </Container>
      <Button
        shadow="0px 4px 8px 1px #D0D0D0"
        backgroundColor="primary.4"
        width="100%"
        borderRadius="12px"
        paddingY="3"
        onClick={goBack}
        marginTop="4"
        justifySelf="flex-end"
      >
        <Typography color="white" fontSize="5">
          Entendido
        </Typography>
      </Button>
    </Container>
  )
}

export default NoPrintTicket
