import styled from 'styled-components'
import {
  compose,
  space,
  layout,
  color,
  typography,
  border,
  textStyle,
  system,
  position,
  grid,
} from 'styled-system'
import { backgroundGradient, colorsWithOpacity } from 'utils'

const Typography = styled.h1`
  margin: 0;
  ${compose(
    grid,
    space,
    layout,
    color,
    typography,
    border,
    textStyle,
    position,
    backgroundGradient,
    colorsWithOpacity,
    system({
      textDecoration: true,
      textTransform: true,
      whiteSpace: true,
      textOverflow: true,
    }),
  )}
  margin-block-start: 0 !important;
`

Typography.defaultProps = {
  maxWidth: `100%`,
}

export { Typography }
