import { useEffect } from 'react'
import io from 'socket.io-client'
import { SERVER_HOST } from 'config/constants'
function useIO({
  emitEvent = `socketConnection`,
  emitPayload,
  subscribeEvent,
  subscribeEventHandler,
  onReconnectHandler = () => null,
  aditionalFunction = () => null,
}) {
  useEffect(() => {
    const socket = io.connect(SERVER_HOST, {
      transports: [`websocket`],
    })
    socket.on(`connect`, () => {
      socket.emit(emitEvent, emitPayload)
    })

    socket.on(subscribeEvent, (data) => {
      subscribeEventHandler(data)
    })
    aditionalFunction()
    socket.on(`disconnect`, onReconnectHandler)
    return () => socket.disconnect()
  }, [])
}

export default useIO
