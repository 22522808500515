import React, { useMemo } from 'react'
import { useAttentions } from 'context'
import CallButtonNormal from './Normal'
import CallButtonMultiModule from './MultiModule'
import CallButtonAutoCall from './AutoCall'

function getButtonCallByCallType({ moduleType, callTypeId }) {
  // VALIDACION MULTIMODULO
  if (moduleType === 3) {
    return CallButtonMultiModule
    // VALIDACION LLAMADO AUTOMATICO
  } else if (callTypeId === 2) {
    return CallButtonAutoCall
  }
  return CallButtonNormal
}

export default function CallButton({
  currentAttentions,
  currentLines,
  setIsCalling,
  user,
  currentUnit,
  isUserAvailable,
}) {
  const {
    modules,
    modulesById,
    currentLineTypeId,
    activeModuleId,
    setActiveModuleId,
    currentAttentionByModule,
    attentionsById,
    currentAttentionByTicket,
  } = useAttentions()

  const { nextAttentionId, callableAttentions } = useMemo(() => {
    const callableAttentions = currentAttentions.filter((attentionId) => {
      const ticketId = attentionsById[attentionId].ticketId
      return currentAttentionByTicket[ticketId] == null
    })
    const nextAttentionId = callableAttentions[0]
    return { nextAttentionId, callableAttentions }
  }, [currentAttentions])
  const canCall =
    callableAttentions.length > 0 &&
    isUserAvailable &&
    currentAttentionByModule[activeModuleId] == null &&
    currentLineTypeId != 3

  const Button = getButtonCallByCallType({
    moduleType: user.moduleType,
    callTypeId: currentUnit.callTypeId,
  })
  return (
    <Button
      currentUnit={currentUnit}
      userId={user.id}
      canCall={canCall}
      nextAttentionId={nextAttentionId}
      callableAttentions={callableAttentions}
      modules={modules}
      modulesById={modulesById}
      activeModuleId={activeModuleId}
      setActiveModuleId={setActiveModuleId}
      currentLines={currentLines}
      setIsCalling={setIsCalling}
    />
  )
}
