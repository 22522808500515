import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import { Typography, Icon } from 'components'
import { useStateWithMerge } from 'hooks'
import { print } from 'utils'
export default function Ticket({ ticket }) {
  const [state, setState] = useStateWithMerge({
    isLoading: true,
    message: null,
  })
  const { isLoading, message } = state

  async function startPrint(ticket) {
    try {
      await print({ ...ticket, app: `checkin` })
      setState({
        message: null,
        isLoading: false,
      })
    } catch (error) {
      setState({
        isLoading: false,
        message: error.message,
      })
    }
  }

  useEffect(() => {
    if (ticket) {
      startPrint(ticket)
    }
  }, [ticket])
  return ticket ? (
    isLoading ? (
      <>
        <Typography
          fontSize={6}
          color="primary.5"
          textAlign="center"
          lineHeight="4rem"
        >
          Solicitando Ticket...
        </Typography>
        <Icon icon="spinner" spin color="primary.5" />
      </>
    ) : (
      <>
        <Typography
          width="80%"
          textAlign="center"
          color="primary.3"
          fontSize={5}
          fontWeight="3"
        >
          {ticket.hasToPay
            ? `Su pago se encuentra pendiente. Diríjase a Recaudación`
            : `${
                ticket.box.name
                  ? `Diríjase al box ${ticket.box.name} - ${
                      ticket.box.description || ``
                    }`
                  : `Dirijase a admisión para recibir instrucciones`
              }  para su hora de las ${moment(ticket.scheduledTime).format(
                `HH:mm`,
              )} hrs. con ${ticket.professional.user.firstName || ``} ${
                ticket.professional.user.lastName || ``
              }`}
        </Typography>
        {message ? (
          <Typography
            fontSize={7}
            color="primary.5"
            textAlign="center"
            width="100%"
          >
            {message}
          </Typography>
        ) : (
          <>
            <Typography
              width="50%"
              textAlign="center"
              color="primary.3"
              fontSize={7}
              fontWeight="3"
            >
              RETIRE SU TICKET
            </Typography>
            <Icon icon="down" fontSize={7} color="primary.3" />
          </>
        )}
      </>
    )
  ) : null
}
