import React from 'react'
import { Container, Typography, Icon, Button, Input } from 'components'
import { useStateWithMerge } from 'hooks'
import API from 'config/api'

function GenerateTicketsForm({
  initialTicketNumber,
  ticketsToGenerate,
  generateTickets,
  setField,
}) {
  return (
    <Container
      width="100%"
      padding="0.5em"
      flexWrap="nowrap"
      alignItems="center"
      flexDirection="column"
    >
      <Input
        placeholder="Número de ticket inicial"
        width="100%"
        type="number"
        min={1}
        value={initialTicketNumber}
        name="initialTicketNumber"
        onChange={setField}
      />
      <Input
        placeholder="Número de tickets a generar"
        width="100%"
        type="number"
        min={1}
        max={100}
        value={ticketsToGenerate}
        name="ticketsToGenerate"
        onChange={setField}
      />
      <Button
        width="80%"
        padding="1"
        marginY="1"
        backgroundColor="secundary.0"
        onClick={generateTickets}
      >
        Generar Tickets
      </Button>
    </Container>
  )
}

function getInitialState() {
  return {
    generateTickets: false,
    ticketsToGenerate: null,
    initialTicketNumber: null,
    attentions: [],
  }
}

function NormalLine({ id, totalPeople = 0, attendedPeople = 0, smallBox }) {
  const [state, setState] = useStateWithMerge(getInitialState)
  const { showGenerateTicketsForm, ticketsToGenerate, initialTicketNumber } =
    state
  async function generateTickets() {
    try {
      await API.generateAnalogTickets({
        lineId: id,
        ticketsToGenerate,
        initialTicketNumber,
      })
      setState({ showGenerateTicketsForm: false })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
  return showGenerateTicketsForm ? (
    <GenerateTicketsForm
      ticketsToGenerate={ticketsToGenerate}
      initialTicketNumber={initialTicketNumber}
      setField={(value, field) => setState({ [field]: value })}
      generateTickets={generateTickets}
    />
  ) : (
    <>
      <Container
        width="100%"
        padding="0.5em"
        flexWrap="nowrap"
        alignItems="center"
      >
        <Icon icon="ticket" color="primary.4" fontSize="2" marginRight="1em" />
        <Typography
          fontWeight="bold"
          color="primary.4"
          fontSize={smallBox ? `1` : `2`}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {`${totalPeople} números generados hoy`}
        </Typography>
      </Container>
      {totalPeople === 0 ? (
        <Button
          width="80%"
          padding="1"
          marginY="1"
          backgroundColor="secundary.0"
          onClick={() => setState({ showGenerateTicketsForm: true })}
        >
          Generar Tickets
        </Button>
      ) : (
        <Container
          width="100%"
          padding="0.5em"
          flexWrap="nowrap"
          alignItems="center"
        >
          <Icon
            icon="ticket"
            color="primary.4"
            fontSize="2"
            marginRight="1em"
          />
          <Typography
            fontWeight="bold"
            color="primary.4"
            fontSize={smallBox ? `1` : `2`}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {`${totalPeople - attendedPeople} números restantes`}
          </Typography>
        </Container>
      )}
    </>
  )
}

export default NormalLine
