import React from 'react'
import { withRouter } from 'react-router-dom'
import { Typography, Icon } from 'components'

import ServiceButton from './ServiceButton'

function VirtualTicketButton({ match, unit, history }) {
  function handleClick() {
    history.push({
      pathname: `${match.url}/reservation`,
      state: {
        name: `Hora Reservada`,
      },
    })
  }
  return (
    <ServiceButton onClick={handleClick}>
      <Typography color="white">
        <Icon icon="calendarCheck" marginRight={3} color="white" fontSize={6} />
        {unit.virtualTicketName}
      </Typography>
    </ServiceButton>
  )
}

export default withRouter(VirtualTicketButton)
