import { validate as validateRut, format as formatRut, clean } from 'rut.js'
export const callTypes = {
  BY_RUT: `byRut`,
  BY_PASSPORT: `byPassport`,
  BY_CUI: `byCUI`,
  BY_RIPLEY_ORDER: `byRipleyOrder`,
  BY_REQUEST_DATA: `byRequestData`,
  BY_EMAIL: `byEmail`,
  BY_PRESCRIPTION: `byPrescription`,
}

export const callTypesByCountry = {
  CL: callTypes.BY_RUT,
  PE: callTypes.BY_CUI,
}

export const keyboardModes = {
  NUMERIC_KEYBOARD: `NUMERIC_KEYBOARD`,
  ABC_KEYBOARD: `ABC_KEYBOARD`,
}

export const callModes = [callTypes.BY_RUT, callTypes.BY_PASSPORT]
export const callModesById = {
  [callTypes.BY_RUT]: {
    id: callTypes.BY_RUT,
    textOption: `RUT`,
    placeholder: `Ingresa tu RUT`,
    validation: validateRut,
    format: formatRut,
    extraValidation: (_prevDoc, _newDoc) => {
      const prevDoc = clean(_prevDoc)
      const newDoc = clean(_newDoc)
      if (
        prevDoc.length === 8 &&
        validateRut(prevDoc) &&
        !validateRut(newDoc)
      ) {
        return false
      }
      return prevDoc.length < 9
    },
    [keyboardModes.NUMERIC_KEYBOARD]: [
      `1`,
      `2`,
      `3`,
      `4`,
      `5`,
      `6`,
      `7`,
      `8`,
      `9`,
      `K`,
      `0`,
    ],
  },
  [callTypes.BY_PASSPORT]: {
    id: callTypes.BY_PASSPORT,
    textOption: `Pasaporte`,
    placeholder: `Ingresa tu Pasaporte`,
    validation: (doc) => doc.length > 6,
    format: (doc) => doc.toUpperCase(),
    extraValidation: (doc) => doc.length <= 9,
    [keyboardModes.ABC_KEYBOARD]: [
      `1`,
      `2`,
      `3`,
      `4`,
      `5`,
      `6`,
      `7`,
      `8`,
      `9`,
      `0`,
      `Q`,
      `W`,
      `E`,
      `R`,
      `T`,
      `Y`,
      `U`,
      `I`,
      `O`,
      `P`,
      `A`,
      `S`,
      `D`,
      `F`,
      `G`,
      `H`,
      `J`,
      `K`,
      `L`,
      `Ñ`,
      `Z`,
      `X`,
      `C`,
      `V`,
      `B`,
      `N`,
      `M`,
    ],
  },
  [callTypes.BY_CUI]: {
    id: callTypes.BY_CUI,
    textOption: `documento de identidad`,
    placeholder: `Ingrese su documento de identidad`,
    validation: (doc) => doc.length == 8,
    extraValidation: (doc) => doc.length < 8,
    format: (doc) => doc,
    [keyboardModes.NUMERIC_KEYBOARD]: [
      `1`,
      `2`,
      `3`,
      `4`,
      `5`,
      `6`,
      `7`,
      `8`,
      `9`,
      ` `,
      `0`,
    ],
    [keyboardModes.ABC_KEYBOARD]: [
      `A`,
      `B`,
      `C`,
      `D`,
      `E`,
      `F`,
      `G`,
      `H`,
      `I`,
      `J`,
      `K`,
      `123`,
    ],
  },
  [callTypes.BY_RIPLEY_ORDER]: {
    id: callTypes.BY_RIPLEY_ORDER,
    textOption: `codigo de validacion`,
    placeholder: `Ingrese el codigo de validacion de su compra`,
    validation: (doc) => doc.length > 0,
    format: (doc) => doc,
    [keyboardModes.NUMERIC_KEYBOARD]: [
      `1`,
      `2`,
      `3`,
      `4`,
      `5`,
      `6`,
      `7`,
      `8`,
      `9`,
      ` `,
      `0`,
    ],
  },
  [callTypes.BY_REQUEST_DATA]: {
    id: callTypes.BY_REQUEST_DATA,
    textOption: ``,
    placeholder: ``,
    validation: (doc) => doc,
    format: (doc) => doc,
    extraValidation: (doc) => doc,
    [keyboardModes.ABC_KEYBOARD]: [
      `Q`,
      `W`,
      `E`,
      `R`,
      `T`,
      `Y`,
      `U`,
      `I`,
      `O`,
      `P`,
      `A`,
      `S`,
      `D`,
      `F`,
      `G`,
      `H`,
      `J`,
      `K`,
      `L`,
      `Ñ`,
      `Z`,
      `X`,
      `C`,
      `V`,
      `B`,
      `N`,
      `M`,
    ],
  },
  [callTypes.BY_EMAIL]: {
    id: callTypes.BY_PASSPORT,
    textOption: `Email`,
    validation: (doc) => doc.length > 6,
    [keyboardModes.ABC_KEYBOARD]: [
      `1`,
      `2`,
      `3`,
      `4`,
      `5`,
      `6`,
      `7`,
      `8`,
      `9`,
      `0`,
      `-`,
      `Q`,
      `W`,
      `E`,
      `R`,
      `T`,
      `Y`,
      `U`,
      `I`,
      `O`,
      `P`,
      `_`,
      `A`,
      `S`,
      `D`,
      `F`,
      `G`,
      `H`,
      `J`,
      `K`,
      `L`,
      `Ñ`,
      `.`,
      `@`,
      `Z`,
      `X`,
      `C`,
      `V`,
      `B`,
      `N`,
      `M`,
    ],
  },
  [callTypes.BY_PRESCRIPTION]: {
    id: callTypes.BY_PRESCRIPTION,
    textOption: `Nº Receta`,
    placeholder: `Escanee el código de la receta`,
    validation: (doc) => doc.length > 0,
    format: (doc) => doc,
    extraValidation: (doc) => doc,
    [keyboardModes.NUMERIC_KEYBOARD]: [],
  },
}
function generateArray(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)
}
function createKeyboardConfig(keys, listeners) {
  const { handleButtonClick, handleDeleteInput } = listeners
  const layoutConfig = keys.map((key) => ({
    key,
    onClick: handleButtonClick,
  }))
  return {
    layoutConfig: [
      ...layoutConfig,
      {
        key: `del`,
        icon: `backspace`,
        onClick: handleDeleteInput,
      },
    ],
    forbiddenKeys: [`&`],
    targetKeyCodes: [
      8,
      13,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      75,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105,
      219,
      // Teclado QWERTY
      ...generateArray(65, 90),
    ],
    actionTypes: { ENTER: `Enter`, BACKSPACE: `Backspace` },
  }
}

export default createKeyboardConfig
