import React, { useEffect } from 'react'
import { useStateWithMerge } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useUnit } from 'context'
import { Icon, Container, MaterialIcon } from 'components'
import API from 'config/api'
import { notification } from 'antd'
import { genericErrorRedirect } from 'screens/payment/Redirection'
import Schedules from './Schedules'
import SchedulesWithAutopago from './SchedulesWithAutopago'

function SchedulesIndex({ goBack, unit, flushInterval }) {
  const [state, setState] = useStateWithMerge({
    schedules: [],
    title: ``,
    disableGoBack: ``,
    patientData: ``,
    error: null,
    isLoading: true,
  })
  const history = useHistory()
  const { isAutopagoActive: isAutopago } = useUnit()
  useEffect(() => {
    if (history?.location?.state == null) {
      goBack()
    }
    setState({ ...history.location.state, isLoading: false })
  }, [])
  const {
    schedules = [],
    title,
    disableGoBack,
    patientData,
    error = null,
    isLoading,
    posType,
  } = state
  const goToLines = () =>
    history.push({ pathname: `/totem/${unit.id}/lines`, state: patientData })
  const getSchedule = async (schedule) => {
    setState({ isLoading: true })
    try {
      const scheduleInfo = await API.getSchedule({
        ...patientData,
        isPrevious: schedule.isPrevious,
        unitId: unit.id,
      })
      if (!scheduleInfo) {
        return goToLines(patientData)
      }
      const { schedules, ...extraInfo } = scheduleInfo
      setState({ isLoading: false, schedules, ...extraInfo })
    } catch (e) {
      const error =
        typeof e === `string` ? e : `Ha ocurrido un error, reintente`
      return notification.error({ message: error })
    } finally {
      setState({ isLoading: false, patientData })
    }
  }
  async function handleConfirm(schedule) {
    if (isAutopago && schedule.toCopaymentDirectly) {
      history.push({
        pathname: `/totem/${unit.id}/copago`,
        state: {
          scheduleData: schedule,
          posType,
        },
      })
    } else if (isAutopago && schedule.toPayDirectly) {
      history.push({
        pathname: `/totem/${unit.id}/pagar`,
        state: {
          scheduleData: schedule,
          posType,
        },
      })
    } else if (isAutopago && schedule.action === `TO_POS`) {
      history.push({
        pathname: `/totem/${unit.id}/financiador`,
        state: {
          scheduleData: {
            ...schedule,
            ...patientData,
            label: schedule?.labelName,
            unitId: unit.id,
          },
          posType,
        },
      })
    } else if (isAutopago && schedule.action === `TO_PAYMENT`) {
      genericErrorRedirect({
        unitId: unit.id,
        redirect: `/totem/${unit.id}/print`,
        timeout: 4000,
        data: {
          ...schedule,
          ...patientData,
          label: schedule?.labelName,
        },
        hasToExit: false,
        history,
        title: `Retire su ticket y espere por su atención en caja.`,
        iconPaddingTop: `3rem`,
        iconSetting: {
          icon: <MaterialIcon icon="checkCircleOutline" />,
          iconColor: `green.0`,
        },
      })
    } else if (schedule.isPrevious) {
      await getSchedule(schedule)
    } else if (schedule.requestName) {
      history.push({
        pathname: `/totem/${unit.id}/requestName`,
        state: {
          ...schedule,
          ...patientData,
          disableGoBack,
        },
      })
    } else {
      history.push({
        pathname: `/totem/${unit.id}/print`,
        state: {
          ...schedule,
          ...patientData,
          label: schedule?.labelName,
        },
      })
    }
  }
  if (isLoading) {
    return (
      <Container
        width="100%"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Icon icon="circleNotch" spin fontSize="9" />
      </Container>
    )
  }
  if (isAutopago) {
    return (
      <SchedulesWithAutopago
        goBack={goBack}
        unit={unit}
        goToLines={goToLines}
        title={title}
        error={error}
        schedules={schedules}
        handleConfirm={handleConfirm}
      />
    )
  } else {
    return (
      <Schedules
        goBack={goBack}
        unit={unit}
        goToLines={goToLines}
        title={title}
        error={error}
        schedules={schedules}
        handleConfirm={handleConfirm}
        disableGoBack={disableGoBack}
        flushInterval={flushInterval}
      />
    )
  }
}

export default SchedulesIndex
