/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import { Container, Input, Icon, Button, Typography } from 'components'
import { useKeyListener, useInactivityHandler, useStateWithMerge } from 'hooks'
import { useUnit } from 'context'
import { validate, format } from 'rut.js'
import createKeyboardConfig from 'config/keyboard'
import API from 'config/apiCheckin'
import { appointmentMessage } from 'utils/message'
import NotificationModal from './NotificationModal'
import Keyboard from './Keyboard'

const keysLayout = [`1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`, `K`, `0`]

const initialState = {
  rut: ``,
  data: null,
  inactivity: 0,
  isModalOpen: false,
  message: ``,
  isLoading: false,
  appointments: null,
}

function Totem() {
  const history = useHistory()
  const [state, setState] = useStateWithMerge(initialState)
  const { isLoading, rut, isModalOpen, message } = state
  const isRutValid = validate(rut)
  const { checkinHost } = useUnit()

  function handleButtonClick(e) {
    setState({
      rut: format(rut + e.target.name),
    })
    e.target.blur()
  }

  function handleDeleteInput(e) {
    if (rut && rut !== `-`) {
      setState({
        rut: format(rut.slice(0, -1)),
      })
    } else {
      setState(initialState)
    }
    if (e) {
      e.target.blur()
    }
  }

  async function handleSubmit() {
    if (isRutValid) {
      setState({ isLoading: true })
      try {
        const { appointments, patient } = await API.getAppointmentsByRut({
          checkinHost,
          rut,
        })
        setState({ appointments })

        if (!appointments.availableAppointments.length) {
          setState({
            isModalOpen: true,
            rut: ``,
            message: null,
            isLoading: false,
          })
        } else {
          history.push({
            pathname: `/checkin/myAppointments`,
            state: {
              appointments,
              patient,
            },
          })
        }
      } catch (e) {
        setState({ isModalOpen: true, rut: ``, message: e, isLoading: false })
      }
    }
  }

  const { layoutConfig, targetKeyCodes, actionTypes } = createKeyboardConfig(
    keysLayout,
    { handleButtonClick, handleDeleteInput },
  )

  const keysListener = useKeyListener(targetKeyCodes)

  useInactivityHandler({
    timeout: 10,
    inactivityHandler: history.goBack,
  })
  useEffect(() => {
    if (keysListener) {
      switch (keysListener.key) {
        case actionTypes.ENTER:
          handleSubmit()
          break
        case actionTypes.BACKSPACE:
          handleDeleteInput()
          break
        default:
          setState({
            rut: format(rut + keysListener.key),
          })
      }
    }
  }, [keysListener])

  return (
    <Container
      width="100%"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
    >
      <Container
        display="flex"
        justifyContent="center"
        width="100%"
        paddingTop="2vh"
      />

      <Container
        flexDirection="column"
        width="100%"
        paddingY="1"
        alignContent="center"
        p="1em 2em"
      >
        <Input
          name="rut"
          value={rut}
          onChange={(rut) => setState({ rut: format(rut) })}
          width="50%"
          borderRadius="5px"
          placeholder="Ingrese su RUT"
          fontSize="5"
          padding="4"
          textAlign="center"
          color="primary.4"
          autocomplete="off"
        />
      </Container>
      <Container width="50%" height="100%">
        <Keyboard config={layoutConfig} columns={3} />
        <Button
          backgroundColor={isRutValid && !isLoading ? `primary.4` : `gray.3`}
          width="100%"
          borderRadius=".8rem"
          fontSize="5"
          fontWeight="bold"
          marginTop="1"
          marginBottom="4"
          height="15%"
          padding={[`5%`, `3%`, `5%`, `3%`]}
          withShadow
          disabled={!isRutValid || isLoading}
          onClick={() => handleSubmit()}
        >
          {isLoading ? <Icon icon="spinner" /> : `Pedir Ticket`}
        </Button>
        <NotificationModal
          isVisible={isModalOpen}
          onClose={() => setState({ isModalOpen: false })}
          message={message}
        >
          {state.appointments &&
          !state.appointments.availableAppointments.length ? (
            <Container flexDirection="column" width="80%">
              <Typography fontSize={6} color="primary.4">
                Horas no disponibles
              </Typography>
              <Typography fontSize={4} color="primary.4">
                Usted se encuentra fuera de horario o no tiene horas diponibles.
                Por favor diríjase a Admisión.
              </Typography>
              <Container
                border="0.5px solid"
                borderColor="primary.4"
                width="100%"
                marginBottom="2%"
                marginTop="2%"
              />
              <Container width="100%">
                {state.appointments.unavailableAppointments
                  ? state.appointments.unavailableAppointments.map(
                      (appointment, key) => {
                        return (
                          <Button
                            disabled={true}
                            key={key}
                            width="100%"
                            padding="4"
                            withShadow
                            fontSize="2"
                            backgroundColor="gray.1"
                            color="white"
                            borderRadius="10px"
                            marginBottom="3"
                          >
                            <Container
                              width="100%"
                              justifyContent="space-between"
                            >
                              <Container
                                width="35%"
                                justifyContent="space-between"
                              >
                                <Typography fontWeight="2" color="gray.6">
                                  {`${appointment.specialty.name} - ${moment(
                                    appointment.scheduledTime,
                                  ).format(`HH:mm`)} hrs`}
                                </Typography>
                                <Typography
                                  fontWeight="0"
                                  fontStyle="italic"
                                  color="gray.6"
                                >
                                  {appointmentMessage(appointment)}
                                </Typography>
                              </Container>
                            </Container>
                          </Button>
                        )
                      },
                    )
                  : null}
              </Container>
              <Container justifyContent="center" width="100%">
                <Button
                  width="50%"
                  backgroundColor="primary.4"
                  color="white"
                  height="90%"
                  borderRadius="6%"
                  withShadow
                  fontSize="6"
                  fontWeight="500"
                  onClick={() => setState({ isModalOpen: false })}
                >
                  Aceptar
                </Button>
              </Container>
            </Container>
          ) : null}
        </NotificationModal>
      </Container>
    </Container>
  )
}

export default Totem
