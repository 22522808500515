import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import theme from 'config/theme'

function ThemeProvider({ children }) {
  const [customTheme, setCustomTheme] = useState(null)

  const setNewThemeColors = (colorsObj) => {
    setCustomTheme({ ...theme, colors: colorsObj })
  }

  const currentTheme = customTheme || theme

  return (
    <ThemeContext.Provider value={{ setNewThemeColors, ...currentTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

function useTheme() {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error(`useTheme must be used within a ThemeProvider`)
  }
  return context
}

export { ThemeProvider, useTheme }
