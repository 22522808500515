import React from 'react'
import styled from 'styled-components'
import { compose, layout, color, space, border, system } from 'styled-system'
import AntdModal from 'antd/es/modal'

const closeColor = system({
  closeColor: {
    property: `color`,
    scale: `colors`,
  },
})
const Modal = styled(AntdModal)`
  ${layout}
  & .ant-modal-header {
    padding: 1.5em 1em !important;
    ${compose(color, border)}
    padding: 30px 24px;
    & .ant-modal-title {
      color: white;
    }
  }
  & .ant-modal-body {
    ${space}
    width: 100%;
  }
  & .ant-modal-close-x {
    ${closeColor}
  }
`

Modal.defaultProps = {
  width: `80%`,
  borderRadius: `0px`,
  backgroundColor: `primary.2`,
  closeColor: `white`,
  fontSize: `5`,
}

const ModalWrapper = ({
  onClose,
  isVisible,
  title,
  children,
  centered,
  ...props
}) => {
  return isVisible ? (
    <Modal
      visible
      onCancel={onClose}
      title={title}
      footer={null}
      maskClosable={false}
      keyboard={false}
      {...props}
      centered={centered}
    >
      {children}
    </Modal>
  ) : null
}

export default ModalWrapper
