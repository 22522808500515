import React from 'react'
import { Typography } from './styles'

const Typo = ({ children, variant = `title`, ...props }) => {
  return (
    <Typography textStyle={variant} {...props}>
      {children}
    </Typography>
  )
}

export default Typo
