import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import moment from 'moment-timezone'
import 'moment/locale/es'
import { TIME_ZONE } from 'config/constants'
import App from './App'
import {
  ThemeProvider,
  UnitProvider,
  TimeProvider,
  OnlineStatusProvider,
} from './context'

moment.tz.setDefault(TIME_ZONE)
Sentry.init({
  dsn: `https://f03408eb7dde420bb50ab60fa4ca8583@o381419.ingest.sentry.io/5687652`,
  environment: process.env.REACT_APP_SENTRY_ENV || `local`,
})

const CURRENT_VERSION_ID = 20

Sentry.setContext(`Division`, {
  id: process.env.REACT_APP_SENTRY_ID || null,
  name: process.env.REACT_APP_SENTRY_NAME || `Sin nombre`,
  version: CURRENT_VERSION_ID,
})

moment.locale(`es`)
const queryClient = new QueryClient()

ReactDOM.render(
  <ThemeProvider>
    <QueryClientProvider client={queryClient}>
      <UnitProvider>
        <TimeProvider>
          <OnlineStatusProvider>
            <App />
          </OnlineStatusProvider>
        </TimeProvider>
      </UnitProvider>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById(`root`),
)
