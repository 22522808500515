/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { useInactivityHandler } from 'hooks'
import { Button, Icon } from 'components'
import {
  Copayment,
  Finale,
  Financer,
  Fingerprint,
  Payment,
  PaymentType,
  Redirection,
  DocumentObtainSelection,
  VerifyEmail,
  EnterEmail,
  ScheduleList,
  AccompanistRut,
} from 'screens/payment'
import { useUnit } from 'context'
import IdAfterLines from './Identification/IdAfterLines'
import IdBeforeLines from './Identification/IdBeforeLines'
import Reservation from './Identification/Reservation'
import LinesAfterId from './Services/LinesAfterId'
import LinesBeforeId from './Services/LinesBeforeId'
import Schedules from './Identification/IdBeforeLines/Schedules'
import RequestName from './Identification/IdBeforeLines/RequestName'
import ValidationCode from './Identification/ValidationCode'
import Print from './Print'

const getAutopagoRoutes = ({ unit, flushInterval, goBack }) => {
  return [
    {
      key: 5,
      path: `huella`,
      title: `Pagos - Lectura de Huella`,
      Component(props) {
        return (
          <Fingerprint {...props} unit={unit} flushInterval={flushInterval} />
        )
      },
    },
    {
      key: 4,
      path: `financiador`,
      title: `Pagos - Financiador`,
      Component(props) {
        return <Financer {...props} />
      },
    },
    {
      key: 6,
      path: `copago`,
      title: `Pagos - Detalle de Copago`,
      Component(props) {
        return <Copayment {...props} unit={unit} />
      },
    },
    {
      key: 7,
      path: `tipoPago`,
      title: `Pagos - Medio de Pago`,
      Component(props) {
        return <PaymentType {...props} unit={unit} />
      },
    },
    {
      key: 8,
      path: `pagar`,
      title: `Pagos - Procesar Pago`,
      Component(props) {
        return <Payment {...props} unit={unit} flushInterval={flushInterval} />
      },
    },
    {
      key: 9,
      path: `fin`,
      title: `Pagos - Finalizado`,
      Component(props) {
        return <Finale {...props} unit={unit} flushInterval={flushInterval} />
      },
    },
    {
      key: 10,
      path: `redirigiendo`,
      title: `Redirigiendo...`,
      Component(props) {
        return <Redirection {...props} unit={unit} />
      },
    },
    {
      key: 11,
      path: `obtencionDocumentos`,
      title: `Obtención de documentos`,
      Component(props) {
        return <DocumentObtainSelection {...props} unit={unit} />
      },
    },
    {
      key: 12,
      path: `verificarEmail`,
      title: `Verifique su email`,
      Component(props) {
        return <VerifyEmail {...props} />
      },
    },
    {
      key: 13,
      path: `ingresarNombre`,
      title: `Ingrese su nombre`,
      Component(props) {
        return (
          <EnterEmail {...props} flushInterval={flushInterval} unit={unit} />
        )
      },
    },
    {
      key: 13,
      path: `ingresarApellido`,
      title: `Ingrese su apellido`,
      Component(props) {
        return (
          <EnterEmail {...props} flushInterval={flushInterval} unit={unit} />
        )
      },
    },
    {
      key: 14,
      path: `ingresarEmail`,
      title: `Ingrese su email`,
      Component(props) {
        return (
          <EnterEmail {...props} flushInterval={flushInterval} unit={unit} />
        )
      },
    },
    {
      key: 15,
      path: `listaDeHoras`,
      title: `Seleccione una hora`,
      Component(props) {
        return <ScheduleList {...props} unit={unit} goBack={goBack} />
      },
    },
    {
      key: 16,
      path: `rutAcompanante`,
      title: `Ingrese el RUT del acompañante`,
      Component(props) {
        return <AccompanistRut {...props} unit={unit} />
      },
    },
    {
      key: 16,
      path: `sinEncolamiento`,
      title: `Finalizar`,
      Component(props) {
        return <Redirection {...props} unit={unit} />
      },
    },
  ]
}

const getRoutes = ({ unit, goBack, showGoBack, flushInterval, isAutopago }) => {
  const routes = []
  const { showOnlyVirtualTicket, isRelated, hasMultiTransfer } = unit

  const unitLineIds = unit?.lines ?? []
  const unitLinesById = unit?.linesById ?? {}

  const linesThatRequireRut = unitLineIds.filter(
    (lineId) => unitLinesById[lineId]?.withoutRutValidation === false,
  )

  const linesRequireRut = linesThatRequireRut.length > 0

  const shouldAskIdBeforeLines =
    isRelated || (hasMultiTransfer && linesRequireRut)

  if (showOnlyVirtualTicket) {
    routes.push({
      key: 1,
      path: `reservation`,
      title: `Panel Inicial`,
      Component(props) {
        return (
          <>
            {showGoBack ? (
              <Button backgroundColor="transparent" onClick={goBack}>
                <Icon color="gray.3" fontSize="6" icon="arrowLeft" />
              </Button>
            ) : null}
            <Reservation
              {...props}
              unit={unit}
              flushInterval={flushInterval}
              goBack={showGoBack ? goBack : null}
              showOnlyVirtualTicket={true}
            />
          </>
        )
      },
    })
  } else if (shouldAskIdBeforeLines) {
    routes.push(
      {
        key: 1,
        path: `patient`,
        title: `Panel Inicial`,
        Component(props) {
          return (
            <>
              {showGoBack ? (
                <Button backgroundColor="transparent" onClick={goBack}>
                  <Icon color="gray.3" fontSize="6" icon="arrowLeft" />
                </Button>
              ) : null}
              <IdBeforeLines
                {...props}
                unit={unit}
                flushInterval={flushInterval}
                goBack={goBack}
              />
            </>
          )
        },
      },
      {
        key: 2,
        path: `lines`,
        title: `Panel Inicial`,
        Component(props) {
          return (
            <LinesAfterId
              {...props}
              unit={unit}
              flushInterval={flushInterval}
              goBack={goBack}
            />
          )
        },
      },
      {
        key: 3,
        path: `schedules`,
        title: `Citas`,
        Component(props) {
          return (
            <Schedules
              {...props}
              unit={unit}
              flushInterval={flushInterval}
              goBack={goBack}
            />
          )
        },
      },
      {
        key: 4,
        path: `confirmation`,
        title: `Confirmation`,
        Component(props) {
          return (
            <ValidationCode
              {...props}
              unit={unit}
              flushInterval={flushInterval}
              goBack={goBack}
            />
          )
        },
      },
      {
        key: 5,
        path: `requestName`,
        title: `Datos`,
        Component(props) {
          return (
            <RequestName
              {...props}
              unit={unit}
              flushInterval={flushInterval}
              goBack={goBack}
            />
          )
        },
      },
      {
        key: 6,
        path: `redirigiendoError`,
        title: `Redirigiendo...`,
        Component(props) {
          return <Redirection {...props} unit={unit} />
        },
      },
    )
  } else {
    routes.push(
      {
        key: 1,
        path: `lines`,
        title: `Panel Inicial`,
        Component(props) {
          return (
            <>
              {showGoBack ? (
                <Button backgroundColor="transparent" onClick={goBack}>
                  <Icon color="gray.3" fontSize="6" icon="arrowLeft" />
                </Button>
              ) : null}
              <LinesBeforeId
                {...props}
                unit={unit}
                flushInterval={flushInterval}
                goBack={showGoBack ? goBack : null}
              />
            </>
          )
        },
      },
      {
        key: 2,
        path: `lines/reservation`,
        title: `Panel Inicial`,
        Component(props) {
          return (
            <Reservation
              {...props}
              unit={unit}
              flushInterval={flushInterval}
              goBack={goBack}
            />
          )
        },
      },
      {
        key: 3,
        path: `lines/:lineId`,
        title: `Panel Inicial`,
        Component(props) {
          return (
            <IdAfterLines
              {...props}
              unit={unit}
              flushInterval={flushInterval}
              goBack={goBack}
            />
          )
        },
      },
    )
  }
  routes.push({
    key: 9999,
    path: `print`,
    title: `Emisión de ticket`,
    Component(props) {
      return (
        <Print
          {...props}
          unit={unit}
          flushInterval={flushInterval}
          goBack={goBack}
        />
      )
    },
  })
  if (isAutopago) {
    getAutopagoRoutes({ unit, flushInterval, goBack }).forEach((route) =>
      routes.push(route),
    )
  }
  return routes
}

function MainApp({ unit, showGoBack, match }) {
  const history = useHistory()
  const { isAutopagoActive: isAutopago } = useUnit()
  const location = useLocation()
  const goBack = () => {
    if (!location.pathname.includes(`/totem/units`)) {
      history.push(`/totem/units`)
    }
  }
  const flushInterval = useInactivityHandler({
    inactivityHandler: goBack,
    resetDependencies: [history.location.pathname],
  })
  const routes = getRoutes({
    unit,
    flushInterval,
    goBack,
    showGoBack,
    isAutopago,
  })
  return (
    <Switch>
      {routes.map(({ path, Component }) => (
        <Route
          key={path}
          path={`${match.url}/${path}`}
          exact
          render={Component}
        />
      ))}
      <Redirect to={`${match.url}/${routes[0].path}`} />
    </Switch>
  )
}
export default MainApp
