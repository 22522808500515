import React from 'react'
import { Container, Typography, Img } from 'components'
import { ILUSTRACION_FUERA_HORARIO } from 'assets'

function OutOfService({ unit }) {
  const outOfServiceText =
    unit.outOfServiceText ||
    `Los servicios de ${unit.name} se encuentran fuera de horario de
  atención, podemos atenderlo en los siguientes horarios:`

  return (
    <Container
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
      maxWidth="900px"
    >
      <Typography
        color="primary.4"
        fontSize={7}
        fontWeight="bolder"
        textAlign="center"
      >
        TÓTEM FUERA DE HORARIO
      </Typography>
      <Typography
        color="primary.4"
        fontSize={4}
        textAlign="center"
        paddingY={4}
        paddingX={5}
      >
        {outOfServiceText}
      </Typography>
      <Container
        backgroundColor="primary.4"
        width="60%"
        flexDirection="column"
        alignItems="center"
        padding={4}
        borderRadius="10px"
      >
        <Typography
          color="white"
          fontSize={4}
          textDecoration="underline"
          marginBottom={3}
        >
          Horarios de Atención
        </Typography>
        <Typography color="white" fontSize={4}>
          {unit.initTime.substring(0, 5)} hrs a {unit.endTime.substring(0, 5)}
          hrs
        </Typography>
      </Container>
      <Img src={ILUSTRACION_FUERA_HORARIO} width="33vh" marginTop={4} />
    </Container>
  )
}

export default OutOfService
