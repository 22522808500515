import React, { useState } from 'react'
import { Container, Typography, Button, Input, Icon } from 'components'
import { Steps } from 'antd'
import { removeIndexFromArray } from 'utils'

const { Step } = Steps
function getDefaultLine(lines, currentLine) {
  const firstNotCurrentLine = lines.find((line) => line.value != currentLine)
  return firstNotCurrentLine ? firstNotCurrentLine.value : null
}
function PlanTicketJourney({
  onClose,
  onConfirm,
  lines,
  linesById,
  currentLine,
}) {
  const [linesToDerive, setLinesToDerive] = useState([
    getDefaultLine(lines, currentLine),
  ])

  function setLine(value, index) {
    return setLinesToDerive((prevLines) => {
      const nextLines = [...prevLines]
      nextLines[index] = value
      return nextLines
    })
  }
  function addLine() {
    return setLinesToDerive((prevLines) => {
      return [...prevLines, getDefaultLine(lines, currentLine)]
    })
  }
  function removeLine(index) {
    return setLinesToDerive((prevLines) => {
      const nextLines = removeIndexFromArray(prevLines, index)
      return nextLines
    })
  }
  const canSubmit = linesToDerive.every((line) => line != null)
  function confirm() {
    if (canSubmit) {
      onConfirm(linesToDerive)
    }
  }
  return (
    <>
      <Typography fontSize="4" color="primary.2">
        Filas por las que pasara el ticket
      </Typography>

      <Container width="100%" marginY="3">
        <Container width="100%">
          <Steps current={1} direction="vertical">
            <Step
              title={<Typography>{linesById[currentLine].name}</Typography>}
            />
            {linesToDerive.map((lineId, index) => (
              <Step
                key={`${index}-${lineId}`}
                title={
                  <Container
                    width="80%"
                    flexWrap="nowrap"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Input
                      width="80%"
                      fontSize="2"
                      margin="0.5em 0"
                      value={lineId}
                      type="select"
                      options={lines}
                      name="lineId"
                      onChange={(lineId) => setLine(lineId, index)}
                    />
                    <Button
                      onClick={() => removeLine(index)}
                      backgroundColor="transparent"
                      color="primary.2"
                      border="1px solid"
                      borderColor="primary.2@0.1"
                      hoverProps={{
                        backgroundColor: `primary.2`,
                        color: `white`,
                      }}
                    >
                      <Icon icon="trash" marginRight="2" />
                      Eliminar paso
                    </Button>
                  </Container>
                }
              />
            ))}
          </Steps>
        </Container>
        <Button
          onClick={addLine}
          backgroundColor="transparent"
          color="primary.2"
          border="1px solid"
          borderColor="primary.2@0.1"
          padding="2"
          marginY="2"
          marginLeft="48px"
          fontSize="2"
          hoverProps={{
            backgroundColor: `primary.2`,
            color: `white`,
          }}
        >
          <Icon icon="plusCircle" marginRight="2" />
          Agregar paso
        </Button>
      </Container>
      <Container border="0.5px solid" borderColor="gray.0" />
      <Container marginTop="2" alignItems="center" justifyContent="flex-end">
        <Button onClick={onClose}>
          <Typography textDecoration="underline">Cancelar</Typography>
        </Button>
        <Button
          onClick={confirm}
          marginLeft="2"
          borderRadius="3px"
          paddingY="1"
          paddingX="2"
          backgroundColor={canSubmit ? `primary.2` : `gray.2`}
        >
          Finalizar y derivar
        </Button>
      </Container>
    </>
  )
}

export default PlanTicketJourney
