//KEYS FOR LOCAL STORAGE
export const ADMISSIONIST_TOKEN_KEY = `admissionist_token`
export const CONFIG_BY_UNIT_KEY = `saltala_config_by_unit`
export const PRINT_SERVER_HOST_KEY = `print_server_url`
export const CHECKIN_ACTIVE_KEY = `checkin_integration_active`
export const AUTOPAGO_ACTIVE_KEY = `autopago_active`
export const TOTEM_IP_KEY = `totem_ip`
export const AUTOPAGO_RECORD_ID_KEY = `autopago_record_id`
export const CONFIG_TV_KEY = `config_tv`
export const TOKEN_KEY = `checkin_local_token`
export const IGNORE_SCHEDULE_KEY = `totem_ignore_schedule`
export const PRESCRIPTION_SCAN_ACTIVE_KEY = `prescription_scan_active`
export const INACTIVITY_TIMEOUT_IN_SECONDS =
  localStorage.getItem(AUTOPAGO_ACTIVE_KEY) === `true` ? 32 : 15
function getServerHost() {
  const apiUrl = process.env.REACT_APP_API_URL
  const env = process.env.NODE_ENV
  if (apiUrl != null) {
    return apiUrl
  } else {
    return env === `production`
      ? `${window.location.protocol}//${window.location.host}`
      : `${window.location.protocol}//${window.location.hostname}:4001`
  }
}

//URL
export const SERVER_HOST = getServerHost()
export const API_BASE_URL = `${SERVER_HOST}/api/v1`
const PRINT_SERVER_HOST =
  localStorage.getItem(PRINT_SERVER_HOST_KEY) || `http://localhost:5001`

export const PRINT_FILA_BASE_URL = `${PRINT_SERVER_HOST}/print/fila`
export const PRINT_CHECKIN_BASE_URL = `${PRINT_SERVER_HOST}/print/checkin`
export const PRINT_BONUS_BASE_URL = `${PRINT_SERVER_HOST}/print/bonus`
export const PRINT_H2H_BASE_URL = `${PRINT_SERVER_HOST}/print/h2h`
export const PRINT_AUTOPAGO_BASE_URL = `${PRINT_SERVER_HOST}/print/autopago`
export const PRINT_AUTOPAGO_ZONE_BASE_URL = `${PRINT_SERVER_HOST}/print/autopagoZone`
export const PRINT_PROVIDERVOUCHER_BASE_URL = `${PRINT_SERVER_HOST}/print/voucherWithProvider`
export const PRINT_INTEGRATED_BASE_URL = `${PRINT_SERVER_HOST}/print/integrated`
export const PRINT_NEW_CHECKIN_BASE_URL = `${PRINT_SERVER_HOST}/print/newCheckin`
export const PRINT_HOSPITAL_PUERTO_MONTT_BASE_URL = `${PRINT_SERVER_HOST}/print/hospitalPuertoMontt`
export const READ_FINGERPRINT_URL = `${PRINT_SERVER_HOST}/fingerprint/read`
export const POS_SALE_URL = `${PRINT_SERVER_HOST}/pos/sale`
export const GET_IP_URL = `${PRINT_SERVER_HOST}/totem/getIp`

export const TIME_ZONE = process.env.REACT_APP_TIME_ZONE || `America/Santiago`
export const POS_TYPES = {
  H2H: `H2H`,
  INTEGRATED: `INTEGRATED`,
}

const TO_ATTENTION = `TO_ATTENTION`
const TO_PAYMENT = `TO_PAYMENT`
export const actionTypes = {
  TO_PAYMENT,
  TO_ATTENTION,
}
