import axios from 'axios'
import { TOKEN_KEY } from './constants'
const requestHandler = (config) => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) {
    config.headers.Authorization = token
  }
  config.headers.Authorization = token
  return config
}

const responseHandler = (response) => {
  const {
    data: { data, error, success },
  } = response
  if (success) {
    return data
  } else {
    return Promise.reject(error)
  }
}

const API = {}

const axiosInstance = axios.create({
  validateStatus() {
    return true
  },
})

axiosInstance.interceptors.request.use(requestHandler, (error) =>
  Promise.reject(error),
)

axiosInstance.interceptors.response.use(responseHandler, (error) =>
  Promise.reject(error),
)

// ---APPOINTMENTS---

API.getAppointmentsByRut = ({ checkinHost, rut }) =>
  axiosInstance.get(`${checkinHost}/patient/appointmentsByRut`, {
    params: {
      rut,
    },
  })

API.updateAppointment = ({ checkinHost, ...payload }) =>
  axiosInstance.patch(`${checkinHost}/appointment`, { ...payload })

export default API
