/* eslint-disable no-unreachable */
/* eslint-disable no-console */
/* eslint-disable import/extensions */
import React, { useState, useEffect, useContext, useMemo } from 'react'
import { CONFIG_TV_KEY, SERVER_HOST } from 'config/constants'
import axios from 'axios'
import Speech from 'speak-tts'
import { useQuery } from 'hooks'
import { Howl, Howler } from 'howler'
import { normalizeString, asyncMapInSeries } from 'utils'
import { sounds as configSounds, whistleSounds } from 'config/sounds.json'
import { Container } from 'components'
import API from 'config/api'
import { useUnit } from './UnitContext'
const VOICE_TIMEOUT_IN_SECONDS = process.env.REACT_APP_VOICE_TIMEOUT || 30
function getTens(number) {
  const [tenth, unit] = number
  const tenthPathByNumber = {
    2: `VEINTI`,
    3: `TRENTAI`,
    4: `CUARENTAI`,
    5: `CINCUENTAI`,
    6: `SESENTAI`,
    7: `SETENTAI`,
    8: `OCHENTAI`,
    9: `NOVENTAI`,
  }
  if (unit == 0 || tenth == 1) {
    return [number]
  } else if (tenth == 0) {
    return [unit]
  } else {
    return [tenthPathByNumber[tenth], unit]
  }
}

function getHundreds(number) {
  let [hundred, ...tenth] = number
  tenth = tenth.join(``)
  if (number == 100 || tenth == `00`) {
    return [number]
  } else if (hundred == 0) {
    return getTens(tenth)
  } else {
    return [`${hundred}${hundred == 1 ? `00to` : `00`}`, ...getTens(tenth)]
  }
}

function getThousands(number) {
  const hundred = number.substr(-3)
  const thousand = number.substring(0, number.length - 3)
  const parsedThousand =
    thousand == `1` ? [`1000`] : [...parseNumber(thousand), `1000`]
  const parsedNumber = [...parsedThousand, ...parseNumber(hundred)]
  return parsedNumber
}

function parseNumber(number) {
  if (number.length == 1) {
    return [number]
  } else if (number.length == 2) {
    return getTens(number)
  } else if (number.length == 3) {
    return getHundreds(number)
  } else if (number.length > 3) {
    return getThousands(number)
  } else {
    return [number]
  }
}

function isOnlySoundCondition(callTypeId) {
  const onlySoundCallTypes = [3, 4, 6]
  return onlySoundCallTypes.includes(callTypeId)
}

function getSentence(attention) {
  const {
    ticket: { identifier, ticketNumber, patientFirstName, patientLastName },
    module: {
      description: moduleName,
      identifier: moduleIdentifier,
      callTypeId,
      namingConfig,
    },
    lineTypeId,
  } = attention

  // Si los tipos de llamados son de solo sonido
  // se retorna temprano el sonido a reproducir
  if (isOnlySoundCondition(callTypeId)) {
    let soundToReproduce = `beep`
    if (lineTypeId == 1) {
      soundToReproduce = `beepbox`
    } else if (lineTypeId == 2) {
      soundToReproduce = `beepcaja`
    }
    return [soundToReproduce]
  }
  let ticket = `${identifier.split(``).join(` `)} ${ticketNumber}`
  const module =
    namingConfig?.mainConfig?.spoken ?? `${moduleName} ${moduleIdentifier}`
  const withPatientCallTypes = [2, 7]
  if (
    withPatientCallTypes.includes(callTypeId) &&
    patientFirstName &&
    patientLastName
  ) {
    const [formattedFirstName] = patientFirstName.split(` `)
    const [formattedLastName] = patientLastName.split(` `)
    ticket = `${formattedFirstName}_${formattedLastName}`
  }
  const shortVoiceCallType = [5, 7].includes(callTypeId)
  const sentence = shortVoiceCallType
    ? `${ticket} ${module}`
    : `atencion ${ticket} dirijase a ${module}`
  const cleanSentence = sentence.split(` `).reduce((prev, current) => {
    const trimmedString = current.trim()
    const formattedWords = isNaN(trimmedString)
      ? [trimmedString]
      : parseNumber(trimmedString)
    return [...prev, ...formattedWords]
  }, [])

  return cleanSentence
}

function loadMeSpeak() {
  return new Promise((resolve) => {
    let meSpeak = window.meSpeak
    meSpeak.loadVoice(`es`, (success) => {
      if (success) {
        if (meSpeak.canPlay()) {
          resolve(meSpeak)
        } else {
          resolve(null)
        }
      } else {
        resolve(null)
      }
    })
  })
}

function loadSpeakTTS() {
  return new Promise((resolve) => {
    const isTTSSupported =
      `speechSynthesis` in window &&
      `SpeechSynthesisUtterance` in window &&
      false
    let speech = null
    if (isTTSSupported) {
      //Agregar nombres de voces siempre en minusculas
      const knownSpanishVoices = [`google español`, `español españa`]
      speech = new Speech()
      speech
        .init({
          volume: 1,
          lang: `es`,
          rate: 1,
          pitch: 0.8,
          listeners: {
            onvoiceschanged: (voices) => {
              const voice = voices.find((voice) =>
                knownSpanishVoices.includes(voice?.name?.toLowerCase()),
              )
              if (voice) {
                speech.setVoice(voice.name)
                resolve(speech)
              } else {
                resolve(null)
              }
            },
          },
        })
        .catch((error) => {
          console.log(`Error inicializando tts`, error)
          resolve(null)
        })
    } else {
      resolve(null)
    }
  })
}

async function loadHowler() {
  const { modules } = await API.getModules()
  const isOnlySound = modules.every((module) =>
    isOnlySoundCondition(module.callTypeId),
  )
  Howler.volume(1)
  Howler.autoUnlock = true
  Howler.html5PoolSize = 400
  const sounds = isOnlySound ? whistleSounds : configSounds

  const soundsByKey = {}
  await Promise.all(
    sounds.map(
      (sound) =>
        new Promise((resolve) => {
          soundsByKey[sound.key] = new Howl({
            src: sound.path,
            html5: true,
            onload: resolve,
          })
        }),
    ),
  )
  return soundsByKey
}

function defaultSpeakFn() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, 3000)
  })
}
function loadAndPlaySound(key) {
  return new Promise(async (resolve) => {
    try {
      const soundPath = await API.getSoundPath(key)
      const sound = new Howl({
        src: `${SERVER_HOST}${soundPath}`,
        html5: true,
        volume: 1,
        onloaderror(error) {
          console.log(`fallo al cargar howler`, error, key)
          resolve()
        },
      })
      sound.once(`load`, () => {
        console.log(`se cargo`)
        sound.play()
      })
      //Quizas ver el unload para removerlo de memoria
      sound.once(`end`, resolve)
      sound.once(`playerror`, (...args) => {
        console.log(`fallo howler`, args, key)
        resolve()
      })
    } catch (error) {
      console.log(error)
      resolve()
    }
  })
}
const SoundContext = React.createContext()
function SoundProvider({ children }) {
  const { units, unitsById } = useUnit()
  const { hasCloudVoices } = unitsById[units[0]]
  const query = useQuery()
  const config = useMemo(() => {
    return JSON.parse(localStorage.getItem(CONFIG_TV_KEY))
  }, [])
  const [speak, setSpeak] = useState({
    hasInitialized: false,
    speakFn: defaultSpeakFn,
  })

  useEffect(() => {
    async function speechInitializer() {
      const noSound = config?.noSound || query.get(`noSound`)
      if (noSound) {
        setSpeak({
          hasInitialized: true,
        })
      } else {
        const soundsByKey = await loadHowler()
        const speech = await loadSpeakTTS()
        const meSpeak = await loadMeSpeak()

        function playSoundAsync(soundKey) {
          return new Promise(async (resolve) => {
            if (!soundKey) {
              return resolve()
            }
            const normalizedKey = normalizeString(soundKey)
            const sound = soundsByKey[normalizedKey]

            if (sound) {
              console.log(`howler`, soundKey)
              sound.once(`end`, resolve)
              sound.once(`playerror`, (...args) => {
                console.log(`fallo howler`, args, soundKey)
                resolve()
              })
              sound.play()
            } else if (hasCloudVoices) {
              await loadAndPlaySound(normalizedKey)
              resolve()
            } else if (speech?.synthesisVoice) {
              console.log(`tts`, soundKey)
              speech.speak({
                text: soundKey,
                listeners: {
                  onend: () => {
                    resolve()
                  },
                },
              })
              /*
            Problem: there are cases where it fails and doesnt go to the
            catch or error listener (like with " ")
            Temporal fix: set a timeout relative to the length of the string,
            so it resolves eventually if it fails
            but not before is done talking if it doesnt
            */
              setTimeout(() => resolve(), soundKey.length * 600)
            } else if (meSpeak) {
              meSpeak.speak(
                soundKey,
                { amplitude: 100, pitch: 20, speed: 140 },
                (success) => {
                  if (success) {
                    console.log(`meSpeak`, soundKey)
                  } else {
                    console.log(`fallo meSpeak`, soundKey)
                  }
                  resolve()
                },
              )
            } else {
              console.log(`voiceserver`, soundKey)
              axios
                .post(`http://localhost:5000/speak`, {
                  text: normalizedKey,
                })
                .then(resolve)
                .catch((e) => {
                  console.log(`fallo voiceserver`, e, soundKey)
                  resolve()
                })
            }
          })
        }

        function speak(attention) {
          return new Promise((resolve) =>
            setTimeout(async () => {
              try {
                const sentence = getSentence(attention)
                //Antes de hablar se deja un tiemout (largo)
                //para evitar un pegado infinito si algo falla
                setTimeout(() => {
                  resolve()
                }, VOICE_TIMEOUT_IN_SECONDS * 1000)
                await asyncMapInSeries(sentence, playSoundAsync)
                resolve()
              } catch (e) {
                console.log(`Error al hablar`, e)
                resolve()
              }
            }, 500),
          )
        }

        setSpeak({
          speakFn: speak,
          hasInitialized: true,
        })
      }
    }

    speechInitializer()
  }, [])
  return (
    <SoundContext.Provider value={speak.speakFn}>
      <Container
        isLoading={!speak.hasInitialized}
        loaderSize="7"
        height="100vh"
      >
        {children}
      </Container>
    </SoundContext.Provider>
  )
}

function useSound() {
  const context = useContext(SoundContext)
  if (!context) {
    throw new Error(`useSound must be used within a SoundProvider`)
  }
  return context
}

export { SoundProvider, useSound }
