import { Button, Container, Typography } from 'components'
import { INACTIVITY_TIMEOUT_IN_SECONDS } from 'config/constants'
import React, { useEffect, useState } from 'react'

const FeedbackContainer = ({ children, padding = `` }) => (
  <Container justifyContent="center" width="100%" padding="8.6%">
    <Container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      backgroundColor="white"
      minHeight="45vh"
      width="100%"
      borderRadius="2%"
      padding={padding}
      shadow="0px 5px 5px 1px rgba(0, 0, 0, .1)"
    >
      {children}
    </Container>
  </Container>
)

export const TemporalFeedback = ({ prevComponent, postComponent, timeout }) => {
  const [countTimeoutMessage, setCountTimeoutMessage] = useState(timeout)
  useEffect(() => {
    if (timeout && timeout > 0) {
      const intervalId = setInterval(() => {
        setCountTimeoutMessage((count) => count - 1)
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [])
  if (countTimeoutMessage > 0) {
    return prevComponent
  }
  return <FeedbackContainer padding="0 2vh">{postComponent}</FeedbackContainer>
}

// eslint-disable-next-line complexity
const Feedbacks = ({
  icon,
  iconColor,
  iconPaddingTop = `0`,
  iconPaddingBottom = `7.2vh`,
  upperIcon,
  upperIconColor,
  title,
  subtitle,
  toRetry,
  toExit,
  textToExit,
  textToRetry,
  styleToExit,
  styleToRetry,
  goBack,
  extraMidContent,
  titleFontSize = `3.7vh`,
}) => {
  useEffect(() => {
    if (goBack) {
      setTimeout(() => goBack(), INACTIVITY_TIMEOUT_IN_SECONDS * 500)
    }
  }, [goBack])
  return (
    <FeedbackContainer>
      {icon ? (
        <Container
          paddingTop={iconPaddingTop}
          paddingBottom={iconPaddingBottom}
        >
          <Typography
            position="relative"
            fontSize="9.5vh"
            fontWeight="500"
            textAlign="center"
            color={iconColor ? iconColor : null}
            lineHeight="0"
          >
            {icon}
            {upperIcon ? (
              <Typography
                fontSize="4.7vh"
                fontWeight="500"
                textAlign="center"
                color={upperIconColor ? upperIconColor : null}
                position="absolute"
                right="-1.56vh"
                top="0.12vh"
              >
                {upperIcon}
              </Typography>
            ) : null}
          </Typography>
        </Container>
      ) : null}
      <Container>
        {title ? (
          <Typography
            fontSize={titleFontSize}
            fontWeight="500"
            textAlign="center"
            lineHeight="4.4vh"
            paddingX="3.6vh"
          >
            {title}
          </Typography>
        ) : null}
      </Container>
      {subtitle ? (
        <Container marginY="1.8vh" paddingX="3.6vh">
          <Typography
            fontSize="2.56vh"
            fontWeight="1"
            textAlign="center"
            lineHeight="3.3vh"
          >
            {subtitle}
          </Typography>
        </Container>
      ) : null}
      {extraMidContent ? (
        <Container marginY="1.8vh" paddingX="3.7%" width="100%">
          {extraMidContent}
        </Container>
      ) : null}
      {toRetry || toExit ? (
        <Container
          marginY="7%"
          flexDirection="column"
          width="100%"
          paddingX="3.7%"
        >
          {toRetry ? (
            <Button
              data-testid="firstButton"
              shadow="0px 5px 5px 1px rgba(0, 0, 0, .1)"
              backgroundColor="primary.4"
              color="white"
              fontSize="2.35vh"
              padding="1.25vh"
              borderRadius="1vh"
              marginTop={
                styleToRetry && styleToRetry?.marginTop
                  ? styleToRetry?.marginTop
                  : undefined
              }
              marginBottom={
                styleToRetry && styleToRetry?.marginBottom
                  ? styleToRetry?.marginBottom
                  : undefined
              }
              width="100%"
              onClick={toRetry}
            >
              {textToRetry}
            </Button>
          ) : null}
          {toExit ? (
            <Button
              data-testid="secondButton"
              backgroundColor={
                styleToExit && styleToExit?.backgroundColor
                  ? styleToExit?.backgroundColor
                  : `white`
              }
              color={
                styleToExit && styleToExit?.color
                  ? styleToExit?.color
                  : `primary.4`
              }
              fontSize="2.35vh"
              padding="1.25vh"
              borderRadius="1vh"
              width="100%"
              marginTop={
                styleToExit && styleToExit?.marginTop
                  ? styleToExit?.marginTop
                  : `5.3%`
              }
              border="2px"
              borderStyle="solid"
              borderColor="primary.4"
              onClick={toExit}
              shadow={
                styleToExit && styleToExit?.shadow ? styleToExit?.shadow : null
              }
            >
              {textToExit ? textToExit : null}
            </Button>
          ) : null}
        </Container>
      ) : null}
    </FeedbackContainer>
  )
}

export default Feedbacks
