import React from 'react'
import moment from 'moment-timezone'
import { Container, Typography, Icon } from 'components'

export default function NormalTicket({
  waitingTime,
  scheduledDate,
  displayName,
  patientRut,
  patientDocument,
  patientFullName,
  accessType,
  attentionTypeId,
  label,
  setTicketToDelete,
  isCallable,
}) {
  return (
    <Container width="20%" padding="0 0.5em" marginBottom="3">
      <Container
        flexDirection="column"
        alignItems="flex-start"
        backgroundColor="gray.0"
        width="100%"
        padding="0.5em"
        borderRadius="0.5em"
        position="relative"
        withShadow
      >
        <Container>
          <Typography
            color="primary.4"
            fontSize="1"
            fontStyle="italic"
            marginRight="3"
          >
            <Icon icon="userClock" marginRight="2" />
            {waitingTime} min
          </Typography>
          {scheduledDate != null && accessType != 11 ? (
            <Typography color="primary.4" fontSize="1" fontStyle="italic">
              <Icon icon="calendar" marginRight="2" />
              {moment(scheduledDate).format(`LT A`)}
            </Typography>
          ) : null}
        </Container>
        <Container
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography color="primary.4" fontSize="5" fontWeight="bold">
            {displayName}
            {accessType == 2 ? (
              <Icon icon="phone" fontSize="4" marginLeft="1" />
            ) : null}
            {[7, 8].includes(attentionTypeId) ? (
              <Icon icon="clock" fontSize="1" marginLeft="1" />
            ) : null}
          </Typography>
          {label ? (
            <Typography color="primary.4">
              <Icon marginRight="1" icon="exclamationCircle" />
              {label}
            </Typography>
          ) : null}
        </Container>
        <Typography color="primary.4" fontSize="2">
          {patientDocument || patientRut}
        </Typography>
        {!isCallable ? <Icon icon="cancel" /> : null}
        <Typography color="primary.4" fontSize="1">
          {patientFullName}
        </Typography>
        <Icon
          icon="close"
          color="primary.4"
          fontSize="1"
          position="absolute"
          w
          top="2"
          right="2"
          onClick={setTicketToDelete}
        />
      </Container>
    </Container>
  )
}
