/* eslint-disable max-lines-per-function */
import {
  Button,
  Container,
  Icon,
  Input,
  MaterialIcon,
  Typography,
} from 'components'
import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Keyboard from 'screens/totem/Identification/Keyboard'
import createKeyboardConfig, {
  callTypes,
  keyboardModes,
  callModesById,
} from 'config/keyboard'
import { validateEmail } from 'utils'
import { useInactivityHandler, useKeyListener, useStateWithMerge } from 'hooks'
import API from 'config/api'
import { useUnit } from '../../context'
import { genericErrorRedirect } from './Redirection'
import Feedbacks from './Feedbacks'
import { handleSendDocuments } from './VerifyEmail'
import {
  getTitle,
  getTitleButton,
  getTitleError,
  goBackByPath,
  handleInvalidByType,
  propsByInputValueType,
  textInputsList,
} from './utils'

const defaultState = {
  email: ``,
  submitActivated: false,
}
const VerifyEmail = ({ flushInterval, unit }) => {
  const history = useHistory()
  const location = useLocation()
  const locationState = location?.state ?? {}
  const { typeInputIntegratedUnit, prevPath, posType } = locationState
  const [state, setState] = useStateWithMerge(defaultState)
  const { email, submitActivated } = state
  const emailInputRef = useRef()
  const {
    division: { corporationId },
    isAutopagoActive: isAutopago,
  } = useUnit()

  const aToZChars = Array.from({ length: 26 }, (_, index) => index + 65)
  const zeroTo9numbers = Array.from({ length: 10 }, (_, index) => index + 48)
  const charsToListener = [
    ...zeroTo9numbers,
    ...aToZChars,
    8,
    58,
    186,
    188,
    189,
    190,
    209,
  ]
  if (typeInputIntegratedUnit !== `email`) {
    charsToListener.push(32) // space
  }

  const keysListener = useKeyListener(charsToListener)

  const goBack = () => {
    if (!location.pathname.includes(`/totem/units`)) {
      history.push(`/totem/units`)
    }
  }

  useInactivityHandler({
    timeout: 60,
    inactivityHandler: goBack,
    resetDependencies: [history.location.pathname],
  })
  useEffect(() => {
    setTimeout(() => flushInterval(), 500)
  }, [])

  useEffect(() => {
    const emailInput = emailInputRef.current.input
    emailInput.scrollLeft = emailInput.scrollWidth
  }, [email])

  useEffect(() => {
    if (keysListener?.key === `Backspace`) {
      setState({ email: email.slice(0, -1) })
    } else if (keysListener) {
      setState({ email: email + keysListener?.key.toUpperCase() })
    }
  }, [keysListener])

  function handleButtonClick(e) {
    setState({ email: email + e.target.name })
    e.target.blur()
  }

  function handleDeleteInput(e) {
    setState({ email: email.slice(0, -1) })
    if (e) {
      e.target.blur()
    }
  }

  function handleSpace() {
    setState({ email: `${email} ` })
  }

  const keyboardType = keyboardModes.ABC_KEYBOARD
  const currentMode = callModesById[callTypes.BY_EMAIL]
  const { layoutConfig } = createKeyboardConfig(currentMode[keyboardType], {
    handleButtonClick,
    handleDeleteInput,
  })

  async function getRedirection({ appointmentAgendaX, goToSchedules }) {
    if (goToSchedules) {
      const { patientRut, patientRutDv } = locationState.patientData
      const patientData = { patientRut, patientRutDv }
      const scheduleInfo = await API.getSchedule({
        ...patientData,
        unitId: unit.id,
        isAutopagoFrontend: isAutopago,
        corporationId,
        unitEarlyMinutes: unit.earlyMinutes,
        unitLateMinutes: unit.lateMinutes,
      })
      return history.push({
        pathname: `/totem/${unit.id}/schedules`,
        state: { ...scheduleInfo, patientData },
      })
    } else {
      return history.push({
        pathname: `/totem/${unit.id}/copago`,
        state: {
          scheduleData: { ...appointmentAgendaX, unitId: unit.id },
          posType,
        },
      })
    }
  }

  async function handleOnClick() {
    if (textInputsList.includes(typeInputIntegratedUnit)) {
      setState({ submitActivated: true })
      if (email.trim().length != 0) {
        const { nextPath, nextInputValueType, currentPath } =
          propsByInputValueType[typeInputIntegratedUnit]
        const state = {
          ...locationState,
          patientData: {
            ...locationState.patientData,
            [typeInputIntegratedUnit]: email,
          },
          typeInputIntegratedUnit: nextInputValueType,
          prevPath: currentPath,
          posType,
        }
        return history.push({
          pathname: `/totem/${unit.id}/${nextPath}`,
          state,
        })
      }
    } else if (typeInputIntegratedUnit == `email`) {
      setState({ submitActivated: true })
      if (validateEmail(email)) {
        try {
          const { selectedSchedule, patientData } = locationState
          const { appointment } = await API.sendAppointmentAgendaX({
            ...selectedSchedule,
            ...patientData,
            documentType: 3,
            email,
          })
          const { appointmentAgendaX, goToSchedules } = appointment
          getRedirection({ appointmentAgendaX, goToSchedules })
        } catch (error) {
          genericErrorRedirect({
            unitID: unit.id,
            redirect: `/totem`,
            timeout: 4000,
            data: {},
            hasToExit: false,
            history,
            title: `Error de conexión`,
            subtitle: `No es posible completar su solicitud en este momento.`,
            iconPaddingTop: `3rem`,
            iconSetting: {
              icon: <MaterialIcon icon="conectionError" />,
              iconColor: `primary.4`,
            },
          })
        }
      }
    } else {
      await handleSendDocuments({
        email,
        locationState,
        history,
        setState,
        isEmailValid: validateEmail(email),
      })
    }
  }

  return (
    <>
      {prevPath ? (
        <Container width="100%">
          <Button
            backgroundColor="transparent"
            paddingY="3"
            onClick={() =>
              goBackByPath({ locationState, history, unitId: unit.id })
            }
          >
            <Icon icon="arrowLeft" color="gray.3" fontSize="6" />
          </Button>
        </Container>
      ) : null}
      <Feedbacks
        textToRetry={
          <Container justifyContent="center" alignItems="center">
            <MaterialIcon
              icon={
                typeInputIntegratedUnit === `email` ||
                typeInputIntegratedUnit === undefined
                  ? `mail`
                  : undefined
              }
              fontSize="2.76vh"
              marginRight="1.8vh"
            />
            {getTitleButton(typeInputIntegratedUnit)}
          </Container>
        }
        toRetry={handleOnClick}
        styleToRetry={{ marginBottom: `1.2vh` }}
        extraMidContent={
          <Container
            width="100%"
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Typography fontSize="2.64vh" padding="3.6vh 0 2.4vh">
              {getTitle(typeInputIntegratedUnit)}
            </Typography>
            <Input
              ref={emailInputRef}
              width="100%"
              padding="3.7% 5%"
              fontSize="2.64vh"
              borderWidth="0.15vh"
              borderColor="#D0D0D0"
              borderRadius="1vh"
              placeholder=" "
              value={email}
              error={
                handleInvalidByType({
                  email,
                  submitActivated,
                  typeInputIntegratedUnit,
                })
                  ? getTitleError(typeInputIntegratedUnit)
                  : undefined
              }
              errorProps={{ fontSize: `2vh` }}
            />
            <Keyboard
              isQwerty
              isEmail
              config={layoutConfig}
              columns={22}
              marginTop={{ _: `4vh`, md: 0 }}
              marginLeft={{ _: 0, md: `1vw` }}
            />
            {textInputsList.includes(typeInputIntegratedUnit) ? (
              <Button
                width="100%"
                paddingY="2"
                marginTop="2"
                backgroundColor="primary.4@0.2"
                borderRadius="8px"
                fontSize={6}
                fontWeight="600"
                color="primary.4"
                hoverProps={{ backgroundColor: `white`, color: `gray.1` }}
                onClick={handleSpace}
              >
                ESPACIO
              </Button>
            ) : null}
          </Container>
        }
      />
    </>
  )
}

export default VerifyEmail
