import React, { useEffect, useState } from 'react'
import { Container, MaterialIcon } from 'components'
import { useHistory, useLocation } from 'react-router-dom'
import { print } from 'utils'
import API from 'config/api'
import { POS_TYPES } from 'config/constants'
import { useUnit } from 'context'
import { redirectWithoutQueuing, toExit } from './Redirection'
import Feedbacks from './Feedbacks'

export const handlePrints = async ({
  paymentResponse,
  getBonusResponse,
  bonusResponse,
  bonusDocument,
  notifyResult,
  scheduleData,
  posType,
  appointmentData,
  data,
}) => {
  try {
    if (paymentResponse && notifyResult && posType === POS_TYPES.H2H) {
      await print({
        voucher: {
          raw: paymentResponse.result.sale.VOUCHER,
          appointmentData: appointmentData || { appointment: scheduleData },
        },
        app: `h2h`,
      })
    }
    if (paymentResponse && !notifyResult && posType === POS_TYPES.H2H) {
      await print({
        voucher: {
          raw: paymentResponse.result.sale.VOUCHER,
          privateInfo: scheduleData,
          appointmentData: appointmentData || { appointment: scheduleData },
        },
        app: `h2h`,
      })
    }
    if (paymentResponse && posType === POS_TYPES.INTEGRATED && scheduleData) {
      await print({
        voucher: { ...paymentResponse.result, ...scheduleData },
        app: `integrated`,
      })
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
  try {
    if (bonusResponse) {
      await print({
        ...bonusResponse?.parsedResponse,
        financerName: data?.financerName,
        getBonusResponse,
        app: `bonus`,
      })
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
  try {
    if (bonusDocument) {
      await print({
        bonusDocument,
        app: `voucherWithProvider`,
      })
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

const handleSendToPrint = async ({ locationState, history }) => {
  const {
    scheduleData,
    paymentResponse,
    getBonusResponse,
    bonusResponse,
    notifyResult,
    posType,
    bonusDocument,
    data,
    appointmentData,
  } = locationState
  await handlePrints({
    paymentResponse,
    bonusResponse,
    getBonusResponse,
    bonusDocument,
    notifyResult,
    scheduleData,
    posType,
    appointmentData,
    data,
  })
  if (scheduleData.hasNotQueuing) {
    redirectWithoutQueuing({
      history,
      icon: <MaterialIcon icon="checkCircleOutline" />,
      iconColor: `green.0`,
      title: `Listo!`,
      subtitle: `Retire sus documentos
      y espere por su atención.`,
    })
  } else {
    toExit({
      history,
      scheduleData: {
        ...scheduleData,
        financerCode: data?.financerCode,
        cardType: paymentResponse?.result?.sale?.FAMTRX,
      },
      isAutopagoFinished: true,
      paymentResponse,
      bonusResponse,
      action: `TO_ATTENTION`,
    })
  }
}

const handleVerifyEmail = async ({ locationState, history, unitId }) => {
  const {
    scheduleData: {
      additionalPatientData: {
        patientData: { email },
      },
    },
  } = locationState
  if (email) {
    history.push({
      pathname: `/totem/${unitId}/verificarEmail`,
      state: locationState,
    })
  } else {
    history.push({
      pathname: `/totem/${unitId}/ingresarEmail`,
      state: locationState,
    })
  }
}

const DocumentObtainSelection = ({ unit }) => {
  const history = useHistory()
  const location = useLocation()
  const {
    division: { corporationId },
  } = useUnit()
  const locationState = location?.state ?? {}
  const { paymentResponse, scheduleData } = locationState
  const payload = {
    corporationId,
    paymentResponse,
    scheduleData,
    unitId: unit.id,
  }
  const [isLoading, setIsLoading] = useState(true)

  async function documentObtainHook() {
    const result = await API.beforeDocumentObtainSelectionHook(payload)
    if (result) {
      locationState.bonusDocument = result
    }
    setIsLoading(false)
  }

  useEffect(() => {
    documentObtainHook()
  }, [])

  return isLoading ? (
    <Container
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      isLoading={isLoading}
      loaderSize="7"
    />
  ) : (
    <Feedbacks
      icon={<MaterialIcon icon="checkCircleOutline" />}
      iconColor="green.0"
      iconPaddingTop="6.5vh"
      iconPaddingBottom="3.5vh"
      title={
        !paymentResponse
          ? `Bono generado exitosamente`
          : `Pago realizado exitosamente`
      }
      toExit={() => handleSendToPrint({ locationState, history })}
      textToExit="Imprimir documentos"
      textToRetry={
        <Container justifyContent="space-evenly" alignItems="center">
          <MaterialIcon icon="mail" fontSize="3vh" />
          Enviar documentos por correo
        </Container>
      }
      toRetry={() =>
        handleVerifyEmail({
          locationState: {
            ...locationState,
            scheduleData: {
              ...scheduleData,
              unitId: unit.id,
            },
          },
          history,
          unitId: unit.id,
        })
      }
      styleToRetry={{ marginTop: `2vh` }}
    />
  )
}

export default DocumentObtainSelection
