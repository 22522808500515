/* eslint-disable complexity */
import React, { useEffect, useRef } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { clean as cleanRut, format as formatRut } from 'rut.js'
import {
  Container,
  Button,
  Input,
  Typography,
  Img,
  MaterialIcon,
} from 'components'
import { useKeyListener, useStateWithMerge } from 'hooks'
import { useDisabled, useUnit } from 'context'
import createKeyboardConfig, {
  callTypes,
  keyboardModes,
  callModes,
  callModesById,
  callTypesByCountry,
} from 'config/keyboard'
import LayoutImageOrText from '../LayoutImageOrText'
import Keyboard from './Keyboard'
import QrScan from './QrScan'

function setInitialCallMode({
  isPrescriptionScan,
  country,
  goToIdBeforeLines,
}) {
  if (isPrescriptionScan && goToIdBeforeLines) {
    return callTypes.BY_RUT
  }
  if (isPrescriptionScan) {
    return callTypes.BY_PRESCRIPTION
  }
  return callTypesByCountry[country] ?? callTypes.BY_RUT
}

const initialState = ({
  country = `CL`,
  isPrescriptionScan,
  goToIdBeforeLines,
}) => ({
  rawDocument: ``,
  document: ``,
  isDocumentValid: false,
  callMode: setInitialCallMode({
    isPrescriptionScan,
    country,
    goToIdBeforeLines,
  }),
  keyboardType: keyboardModes.NUMERIC_KEYBOARD,
  isPrescriptionScanActive: false,
})

// eslint-disable-next-line max-lines-per-function
function Identification({
  goBack,
  onConfirm,
  children,
  isCalling,
  isRelated,
  hasMultiTransfer,
  allowPassport,
  isReservation = false,
  logo,
  unit,
  canAskWithoutRut = true,
  isValidatingRut = false,
}) {
  const location = useLocation()
  const goToIdBeforeLines = location?.state?.goToIdBeforeLines
  const line = location.state ?? null
  const history = useHistory()
  const { lineId } = useParams()
  const disabledRef = useRef(false)
  const inputRef = useRef(null)
  const { isPrescriptionScanActive: isPrescriptionScan } = useUnit()
  const [state, setState] = useStateWithMerge(() =>
    initialState({
      country: unit?.country,
      isPrescriptionScan,
      goToIdBeforeLines,
    }),
  )
  const { isDisabled } = useDisabled()
  const {
    ticket,
    callMode,
    document,
    isDocumentValid,
    keyboardType,
    rawDocument,
  } = state
  const currentMode = callModesById[callMode]
  const { layoutConfig, targetKeyCodes, actionTypes, forbiddenKeys } =
    createKeyboardConfig(currentMode[keyboardType], {
      handleButtonClick,
      handleDeleteInput,
    })
  const keysListener = useKeyListener(targetKeyCodes)

  useEffect(() => {
    if (callMode === callTypes.BY_PRESCRIPTION) {
      inputRef.current.focus()
    }
  }, [callMode])

  useEffect(() => {
    if (!line && !isRelated && !hasMultiTransfer && !isReservation) {
      goBack()
    }
    if (keysListener && callMode !== callTypes.BY_PRESCRIPTION) {
      switch (keysListener.key) {
        case actionTypes.ENTER:
          if (ticket) {
            goBack()
            break
          } else {
            handleTicketInput()
          }
          break
        case actionTypes.BACKSPACE:
          handleDeleteInput()
          break
        default: {
          const { validation, format, extraValidation } = currentMode
          const doc = format(document + keysListener.key)
          setState({ rawDocument: rawDocument + keysListener.key })
          if (forbiddenKeys.includes(keysListener.key)) {
            disabledRef.current = true
          } else if (
            extraValidation(document, doc, keysListener.key) &&
            !disabledRef.current
          ) {
            setState({
              document: doc,
              isDocumentValid: validation(doc),
            })
          }
        }
      }
    }
  }, [keysListener])

  useEffect(() => {
    if (callMode === callTypes.BY_RUT && isPrescriptionScan) {
      setTimeout(() => {
        setState({ callMode: callTypes.BY_PRESCRIPTION, document: `` })
      }, 60000)
    }
  }, [callMode])

  if (canAskWithoutRut) {
    canAskWithoutRut =
      !isReservation &&
      ((!line && document.length === 0) ||
        (line &&
          !line.isRutRequired &&
          document.length === 0 &&
          currentMode.id === callTypes.BY_RUT))
  }
  const canSubmit =
    (canAskWithoutRut ||
      isDocumentValid ||
      (document.length > 1 && callMode === callTypes.BY_PRESCRIPTION)) &&
    !isCalling

  //Manejo del teclado
  function handleButtonClick(e) {
    const { validation, format, extraValidation } = currentMode
    const key = e.target.name
    if (key === `ABC`) {
      setState({ keyboardType: keyboardModes.ABC_KEYBOARD })
    } else if (key === `123`) {
      setState({ keyboardType: keyboardModes.NUMERIC_KEYBOARD })
    } else {
      const doc = format(document + key)
      if (extraValidation(document, doc)) {
        setState({
          document: doc,
          isDocumentValid: validation(doc),
        })
      }
    }
    e.target.blur()
  }
  function handleDeleteInput(e) {
    const { validation, format } = currentMode
    disabledRef.current = false
    if (document && document !== `-`) {
      setState({
        document: format(document.slice(0, -1)),
        isDocumentValid: validation(format(document.slice(0, -1))),
      })
    } else {
      setState({ document: ``, isDocumentValid: false })
    }
    if (e) {
      e.target.blur()
    }
  }

  function handleTicketInput() {
    if (canSubmit) {
      const isByPrescription = callMode === callTypes.BY_PRESCRIPTION
      const isByRut = callMode === callTypes.BY_RUT
      const indexOfRut = rawDocument.indexOf(`RUN`)
      const payload = {}
      if (indexOfRut != -1) {
        let indexOfMiddleDash = rawDocument.indexOf(`'`)
        if (indexOfMiddleDash == -1) {
          // En caso de lector lea en español
          indexOfMiddleDash = rawDocument.indexOf(`-`)
        }
        let rawRut = rawDocument.substring(
          indexOfRut + 3,
          indexOfMiddleDash + 2,
        )
        const [rut, dv] = formatRut(rawRut).split(`-`)
        payload.patientRut = parseInt(cleanRut(rut), 10)
        payload.patientRutDv = dv
      } else if (!canAskWithoutRut && isByRut) {
        const [rut, dv] = document.split(`-`)
        payload.patientRut = parseInt(cleanRut(rut), 10)
        payload.patientRutDv = dv
      } else if (!isByRut && !isByPrescription) {
        payload.patientDocument = document
      } else if (isByPrescription) {
        payload.prescriptionCode = document
      }
      if (onConfirm != null) {
        onConfirm(payload)
      } else {
        history.push({
          pathname: `/totem/${unit.id}/print`,
          state: { ...payload, lineId, isReservation },
        })
      }
      if (!callMode === callTypes.BY_PRESCRIPTION) {
        setState(initialState(unit.country))
      }
    }
  }

  function getFlexDirection() {
    if (unit.country === `PE`) {
      return { md: `row-reverse`, _: `column` }
    } else {
      return { md: `row`, _: `column` }
    }
  }
  const isQwertyKeyboard = keyboardType === keyboardModes.ABC_KEYBOARD

  return (
    <>
      {unit?.totemLayout && unit?.totemLayout !== 1 && isDisabled ? (
        <LayoutImageOrText bottom="65vh" totemLayout={unit.totemLayout} />
      ) : null}
      <Container width="100%" justifyContent="center" marginTop="1vh">
        <Container
          width={{ md: `90%`, _: `70%` }}
          flexDirection={getFlexDirection()}
          justifyContent={isDisabled ? `flex-start` : `center`}
          alignItems={isDisabled ? `flex-end` : `flex-end`}
          flexWrap="no-wrap"
          paddingY="4vh"
        >
          <Container
            height="auto"
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="no-wrap"
            marginLeft={unit.country === `PE` ? `3vh` : 0}
          >
            <Container
              width="100%"
              boxSizing="border-box"
              justifyContent="center"
              backgroundColor="transparent"
            >
              <Img src={logo} maxWidth="80%" marginBottom="16px" />
            </Container>
            {children}
            {/* CUI Peru no compatible con passport de momento */}
            <Container
              justifyContent="center"
              alignItems="center"
              flexWrap="no-wrap"
              width="100%"
            >
              <Typography
                width="60%"
                color="primary.6"
                fontSize={5}
                marginBottom="10px"
                textAlign="center"
                fontWeight="900"
              >
                Ingrese su{` `}
                {(allowPassport || isPrescriptionScan) && unit?.country != `PE`
                  ? ``
                  : currentMode.textOption}
              </Typography>
              {allowPassport && unit?.country != `PE` && !isPrescriptionScan ? (
                <EntryOptions
                  callMode={callMode}
                  setState={setState}
                  options={callModes.map(
                    (callModeId) => callModesById[callModeId],
                  )}
                />
              ) : null}
              {isPrescriptionScan && (
                <EntryOptions
                  callMode={callMode}
                  setState={setState}
                  options={[`byRut`, `byPrescription`].map(
                    (callModeId) => callModesById[callModeId],
                  )}
                />
              )}
            </Container>
            <Container
              width="100%"
              marginY="1vh"
              flexDirection="row"
              flexWrap="no-wrap"
              backgroundColor="white"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              padding="0.5em"
              border="solid"
              borderWidth="1px"
              height={{ _: `5vh`, md: `10vh` }}
              borderColor={
                !isDocumentValid &&
                document.length > 0 &&
                callMode !== callTypes.BY_PRESCRIPTION
                  ? `error.3`
                  : `gray.1`
              }
            >
              <Input
                placeholder=" "
                name="document"
                value={document}
                onChange={(rut) => setState({ document: rut })}
                width="100%"
                fontSize="6"
                textAlign="center"
                boxSizing="border-box"
                border="0px"
                padding="0"
                ref={inputRef}
                autoComplete="off"
                className="scan"
              />
              <Container
                alignSelf="center"
                width="2.5rem"
                justifyContent="flex-end"
              >
                {(isDocumentValid && callMode !== callTypes.BY_PRESCRIPTION) ||
                (callMode === callTypes.BY_PRESCRIPTION &&
                  document.length > 0) ? (
                  <MaterialIcon
                    icon="checkCircleOutline"
                    position="relative"
                    top="-0.2em"
                    fontSize="2.5rem"
                    height="fit-content"
                    color="green.0"
                    maxHeight="1em"
                  />
                ) : (
                  currentMode.extraValidation(document) &&
                  document.length > 0 && (
                    <MaterialIcon
                      icon="errorOutline"
                      position="relative"
                      top="-0.2em"
                      color="error.3"
                      fontSize="2.5rem"
                      maxHeight="1em"
                    />
                  )
                )}
              </Container>
            </Container>
            {isValidatingRut ? (
              <Container
                display={{ _: `none`, md: `flex` }}
                isLoading={true}
                marginTop="2vh"
                height={{ _: `5vh`, md: `10vh` }}
                alignItems="center"
              />
            ) : (
              <SubmitButton
                unit={unit}
                document={document}
                canSubmit={canSubmit}
                isCalling={isCalling}
                isDisabled={isDisabled}
                handleTicketInput={handleTicketInput}
                canAskWithoutRut={canAskWithoutRut}
                display={{ _: `none`, md: `block` }}
              />
            )}
          </Container>
          {isPrescriptionScan && callMode === callTypes.BY_PRESCRIPTION ? (
            <QrScan handleTicketInput={handleTicketInput} state={state} />
          ) : (
            <Keyboard
              isQwerty={isQwertyKeyboard}
              config={layoutConfig}
              columns={isQwertyKeyboard ? 20 : 3}
              marginTop={{ _: `1vh`, md: 0 }}
              marginLeft={{ _: 0, md: `1vw` }}
            />
          )}
          {isValidatingRut ? (
            <Container
              display={{ _: `flex`, md: `none` }}
              isLoading={true}
              marginTop="2vh"
              height={{ _: `5vh`, md: `10vh` }}
              justifyContent="center"
              width="100%"
            />
          ) : (
            <SubmitButton
              testId="keyboardActionButton"
              unit={unit}
              document={document}
              canSubmit={canSubmit}
              isCalling={isCalling}
              isDisabled={isDisabled}
              handleTicketInput={handleTicketInput}
              canAskWithoutRut={canAskWithoutRut}
              display={
                callMode != callTypes.BY_PRESCRIPTION
                  ? { _: `block`, md: `none` }
                  : `none`
              }
              isPrescriptionScan={isPrescriptionScan}
            />
          )}
        </Container>
      </Container>
      {unit?.totemLayout && unit?.totemLayout !== 1 && !isDisabled ? (
        <LayoutImageOrText
          bottom={{ _: `7vh`, sm: `7vh`, xs: `7vh`, md: `0` }}
          totemLayout={unit.totemLayout}
        />
      ) : null}
    </>
  )
}

function EntryOptions({ options, callMode, setState }) {
  return (
    <Container
      borderRadius="40px"
      width="100%"
      marginBottom="2"
      marginTop={{ _: `2`, sm: 0 }}
      padding={2}
      backgroundColor="white"
    >
      {options.map((option) => {
        const { id, textOption } = option
        const isActive = callMode === id
        return (
          <Container
            borderRadius="40px"
            key={id}
            justifyContent="center"
            alignItems="center"
            padding="3"
            width={`${100 / options.length}%`}
            backgroundColor={isActive ? `primary.4` : `white`}
            withShadow={isActive}
            onClick={() =>
              setState({
                callMode: id,
                document: ``,
                isDocumentValid: false,
                keyboardType:
                  id === callTypes.BY_PASSPORT
                    ? keyboardModes.ABC_KEYBOARD
                    : keyboardModes.NUMERIC_KEYBOARD,
              })
            }
          >
            <Typography
              fontWeight="900"
              fontSize={3}
              letterSpacing={1}
              color={isActive ? `white` : `gray.2`}
            >
              {textOption}
            </Typography>
          </Container>
        )
      })}
    </Container>
  )
}

function SubmitButton({
  canSubmit,
  isCalling,
  isDisabled,
  handleTicketInput,
  canAskWithoutRut,
  unit,
  document,
  isPrescriptionScan,
  ...props
}) {
  return (
    <Button
      data-testid={props?.testId}
      backgroundColor={canSubmit ? `primary.7` : `gray.0`}
      color={canSubmit ? `white` : `gray.3`}
      width="100%"
      borderRadius="5px"
      fontSize="5"
      fontWeight="bold"
      disabled={isCalling}
      marginTop="2vh"
      onClick={handleTicketInput}
      withShadow={canSubmit}
      height={{ _: `5vh`, md: `10vh` }}
      {...props}
    >
      {document?.length > 0 && unit?.isRelated && !isPrescriptionScan
        ? `Continuar`
        : `Pedir Ticket ${canAskWithoutRut ? `Sin Rut` : ``}`}
    </Button>
  )
}

export default Identification
