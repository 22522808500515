import React from 'react'
import moment from 'moment-timezone'
import {
  CustomInput,
  CustomSelect,
  InputPassword,
  DatePicker,
  TimePicker,
  Radio,
  RadioButton,
  RadioGroup,
  Checkbox,
  InputNumber,
} from './styles'
import Container from './Container'
import Typography from './Typography'

const inputType = {
  text({ value, onChange, placeholder, name, disabled, inputRef, ...props }) {
    return (
      <CustomInput
        onChange={({ target: { name, value } }) => onChange(value, name)}
        placeholder={placeholder || `Escribe aquí`}
        name={name}
        value={value}
        disabled={disabled}
        type={inputType}
        ref={inputRef}
        {...props}
      />
    )
  },
  password({
    value,
    onChange,
    placeholder,
    name,
    disabled,
    inputRef,
    ...props
  }) {
    return (
      <InputPassword
        onChange={({ target: { name, value } }) => onChange(value, name)}
        placeholder={placeholder || `Escribe aquí`}
        name={name}
        value={value}
        disabled={disabled}
        ref={inputRef}
        {...props}
      />
    )
  },
  select({
    value,
    onChange,
    placeholder,
    options = [],
    name,
    inputRef,
    ...props
  }) {
    return (
      <CustomSelect
        onChange={(value) => onChange(value, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value}
        key={name}
        showSearch
        ref={inputRef}
        filterOption={(inputValue, option) =>
          option.props.children.toLowerCase().includes(inputValue.toLowerCase())
        }
        {...props}
      >
        {options.map((option) => (
          <CustomSelect.Option key={option.value} value={option.value}>
            {option.label}
          </CustomSelect.Option>
        ))}
      </CustomSelect>
    )
  },
  radio({
    value,
    onChange,
    placeholder,
    options = [],
    name,
    inputRef,
    optionsMargin,
    ...props
  }) {
    return (
      <RadioGroup
        onChange={({ target: { value } }) => onChange(value, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value}
        key={name}
        ref={inputRef}
        {...props}
      >
        {options.map((option) => (
          <Radio
            margin={optionsMargin}
            key={option.value}
            value={option.value}
            disabled={option.disabled || false}
          >
            {option.label}
          </Radio>
        ))}
      </RadioGroup>
    )
  },
  radioButton({
    value,
    onChange,
    placeholder,
    options = [],
    name,
    labelKey = `label`,
    valueKey = `value`,
    inputRef,
    ...props
  }) {
    return (
      <RadioGroup
        onChange={({ target: { value } }) => onChange(value, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value}
        key={name}
        ref={inputRef}
        {...props}
      >
        {options.map((option) => (
          <RadioButton
            disabled={option.disabled}
            key={option[valueKey]}
            value={option[valueKey]}
          >
            {option[labelKey]}
          </RadioButton>
        ))}
      </RadioGroup>
    )
  },
  checkbox({ value, onChange, name, inputRef, ...props }) {
    return (
      <Checkbox
        checked={value}
        name={name}
        onChange={({ target: { checked } }) => onChange(checked, name)}
        ref={inputRef}
        {...props}
      />
    )
  },
  date({ value, onChange, placeholder, name, format, inputRef, ...props }) {
    return (
      <DatePicker
        value={value ? moment(value, format) : null}
        key={name}
        onChange={(date) => onChange(date, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        format={format}
        ref={inputRef}
        {...props}
      />
    )
  },
  time({
    value,
    onChange,
    placeholder,
    name,
    period,
    allowClear = false,
    format = `HH:mm`,
    inputRef,
    ...props
  }) {
    return (
      <TimePicker
        value={value ? moment(value, format) : null}
        key={name}
        onChange={(date, dateString) => {
          const newValue = moment(dateString, format)
          if (newValue.isValid()) {
            onChange(newValue.format(format), name)
          }
        }}
        format={format}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        allowClear={allowClear}
        ref={inputRef}
        {...props}
      />
    )
  },
  number({ value, name, onChange, addonBefore = null, inputRef, ...props }) {
    return (
      <Container alignItems="stretch" width="100%">
        {addonBefore ? (
          <Container
            alignItems="center"
            justifyContent="center"
            backgroundColor="gray.0"
            borderRadius="4px 0px 0px 4px"
            borderY="1px solid"
            borderLeft="1px solid"
            borderColor="gray.1"
            width="20%"
          >
            <Typography fontWeight="3" color="gray.3">
              {addonBefore}
            </Typography>
          </Container>
        ) : null}
        <InputNumber
          value={value}
          onChange={(value) => onChange(value, name)}
          name={name}
          width={addonBefore ? `80% !important` : null}
          borderRadius={addonBefore ? `0px 4px 4px 0px` : null}
          ref={inputRef}
          {...props}
        />
      </Container>
    )
  },
}

const Input = React.forwardRef(
  (
    {
      type = `text`,
      label,
      theme,
      error,
      margin = null,
      color,
      width = null,
      onChange = () => null,
      errorProps = {},
      ...props
    },
    ref,
  ) => {
    const Component = inputType[type]
    return (
      <Container flexDirection="column" width={width} margin={margin}>
        {label && <Typography color={color}>{label}</Typography>}
        <Component
          error={error}
          onChange={onChange}
          color={color}
          type={type}
          inputRef={ref}
          {...props}
        />
        {error && (
          <Typography
            fontWeight="100"
            margin="0"
            color="error.3"
            {...errorProps}
          >
            {error}
          </Typography>
        )}
      </Container>
    )
  },
)
Input.displayName = `Input`

export default Input
