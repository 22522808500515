import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser as user,
  faUsers as users,
  faBell as bell,
  faMobileAlt as phone,
  faCircle as circle,
  faExclamationCircle as exclamationCircle,
  faTimes as close,
  faClock as clock,
  faSpinner as spinner,
  faTimesCircle as timesCircle,
  faCheckCircle as checkCircle,
  faChevronRight as chevronRight,
  faChevronLeft as chevronLeft,
  faPlusCircle as plusCircle,
  faCaretDown as caretDown,
  faCaretUp as caretUp,
  faCaretLeft as caretLeft,
  faCaretRight as caretRight,
  faBackspace as backspace,
  faSun as sun,
  faSearch as search,
  faCalendarCheck as calendarCheck,
  faTrash as trash,
  faWheelchair as wheelchair,
  faArrowUp as arrowUp,
  faArrowLeft as arrowLeft,
  faUserClock as userClock,
  faCalendar as calendar,
  faCopy as copy,
  faLayerGroup as layerGroup,
  faEdit as edit,
  faTicketAlt as ticket,
  faCheck as check,
  faCancel as cancel,
  faX as X,
  faCircleNotch as circleNotch,
  faTriangleExclamation as warning,
  faCircleXmark as circleX,
  faCircleCheck as circleCheck,
  faSave as save,
  faTimes as times,
  faBars as bars,
  faPlus as plus,
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebook as facebook,
  faInstagram as instagram,
  faTwitter as twitter,
} from '@fortawesome/free-brands-svg-icons'

/**
 * despejado -> sun despejado.png
 * nublado -> nubes parcial.png
 * cubierto -> nubes cubierto.png
 * nubosidad parcial -> parcialalta.png
 * cubierto y chubascos -> nubes lluviecita chubascos.png
 * nublado y chubascos -> chubascos.png
 * cubierto y llovizna -> llovizna.png
 * escasa nubosidad -> pocasnubes.png
 * cubierto y nevadas -> nieve.png
 *
 */

import {
  compose,
  color,
  typography,
  space,
  position,
  border,
} from 'styled-system'

const icons = {
  arrowLeft,
  arrowUp,
  backspace,
  bell,
  calendar,
  calendarCheck,
  cancel,
  caretDown,
  caretLeft,
  caretRight,
  caretUp,
  circleCheck,
  circleX,
  check,
  checkCircle,
  chevronLeft,
  chevronRight,
  circle,
  circleNotch,
  clock,
  close,
  copy,
  edit,
  exclamationCircle,
  facebook,
  instagram,
  layerGroup,
  phone,
  plusCircle,
  search,
  spinner,
  sun,
  ticket,
  timesCircle,
  trash,
  twitter,
  user,
  userClock,
  users,
  warning,
  wheelchair,
  X,
  save,
  times,
  bars,
  plus,
}

const Icon = styled(FontAwesomeIcon)`
  ${compose(color, typography, space, position, border)}
  ${({ onClick = null }) => (onClick == null ? `` : `  cursor: pointer`)}
`
function IconWrapper({ icon, ...props }) {
  if (!icons[icon]) {
    return null
  }
  return <Icon icon={icons[icon]} {...props} />
}

export default IconWrapper
