/* eslint-disable max-lines-per-function */
import React, { useState } from 'react'
import moment from 'moment-timezone'
import { clean } from 'rut.js'
import API from 'config/api'
import { Container } from 'components'
import { useAttentions, useAuth } from 'context'
import { notification } from 'antd'
import CurrentAttention from './CurrentAttention'
import ActionsRow from './ActionsRow'
import PanelHeader from './PanelHeader'
import ModalWrapper, { modalTypes } from './ModalWrapper'

function CallPanel({ currentAttentions, currentLines, hasStock }) {
  const {
    setCurrentAttention,
    activeModuleId,
    currentAttention,
    auxiliaryLines,
  } = useAttentions()
  const { user, currentUnit, isUserAvailable } = useAuth()
  const [modalConfig, setModalConfig] = useState(null)
  function editTicketPatient(
    onEditPatientSuccess = null,
    actionsParams = null,
  ) {
    async function onConfirm(patient) {
      try {
        const payload = {
          ...patient,
          ticketId: currentAttention.ticketId,
        }
        if (patient.patientRut) {
          const [rut, dv] = patient.patientRut.split(`-`)
          payload.patientRut = parseInt(clean(rut), 10)
          payload.patientRutDv = dv
        }
        await API.editTicketPatient(payload)
        const nextCurrentAttention = {
          ...currentAttention,
          ...patient,
        }
        setCurrentAttention({
          currentAttention: nextCurrentAttention,
          moduleId: activeModuleId,
        })
        if (onEditPatientSuccess != null) {
          if (actionsParams) {
            await onEditPatientSuccess(actionsParams)
          } else {
            await onEditPatientSuccess()
          }
        }
        setModalConfig(null)
        notification.success({
          message: `Datos del paciente editados con exito`,
        })
      } catch (e) {
        const error =
          typeof e === `string`
            ? e
            : `Error al guardar datos del paciente, reintente.`
        notification.error({ message: error })
      }
    }
    const modalConfig = {
      patient: {
        patientRut: currentAttention.patientRut,
        patientFirstName: currentAttention.patientFirstName,
        patientLastName: currentAttention.patientLastName,
        patientMaternalLastName: currentAttention.patientMaternalLastName,
        patientMail: currentAttention.patientMail,
        patientDocument: currentAttention.patientDocument,
      },
      openModal: modalTypes.editPatient,
      onConfirm,
    }
    setModalConfig(modalConfig)
  }
  function scheduleAttentions() {
    async function onConfirm(attentions) {
      const attentionsWithTicketId = attentions.map((attention) => {
        const getDate = moment(attention.getDateTime, `HH:mm`)
        return {
          ...attention,
          ticketId: currentAttention.ticketId,
          getDate,
          attentionTypeId: 7, //Atencion programada
        }
      })
      try {
        await API.scheduleAttentions(attentionsWithTicketId)
        notification.success({ message: `Atenciones programadas con exito` })
        setModalConfig(null)
      } catch (e) {
        const error =
          typeof e === `string`
            ? e
            : `Error al guardar datos del paciente, reintente.`
        notification.error({ message: error })
      }
    }

    const linesOptions = auxiliaryLines.currentUnitLines.map((line) => {
      return { value: line.id, label: line.name }
    })
    const modalConfig = {
      onConfirm,
      lines: linesOptions,
      currentLine: currentAttention.lineId,
      openModal: modalTypes.scheduleAttentions,
      ticket: currentAttention.displayName,
    }
    setModalConfig(modalConfig)
  }
  function planTicketJourney(onPlanTicketSuccess = () => null) {
    async function onConfirm(attentions) {
      const { ticketId } = currentAttention
      const ticketSuggestedLines = [
        {
          lineId: currentAttention.lineId,
          ticketId,
          executedAt: moment(),
        },
        ...attentions.map((lineId) => {
          return {
            lineId,
            ticketId: currentAttention.ticketId,
          }
        }),
      ]
      const [nextLineId] = attentions

      try {
        await API.planTicketJourney({
          ticketSuggestedLines,
          ticketId,
        })
        await onPlanTicketSuccess(nextLineId)
        notification.success({ message: `Ticket derivado con exito` })
        setModalConfig(null)
      } catch (e) {
        const error =
          typeof e === `string`
            ? e
            : `Error al guardar datos del paciente, reintente.`
        notification.error({ message: error })
      }
    }
    const linesById = {}
    const linesOptions = auxiliaryLines.currentUnitLines.map((line) => {
      linesById[line.id] = line
      return { value: line.id, label: line.name }
    })
    const modalConfig = {
      onConfirm,
      lines: linesOptions,
      linesById,
      currentLine: currentAttention.lineId,
      openModal: modalTypes.planTicketJourney,
      ticket: currentAttention.displayName,
    }
    setModalConfig(modalConfig)
  }
  function openStockAdmissionistModal() {
    const { patientFirstName, patientLastName, patientDocument, patientRut } =
      currentAttention
    setModalConfig({
      openModal: modalTypes.stockAdmissionistModal,
      patientName: `${patientFirstName || ``} ${patientLastName || ``}`,
      patientRut,
      document: patientDocument,
      isAvailable: true,
    })
  }

  const isAccessTypeRestricted =
    currentAttention && [5, 6, 11].includes(currentAttention.accessType)
  return (
    <Container
      flex={4}
      marginRight="1em"
      withShadow
      padding="0.5em 1em"
      borderTop="3px solid"
      borderColor="primary.8"
    >
      {modalConfig != null && (
        <ModalWrapper {...modalConfig} onClose={() => setModalConfig(null)} />
      )}
      <PanelHeader
        currentAttention={currentAttention}
        editTicketPatient={editTicketPatient}
        scheduleAttentions={scheduleAttentions}
        openStockAdmissionistModal={openStockAdmissionistModal}
        isAccessTypeRestricted={isAccessTypeRestricted}
        hasStock={hasStock}
        currentLines={currentLines}
      />
      <CurrentAttention currentAttention={currentAttention} />
      <ActionsRow
        currentLines={currentLines}
        currentAttentions={currentAttentions}
        editTicketPatient={editTicketPatient}
        planTicketJourney={planTicketJourney}
        auxiliaryLines={auxiliaryLines}
        currentAttention={currentAttention}
        isAccessTypeRestricted={isAccessTypeRestricted}
        user={user}
        currentUnit={currentUnit}
        isUserAvailable={isUserAvailable}
      />
    </Container>
  )
}

export default CallPanel
