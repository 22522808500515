import React from 'react'
import { Container, Button, Input } from 'components'
import { useAuth, useAttentions } from 'context'
import { getPropsByConditionToButtons } from 'utils'
import ExceptionalButton from './ExceptionalButton'

function PanelHeader({
  currentAttention,
  editTicketPatient,
  scheduleAttentions,
  openStockAdmissionistModal,
  isAccessTypeRestricted,
  hasStock,
  currentLines,
}) {
  const { motives, motivesById, motiveId, setMotiveId } = useAttentions()
  const canEditPatient =
    currentAttention && currentAttention.allowEditingPatient
  const canScheduleAttentions =
    currentAttention &&
    currentAttention.allowSchedulingAttentions &&
    !isAccessTypeRestricted
  const canShowStock = !!currentAttention?.patientRut

  const { currentUnit } = useAuth()
  const { hasExceptionalCall } = currentUnit

  return (
    <Container width="100%" justifyContent="space-between" alignItems="center">
      <Input
        width={hasStock ? `18%` : `20%`}
        type="select"
        fontSize="2"
        disabled={currentAttention == null}
        value={motiveId}
        onChange={setMotiveId}
        placeholder="Especificar motivo de atención"
        options={motives.map((type) => ({
          value: type,
          label: motivesById[type].description,
        }))}
      />
      <Button
        {...getPropsByConditionToButtons(canEditPatient)}
        fontSize="1"
        width={hasStock ? `18%` : `25%`}
        paddingY="1"
        paddingX="2"
        disabled={!canEditPatient}
        onClick={() => (canEditPatient ? editTicketPatient() : null)}
      >
        Editar datos de paciente
      </Button>
      <Button
        {...getPropsByConditionToButtons(canScheduleAttentions)}
        fontSize="1"
        width={hasStock ? `18%` : `25%`}
        paddingY="1"
        paddingX="2"
        disabled={!canScheduleAttentions}
        onClick={() => (canScheduleAttentions ? scheduleAttentions() : null)}
      >
        Programar atenciones
      </Button>

      {hasExceptionalCall ? (
        <ExceptionalButton
          currentLines={currentLines}
          width={hasStock ? `18%` : `25%`}
        />
      ) : null}
      {hasStock ? (
        <Button
          {...getPropsByConditionToButtons(canShowStock)}
          fontSize="1"
          width="18%"
          paddingY="1"
          paddingX="2"
          onClick={openStockAdmissionistModal}
          disabled={!canShowStock}
        >
          Sesiones por modulo
        </Button>
      ) : null}
    </Container>
  )
}

export default PanelHeader
