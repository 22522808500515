import React, { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { Container, Icon } from 'components'
import notification from 'antd/es/notification'
import API from 'config/api'
import {
  CONFIG_BY_UNIT_KEY,
  CHECKIN_ACTIVE_KEY,
  PRINT_SERVER_HOST_KEY,
  IGNORE_SCHEDULE_KEY,
  AUTOPAGO_ACTIVE_KEY,
  PRESCRIPTION_SCAN_ACTIVE_KEY,
  SERVER_HOST,
} from 'config/constants'
import { useIO, useStateWithMerge } from 'hooks'
import { getNewUnitConfig } from 'utils'
import {
  LOGO_KEIRON,
  LOGO_UC,
  LOGO_MEDICENTER,
  LOGO_LASCONDES,
  LOGO_RIPLEY,
  LOGO_IT,
  LOGO_INTEGRAMEDICA,
  LOGO_LOBARNECHEA,
  LOGO_CRS,
  LOGO_SALVADOR,
} from 'assets'
import { useTheme } from './ThemeContext'

const logoColection = {
  1: LOGO_LASCONDES,
  2: LOGO_SALVADOR,
  3: LOGO_MEDICENTER,
  11: LOGO_IT,
  18: LOGO_UC,
  20: LOGO_RIPLEY,
  22: LOGO_INTEGRAMEDICA,
  32: LOGO_LOBARNECHEA,
  40: LOGO_CRS,
  41: LOGO_RIPLEY,
}

function getLogo(corporation) {
  if (corporation?.localLogoPath != null) {
    return `${SERVER_HOST}${corporation.localLogoPath}`
  }
  if (corporation?.id) {
    return logoColection[corporation.id] || LOGO_KEIRON
  }
  return LOGO_KEIRON
}

//Retrocompatibilidad de intentar convertir config local previa
//A objeto multiunidad
function legacyLocalConfigMigration({ units, unitsById }) {
  const LEGACY_LINES_KEY = `saltala_visible_lines`
  const LEGACY_SHOW_VIRTUAL_TICKET_KEY = `saltala_virtual_ticket`
  const legacyVisibleLines = JSON.parse(localStorage.getItem(LEGACY_LINES_KEY))
  const legacyShowOnlyVirtualTicket =
    JSON.parse(localStorage.getItem(LEGACY_SHOW_VIRTUAL_TICKET_KEY)) ?? false
  if (legacyVisibleLines?.length > 0) {
    localStorage.removeItem(LEGACY_LINES_KEY)
    localStorage.removeItem(LEGACY_SHOW_VIRTUAL_TICKET_KEY)
    const legacyUnitId = units.find((unitId) => {
      const { lines } = unitsById[unitId]
      return legacyVisibleLines.every((legacyLineId) =>
        lines.includes(legacyLineId),
      )
    })
    if (legacyUnitId != null) {
      const localConfigByUnit = {
        [legacyUnitId]: getNewUnitConfig({
          visibleLines: legacyVisibleLines,
          showOnlyVirtualTicket: legacyShowOnlyVirtualTicket,
          isActive: true,
        }),
      }
      localStorage.setItem(
        CONFIG_BY_UNIT_KEY,
        JSON.stringify(localConfigByUnit),
      )
      return localConfigByUnit
    }
  }
  return {}
}
const UnitContext = React.createContext()

function getInitialState() {
  const isCheckinActive =
    JSON.parse(localStorage.getItem(CHECKIN_ACTIVE_KEY)) || false
  const isAutopagoActive =
    JSON.parse(localStorage.getItem(AUTOPAGO_ACTIVE_KEY)) || false
  const isPrescriptionScanActive =
    JSON.parse(localStorage.getItem(PRESCRIPTION_SCAN_ACTIVE_KEY)) || false
  const ignoreSchedule =
    JSON.parse(localStorage.getItem(IGNORE_SCHEDULE_KEY)) || false
  return {
    units: [],
    unitsById: {},
    division: null,
    checkinHost: null,
    isLoading: true,
    logo: LOGO_KEIRON,
    isCheckinActive,
    ignoreSchedule,
    isAutopagoActive,
    isPrescriptionScanActive,
  }
}

function UnitProvider({ children }) {
  const [state, setState] = useStateWithMerge(getInitialState)
  const { setNewThemeColors } = useTheme()
  const {
    units,
    unitsById,
    division,
    checkinHost,
    isLoading,
    logo,
    isCheckinActive,
    ignoreSchedule,
    isAutopagoActive,
    isPrescriptionScanActive,
  } = state
  const { refetch: getCurrentUnits } = useQuery(
    `CurrentUnits`,
    API.getCurrentUnits,
    {
      retry: 1,
      retryDelay: 1500,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess({ units, unitsById, division, checkinHost }) {
        const logo = getLogo(division?.corporation)
        if (division?.corporation?.localThemeColors) {
          setNewThemeColors(division?.corporation?.localThemeColors)
        }
        setState({
          units,
          unitsById,
          division,
          checkinHost,
          logo,
        })
      },
      onSettled() {
        setState({ isLoading: false })
      },
    },
  )
  useIO({
    emitPayload: `unit`,
    subscribeEvent: `unitUpdated`,
    subscribeEventHandler: getCurrentUnits,
    onReconnectHandler: (event) => {
      if (event !== `io client disconnect`) {
        notification.error({
          message: `Se ha perdido la conexión con servidor`,
          duration: 10,
        })
      }
    },
  })

  async function unitLogin(unitId, password) {
    const { unit, division, checkinHost, corporation } = await API.totemLogin({
      unitId,
      password,
    })
    const logo = getLogo(division.corporationId)

    if (corporation?.localThemeColors) {
      setNewThemeColors(corporation.localThemeColors)
    }

    setState((prevState) => {
      if (prevState.division == null) {
        localStorage.removeItem(CONFIG_BY_UNIT_KEY)
        localStorage.removeItem(CHECKIN_ACTIVE_KEY)
        localStorage.removeItem(PRINT_SERVER_HOST_KEY)
      }
      const nextUnits = [...prevState.units, unit.id]
      const nextUnitsById = { ...prevState.unitsById, [unit.id]: unit }
      return {
        units: nextUnits,
        unitsById: nextUnitsById,
        division,
        checkinHost,
        isLoading: false,
        logo,
      }
    })
    return unit
  }

  const localConfigByUnit = useMemo(() => {
    if (units.length === 0) {
      return {}
    } else {
      const localConfigByUnit = JSON.parse(
        localStorage.getItem(CONFIG_BY_UNIT_KEY),
      )
      if (localConfigByUnit == null) {
        return legacyLocalConfigMigration({ units, unitsById })
      } else {
        return localConfigByUnit
      }
    }
  }, [units, unitsById])

  const localActiveUnits = useMemo(() => {
    const activeUnits = units.filter((unitId) =>
      localConfigByUnit[unitId] ? localConfigByUnit[unitId].isActive : false,
    )
    return activeUnits.length === 0 ? units : activeUnits
  }, [units])

  const value = {
    units,
    unitsById,
    localActiveUnits,
    localConfigByUnit,
    division,
    logo,
    checkinHost,
    isCheckinActive: isCheckinActive && checkinHost?.length > 0,
    isAutopagoActive,
    isPrescriptionScanActive,
    ignoreSchedule,
    unitLogin,
  }
  return (
    <UnitContext.Provider value={value}>
      {isLoading ? (
        <Container
          width="100vw"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Icon icon="spinner" spin fontSize="7" />
        </Container>
      ) : (
        children
      )}
    </UnitContext.Provider>
  )
}

function useUnit() {
  const context = useContext(UnitContext)
  if (!context) {
    throw new Error(`useUnit must be used within a UnitProvider`)
  }
  return context
}

export { UnitProvider, useUnit }
