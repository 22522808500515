import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import LineButton from '../LineButton'

function LineItem({
  id,
  name,
  waitingPeople,
  isRutRequired,
  waitTime,
  isDisabled,
  unitId,
  withoutRutValidation,
  ...props
}) {
  const history = useHistory()
  const location = useLocation()

  function handleLineClick() {
    history.push({
      pathname: `${location.pathname}/${id}`,
      state: {
        name: `${name}`,
        waitingPeople: `${waitingPeople}`,
        waitTime,
        isRutRequired,
      },
    })
  }

  async function handleSubmitWithoutRut() {
    history.push({
      pathname: `/totem/${unitId}/print`,
      state: { lineId: id, unitId },
    })
  }

  return (
    <LineButton
      handleLineClick={
        withoutRutValidation
          ? () => handleSubmitWithoutRut()
          : () => handleLineClick()
      }
      name={name}
      isDisabled={isDisabled}
      {...props}
    />
  )
}

export default LineItem
