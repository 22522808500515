import moment from 'moment-timezone'
import Icon from 'components/MaterialIcon'
import API from 'config/api'
import { POS_TYPES } from 'config/constants'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useUnit } from 'context'
import { useAutopago } from 'context/AutopagoContext'
import { LOADER_SPINNER } from '../../assets'
import Feedbacks from './Feedbacks'
import { genericErrorRedirect, redirectWithoutQueuing } from './Redirection'
import { handlePrints } from './DocumentObtainSelection'

const getVoucherInTicketToPOSIntegrated = (result) => {
  const {
    commerceCode,
    terminalId,
    realDate,
    realTime,
    last4Digits,
    cardType,
    amount,
    sharesNumber,
    sharesAmount,
    operationNumber,
    authorizationCode,
    hasNotPaymentAddress,
  } = result
  let voucher = `TRANSBANK
TARJETA DE ${cardType === `DB` ? `DEBITO` : `CREDITO`}`
  voucher = !hasNotPaymentAddress
    ? `${voucher} 
LA CONCEPCION 191, PROVIDENCIA
SANTIAGO`
    : voucher
  voucher = `${voucher}
${commerceCode}-${terminalId}
${moment(`${realDate}${realTime}`, `DDMMYYYYHHmmss`).format(
  `DD/MM/YYYY HH:mm:ss`,
)}
********${last4Digits} B-${cardType}
MONTO VENTA:   $${amount.toLocaleString()}
TOTAL:     $${amount.toLocaleString()}
`
  voucher =
    sharesNumber !== `00` && sharesAmount === `0`
      ? `${voucher}
${sharesNumber} CUOTAS CON INTERES`
      : voucher

  voucher =
    sharesNumber !== `00` && sharesAmount !== `0`
      ? `${voucher}
${sharesNumber} CUOTAS SIN INTERES TASA: 0,00%
MONTO CUOTA:  $${sharesAmount.toLocaleString()}`
      : voucher
  voucher = `${voucher}
MONEDA: PESO
OPERACION: ${operationNumber}
AUTORIZACION: ${authorizationCode}`
  return voucher
}
const handleBonusProcessingInformationSchema = ({
  scheduleData,
  validateAdmissionResponse,
  dataAppointment,
  bonusResponse,
  getBonusResponse,
  paymentResponse,
}) => {
  let dataFormated = {
    ...scheduleData,
    ...dataAppointment.appointment,
    patientRut: dataAppointment.patientRut,
  }
  if (validateAdmissionResponse) {
    dataFormated = {
      ...dataFormated,
      ...validateAdmissionResponse,
      copaymentTotal: validateAdmissionResponse.copaymentTotal,
    }
  }
  if (bonusResponse) {
    dataFormated = {
      ...dataFormated,
      bonuses: [
        {
          id: bonusResponse.parsedResponse.bonusIdText,
          emissionDate: bonusResponse.parsedResponse.date,
          services: dataAppointment.appointment.services,
        },
      ],
    }
  }
  if (getBonusResponse) {
    dataFormated = {
      ...dataFormated,
      extraDiscounts: getBonusResponse.parsedResponse.extraDiscounts,
    }
  }
  if (paymentResponse && paymentResponse.result) {
    dataFormated = {
      ...dataFormated,
      payment: paymentResponse.result.sale ?? paymentResponse.result,
    }
  }
  return dataFormated
}

const Finale = ({ flushInterval }) => {
  const history = useHistory()
  const location = useLocation()
  const {
    division: { corporationId },
  } = useUnit()
  const {
    autopagoRecordId: lastAutopagoRecordId,
    totemIp,
    handleSetAutopagoRecordId,
  } = useAutopago()
  const locationState = location?.state ?? {}
  const {
    scheduleData,
    scheduleData: { unitId },
    notifyResult,
    paymentResponse,
    validateAdmissionResponse,
    data,
    posType,
    appointmentData,
  } = locationState
  useEffect(() => {
    const handleEmitImedBonus = async () => {
      let bonusResponse
      try {
        setTimeout(() => flushInterval(), 500)
        let bonusProcessingInformation, getBonusResponse
        if (notifyResult) {
          bonusResponse = await API.emitImedBonus({
            ...scheduleData,
            ...paymentResponse?.result,
            ...notifyResult,
            autopagoRecordId: lastAutopagoRecordId,
            totemIp,
          })
          const { preBonusId } = validateAdmissionResponse
          getBonusResponse = await API.getImedBonus({
            id: preBonusId,
            corporationId,
          })
          bonusProcessingInformation = handleBonusProcessingInformationSchema({
            scheduleData,
            validateAdmissionResponse,
            dataAppointment: data,
            bonusResponse,
            getBonusResponse,
            paymentResponse,
          })
        } else {
          bonusProcessingInformation = handleBonusProcessingInformationSchema({
            scheduleData,
            dataAppointment: data ?? scheduleData,
            paymentResponse,
          })
        }
        await API.beforeFinaleHook({
          data,
          scheduleData,
          bonusProcessingInformation,
          autopagoRecordId: lastAutopagoRecordId,
          totemIp,
        })
        history.push({
          pathname: `/totem/${unitId}/obtencionDocumentos`,
          state: {
            ...locationState,
            bonusResponse,
            getBonusResponse,
          },
        })
      } catch (e) {
        const { autopagoRecordId: newAutopagoRecordId } = e
        const autopagoRecordId = newAutopagoRecordId || lastAutopagoRecordId
        handleSetAutopagoRecordId?.(autopagoRecordId)
        let integrationMessage
        if (paymentResponse && posType === POS_TYPES.H2H) {
          const appointmentExtraData =
            appointmentData?.appointment?.services[0]?.extra ||
            scheduleData?.services[0]?.extra
          const { internalId, externalId } = appointmentExtraData || {}
          const externalAndInternalServiceCodes = []
          if (internalId) {
            externalAndInternalServiceCodes.push(
              `          ID PRESTACIÓN INT: ${internalId}\r`,
            )
          }
          if (externalId) {
            externalAndInternalServiceCodes.push(
              `           ID PRESTACIÓN EXT: ${externalId}\r`,
            )
          }
          integrationMessage = decodeURIComponent(
            paymentResponse.result.sale.VOUCHER,
          ).match(/.{1,40}/g)
          integrationMessage.splice(7, 0, ...externalAndInternalServiceCodes)
          integrationMessage = integrationMessage
            .map((line) => {
              if (/BOLETA/i.test(line)) {
                line = line.split(/BOLETA/i)[0]
              }
              return line.trim()
            })
            .join(`\n`)
          genericErrorRedirect({
            unitId,
            redirect: `/totem/${unitId}/print`,
            timeout: 4000,
            data: {
              ...scheduleData,
              action: `TO_PAYMENT`,
              integrationMessage,
              isTicketWithVoucher: true,
            },
            hasToExit: false,
            history,
            title: `Lo sentimos`,
            subtitle: `No se puede realizar su solicitud. Retire su ticket y espere su atención en caja.`,
            iconPaddingTop: `3rem`,
            iconSetting: {
              icon: <Icon icon="errorOutline" />,
              iconColor: `primary.4`,
            },
          })
        }
        if (paymentResponse && posType === POS_TYPES.INTEGRATED) {
          const { result } = paymentResponse
          integrationMessage = result
            ? getVoucherInTicketToPOSIntegrated({ ...result, ...scheduleData })
            : integrationMessage

          if (scheduleData.hasNotQueuing) {
            await handlePrints({
              paymentResponse,
              scheduleData,
              posType,
            })
            redirectWithoutQueuing({
              history,
              icon: <Icon icon="errorOutline" />,
              iconColor: `primary.4`,
              title: `Listo!`,
              subtitle: `El pago fue realizado. Solicite validación del personal.`,
            })
          } else {
            genericErrorRedirect({
              unitId,
              redirect: `/totem/${unitId}/print`,
              timeout: 4000,
              data: {
                ...scheduleData,
                action: `TO_PAYMENT`,
                integrationMessage,
                isTicketWithVoucher: true,
              },
              hasToExit: false,
              history,
              title: `Lo sentimos`,
              subtitle: `No se puede realizar su solicitud. Retire su ticket y espere su atención en caja.`,
              iconPaddingTop: `3rem`,
              iconSetting: {
                icon: <Icon icon="errorOutline" />,
                iconColor: `primary.4`,
              },
            })
          }
        }
      }
    }
    handleEmitImedBonus()
  }, [])

  return (
    <Feedbacks
      icon={<img src={LOADER_SPINNER} width="30%" height="30%" />}
      iconColor="primary.4"
      iconPaddingTop="1rem"
      iconPaddingBottom="3.2rem"
      title={
        !paymentResponse
          ? `Estamos generando su bono, espere por favor.`
          : `Estamos procesando el pago, por favor espere.`
      }
    />
  )
}

export default Finale
