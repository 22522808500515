import React, { useState, useEffect, useContext, useRef } from 'react'

const OnlineStatusContext = React.createContext({
  isOnline: navigator.onLine,
  wasOnlineOnMount: navigator.onLine,
})

function OnlineStatusProvider({ children }) {
  const internetOnMountRef = useRef(false)
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine)

  useEffect(() => {
    internetOnMountRef.current = navigator.onLine
    window.addEventListener(`offline`, () => {
      setOnlineStatus(false)
    })
    window.addEventListener(`online`, () => {
      setOnlineStatus(true)
    })

    return () => {
      window.removeEventListener(`offline`, () => {
        setOnlineStatus(false)
      })
      window.removeEventListener(`online`, () => {
        setOnlineStatus(true)
      })
    }
  }, [])

  return (
    <OnlineStatusContext.Provider
      value={{
        isOnline: onlineStatus,
        wasOnlineOnMount: internetOnMountRef.current,
      }}
    >
      {children}
    </OnlineStatusContext.Provider>
  )
}

function useOnlineStatus() {
  const context = useContext(OnlineStatusContext)
  return context
}

export { OnlineStatusProvider, useOnlineStatus }
