import React, { useMemo } from 'react'
import { format as formatRut } from 'rut.js'
import API from 'config/api'
import { useStateWithMerge, useIO } from 'hooks'
import { useQuery, useMutation } from 'react-query'
import { Container, Typography, Input, Table, Button, Icon } from 'components'
import notification from 'antd/es/notification'

const initialState = {
  statusId: 1,
  page: 1,
  pageSize: 10,
}

const getColumns = ({ handleStatusChange, isUpdating }) => [
  {
    title: `Módulo`,
    width: `8%`,
    align: `center`,
    dataIndex: `ticket.module`,
    render(module) {
      if (module == null) {
        return `-`
      }
      return module
    },
  },
  {
    title: `Rut`,
    width: `8%`,
    render({ ticket }) {
      const { patientRut, patientRutDv } = ticket
      return formatRut(`${patientRut}${patientRutDv}`)
    },
  },
  { title: `CUD`, dataIndex: `info.cud` },
  { title: `Producto`, dataIndex: `name` },
  { title: `Un.`, width: `5%`, dataIndex: `amount` },
  { title: `SKU`, dataIndex: `info.sku` },
  {
    title: `Ubicación`,
    dataIndex: `info.skuLocation`,
    render(location) {
      if (location == null) {
        return `-`
      }
      return location
    },
  },
  {
    title: `Acción`,
    width: `8%`,
    render(item) {
      return (
        <ActionItem
          {...item}
          onClick={handleStatusChange}
          isLoading={isUpdating}
        />
      )
    },
  },
]

function Items() {
  const [state, setState] = useStateWithMerge(initialState)
  const { statusId, page, pageSize } = state
  const { data: statuses, isLoading } = useQuery(
    `ItemStatuses`,
    API.getItemStatuses,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
  const {
    data: { items, totalItems },
    refetch: refetchItems,
  } = useQuery(
    [`Items`, statusId, page, pageSize],
    () => API.getItems({ statusId, pagination: { page, pageSize } }),
    {
      initialData: { items: [], totalItems: 0 },
      enabled: !!statusId,
      refetchInterval: 60000,
    },
  )
  const { mutate: onUpdateItemStatus, isLoading: isUpdating } = useMutation(
    (body) => API.updateItemStatus(body),
    {
      onError(e) {
        const error =
          typeof e === `string` ? e : `Error al cargar citas, reintente`
        notification.error({ message: error })
      },
    },
  )
  function handleChange(value, name) {
    setState({ [name]: value })
  }
  function handleStatusChange({ id, statusId }) {
    onUpdateItemStatus({ id, statusId })
    const statusToMove = statusId == 2 ? statusId : 1
    setState({ statusId: statusToMove })
  }
  const columns = useMemo(() => {
    return getColumns({ handleStatusChange, isUpdating })
  }, [isUpdating])

  function onTableChange({ current, pageSize }) {
    setState({ page: current, pageSize })
  }
  useIO({
    emitPayload: `monitor`,
    subscribeEvent: `itemsUpdated`,
    subscribeEventHandler: refetchItems,
  })
  return (
    <Container backgroundColor="gray.0" height="100vh" width="100%" padding="3">
      <Container
        isLoading={isLoading}
        shadow="0px 6px 8px rgba(0,0,0, 0.08), 0px 3px 4px rgba(0,0,0, 0.12);"
        backgroundColor="white"
        padding="3"
        width="100%"
        minHeight="90vh"
        maxHeight="95vh"
        alignContent="start"
      >
        <Typography fontSize="5">Monitor de bodega</Typography>
        <Container width="100%" marginY="4">
          <Input
            type="radioButton"
            value={statusId}
            name="statusId"
            valueKey="id"
            labelKey="name"
            onChange={handleChange}
            options={statuses}
          />
        </Container>
        <Table
          columns={columns}
          dataSource={items}
          headerWeight="bold"
          oddBackgroundColor="white"
          headerBackgroundColor="primary.2"
          headerColor="white"
          onChange={onTableChange}
          pagination={{
            showSizeChanger: true,
            pageSize,
            current: page,
            total: totalItems,
          }}
          scroll={{ y: `60vh` }}
        />
      </Container>
    </Container>
  )
}

function ActionItem({ id, statusId, onClick, isLoading }) {
  function handleClick(statusId) {
    onClick({ id, statusId })
  }
  if (statusId == 2) {
    return (
      <Container width="100%" justifyContent="center">
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => handleClick(3)}
          borderRadius="50%"
          backgroundColor="green.0"
          marginRight="2"
          width="1.8em"
          height="1.8em"
          justifyContent="center"
          alignItems="center"
        >
          <Icon icon="check" />
        </Button>
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => handleClick(4)}
          borderRadius="50%"
          backgroundColor="error.0"
          width="1.8em"
          height="1.8em"
          justifyContent="center"
          alignItems="center"
        >
          <Icon icon="X" />
        </Button>
      </Container>
    )
  }
  if (statusId == 1) {
    return (
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => handleClick(2)}
        name="2"
        backgroundColor="primary.2"
      >
        Recolectar
      </Button>
    )
  }
  return null
}

export default Items
