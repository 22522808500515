import React, { useEffect, useState, useRef } from 'react'
import API from 'config/api'
import { Typography, Button, Progress } from 'components'
import { useAttentions, useAuth } from 'context'
import { useIsMounted, useAsyncRef } from 'hooks'
import { notification } from 'antd'

function CallButton({ canCall, callableAttentions, userId, activeModuleId }) {
  const canCallRef = useAsyncRef(canCall, true)
  const { currentActivityType } = useAuth()
  const { currentLineId, requestInQueue } = useAttentions()
  const isMounted = useIsMounted()
  const timerRef = useRef(null)
  const [progress, setProgress] = useState(0)
  const [retryState, setRetryState] = useState({
    isRetrtying: false,
    retryNumber: 0,
  })
  function autoCall(attentionId, retryNumber) {
    if (progress === 0 && canCallRef.current && attentionId) {
      setProgress(2.5)
      setTimeout(async () => {
        clearInterval(timerRef.current)
        if (isMounted.current) {
          setProgress(0)
          await callAttention(attentionId, retryNumber)
        }
      }, 4000)
      timerRef.current = setInterval(() => {
        setProgress((prev) => prev + 2.5)
      }, 100)
    }
  }
  async function callAttention(attentionId, retry = 3, retryInterval = 3000) {
    if (retry === 0) {
      setRetryState({
        isRetrying: false,
        retryNumber: 0,
      })
      return notification.error({
        message: `Hubo un error al llamar a ese ticket. Por favor, refresque la aplicación e intente de nuevo.`,
      })
    }

    try {
      await API.callAttention({
        userId,
        moduleId: activeModuleId,
        attentionId,
      })
    } catch (e) {
      // ticket ya fue llamado
      if (e.alreadyCalled) {
        // El ticket que se está intentando atentender ya se atendió
        if (canCall && callableAttentions[1]) {
          // Llama al siguiente ticket
          autoCall(callableAttentions[1])
        }
        return notification.error({ message: e.error })
      } else if (retry !== 0) {
        // Se reintenta X cantidad de veces
        setRetryState({
          isRetrying: true,
          retryNumber: retry,
        })

        return setTimeout(() => autoCall(attentionId, retry - 1), retryInterval)
      } else {
        let error = `No se ha podido realizar el llamado, reintente.`
        if (e && e.error && typeof e.error === `string`) {
          error = e.error
        }
        return notification.error({ message: error, duration: 30 })
      }
    }
  }
  useEffect(() => {
    if (!requestInQueue && callableAttentions[0]) {
      autoCall(callableAttentions[0])
    }
  }, [currentActivityType, currentLineId, callableAttentions[0]])
  return (
    <Button
      marginLeft="0.5em"
      flex={3}
      backgroundColor="gray.0"
      padding="0.5em"
      position="relative"
      fontSize="4"
      color="gray.3"
      disabled
    >
      Llamado automático
      <Progress
        strokeColor="primary.2"
        strokeLinecap="square"
        percent={progress}
        showInfo={false}
      />
      {retryState && retryState.isRetrying ? (
        <Typography fontStyle="italic" fontSize="1" color="gray.1">
          Reintentando...
        </Typography>
      ) : null}
    </Button>
  )
}

export default CallButton
