import React, { useEffect } from 'react'
import { Container, Typography } from 'components'
import useDebounce from 'hooks/useDebounce'
import { Player as Lottie } from '@lottiefiles/react-lottie-player'

function QrScan({ handleTicketInput, state }) {
  const { document } = state

  const debouncedCode = useDebounce(document, 1000)

  useEffect(() => {
    if (document.length > 0) {
      handleTicketInput()
    }
  }, [debouncedCode])

  return (
    <Container
      marginTop={{ _: `1vh`, md: 0 }}
      marginLeft={{ _: 0, md: `1vw` }}
      width="100%"
      height="34.5vh"
      alignItems="center"
      flexDirection="column"
      flexWrap="no-wrap"
    >
      <Container marginY="2vh" paddingLeft="15vw">
        <Lottie
          autoplay
          loop
          src="https://lottie.host/3c020a3e-c4d8-45f3-8d59-e708dba1b705/mALwGQZJEu.json"
          style={{ height: `300px`, width: `300px` }}
        />
      </Container>
      <Typography
        fontSize="35px"
        fontWeight="500"
        color="black@0.8"
        textAlign="center"
        width="100%"
      >
        Escanee el código de barras con el lector lateral derecho
      </Typography>
    </Container>
  )
}

export default QrScan
