import React from 'react'
import { Container, Typography, Img, Icon } from 'components'
import { useTime } from 'context'
import { capitalizeFirstLetter } from 'utils'

import VideoWrapper from './VideoWrapper'

function SocialNetwork({ icon, socialNetwork }) {
  return socialNetwork?.length > 0 ? (
    <Container alignItems="center">
      <Container
        borderRadius="50%"
        backgroundColor="white"
        alignItems="center"
        justifyContent="center"
        width="6vh"
        height="6vh"
        marginRight="1"
      >
        <Icon icon={icon} color="primary.1" fontSize={{ _: 5, lg: 6 }} />
      </Container>
      <Typography fontSize={{ _: 2, lg: 3 }} color="white">
        {socialNetwork}
      </Typography>
    </Container>
  ) : null
}

function SocialNetworks({ config }) {
  const { instagramUrl, facebookUrl, twitterUrl } = config

  return (
    <Container
      alignItems="center"
      flexDirection=""
      justifyContent="space-evenly"
      width="50%"
      marginLeft="4"
    >
      <Typography fontSize={{ _: 2, lg: 3 }} color="white">
        Síguenos en:{` `}
      </Typography>
      <SocialNetwork socialNetwork={twitterUrl} icon="twitter" />
      <SocialNetwork socialNetwork={facebookUrl} icon="facebook" />
      <SocialNetwork socialNetwork={instagramUrl} icon="instagram" />
    </Container>
  )
}

function FooterTV({ config }) {
  const currentTime = useTime()
  return (
    <Container
      backgroundColor="primary.1"
      zIndex="1"
      position="absolute"
      bottom="0"
      right="0"
      width="85%"
      borderRadius="57px 0px 0px 0px"
      alignItems="center"
      justifyContent="space-between"
    >
      <SocialNetworks config={config} />
      <Container
        width="40%"
        justifyContent="flex-end"
        alignItems="center"
        marginRight="4"
      >
        <Container flexDirection="column" marginRight="3" alignItems="center">
          <Typography color="white" fontSize={{ _: 2, lg: 3 }}>
            {currentTime.format(`dddd`).toUpperCase()}
          </Typography>
          <Typography color="white" fontSize={{ _: 2, lg: 3 }}>
            {capitalizeFirstLetter(currentTime.format(`DD/MM/YY`))}
          </Typography>
        </Container>
        <Typography color="white" fontSize={{ _: 7, lg: 8 }} fontWeight="700">
          {capitalizeFirstLetter(currentTime.format(`HH:mm:ss  [hrs.]`))}
        </Typography>
      </Container>
    </Container>
  )
}

function TV({ logo, config, unit }) {
  return (
    <Container
      height="100vh"
      width="100vw"
      overflow="hidden"
      position="relative"
    >
      <Container
        backgroundColor="primary.1"
        zIndex="1"
        position="absolute"
        left="0"
        top="0"
        borderRadius="0px 0px 57px 0px"
        withShadow
      >
        <Img src={logo} height="100px" width="auto" paddingX="5" paddingY="3" />
      </Container>
      <VideoWrapper unit={unit} videoUrl={config?.videoUrl} fullScreen />
      <FooterTV config={config} />
    </Container>
  )
}

export default TV
