import React, { useState } from 'react'
import { Container, Typography, Button, Input, Icon } from 'components'
import moment from 'moment-timezone'
import { removeIndexFromArray } from 'utils'
const getDefualtAttention = (currentLine) => ({
  lineId: currentLine,
  getDateTime: null,
})
function ScheduleAttention({ onClose, onConfirm, lines, currentLine }) {
  const [attentions, setAttentions] = useState([
    getDefualtAttention(currentLine),
  ])

  function setField(value, field, index) {
    return setAttentions((prevAttentions) => {
      const nextAttentions = [...prevAttentions]
      nextAttentions[index][field] = value
      return nextAttentions
    })
  }
  function addAttention() {
    return setAttentions((prevAttentions) => {
      return [...prevAttentions, getDefualtAttention(currentLine)]
    })
  }
  function removeAttention(index) {
    return setAttentions((prevAttentions) => {
      const nextAttentions = removeIndexFromArray(prevAttentions, index)
      return nextAttentions
    })
  }
  const canSubmit = attentions.every(
    (attention) => attention.getDateTime != null,
  )
  function confirm() {
    if (canSubmit) {
      onConfirm(attentions)
    }
  }
  return (
    <>
      <Typography fontSize="4" color="primary.2">
        Programaciones
      </Typography>

      <Container
        width="100%"
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(20%, 30%))"
        gridGap={4}
        marginY="3"
      >
        {attentions.map((attention, index) => {
          return (
            <AttentionCard
              key={index}
              index={index}
              lines={lines}
              setField={setField}
              removeAttention={removeAttention}
              {...attention}
            />
          )
        })}
        <Button
          onClick={addAttention}
          backgroundColor="transparent"
          color="primary.2"
          border="1px solid"
          borderColor="primary.2@0.1"
          hoverProps={{
            backgroundColor: `primary.2`,
            color: `white`,
          }}
        >
          Nueva
        </Button>
      </Container>
      <Container border="0.5px solid" borderColor="gray.0" />
      <Container marginTop="2" alignItems="center" justifyContent="flex-end">
        <Button onClick={onClose}>
          <Typography textDecoration="underline">Cancelar</Typography>
        </Button>
        <Button
          onClick={confirm}
          marginLeft="2"
          borderRadius="3px"
          paddingY="1"
          paddingX="2"
          backgroundColor={canSubmit ? `primary.2` : `gray.2`}
        >
          Guardar
        </Button>
      </Container>
    </>
  )
}

function AttentionCard({
  lineId,
  lines,
  index,
  setField,
  removeAttention,
  getDateTime,
  minutes,
}) {
  function handleTime(value, name) {
    if (name === `getDateTime`) {
      const minutes = moment(value, `HH:mm`).diff(moment(), `minutes`)
      setField(minutes, `minutes`, index)
    }
    if (name === `minutes`) {
      // Se evita programar hacia el pasado
      if (parseInt(value, 10) < 0) {
        return
      }
      const hour = moment().add(value, `minutes`).format(`HH:mm`)
      setField(hour, `getDateTime`, index)
    }
    setField(value, name, index)
  }
  return (
    <Container
      position="relative"
      padding="2"
      border="1px solid"
      borderColor="gray.0@0.5"
      withShadow
    >
      <Container
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize="2" color="primary.2">
          <Icon icon="clock" marginRight="1" />
          Atención {index + 1}
        </Typography>
        <Container
          onClick={() => removeAttention(index)}
          backgroundColor="error.0@0.2"
          padding="2"
          borderRadius="50%"
        >
          <Icon color="error" icon="trash" />
        </Container>
      </Container>
      <Input
        label="Fila"
        width="100%"
        fontSize="2"
        margin="0.5em 0"
        value={lineId}
        type="select"
        options={lines}
        name="lineId"
        onChange={(lineId) => setField(lineId, `lineId`, index)}
      />
      <Container width="100%" justifyContent="space-between">
        <Input
          name="minutes"
          value={minutes}
          label="En (minutos)"
          width="40%"
          onChange={handleTime}
        />
        <Input
          label="Hora"
          type="time"
          value={getDateTime}
          name="getDateTime"
          onChange={handleTime}
          width="40%"
          disabledHours={() => {
            // Se habilitan solo las horas posteriores a la actual
            // Se evita programar hacia el pasado
            const hour = moment().hour()
            return [...Array(hour).keys()]
          }}
          disabledMinutes={(hourSelected) => {
            // Si la hora seleccionada es la actual
            // Se restringen los minutos pasados
            // Para evitar programar en el pasado
            if (moment().hour() === hourSelected) {
              const minute = moment().minute()
              return [...Array(minute).keys()]
            }
            return false
          }}
        />
      </Container>
    </Container>
  )
}

export default ScheduleAttention
