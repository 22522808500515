import React from 'react'
import moment from 'moment-timezone'
import { Container, Table, Typography, Input, Icon } from 'components'
import API from 'config/api'
import { format } from 'rut.js'
import { useTableManager, useStateWithMerge, useStatsPing } from 'hooks'
import { useAuth } from 'context'
import { notification } from 'antd'
import ModalWrapper, { modalTypes } from './panel/CallPanel/ModalWrapper'

const getColumns = (setState) => [
  { title: `ID`, dataIndex: `id`, key: `id`, width: 50 },
  {
    title: `RUT/PASAPORTE PACIENTE`,
    key: `patientRut`,
    render: ({ patientRut: rut }) => format(rut),
  },
  { title: `PACIENTE`, dataIndex: `patientName`, key: `patientName` },
  {
    title: `STOCK DISPONIBLE`,
    dataIndex: `availableStock`,
    key: `availableStock`,
  },
  {
    title: `TIPO DE STOCK`,
    dataIndex: `stockType`,
    key: `stockType`,
  },
  {
    title: `DISPONIBLE`,
    dataIndex: `isAvailable`,
    key: `isAvailable`,
    render: (isAvailable) => (isAvailable ? `Si` : `No`),
  },
  {
    title: `CREADO POR`,
    dataIndex: `createdBy`,
    key: `createdBy`,
    render: (createdBy) => {
      if (!createdBy) {
        return `Admin`
      }
      const { firstName, lastName } = createdBy
      return `${firstName} ${lastName}`
    },
  },
  {
    title: `FECHA CREACIÓN`,
    dataIndex: `createdAt`,
    key: `createdAt`,
    render: (createdAt) => (createdAt ? moment(createdAt).format(`LLL`) : `-`),
  },
  {
    title: `EDITADO POR`,
    dataIndex: `updatedBy`,
    key: `updatedBy`,
    render: (updatedBy) => {
      if (!updatedBy) {
        return `Admin`
      }
      const { firstName, lastName } = updatedBy
      return `${firstName} ${lastName}`
    },
  },
  {
    title: `FECHA EDICIÓN`,
    dataIndex: `updatedAt`,
    key: `updatedAt`,
    render: (updatedAt) => (updatedAt ? moment(updatedAt).format(`LLL`) : `-`),
  },
  {
    title: `ACCIONES`,
    render(stock) {
      return (
        <Container justifyContent="space-around">
          <Icon
            onClick={() =>
              openStockTableModal({
                patientRut: stock.patientRut,
                patientName: stock.patientName,
                stockId: stock.id,
                patientDocument: stock.patientDocument,
                setState,
              })
            }
            icon="edit"
          />
        </Container>
      )
    },
  },
]

function openStockTableModal({
  patientRut,
  patientName,
  stockId,
  patientDocument,
  setState,
}) {
  setState({
    modalConfig: {
      openModal: modalTypes.stockTableModal,
      patientName,
      stockId,
      patientRut,
      document: patientDocument,
    },
  })
}

const getInitialState = {
  stocks: [],
  modalConfig: null,
  totalStocks: 0,
  filterText: ``,
  error: null,
  searchAvailableStock: true,
}

function Stock() {
  useStatsPing({ category: 1 })
  const [state, setState] = useStateWithMerge(getInitialState)
  const { currentUnit } = useAuth()

  const { stocks, filterText, modalConfig, totalStocks, searchAvailableStock } =
    state

  const initialColumns = getColumns(setState)
  const { columns, pagination, onTableChange } = useTableManager({
    filterText,
    onSearch: getStocks,
    initialColumns,
    searchOnChange: [searchAvailableStock, modalConfig],
  })

  async function getStocks({ pagination: { page, pageSize } }) {
    try {
      const { stocks, totalStocks } = await API.getStocks({
        unitId: currentUnit.id,
        pagination: { page, pageSize },
        filter: filterText,
        isAvailable: searchAvailableStock,
      })

      setState({ stocks, totalStocks })
    } catch (e) {
      const error = typeof e === `string` ? e : `Error al cargar stocks`
      notification.error({ message: error })
    }
  }
  return (
    <Container width="100%" flexDirection="column" padding={1}>
      <Container
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
      >
        <Container width="30%">
          <Typography
            fontSize="5"
            color="primary.2"
            fontWeight="600"
            marginBottom={3}
          >
            STOCKS
          </Typography>
          <Icon
            color="primary.2"
            icon="plusCircle"
            fontSize={3}
            onClick={() =>
              setState({
                modalConfig: {
                  openModal: modalTypes.stockTableModal,
                },
              })
            }
          />
        </Container>
        <Input
          width="30%"
          type="select"
          fontSize="2"
          value={searchAvailableStock}
          onChange={() => {
            setState({ searchAvailableStock: !searchAvailableStock })
          }}
          placeholder="Especificar motivo de atención"
          options={[
            {
              value: true,
              label: `Disponibles`,
            },
            {
              value: false,
              label: `No disponibles`,
            },
          ]}
        />
        <Container alignItems="center">
          <Typography
            fontWeight="2"
            color="primary.2"
            fontSize={1}
            marginRight="0.5em"
          >
            Filtrar:
          </Typography>
          <Input
            borderRadius="4px"
            fontStyle="italic"
            fontWeight="0"
            value={filterText}
            onChange={(filterText) => setState({ filterText })}
          />
        </Container>
      </Container>
      <Table
        bordered
        columns={columns}
        dataSource={stocks}
        onChange={onTableChange}
        pagination={{
          showSizeChanger: true,
          pageSize: pagination.pageSize,
          current: pagination.page,
          total: totalStocks,
        }}
        scroll={{ x: 500 }}
        fontSize="13px"
        headerFontSize="13px"
        headerWeight="bold"
        size="small"
        oddBackgroundColor="white"
      />
      {modalConfig != null && (
        <ModalWrapper
          {...modalConfig}
          onClose={() => setState({ modalConfig: null })}
        />
      )}
    </Container>
  )
}

export default Stock
