import React, { useState, useContext } from 'react'

const DisabledContext = React.createContext()

function DisabledProvider({ children }) {
  const [isDisabled, setIsDisabled] = useState(false)

  const toggleIsDisabled = () => {
    setIsDisabled((prevIsDisabled) => !prevIsDisabled)
  }

  return (
    <DisabledContext.Provider value={{ isDisabled, toggleIsDisabled }}>
      {children}
    </DisabledContext.Provider>
  )
}

function useDisabled() {
  const context = useContext(DisabledContext)
  if (!context) {
    throw new Error(`useDisabled must be used within a DisabledProvider`)
  }
  return context
}

export { DisabledProvider, useDisabled }
