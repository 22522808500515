/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef } from 'react'
import Keyboard from 'screens/totem/Identification/Keyboard'
import createKeyboardConfig, {
  keyboardModes,
  callModesById,
} from 'config/keyboard'
import { useStateWithMerge } from 'hooks'
import {
  Container,
  Button,
  Input,
  MaterialIcon,
  Img,
  Typography,
} from 'components'
import { useUnit } from 'context'
import API from 'config/api'
import { useAutopago } from 'context/AutopagoContext'
import { genericErrorRedirect } from './Redirection'

function AccompanistRut({ location, history }) {
  const [componentState, setComponentState] = useStateWithMerge({
    isDocumentValid: false,
    document: ``,
    verifiedAccompanistList: [],
  })
  const { isDocumentValid, document, verifiedAccompanistList } = componentState
  const {
    autopagoRecordId: lastAutopagoRecordId,
    totemIp,
    handleSetAutopagoRecordId,
  } = useAutopago()
  const currentMode = callModesById.byRut
  const disabledRef = useRef(false)
  const { validation, format, extraValidation } = currentMode
  const { logo } = useUnit()
  const { unitId, state } = location
  const {
    scheduleDataWithValorization,
    scheduleData,
    posType,
    data: { patientRut, financerCode },
    accompanistResponse,
  } = state
  const {
    AcompananteUniversal: universalAccompanist,
    ListAcompanantes: accompanistList,
  } = accompanistResponse.data

  useEffect(() => {
    const verifiedAccompanistRuts = []
    accompanistList.forEach((accompanist) => {
      const rawRut = accompanist.Rut.replaceAll(`"`, ``)
      let rut
      if (rawRut.startsWith(`00`)) {
        rut = rawRut.slice(2, rawRut.length)
      } else {
        rut = rawRut
      }
      const formattedRut = format(rut)
      verifiedAccompanistRuts.push(formattedRut)
    })
    setComponentState({ verifiedAccompanistList: verifiedAccompanistRuts })
  }, [])

  function handleButtonClick(e) {
    const key = e.target.name
    if (key === `ABC`) {
      setComponentState({ keyboardType: keyboardModes.ABC_KEYBOARD })
    } else if (key === `123`) {
      setComponentState({ keyboardType: keyboardModes.NUMERIC_KEYBOARD })
    } else {
      const doc = format(document + key)
      if (extraValidation(document, doc)) {
        setComponentState({
          document: doc,
          isDocumentValid: validation(doc),
        })
      }
    }
    e.target.blur()
  }

  function handleDeleteInput(e) {
    disabledRef.current = false
    if (document && document !== `-`) {
      setComponentState({
        document: format(document.slice(0, -1)),
        isDocumentValid: validation(format(document.slice(0, -1))),
      })
    } else {
      setComponentState({ document: ``, isDocumentValid: false })
    }
    if (e) {
      e.target.blur()
    }
  }

  const { layoutConfig } = createKeyboardConfig(
    callModesById.byRut[keyboardModes.NUMERIC_KEYBOARD],
    {
      handleButtonClick,
      handleDeleteInput,
    },
  )

  async function createAppointmentAndFingerprintRedirect() {
    try {
      const response = await API.createImedAppointment({
        ...scheduleDataWithValorization,
        patientRut,
        financerCode,
        paymentType: 1, //1: Pendiente de Pago
        autopagoRecordId: lastAutopagoRecordId,
        totemIp,
      })
      const autopagoRecordId = response?.autopagoRecordId
      handleSetAutopagoRecordId?.(autopagoRecordId)
      history.push({
        pathname: `/totem/${unitId}/huella`,
        state: {
          posType,
          scheduleData,
          data: {
            financerCode,
            patientRut,
            appointment: response.parsedResponse,
            accompanistRut: document,
          },
        },
      })
    } catch (e) {
      const { autopagoRecordId: newAutopagoRecordId } = e
      const autopagoRecordId = newAutopagoRecordId || lastAutopagoRecordId
      handleSetAutopagoRecordId?.(autopagoRecordId)
      genericErrorRedirect({
        unitId,
        scheduleData,
        history,
        title: `Lo sentimos`,
        subtitle: `No se puede realizar su solicitud. Retire su ticket y espere su atención en caja.`,
        redirect: `/totem/${unitId}/print`,
        timeout: 4000,
        data: { ...scheduleData, action: `TO_PAYMENT` },
        hasToExit: false,
        iconSetting: {
          icon: <MaterialIcon icon="errorOutline" />,
          iconColor: `primary.4`,
        },
      })
    }
  }

  async function onSubmit() {
    if (
      universalAccompanist ||
      (!universalAccompanist && verifiedAccompanistList.includes(document))
    ) {
      await createAppointmentAndFingerprintRedirect()
    } else {
      genericErrorRedirect({
        useOnlyFirstLevel: true,
        unitId,
        scheduleData: state.scheduleData,
        history,
        data: state,
        pathToRedirect: `/totem/${unitId}/rutAcompanante`,
        title: `El RUT de acompañante no esta registrado.`,
        titleFontSize: `3vh`,
        iconSetting: {
          icon: <MaterialIcon icon="errorOutline" />,
          iconColor: `error.3`,
        },
        textToExit: `Pagar en caja`,
        textToRetry: `Ingresar otro rut`,
      })
    }
  }

  return (
    <Container width="100%" justifyContent="center" marginTop="1vh">
      <Container
        width={{ md: `90%`, _: `70%` }}
        flexDirection={{ md: `row`, _: `column` }}
        justifyContent="center"
        alignItems="flex-end"
        flexWrap="no-wrap"
        paddingY="4vh"
      >
        <Container
          height="auto"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="no-wrap"
        >
          <Container
            width="100%"
            boxSizing="border-box"
            justifyContent="center"
            backgroundColor="transparent"
          >
            <Img src={logo} maxWidth="80%" marginBottom="16px" />
          </Container>
          <Container
            marginBottom="4vh"
            alignItems="center"
            flexDirection="column"
          >
            <Typography fontSize={6} fontWeight={2}>
              Paciente requiere acompañante
            </Typography>
            <Typography fontSize={5}>Ingrese el RUT del acompañante</Typography>
          </Container>
          <Container
            width="100%"
            marginY="1vh"
            flexDirection="row"
            flexWrap="no-wrap"
            backgroundColor="white"
            justifyContent="center"
            alignItems="center"
            borderRadius="5px"
            padding="0.5em"
            border="solid"
            borderWidth="1px"
            height={{ _: `5vh`, md: `10vh` }}
            borderColor={
              !isDocumentValid && document.length > 0 ? `error.3` : `gray.1`
            }
          >
            <Input
              placeholder=" "
              name="document"
              value={document}
              onChange={(rut) => setComponentState({ document: rut })}
              width="100%"
              fontSize="6"
              textAlign="center"
              boxSizing="border-box"
              border="0px"
              padding="0"
            />
            <Container
              alignSelf="center"
              width="2.5rem"
              justifyContent="flex-end"
            >
              {isDocumentValid ? (
                <MaterialIcon
                  icon="checkCircleOutline"
                  position="relative"
                  top="-0.2em"
                  fontSize="2.5rem"
                  height="fit-content"
                  color="green.0"
                  maxHeight="1em"
                />
              ) : (
                document.length > 0 && (
                  <MaterialIcon
                    icon="errorOutline"
                    position="relative"
                    top="-0.2em"
                    color="error.3"
                    fontSize="2.5rem"
                    maxHeight="1em"
                  />
                )
              )}
            </Container>
          </Container>
          <Keyboard
            isQwerty={false}
            config={layoutConfig}
            columns={3}
            marginTop={{ _: `1vh`, md: 0 }}
            marginLeft={{ _: 0, md: `1vw` }}
          />
          <Button
            backgroundColor={isDocumentValid ? `primary.7` : `gray.0`}
            color={isDocumentValid ? `white` : `gray.3`}
            width="100%"
            borderRadius="5px"
            fontSize="5"
            fontWeight="bold"
            marginTop="2vh"
            withShadow={isDocumentValid}
            onClick={onSubmit}
            height={{ _: `5vh`, md: `10vh` }}
            disabled={!isDocumentValid}
            data-testid="continueAccompanistRut"
          >
            Continuar
          </Button>
        </Container>
      </Container>
    </Container>
  )
}

export default AccompanistRut
