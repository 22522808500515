/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import {
  Container,
  Icon,
  Typography,
  Button,
  MaterialIcon,
  Input,
} from 'components'
import { useStateWithMerge } from 'hooks'
import { Popconfirm, notification } from 'antd'
import API from 'config/api'

function MultiTransferModal({ patientRut, ticketId, wrapAction }) {
  const [state, setState] = useStateWithMerge({
    hover: null,
    isAdding: false,
    lineToAdd: null,
    ticketSuggestedLinesByPatient: [],
    ticketSuggestedLinesDifference: [],
    modalBateryError: null,
  })
  const {
    hover,
    isAdding,
    lineToAdd,
    ticketSuggestedLinesByPatient,
    ticketSuggestedLinesDifference,
    modalBatteryError,
  } = state

  const { canEdit, patientName } = ticketSuggestedLinesByPatient

  useEffect(() => {
    getTicketSuggestedLines()
  }, [])

  async function getTicketSuggestedLines() {
    try {
      const {
        dataTicketSuggestedLine,
        dataLineGroupDifference,
        integrationResponse,
      } = await API.getTicketSuggestedLines({
        ticketId,
      })
      if (integrationResponse?.message) {
        if (integrationResponse?.success) {
          notification.warning({ message: integrationResponse.message })
        } else {
          setState({ modalBatteryError: integrationResponse.message })
        }
      }
      setState({
        ticketSuggestedLinesByPatient: dataTicketSuggestedLine,
        ticketSuggestedLinesDifference: dataLineGroupDifference?.lineGroupLines,
      })
    } catch (error) {
      notification.error({ message: `Ha ocurrido un error, intente de nuevo` })
    }
  }

  async function deleteTicketSuggestedLine(row) {
    const { lineId, ticketSuggestedLineId } = row
    const payload = {
      lineId,
      ticketSuggestedLineId,
      ticketId,
    }
    try {
      await API.deleteLineFromPatientJourney(payload)
      notification.success({ message: `Fila eliminada correctamente` })
      getTicketSuggestedLines()
    } catch (error) {
      notification.error({ message: `Ha ocurrido un error, intente de nuevo` })
    }
  }

  async function addTicketSuggestedLine() {
    const { lineGroupId, reservationId, type } = ticketSuggestedLinesByPatient
    const payload = {
      lineId: lineToAdd,
      ticketId,
      lineGroupId,
      reservationId,
      type,
    }
    try {
      await API.addLineToPatientJourney(payload)
      notification.success({ message: `Fila agregada correctamente` })
      getTicketSuggestedLines()
    } catch (error) {
      notification.error({ message: `Ha ocurrido un error, intente de nuevo` })
    }
    setState({ isAdding: false, lineToAdd: null })
  }

  const headerTypoProps = {
    color: `primary.4`,
    fontWeight: `1`,
  }
  const registerContainerProps = {
    marginTop: `3`,
    width: `100%`,
    padding: `3`,
    borderRadius: `2px`,
    alignItems: `center`,
  }

  return (
    <Container display="flex" flexDirection="column" width="100%" padding="3">
      <Container display="flex" flexDirection="column" marginBottom="3">
        <Typography fontSize="4" fontWeight="1">
          Derivar atención
        </Typography>
        {modalBatteryError && (
          <Container
            backgroundColor="#FEF0E4"
            padding="0.7rem"
            marginY="0.5rem"
          >
            <MaterialIcon
              fontSize="3"
              icon="warningAmber"
              color="#8D5933"
              marginRight="0.5rem"
            />
            <Typography color="#8D5933" fontWeight="1">
              {modalBatteryError}
            </Typography>
          </Container>
        )}
        <Typography fontSize="3">{`${patientName ?? ``} ${
          patientRut ? `(${patientRut})` : ``
        }`}</Typography>
      </Container>
      <Typography fontWeight="2">
        {`${ticketSuggestedLinesByPatient?.name ?? ``} ${
          ticketSuggestedLinesByPatient?.lineGroupWasModified
            ? `(modificado)`
            : ``
        }`}
      </Typography>
      <Container paddingX="2" width="100%">
        <Container width="100%" height="5%" paddingX="3" marginTop="3">
          <Container width="38%">
            <Typography {...headerTypoProps}>FILA</Typography>
          </Container>
          <Container width="28%">
            <Typography {...headerTypoProps}>PREREQUISITOS</Typography>
          </Container>
        </Container>
        <Container width="100%" height="5%" flexDirection="column">
          {ticketSuggestedLinesByPatient?.lineGroupLines?.map((row, index) => {
            if (!row.lineName) {
              return null
            }
            return (
              <Container
                {...registerContainerProps}
                key={index}
                onMouseEnter={() => setState({ hover: index })}
                onMouseLeave={() => setState({ hover: null })}
                shadow={
                  hover == index ? `3px 2px 8px 2px rgba(0,0,0,0.1)` : null
                }
                backgroundColor={
                  row?.endedAttention
                    ? `green.0@0.15`
                    : hover == index
                    ? `white`
                    : `gray.5`
                }
              >
                <Typography width="38%">{row?.lineName || ``}</Typography>
                <Container width="56%">
                  {row.prerequisites.map((prereq, index) => (
                    <Typography
                      key={index}
                    >{` ${prereq.lineName} |`}</Typography>
                  ))}
                </Container>
                <Container width="5%" justifyContent="flex-end">
                  {row?.endedAttention ? (
                    <MaterialIcon fontSize="3" icon="doneAll" color="green" />
                  ) : (
                    canEdit && (
                      <Popconfirm
                        title="¿Confirma que desea eliminar la atención?"
                        placement="left"
                        onConfirm={() => deleteTicketSuggestedLine(row)}
                        okText="Eliminar"
                        cancelText="Cancelar"
                      >
                        <Icon
                          icon="trash"
                          color={hover == index ? `error.3` : `gray.2`}
                        />
                      </Popconfirm>
                    )
                  )}
                </Container>
              </Container>
            )
          })}
        </Container>
      </Container>
      {isAdding ? (
        <Container width="100%" paddingX="2">
          <Container
            shadow="3px 2px 8px 2px rgba(0,0,0,0.1)"
            backgroundColor="white"
            alignItems="center"
            {...registerContainerProps}
          >
            <Container width="66%">
              <Input
                type="select"
                value={lineToAdd}
                width="80%"
                options={ticketSuggestedLinesDifference.map((line) => ({
                  value: line?.lineId,
                  label: line?.lineName,
                }))}
                onChange={(value) => {
                  setState({ lineToAdd: value })
                }}
              />
            </Container>
            {canEdit && (
              <>
                <Container
                  width="28%"
                  justifyContent="flex-end"
                  alignItems="center"
                  onClick={() => addTicketSuggestedLine()}
                >
                  <Icon icon="check" color="primary.4" marginRight="2" />
                  <Typography color="primary.4" backgroundColor="transparent">
                    AGREGAR
                  </Typography>
                </Container>
                <Container width="5%" justifyContent="flex-end">
                  <Icon
                    icon="trash"
                    color="error.3"
                    onClick={() =>
                      setState({ isAdding: false, lineToAdd: null })
                    }
                  />
                </Container>
              </>
            )}
          </Container>
        </Container>
      ) : (
        canEdit && (
          <Button
            backgroundColor="transparent"
            color="primary.4"
            marginTop="4"
            marginLeft="4"
            fontWeight="2"
            onClick={() => setState({ isAdding: true })}
          >
            <Icon icon="plus" marginRight="3" />
            AGREGAR FILA
          </Button>
        )
      )}

      <Container
        display="flex"
        justifyContent="flex-end"
        width="100%"
        marginTop="4"
      >
        <Button
          color="white"
          backgroundColor="primary.4"
          paddingY="2"
          paddingX="3"
          marginRight="3"
          borderRadius="5px"
          onClick={wrapAction}
        >
          <Icon icon="check" marginRight="2" />
          DERIVAR
        </Button>
      </Container>
    </Container>
  )
}

export default MultiTransferModal
