export function appointmentMessage({ isTooEarly, isTooLate, isBlocked }) {
  if (isBlocked) {
    return ` No disponible`
  }
  if (isTooLate) {
    return ` Atrasado`
  }
  if (isTooEarly) {
    return ` Adelantado`
  }
}
