/* eslint-disable react/jsx-pascal-case */
import React, { useMemo } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { AutopagoProvider } from 'context/AutopagoContext'
import {
  Admissionist,
  Totem,
  Login,
  TV,
  Config,
  ConfigTV,
  TotemCheckin,
  Items,
} from './screens'
import {
  useUnit,
  AuthProvider,
  SoundProvider,
  DisabledProvider,
} from './context'
import GlobalStyle from './GlobalStyle'

const getRoutes = (isAutopago) =>
  [
    {
      path: `/admisionista`,
      title: `App Admisionista`,
      component(props) {
        return (
          <AuthProvider>
            <Admissionist {...props} />
          </AuthProvider>
        )
      },
    },
    {
      path: `/tv/:tvTemplateId?`,
      title: `App TV`,
      component(props) {
        return (
          <SoundProvider>
            <TV {...props} />
          </SoundProvider>
        )
      },
    },
    {
      path: `/config`,
      title: `Configuracion de filas`,
      component: Config,
    },
    {
      path: `/config-tv`,
      title: `Configuracion de TV`,
      component: ConfigTV,
    },
    {
      path: `/totem/:unitId`,
      title: `App Totem`,
      component(props) {
        return (
          <DisabledProvider>
            <AutopagoProvider isAutopago={isAutopago}>
              <Totem {...props} />
            </AutopagoProvider>
          </DisabledProvider>
        )
      },
    },
    {
      path: `/bodega`,
      title: `Bodega`,
      component(props) {
        return <Items {...props} />
      },
    },
    //Para retrocompatibilidad de las antiguas rutas
    {
      path: `/totem`,
      title: `App Totem`,
      component() {
        return <Redirect to="/totem/units" />
      },
    },
    {
      path: `/checkin`,
      title: `Totem Checkin`,
      component(props) {
        return (
          <DisabledProvider>
            <TotemCheckin {...props} />
          </DisabledProvider>
        )
      },
    },
  ].map((route, index) => ({ ...route, key: index }))

function App() {
  const {
    division,
    units,
    unitsById,
    isAutopagoActive: isAutopagoFront,
  } = useUnit()
  const isAutopagoBack = unitsById[units[0]]?.isAutopago
  const isAutopago = isAutopagoFront && isAutopagoBack

  const routes = useMemo(() => getRoutes(isAutopago), [isAutopago])

  return (
    <>
      <GlobalStyle />
      {division == null ? (
        <Login />
      ) : (
        <HashRouter>
          <Switch>
            {routes.map(({ path, component }) => (
              <Route key={path} path={path} component={component} />
            ))}
            <Redirect to={routes[0].path} />
          </Switch>
        </HashRouter>
      )}
    </>
  )
}
export default App
