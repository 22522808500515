import React from 'react'
import API from 'config/api'
import { Button } from 'components'
import { notification } from 'antd'

function RecallButton({ attentionId, isRecalling, setIsRecalling, enabled }) {
  async function onClick() {
    try {
      await API.recallAttention({ attentionId })
      setIsRecalling(true)
      setTimeout(() => setIsRecalling(false), 5000)
    } catch (e) {
      let error = `No se ha podido realizar el re-llamado, reintente.`
      if (e && e.error && typeof e.error === `string`) {
        error = e.error
      }
      return notification.error({ message: error, duration: 30 })
    }
  }
  return (
    <Button
      flex={2}
      backgroundColor={enabled ? `primary.8` : `gray.0`}
      padding="0.5em"
      fontSize="4"
      color={enabled ? `white` : `gray.3`}
      marginRight="0.5em"
      disabled={!enabled}
      onClick={onClick}
    >
      {isRecalling ? `Re-llamando...` : `Re-llamar`}
    </Button>
  )
}

export default RecallButton
