const colors = {
  primary: [
    `#C4F1FE`, //0
    `#56BAD8`, //1
    `#129DC8`, //2
    `#0B789A`, //3
    `#1890FF`, //4
    `#1E3264`, //5
    `#080808`, //6
    `#1890FF`, //7
    `#267EE2`, //8
    `#1D69BF`, //9
    `#C3C4C3`, //10
    `#9BC3F6`, //11
    `#0288D1`, //12
  ],
  secundary: [`#00C1D0`, `#EDF9FC`, `#012B60`],
  tertiary: [
    `#3296FF`,
    `#6C1276`,
    `#334fff`,
    `#506bfe`,
    `#2A7DE1`,
    `#E6E6E6`,
    `#C1D5FD`,
    `#004266`,
  ],
  gray: [
    `#EEEDEC`,
    `#C0BFC0`,
    `#9EA2A8`,
    `#9B9B9B`,
    `#080808`,
    `#F5F5F5`,
    `#E9E9E8`,
  ],
  error: [`#D0021B`, `#F5C9C2`, `#8A1C0A`, `#FF4D4F`, `#FA8C16`],
  green: [`#52c41a`, `#C3F1DC`, `#0B5030`],
  backgroundApp: [`#F0F0F0`],
  warning: `#FA8C16`,
  black: `#000000`,
}
//                 0,  1,  2,  3,  4,  5,  6,  7,  8,  9,  10,  11,  12,  13  14
const fontSizes = [
  12, 14, 16, 18, 20, 25, 35, 50, 70, 85, 120, 250, 360, 400, 500,
]

// 0: light, 1: regular, 2: medium, 3: bold
const fontWeights = [100, 400, 700, 900]

//             0, 1, 2,  3,  4,  5,   6,  7,    8
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const breakpoints = [`800px`, `1280px`, `1600px`, `799px`]

// aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xs = breakpoints[3]

const gradients = [
  `linear-gradient(to bottom, ${colors.primary[5]}, ${colors.tertiary[0]})`,
  `radial-gradient(
    ellipse closest-side,
    ${colors.gray[0]} 30%,
    ${colors.primary[0]} 100%,
    ${colors.primary[0]} 0
  )`,
  `linear-gradient(to bottom, ${colors.gray[1]}, ${colors.gray[3]})`,
]

export default {
  colors,
  fontSizes,
  breakpoints,
  space,
  gradients,
  fontWeights,
}
