import React from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { useUnit } from 'context'
import { Link, Typography, Icon, Container } from 'components'
import Layout from '../totem/Layout'
import RutInput from './RutInput'
import Appointments from './Appointments'

function getRoutes() {
  return [
    {
      key: 0,
      path: `/`,
      title: `Totem`,
      icon: `calendar`,
      Component(props) {
        return <RutInput {...props} goBack={() => null} />
      },
    },
    {
      key: 1,
      path: `/myAppointments`,
      title: `Mis horas`,
      icon: `calendar`,
      Component(props) {
        return (
          <Appointments
            {...props}
            goBack={() => props.history.push(`/totem/patient`)}
          />
        )
      },
    },
  ]
}
const prefix = `/checkin`

function TotemCheckin() {
  const history = useHistory()
  const { unit, logo } = useUnit()
  const routes = getRoutes(unit)
  return (
    <Layout logo={logo}>
      <Container width="100%">
        <Link
          width={[`90%`, `100%`]}
          justifyContent="flex-start"
          onClick={history.goBack}
        >
          <Typography
            textDecoration="underline"
            fontStyle="italic"
            color="primary.4"
            fontSize={5}
          >
            <Icon icon="chevronLeft" color="primary.4" marginRight="10px" />
            Volver atrás
          </Typography>
        </Link>
        <Switch>
          {routes.map(({ path, Component }) => (
            <Route
              key={path}
              exact
              path={`${prefix}${path}`}
              component={Component}
            />
          ))}
          <Redirect to={`${prefix}${routes[0].path}`} />
        </Switch>
      </Container>
    </Layout>
  )
}

export default TotemCheckin
