import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Icon, Link, Typography, Container } from 'components'
import Identification from '../index'
function Reservation({
  history,
  logo,
  unit,
  showOnlyVirtualTicket = false,
  flushInterval,
  goBack,
}) {
  useEffect(() => {
    if (goBack == null) {
      flushInterval()
    }
  })

  return (
    <Identification
      goBack={goBack}
      allowPassport={false}
      isReservation={true}
      logo={logo}
      unit={unit}
      {...unit}
    >
      <Container width="100%" justifyContent="center">
        {showOnlyVirtualTicket ? (
          <Typography
            textAlign="center"
            width="100%"
            marginY="4"
            color="primary.4"
            fontSize={7}
            fontWeight="900"
          >
            Confirme su hora agendada
          </Typography>
        ) : (
          <Link
            width={[`90%`, `100%`]}
            justifyContent="flex-start"
            onClick={() => history.goBack()}
          >
            <Icon color="gray.3" fontSize="6" icon="arrowLeft" />
          </Link>
        )}
      </Container>
    </Identification>
  )
}

export default withRouter(Reservation)
