import React from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Typography, MaterialIcon } from 'components'
import { useTime, useDisabled, useUnit } from 'context'
import { capitalizeFirstLetter } from 'utils'

function Lines({ children, unit }) {
  //todo cambiar nombre de esta func
  const {
    division: { timezoneId },
  } = useUnit()
  const currentTime = useTime()
  const { isDisabled, toggleIsDisabled } = useDisabled()

  return (
    <Container
      minHeight="100vh"
      height="fit-content"
      width="100vw"
      justifyContent={isDisabled ? `flex-end` : `center`}
      alignItems="center"
      flexDirection="column"
      flexWrap="no-wrap"
      backgroundColor="backgroundApp.0"
    >
      {/* container fecha */}
      <Container alignItems="center" width="100%" justifyContent="center">
        <Typography
          height="5vh"
          color="gray.2"
          fontSize={{ _: 1, md: 2, lg: 3 }}
          fontWeight="100"
          top="2vh"
          right="2vh"
          position="absolute"
          display={isDisabled ? `block` : `none`}
        >
          {timezoneId === 1 &&
            capitalizeFirstLetter(
              currentTime.format(`dddd DD [ de ] MMMM YYYY, HH:mm:ss  [hrs.]`),
            )}
        </Typography>
      </Container>
      <Container width="100%" paddingX={{ _: `3`, md: `2` }}>
        {children} {/* Contenido index */}
      </Container>
      {unit?.totemLayout?.disabledButton ? (
        <Container
          flexWrap="no-wrap"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          marginTop={4}
          position="absolute"
          bottom={{
            md: isDisabled ? `80vh` : `1vh`,
            _: isDisabled ? `87vh` : `1vh`,
          }}
          left={isDisabled ? null : 0}
          right={isDisabled ? 0 : null}
          zIndex="1"
        >
          <Container //container de Icono disabled
            width="166px"
            justifyContent="center"
            display="flex"
            padding={2}
            alignSelf="flex-end"
            borderRadius={
              isDisabled ? `50px 0px 0px 50px` : `0px 50px 50px 0px`
            }
            backgroundColor="white"
            withShadow
            hoverProps={{
              backgroundColor: `primary.5`,
            }}
            height="fit-content"
            marginBottom={2}
            onClick={toggleIsDisabled}
          >
            <MaterialIcon
              fontSize={7}
              color="tertiary.1"
              icon={isDisabled ? `expand` : `accessible`}
            />
          </Container>
        </Container>
      ) : null}
      <Typography
        height="5vh"
        color="gray.2"
        fontSize={[1, 2, 3]}
        fontWeight="100"
        top="2vh"
        right="2vh"
        position="absolute"
        display={isDisabled ? `none` : `block`}
      >
        {timezoneId === 1 &&
          capitalizeFirstLetter(
            currentTime.format(`dddd DD [ de ] MMMM YYYY, HH:mm:ss  [hrs.]`),
          )}
      </Typography>
    </Container>
  )
}

export default withRouter(Lines)
