import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment-timezone'

const TimeContext = React.createContext()

function TimeProvider({ children }) {
  const [currentTime, setCurrentTime] = useState(moment())
  useEffect(() => {
    const currentTimeInterval = setInterval(() => {
      setCurrentTime(moment())
    }, 1000)
    //Temporal, para fines de desarrollo, devolver a 1000
    return () => {
      clearInterval(currentTimeInterval)
    }
  }, [])
  return (
    <TimeContext.Provider value={currentTime}>{children}</TimeContext.Provider>
  )
}

function useTime() {
  const context = useContext(TimeContext)
  if (!context) {
    throw new Error(`useTime must be used within a TimeProvider`)
  }
  return context
}

export { TimeProvider, useTime }
