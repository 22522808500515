import React from 'react'
import styled from 'styled-components'
import {
  compose,
  background,
  space,
  color,
  layout,
  flexbox,
  border,
  position,
  grid,
  typography,
} from 'styled-system'
import { setProperty, colorsWithOpacity, backgroundGradient } from 'utils'
import Icon from './Icon'

const Container = styled.div`
  ${compose(
    background,
    space,
    color,
    layout,
    flexbox,
    border,
    position,
    grid,
    typography,
    colorsWithOpacity,
    backgroundGradient,
  )}
  ${({
    withShadow = false,
    overflowY = null,
    onClick = null,
    disabled = false,
    shadow = ``,
  }) => `
  ${setProperty(`overflow-y`, overflowY)}
  ${withShadow ? `box-shadow: 0px 5px 8px 1px rgba(0,0,0,0.3) !important;` : ``}
  ${onClick == null ? `` : `  cursor: pointer`};
  ${disabled ? `cursor: not-allowed` : ``};
  ${
    shadow?.length > 0
      ? `-webkit-box-shadow: ${shadow}; box-shadow: ${shadow};`
      : ``
  }
  `}
    &:hover {
    transition: 200ms;
    ${({ hoverProps, ...props }) =>
      compose(
        layout,
        space,
        flexbox,
        border,
        colorsWithOpacity,
      )({ ...props, ...hoverProps })}
  }
`
Container.defaultProps = {
  display: `flex`,
  flexDirection: `row`,
  flexWrap: `wrap`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  maxWidth: `100%`,
}

const ContainerWrapper = React.forwardRef(
  (
    {
      isLoading = false,
      loaderSize = `4`,
      loaderColor = `primary.5`,
      children,
      ...props
    },
    ref,
  ) => {
    const aditionalProps = isLoading
      ? { justifyContent: `center`, flexDirection: `row`, alignItems: `center` }
      : {}
    return (
      <Container ref={ref} {...props} {...aditionalProps}>
        {isLoading ? (
          <Icon icon="spinner" spin color={loaderColor} fontSize={loaderSize} />
        ) : (
          children
        )}
      </Container>
    )
  },
)

ContainerWrapper.displayName = `ContainerWrapper`

export default ContainerWrapper
