import React from 'react'
import { Container, Tabs, TabPane } from 'components'
import { useAuth, useAttentions } from 'context'
import SpecialAttention from './SpecialAttention'
import TransferToModule from './TransferToModule'

function SpecialAtentionPanel({ currentLines }) {
  const {
    activeModuleId,
    currentAttention,
    auxiliaryModules,
    requestInQueue,
    setRequestInQueue,
  } = useAttentions()
  const {
    user: { id: userId, moduleType },
    currentUnit: { callTypeId },
    isUserAvailable,
  } = useAuth()
  return (
    <Container
      flex={2}
      withShadow
      padding="1em"
      borderTop="3px solid"
      borderColor="primary.8"
    >
      <Tabs defaultActiveKey="2" bs width="100%" activeTabColor="primary.8">
        <TabPane key="1" tab="Numeros especiales">
          <SpecialAttention
            requestInQueue={requestInQueue}
            setRequestInQueue={setRequestInQueue}
            userId={userId}
            activeModuleId={activeModuleId}
            callTypeId={callTypeId}
            moduleType={moduleType}
            currentAttention={currentAttention}
            isUserAvailable={isUserAvailable}
            currentLines={currentLines}
          />
        </TabPane>
        <TabPane tab="Derivación" key="2">
          <TransferToModule
            currentAttention={currentAttention}
            auxiliaryModules={auxiliaryModules}
            userId={userId}
            activeModuleId={activeModuleId}
          />
        </TabPane>
      </Tabs>
    </Container>
  )
}

export default SpecialAtentionPanel
