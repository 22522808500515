import React, { useEffect, useState } from 'react'
import API from 'config/api'
import { useHistory } from 'react-router-dom'
import { Button, Container, MaterialIcon, Typography } from 'components'
import Icon from 'components/MaterialIcon'
import { capitalizeFirstLetter, parseToPesosCL } from 'utils'
import moment from 'moment-timezone'
import { POS_TYPES } from 'config/constants'
import { genericErrorRedirect, toExit } from './Redirection'

const ButtonsGroup = ({
  scheduleData,
  toPaymentSelection,
  copaymentTotal,
  unit,
  data,
}) => {
  const history = useHistory()

  return (
    <Container width="100%">
      {!scheduleData?.hasNotPOS ? (
        <Button
          data-testid="continueCopaymentButton"
          shadow="0px 5px 5px 1px rgba(0, 0, 0, .1)"
          backgroundColor="primary.4"
          color="white"
          fontSize="2rem"
          padding={3}
          borderRadius="0.8rem"
          width="100%"
          onClick={toPaymentSelection}
        >
          {copaymentTotal === `0`
            ? `Continuar en pantalla`
            : `Pagar en pantalla`}
        </Button>
      ) : null}
      <Button
        backgroundColor="white"
        color="primary.4"
        fontSize="2rem"
        padding={3}
        borderRadius="0.8rem"
        width="100%"
        marginTop="1.25rem"
        border="2px"
        borderStyle="solid"
        borderColor="primary.4"
        onClick={() =>
          scheduleData?.hasNotQueuing
            ? genericErrorRedirect({
                unitID: unit.id,
                redirect: `/totem`,
                timeout: 12000,
                data: {},
                hasToExit: false,
                history,
                title: `Listo!`,
                subtitle: `Su cita ha sido agendada.
          Acérquese a pagar en caja.`,
                iconPaddingTop: `3rem`,
                iconSetting: {
                  icon: <MaterialIcon icon="calendarCheck" />,
                  iconColor: `green.0`,
                },
              })
            : toExit({
                history,
                scheduleData: {
                  ...scheduleData,
                  financerCode: data?.financerCode,
                },
              })
        }
      >
        Pagar en caja
      </Button>
    </Container>
  )
}

const InfoContainer = ({
  width = `50%`,
  icon,
  title,
  value,
  fontSize = `24px`,
  fontWeight = `normal`,
  color = `primary.4`,
  valueStyle = {},
  ...props
}) => (
  <Container width={width} flexDirection="column" {...props}>
    <Typography
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      marginBottom="1"
    >
      <Container alignItems="center">
        {icon !== undefined ? icon : null}
        {title !== undefined ? title : null}
      </Container>
    </Typography>
    <Typography fontSize="24px" lineHeight="normal" style={valueStyle}>
      {value !== undefined ? value : null}
    </Typography>
  </Container>
)

const getRoute = ({ copaymentTotal, posType }) => {
  if (copaymentTotal === `0`) {
    return `fin`
  }
  if (posType === POS_TYPES.H2H) {
    return `tipoPago`
  }
  return `pagar`
}

const handleNotifyImedPayment = async ({
  validateAdmissionResponse,
  scheduleData,
  history,
  unitId,
  setIsLoading,
  setInternalNotifyResult,
}) => {
  try {
    setIsLoading(true)
    const { parsedResponse } = await API.notifyImedPayment(
      validateAdmissionResponse,
    )
    setInternalNotifyResult(parsedResponse)
    setIsLoading(false)
  } catch (error) {
    setIsLoading(false)
    genericErrorRedirect({
      unitId,
      scheduleData,
      history,
      iconPaddingTop: `7rem`,
      iconPaddingBottom: `5rem`,
      iconSetting: {
        icon: <Icon icon="language" />,
        iconColor: `primary.4`,
      },
      upperIconSetting: {
        upperIcon: <Icon icon="error" />,
        upperIconColor: `error.3`,
      },
      textToExit: `Continuar trámite en caja`,
      styleToExit: {
        backgroundColor: `primary.4`,
        color: `white`,
        shadow: `0px 5px 5px 1px rgba(0, 0, 0, .1)`,
      },
    })
  }
}

const getPatientData = async (patientData) => {
  try {
    return await API.afterCopaymentHook(patientData)
  } catch (error) {
    return {}
  }
}

const extraInfoContainerStyle = {
  width: `100%`,
  flexDirection: `row`,
  justifyContent: `space-between`,
  fontSize: `30px`,
  color: `black@0.6`,
}

function Copayment({ unit }) {
  const history = useHistory()
  const historyState = history?.location?.state ?? {}
  const {
    scheduleData,
    validateAdmissionResponse,
    notifyResult,
    posType,
    appointmentData,
    data,
  } = historyState
  const { copaymentTotal } = validateAdmissionResponse ?? {}
  const { scheduledDate, professionalName, privateTotal } = scheduleData
  const [isLoading, setIsLoading] = useState(true)
  const [internalNotifyResult, setInternalNotifyResult] = useState(notifyResult)

  let otherBonuses, bonusTotal, total
  if (appointmentData) {
    const prevCopaymentTotal = appointmentData.appointment?.copaymentTotal
    bonusTotal = appointmentData.appointment?.bonusTotal
    total = appointmentData.appointment?.total
    otherBonuses =
      parseInt(prevCopaymentTotal, 10) - parseInt(copaymentTotal, 10)
  }

  useEffect(() => {
    if (validateAdmissionResponse && !internalNotifyResult) {
      handleNotifyImedPayment({
        unitId: unit.id,
        validateAdmissionResponse,
        scheduleData,
        history,
        setIsLoading,
        setInternalNotifyResult,
      })
    } else {
      setIsLoading(false)
    }
  }, [])
  const toPaymentSelection = async () => {
    const {
      patientRut,
      patientRutDv,
      additionalPatientData: { patientData: prevPatientData },
    } = scheduleData
    const patientData = await getPatientData({
      patientRut,
      patientRutDv,
      ...prevPatientData,
    })
    const route = getRoute({ copaymentTotal, posType })
    history.push({
      pathname: `/totem/${unit.id}/${route}`,
      state: {
        ...historyState,
        notifyResult: internalNotifyResult,
        scheduleData: {
          ...scheduleData,
          additionalPatientData: { patientData },
        },
        posType,
      },
    })
  }

  return (
    <Container
      width="100%"
      padding="0 2rem"
      isLoading={isLoading}
      loaderSize={7}
    >
      <Container
        background="white"
        borderRadius="1rem"
        shadow="0px 5px 10px 1px RGBA(0, 0, 0, 0.1)"
        justifyContent="center"
        alignItems="center"
        width="100%"
        padding="49px 35px 41px"
        maxHeight="1023px"
      >
        <Container width="100%" justifyContent="center">
          <Typography fontSize="32px">
            {copaymentTotal
              ? `Bono de atención Ambulatoria`
              : `Atención Ambulatoria`}
          </Typography>
        </Container>
        <Container
          width="100%"
          borderBottom="2px"
          borderStyle="solid"
          borderColor="gray.1"
          paddingTop="53px"
          paddingBottom="43px"
        >
          <InfoContainer
            icon={<Icon icon="accessTime" style={{ marginRight: `1rem` }} />}
            title="Fecha y hora"
            value={capitalizeFirstLetter(
              moment(scheduledDate).format(`D MMMM YYYY, HH:mm`),
            )}
          />
          {professionalName ? (
            <InfoContainer
              icon={
                <Icon icon="accountCircle" style={{ marginRight: `1rem` }} />
              }
              title="Profesional"
              value={professionalName
                .split(` `)
                .map((word) => `${capitalizeFirstLetter(word.toLowerCase())} `)}
            />
          ) : null}
        </Container>
        <InfoContainer
          width="100%"
          title="Resumen del pago"
          flexDirection="row"
          justifyContent="flex-start"
          paddingTop="20px"
          paddingBottom="20px"
          fontSize="32px"
          fontWeight="bold"
          color="black"
        />
        {total && total > 0 && (
          <InfoContainer
            title="Total"
            value={parseToPesosCL.format(total)}
            valueStyle={{
              lineHeight: `2rem`,
              marginLeft: `1rem`,
            }}
            paddingBottom="5px"
            {...extraInfoContainerStyle}
          />
        )}
        {bonusTotal && bonusTotal > 0 && (
          <InfoContainer
            title="Bonificación financiador"
            value={`-${parseToPesosCL.format(bonusTotal)}`}
            valueStyle={{
              lineHeight: `2rem`,
              marginLeft: `1rem`,
              color: `#4CAF50`,
            }}
            paddingBottom="5px"
            {...extraInfoContainerStyle}
          />
        )}
        {otherBonuses > 0 && (
          <InfoContainer
            title="Otras bonificaciones"
            value={`-${parseToPesosCL.format(otherBonuses)}`}
            valueStyle={{
              lineHeight: `2rem`,
              marginLeft: `1rem`,
              color: `#4CAF50`,
            }}
            {...extraInfoContainerStyle}
          />
        )}
        <InfoContainer
          width="100%"
          title={copaymentTotal ? `Total a pagar` : `Monto a pagar`}
          value={parseToPesosCL.format(copaymentTotal ?? privateTotal)}
          flexDirection="row"
          justifyContent="space-between"
          paddingTop={copaymentTotal ? `20px` : `40px`}
          paddingBottom="50px"
          fontSize="32px"
          fontWeight="bold"
          color="black"
          valueStyle={{
            lineHeight: `2rem`,
            marginLeft: `1rem`,
            fontSize: `32px`,
            fontWeight: `bold`,
          }}
        />
        <ButtonsGroup
          scheduleData={scheduleData}
          copaymentTotal={copaymentTotal}
          toPaymentSelection={toPaymentSelection}
          unit={unit}
          data={data}
        />
      </Container>
    </Container>
  )
}

export default Copayment
