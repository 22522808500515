import React, { useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useUnit, useDisabled } from 'context'
import { Container, Typography, Link } from 'components'
import { useStatsPing } from 'hooks'
import Layout from './Layout'
import Unit from './Unit'
import ServiceButton from './Services/ServiceButton'

function MainApp({ match }) {
  const history = useHistory()
  const params = useParams()
  const {
    isCheckinActive,
    division,
    unitsById,
    localActiveUnits,
    localConfigByUnit,
    ignoreSchedule,
  } = useUnit()
  const { isDisabled } = useDisabled()
  useStatsPing({ category: 3 })
  const unit = useMemo(() => {
    let unitId = null
    const parsedUnitId = parseInt(params.unitId, 10)
    if (!Number.isNaN(parsedUnitId)) {
      unitId = parsedUnitId
    } else if (localActiveUnits.length === 1 && !isCheckinActive) {
      unitId = localActiveUnits[0]
    }
    if (unitId == null) {
      return null
    } else {
      const localConfig = localConfigByUnit[unitId]
        ? localConfigByUnit[unitId]
        : {}
      return { ...localConfig, ...unitsById[unitId], ignoreSchedule }
    }
  }, [params.unitId, unitsById])
  const showGoBack = localActiveUnits.length > 1 || isCheckinActive

  return (
    <Layout unit={unit}>
      {unit == null ? (
        <Container
          marginTop={isDisabled ? `25vh` : 0}
          height={isDisabled ? `40vh` : `60vh`}
          flexDirection="column"
          alignItems="center"
          width="100%"
          flexWrap="no-wrap"
          loaderColor="primary.5"
          loaderSize={6}
        >
          <Container
            maxHeight="10vh"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            width="100%"
          >
            <Typography color="primary.4" fontSize={7} fontWeight="900">
              {division && division.name}
            </Typography>
            <Typography
              color="primary.4"
              fontSize={5}
              fontWeight="100"
              marginBottom="5"
            >
              Seleccione una opción
            </Typography>
          </Container>
          <Container
            display="grid"
            width="80%"
            gridGap="2vw"
            gridTemplateColumns={{
              md: `repeat(2, 1fr)`,
              _: isDisabled ? `repeat(2, 1fr)` : `1fr`,
            }}
            gridTemplateRows="repeat(auto-fit)"
          >
            {localActiveUnits.map((unitId) => {
              return (
                <ServiceButton
                  key={unitId}
                  onClick={() => history.push(`/totem/${unitId}`)}
                >
                  {unitsById[unitId].name}
                </ServiceButton>
              )
            })}
            {isCheckinActive ? (
              <Link
                to="/checkin"
                key="checkin"
                width={[isDisabled ? `47%` : `100%`]}
                marginBottom={4}
                backgroundColor="primary.4"
                color="white"
                fontSize="5"
                padding={3}
                borderRadius="15px"
                withShadow={true}
                hoverProps={{
                  backgroundColor: `primary.3`,
                }}
                minHeight={{ _: `8vh`, md: `10vh` }}
                height="fit-content"
              >
                Hora Médica
              </Link>
            ) : null}
          </Container>
        </Container>
      ) : (
        <Unit unit={unit} match={match} showGoBack={showGoBack} />
      )}
    </Layout>
  )
}
export default MainApp
