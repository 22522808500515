import React from 'react'
import { useInactivityHandler } from 'hooks'
import { Container, Typography, MaterialIcon, Button } from 'components'

function SuccessMessage({ goBack, hasExpressPickUp = false, ticketInfo }) {
  useInactivityHandler({ timeout: 5, inactivityHandler: goBack })
  return (
    <>
      <MaterialIcon
        icon="checkCircleOutline"
        fontSize="10"
        color="green.0"
        marginBottom="4"
      />
      <Typography
        textAlign="center"
        fontSize="6"
        fontWeight="900"
        lineHeight="1.3"
      >
        {ticketInfo?.screenMessage ||
          `Retire su ticket y espere por su atención`}
      </Typography>
      {hasExpressPickUp && (
        <Container width="100%" marginTop="4" marginBottom="3">
          <Typography
            textAlign="center"
            fontSize="5"
            fontWeight="500"
            lineHeight="1.1"
          >
            Diríjase a la zona de box y espere llamado en pantalla
          </Typography>
        </Container>
      )}
      <Button
        shadow="0px 4px 8px 1px #D0D0D0"
        backgroundColor="primary.4"
        width="100%"
        borderRadius="12px"
        paddingY="3"
        onClick={goBack}
        marginTop="3"
      >
        <Typography color="white" fontSize="5">
          Finalizar
        </Typography>
      </Button>
    </>
  )
}

export default SuccessMessage
