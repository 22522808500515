/* eslint-disable complexity */
import React from 'react'
import { Container, Typography, Icon, Button, Img } from 'components'
import { useDisabled, useUnit } from 'context'
import LayoutImageOrText from '../LayoutImageOrText'
import OutOfService from './OutOfService'

function Lines({
  unit,
  onGroupBack,
  hasVisibleLines,
  isGroup,
  isLoading,
  children,
  goBack,
  isRipleyIntegration = null,
  pickups = null,
}) {
  const { isDisabled } = useDisabled()
  const { name: unitName } = unit
  const { totemLayout } = unit
  const { logo } = useUnit()
  return (
    <>
      {goBack ? (
        <Container justifyContent="start" width="100%">
          <Button
            backgroundColor="transparent"
            paddingY="3"
            paddingX="4"
            onClick={goBack}
          >
            <Icon icon="arrowLeft" color="gray.3" fontSize="6" />
          </Button>
        </Container>
      ) : null}
      <Container
        marginTop={isDisabled ? `35vh` : `0vh`}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        flexWrap="no-wrap"
        isLoading={isLoading}
        loaderColor="primary.5"
        loaderSize={6}
      >
        {totemLayout != null && unit?.totemLayout !== 1 && isDisabled ? (
          <LayoutImageOrText bottom="65vh" totemLayout={totemLayout} />
        ) : null}
        {isGroup ? (
          <Button
            alignSelf="start"
            backgroundColor="transparent"
            onClick={onGroupBack}
          >
            <Icon color="gray.3" fontSize="6" icon="arrowLeft" />
          </Button>
        ) : null}
        {hasVisibleLines ? (
          <Container
            flexDirection={{ md: `row`, _: `column` }}
            paddingY="4"
            paddingX="1"
            marginBottom={{ _: `6`, md: `1` }}
            maxHeight="30vh"
            flexWrap="no-wrap"
            alignItems={{ _: `center`, md: `end` }}
            justifyContent="center"
            width="100%"
          >
            <Img
              src={logo}
              maxHeight={{ _: `400px`, md: `350px` }}
              maxWidth={{ _: `500px`, md: `550px` }}
              marginRight={{ _: `0`, md: `4` }}
              marginBottom={{ _: `5`, md: `0` }}
            />
            <Container
              flexDirection="column"
              width="fit-content"
              alignItems={{ _: `center`, md: `start` }}
            >
              <Typography
                color="black@0.8"
                fontSize={unitName.length > 30 ? 5 : 6}
                fontWeight="900"
                textAlign="center"
                paddingX={{ _: 5, md: 1 }}
                lineHeight="1"
                marginBottom="2"
              >
                {isRipleyIntegration && pickups > 0
                  ? `Tiene ${pickups} pedidos para recoger`
                  : `Bienvenido a ${unitName}`}
              </Typography>
              <Typography color="black@0.8" fontSize={5} fontWeight="900">
                Seleccione una opción para ser atendido
              </Typography>
            </Container>
          </Container>
        ) : null}
        {hasVisibleLines ? (
          <Container
            position={isDisabled ? `relative` : null}
            bottom={isDisabled ? `0` : `15vh`}
            width="100%"
            overflowY="auto"
            flexDirection="row"
            alignItems="center"
            justifyContent={{
              md: isDisabled ? `flex-start` : `center`,
              _: `center`,
            }}
          >
            {children}
          </Container>
        ) : (
          <OutOfService unit={unit} />
        )}
      </Container>
      {totemLayout != null && unit?.totemLayout !== 1 && !isDisabled ? (
        <LayoutImageOrText
          bottom={{ _: `7vh`, md: `0` }}
          totemLayout={totemLayout}
        />
      ) : null}
    </>
  )
}

export default Lines
