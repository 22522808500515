import React, { useState } from 'react'
import { notification } from 'antd'
import { Container, Typography, Button, Modal } from 'components'
import API from 'config/api'
import NormalTicket from './NormalTicket'
import AnalogTicket from './AnalogTicket'

function DeleteTicketModal({ onClose, onConfirm, displayName }) {
  return (
    <Modal onClose={onClose} closeColor="gray.1" width="50%" isVisible>
      <Typography fontSize="5">Eliminar ticket</Typography>
      <Container border="0.5px solid" borderColor="gray.0" />
      <Typography fontSize="2" marginY="4">
        ¿Está seguro que desea eliminar el ticket {displayName}?
      </Typography>
      <Container border="0.5px solid" borderColor="gray.0" />
      <Container marginTop="2" alignItems="center" justifyContent="flex-end">
        <Button onClick={onClose}>
          <Typography textDecoration="underline">Cancelar</Typography>
        </Button>
        <Button
          onClick={onConfirm}
          marginLeft="2"
          borderRadius="3px"
          paddingY="1"
          paddingX="2"
          backgroundColor="primary.8"
        >
          Eliminar
        </Button>
      </Container>
    </Modal>
  )
}

const ticketsBodyByLineTypeId = {
  1: NormalTicket,
  2: NormalTicket,
  3: AnalogTicket,
}

function Tickets({ attentions, currentAttentionByTicket, lineTypeId = 1 }) {
  const [ticketToDelete, setTicketToDelete] = useState(null)
  const Ticket = ticketsBodyByLineTypeId[lineTypeId] || NormalTicket
  async function deleteTicket() {
    try {
      await API.cancelAttention({ attentionId: ticketToDelete.id })
      notification.success({ message: `Ticket eliminado` })
      setTicketToDelete(null)
    } catch (e) {
      const error =
        typeof e === `string` ? e : `No se ha podido eliminar el ticket`
      notification.error({ message: error })
      setTicketToDelete(null)
    }
  }
  return (
    <Container width="100%" alignItems="stretch">
      {attentions.map((attention) => (
        <Ticket
          key={attention.id}
          {...attention}
          setTicketToDelete={() => setTicketToDelete(attention)}
          isCallable={currentAttentionByTicket[attention.ticketId] == null}
        />
      ))}
      {ticketToDelete != null && (
        <DeleteTicketModal
          displayName={ticketToDelete.displayName}
          onConfirm={deleteTicket}
          onClose={() => setTicketToDelete(null)}
        />
      )}
    </Container>
  )
}

export default Tickets
