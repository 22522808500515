import React, { useState, useRef, useMemo } from 'react'
import { useUnit, useSound } from 'context'
import API from 'config/api'
import { CONFIG_TV_KEY } from 'config/constants'
import { useIO, useStatsPing } from 'hooks'
import { filterByVisibleLines } from 'utils'
import moment from 'moment-timezone'
import { Container } from 'components'
import MultiGroupTV from './MultiGroupTV'
import NormalTV from './TV'
import AdTV from './AdvertisingTv'
import WideTV from './WideTv'
import UrgencyTv from './UrgencyTv'
import VideoTv from './VideoTv'
import MultiLineTV from './MultiLineTV'

const initialState = {
  attentions: [],
  attentionsById: {},
  lastCalledTicket: null,
}

const templateTypes = {
  lastCalls: 1,
  nextCalls: 2,
}

//TODO: Parametrizar por unidad
const MAX_LENGTH = 20
function useVoiceWithQueue({
  unit: { id: unitId, endTime },
  monitorType,
  config,
  tvLines,
  isNextCallsMode,
  template,
  corporationId = null,
}) {
  const [{ attentions, attentionsById, lastCalledTicket }, setState] =
    useState(initialState)
  const hasCalling = monitorType !== 6 && monitorType !== 7
  const queue = useRef([])
  const speak = useSound()

  async function callAttention(nextAttention) {
    updateAttentionQueue(nextAttention)
    await speak(nextAttention)
    dequeueAndCallNext()
  }
  function updateAttentionQueue(nextAttention) {
    setState((prevState) => {
      let nextAttentionsById, nextAttentions
      if (isNextCallsMode) {
        // Se saca el elemento que se esta llamando de los attentions existentes
        nextAttentions = prevState.attentions.filter(
          (attentionId) => attentionId !== nextAttention.id,
        )

        nextAttentionsById = { ...prevState.attentionsById }
        delete nextAttentionsById[nextAttention.id]
      } else {
        nextAttentionsById = { ...prevState.attentionsById }
        nextAttentions = prevState.attentions.reduce(
          (prev, current) => {
            //Si esta repetido o sobrepasa el length permitido
            if (current === nextAttention.id || prev.length >= MAX_LENGTH) {
              delete nextAttentionsById[current]
              return prev
            }
            return [...prev, current]
          },
          [nextAttention.id],
        )
        nextAttentionsById[nextAttention.id] = nextAttention
      }

      return {
        attentions: nextAttentions,
        attentionsById: nextAttentionsById,
        lastCalledTicket: nextAttention,
      }
    })
  }

  // Aqui se saca el elemento de la cola de llamada (queue)
  function dequeueAndCallNext() {
    queue.current.shift()
    if (queue.current.length > 0) {
      setTimeout(() => callAttention(queue.current[0]), 500)
    }
  }

  function handleNextAttention(nextAttention) {
    if (!hasCalling) {
      return
    }
    const [attention] = filterByVisibleLines({
      array: [nextAttention],
      getterFn: (attention) => attention.lineId,
      unitId,
      config,
    })
    if (attention) {
      queue.current.push(nextAttention)

      // Si solo hay un elemento en la cola se llama la atencion
      // Si no, se siguen las llamadas en base al timeout en dequeueAndCallNext
      if (queue.current.length === 1) {
        callAttention(nextAttention)
      }
    }
  }

  async function getCalls() {
    try {
      if (!hasCalling) {
        return
      }

      const endpoint = isNextCallsMode ? API.nextCalls : API.lastCalled

      const endpointParams = isNextCallsMode
        ? { lines: tvLines, showFullName: template?.showFullName ?? false }
        : unitId

      let { attentions, attentionsById } = await endpoint(endpointParams)
      attentions = filterByVisibleLines({
        array: attentions,
        getterFn: (attentionId) => attentionsById[attentionId].lineId,
        unitId,
        config,
      })

      setState({ attentions, attentionsById, lastCalledTicket: null })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  async function handleNextCall({ event, incomingAttention }) {
    const [attention] = filterByVisibleLines({
      array: [incomingAttention],
      getterFn: (attention) => attention.lineId,
      unitId,
      config,
    })
    if (isNextCallsMode && event === `addAttention` && attention) {
      let { attentions, attentionsById } = await API.nextCalls({
        lines: tvLines,
        showFullName: template?.showFullName ?? false,
      })
      attentions = filterByVisibleLines({
        array: attentions,
        getterFn: (attentionId) => attentionsById[attentionId].lineId,
        unitId,
        config,
      })
      setState((prevState) => {
        return {
          ...prevState,
          attentions,
          attentionsById,
        }
      })
    }
  }

  if (attentions.length > 0 && moment().isAfter(moment(endTime, `HH:mm:ss`))) {
    setState(initialState)
  }

  useIO({
    emitPayload: `tv`,
    subscribeEvent: `calledTicket`,
    subscribeEventHandler: handleNextAttention,
    aditionalFunction: getCalls,
  })
  /**
   * Este socket hace romper las tvs de unidades que tienen checkin.
   * Por motivos de tiempo, se aplico un parche para evitar problemas
   * en CRS (corporationId = 40).
   * TODO: A futuro se debe encontrar el problema de raiz
   */
  useIO({
    emitPayload: `tv`,
    subscribeEvent: `attentionEnded`,
    subscribeEventHandler: corporationId !== 40 ? getCalls : () => null,
  })
  useIO({
    emitPayload: `admissionist`,
    subscribeEvent: `attentionChanges`,
    subscribeEventHandler: handleNextCall,
  })

  return { attentions, attentionsById, lastCalledTicket }
}

function MainApp({
  match: {
    params: { tvTemplateId },
  },
}) {
  useStatsPing({ category: 2 })
  const { unitsById, localActiveUnits, logo, division } = useUnit()
  const corporationId = division?.corporation?.id ?? division?.corporationId

  const [
    unit,
    config,
    monitorType,
    margin,
    tvLines,
    tvTemplateCallType,
    template,
  ] = useMemo(() => {
    let unit = unitsById[localActiveUnits[0]]
    let template = null
    if (tvTemplateId != null) {
      localActiveUnits.forEach((unitId) => {
        const currentUnit = unitsById[unitId]
        const currentTemplate = currentUnit?.tvTemplatesById?.[tvTemplateId]
        if (currentTemplate) {
          unit = currentUnit
          template = currentTemplate
        }
      })
    }
    const config =
      template?.config ?? JSON.parse(localStorage.getItem(CONFIG_TV_KEY))
    const monitorType = template?.monitorId ?? unit?.monitorId
    const hasMargin = template?.tvMargin
    const color = template?.tvColor
    const margin = { tvMargin: hasMargin, tvColor: color }
    const tvTemplateCallType = template?.callType
    const allTvLines = []
    config?.groups?.forEach(({ lines }) => {
      if (lines) {
        allTvLines.push(...lines)
      }
    })
    // Se filtran las filas repetidas
    const tvLines = [...new Set(allTvLines)]
    return [
      unit,
      config,
      monitorType,
      margin,
      tvLines,
      tvTemplateCallType,
      template,
    ]
  }, [unitsById, localActiveUnits, tvTemplateId])

  const isNextCallsMode = tvTemplateCallType === templateTypes.nextCalls

  const { attentionsById, attentions, lastCalledTicket } = useVoiceWithQueue({
    unit,
    monitorType,
    config,
    tvLines,
    isNextCallsMode,
    template,
    corporationId,
  })
  const defaultAttentions = isNextCallsMode
    ? [lastCalledTicket, ...attentions]
    : attentions

  const [currentAttentionId, ...lastCalledAttentions] = defaultAttentions
  const currentAttention = isNextCallsMode
    ? lastCalledTicket
    : attentionsById[currentAttentionId]

  const TvProps = {
    unit,
    logo,
    attentionsById,
    lastCalledAttentions,
    currentAttention,
    attentions,
    config,
    isNextCallsMode,
    division,
  }
  const renderTv = useMemo(() => {
    function getTv(props) {
      if (monitorType == 4 && config != null) {
        return <MultiGroupTV {...props} />
      } else if (
        monitorType == 2 &&
        (unit.videoName || unit.externalVideoUrl || config != null)
      ) {
        return <AdTV {...props} />
      } else if (monitorType == 5) {
        return <WideTV {...props} />
      } else if (monitorType == 6) {
        return <UrgencyTv {...props} />
      } else if (monitorType == 7 && config?.videoUrl != null) {
        return <VideoTv {...props} />
      } else if (monitorType == 8) {
        return <MultiLineTV {...props} />
      } else {
        return <NormalTV {...props} />
      }
    }
    return getTv(TvProps)
  }, [monitorType, TvProps])

  return (
    <Container
      border={margin.tvMargin ? `solid` : `none`}
      borderColor={margin.tvColor}
      borderWidth="2.5vh"
      height="100vh"
    >
      {renderTv}
    </Container>
  )
}
export default MainApp
