import React, { useState } from 'react'
import { format as formatRut } from 'rut.js'
import { Container, Typography, Icon, Button, MaterialIcon } from 'components'
import { actionTypes } from 'config/constants'

const noShowErrors = [`An internal server error occurred`]

const transverseErrorMessage = () => (
  <Container
    display="grid"
    alignItems="center"
    gridTemplateColumns="10% 90%"
    gridGap={2}
    width="100%"
    paddingX="4"
  >
    <Icon color="warning" icon="warning" fontSize="6" />
    <Container flexDirection="column">
      <Typography color="gray.4" fontSize="5" lineHeight="1">
        No se ha podido imprimir su ticket.
      </Typography>
      <Typography color="gray.4" fontSize="5" lineHeight="1">
        Anótelo o tómele una foto.
      </Typography>
    </Container>
  </Container>
)

const handleAutopagoZonesMessage = ({
  ticket,
  action,
  moduleName,
  floorId,
  sector,
}) => {
  const messageByAction = {
    TO_PAYMENT: `Diríjase a las cajas de pago ubicadas en la ZONA ${sector} PISO ${floorId}.`,
    TO_ATTENTION: `Diríjase a Zona ${sector} PISO ${floorId} y espera a ser llamado.`,
  }
  return (
    <>
      <Container
        justifyContent="center"
        height={ticket ? `100%` : `30vh`}
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          color="gray.4"
          fontSize="3vh"
          fontWeight="2"
          textAlign="center"
        >
          {messageByAction[action]}
        </Typography>
        {ticket && action === actionTypes.TO_PAYMENT ? (
          <>
            <Typography color="gray.4" fontSize="2.2vh" fontWeight="2">
              Su ticket es el
            </Typography>
            <Typography color="gray.4" fontSize="10" fontWeight="2">
              {ticket}
            </Typography>
          </>
        ) : null}
        {moduleName ? (
          <Typography color="gray.4" fontSize="2.2vh" fontWeight="2">
            Atención en: {moduleName}
          </Typography>
        ) : null}
      </Container>
      {transverseErrorMessage()}
    </>
  )
}

const handleAutopagoMessage = ({
  ticket,
  icon,
  callPlace,
  action,
  moduleName,
  patientRut,
  patientRutDv,
  isNotFeedback,
}) => {
  const placeByAction = {
    TO_ATTENTION: `ubicada`,
    TO_PAYMENT: `ubicadas`,
  }

  const handleLocationInMessage = ({ callPlace, action }) =>
    callPlace ? `${placeByAction[action]} en ${callPlace} ` : ``
  const messageByAction = {
    TO_ATTENTION: `Diríjase a sala de espera`,
    TO_PAYMENT: `Diríjase a las cajas de pago`,
  }

  const handleActionMessageByaction = ({ callPlace, action }) => {
    return `${messageByAction[action]} ${handleLocationInMessage({
      callPlace,
      action,
    })}y espere a ser llamado.`
  }

  return (
    <>
      <Container
        justifyContent="center"
        height={ticket ? `100%` : `30vh`}
        alignItems="center"
        flexDirection="column"
      >
        {icon && isNotFeedback ? (
          <Typography
            position="relative"
            fontSize="9.5vh"
            fontWeight="500"
            textAlign="center"
            color="green.0"
            lineHeight="0"
            marginY="2vh"
          >
            {icon}
          </Typography>
        ) : null}
        <Typography
          color="gray.4"
          fontSize="3vh"
          fontWeight="2"
          textAlign="center"
        >
          {handleActionMessageByaction({
            callPlace,
            action,
          })}
        </Typography>
        {moduleName && action === `TO_ATTENTION` ? (
          <Typography
            color="gray.4"
            fontSize="2.2vh"
            fontWeight="2"
            paddingTop="2vh"
          >
            Profesional atiende en {moduleName}
          </Typography>
        ) : null}
        {ticket && action === actionTypes.TO_PAYMENT ? (
          <Typography color="gray.4" fontSize="9" fontWeight="2">
            {ticket}
          </Typography>
        ) : null}
        {patientRut && patientRutDv ? (
          <Typography color="gray.4" fontSize="2.2vh" fontWeight="2">
            {formatRut(`${patientRut}-${patientRutDv}`)}
          </Typography>
        ) : null}
      </Container>
      {transverseErrorMessage()}
    </>
  )
}

const getMessage = ({
  isPrintingError,
  ticket,
  ticketError,
  hasExpressPickUp = false,
  ticketInfo,
  hasAutopagoZone,
  isAutopago,
  patientRut,
  patientRutDv,
  icon = <MaterialIcon icon="checkCircleOutline" />,
  isNotFeedback,
}) => {
  const showPositiveError = ticketInfo?.positiveError

  if (hasAutopagoZone) {
    return handleAutopagoZonesMessage(ticketInfo)
  }
  if (isAutopago) {
    return handleAutopagoMessage({
      ...ticketInfo,
      icon,
      patientRut,
      patientRutDv,
      isNotFeedback,
    })
  }

  if (showPositiveError) {
    return (
      <>
        <MaterialIcon
          icon={ticketInfo.icon ?? `checkCircleOutline`}
          fontSize="9.5vh"
          color={ticketInfo.iconColor ?? `green.0`}
        />
        <Typography
          color="gray.4"
          fontSize="6"
          lineHeight="1.3"
          textAlign="center"
          marginBottom="3"
        >
          {ticketInfo?.screenMessageForPrintError || ``}
        </Typography>
      </>
    )
  }

  const hasScreenMessage =
    ticketInfo?.screenMessageForPrintError?.trim().length > 0

  return isPrintingError ? (
    <>
      {ticketInfo?.showSuccessIcon ? (
        <MaterialIcon
          icon="checkCircleOutline"
          fontSize="9.5vh"
          color="green.0"
        />
      ) : null}
      <Typography
        color="gray.4"
        fontSize={hasScreenMessage ? `6` : `7`}
        textAlign={hasScreenMessage ? `center` : ``}
      >
        {ticketInfo?.screenMessageForPrintError || `Su ticket es el`}
      </Typography>
      <Typography color="gray.4" fontSize="10" fontWeight="900">
        {ticket}
      </Typography>
      {transverseErrorMessage()}
      {hasExpressPickUp && (
        <Container width="100%" marginTop="4">
          <Typography
            textAlign="center"
            fontSize="5"
            fontWeight="500"
            lineHeight="1.1"
          >
            Diríjase a la zona de box y espere llamado en pantalla
          </Typography>
        </Container>
      )}
    </>
  ) : (
    <>
      <Icon icon="circleX" fontSize="8" color="error.3" marginBottom="4" />
      {typeof ticketError === `string` &&
      !noShowErrors.includes(ticketError) ? (
        <Typography color="gray.4" fontSize="5" lineHeight="1">
          {ticketError}
        </Typography>
      ) : (
        <>
          <Typography color="gray.4" fontSize="5" lineHeight="1">
            No se ha podido emitir su ticket.
          </Typography>
          <Typography color="gray.4" fontSize="5" lineHeight="1">
            Por favor, intente de nuevo.
          </Typography>
        </>
      )}
    </>
  )
}

function ErrorMessage({ goBack, ...props }) {
  const [waitReSize, setWaitResize] = useState(
    (props?.isAutopago && props?.isNotFeedback) ||
      (props?.isAutopagoZone && props?.isNotFeedback),
  )
  if (waitReSize) {
    setTimeout(() => {
      setWaitResize(false)
    }, 100)
    return null
  }
  return (
    <>
      {getMessage(props)}
      <Button
        shadow="0px 4px 8px 1px #D0D0D0"
        backgroundColor="primary.4"
        width="100%"
        borderRadius="12px"
        padding="3"
        onClick={goBack}
        marginTop={props?.ticketInfo?.positiveError ? `0` : `4`}
      >
        <Typography color="white" fontSize="5">
          {props?.ticketInfo?.buttonMessage ?? `Finalizar`}
        </Typography>
      </Button>
    </>
  )
}

export default ErrorMessage
