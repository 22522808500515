import React from 'react'
import { Container, Typography, Icon } from 'components'

function Resume({ currentLines }) {
  const totalAttentions = currentLines
    .slice(1)
    .reduce((acc, current) => acc + current.attentions.length, 0)

  return (
    <Container
      width="100%"
      marginTop="1em"
      padding="1em"
      backgroundColor="primary.4@.22"
      borderRadius="5px"
    >
      <Typography color="primary.4" fontWeight="3" marginBottom="1">
        RESUMEN DE ESPERAS:
      </Typography>
      <Container width="100%">
        {currentLines.map((line, index) => {
          const { name, waitingPeople, waitTime, id, attentions } = line
          return (
            <Container
              flexDirection="column"
              key={id}
              paddingX="2"
              borderRight={
                index == currentLines.length - 1 ? null : `1px solid`
              }
              borderColor="primary.4"
              maxWidth="20%"
            >
              <Typography
                color="primary.4"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                fontWeight="3"
              >
                {name}:
              </Typography>
              <Container alignItems="center">
                <Icon marginX="1" color="primary.4" icon="users" />
                <Typography color="primary.4">
                  {waitingPeople} personas
                </Typography>
                <Icon marginX="1" color="primary.4" icon="clock" />
                <Typography color="primary.4">{waitTime} minutos</Typography>
              </Container>
              <Typography color="primary.4">
                Total esperando:{` `}
                {id === 0 ? totalAttentions : attentions.length}
              </Typography>
            </Container>
          )
        })}
      </Container>
    </Container>
  )
}

export default Resume
