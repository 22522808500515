import React from 'react'
import { Container, Typography, Img } from 'components'
import { LOGO_KEIRON } from 'assets'
import { useTime } from 'context'
import { getEmptyAttention } from 'utils'
import styled from 'styled-components'

const Card = styled(Container)`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
`

function getFilledAttentions({ attentions, attentionsById }) {
  const filledAttentions = []
  for (let i = 0; i < 3; i = i + 1) {
    const attentionId = attentions[i]
    filledAttentions.push(
      attentionId != null ? attentionsById[attentionId] : getEmptyAttention(i),
    )
  }
  return filledAttentions
}

function LastTicketsPanel({
  lastCalledAttentions,
  attentionsById,
  isNextCallsMode,
  ...props
}) {
  function calcFontSize(text) {
    if (text?.length >= 18) {
      return `5vmin`
    } else if (text?.length > 7) {
      return `6vmin`
    }
    return `8vmin`
  }

  const filledAttentions = getFilledAttentions({
    attentions: lastCalledAttentions,
    attentionsById,
  })

  return (
    <Container
      height="100%"
      flexDirection="column"
      justifyContent="flex-start"
      paddingX="3"
      {...props}
    >
      <Typography
        color="tertiary.4"
        fontSize={{ _: 6, lg: 7 }}
        fontWeight="bold"
      >
        {isNextCallsMode ? `Próximos` : `Últimos`} llamados
      </Typography>
      <Container width="100%" height="90%" alignItems="stretch">
        {filledAttentions.map((attention, index) => {
          const {
            moduleShortIdentifier,
            longIdentifier,
            shortIdentifier,
            id,
            showLongIdentifier,
          } = attention
          const isLastAttention = filledAttentions[index + 1] == undefined
          const currentIdentifier = showLongIdentifier
            ? longIdentifier
            : shortIdentifier
          return (
            <Card
              paddingTop="3"
              key={id}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              backgroundColor="white"
              borderRadius="20px"
              flexWrap="no-wrap"
              marginBottom={isLastAttention ? `0px` : { _: 3 }}
            >
              <Typography
                color="tertiary.4"
                fontWeight="700"
                lineHeight="1"
                fontSize={calcFontSize(currentIdentifier)}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {currentIdentifier}
              </Typography>
              {isNextCallsMode ? null : (
                <Typography
                  color="tertiary.4"
                  fontWeight="500"
                  fontSize={{ _: 7, lg: 8 }}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {moduleShortIdentifier}
                </Typography>
              )}
            </Card>
          )
        })}
      </Container>
    </Container>
  )
}

function ActualTicketPanel({ currentAttention, ...props }) {
  function calcFontSize(text) {
    if (text.length >= 18) {
      return `8vmin`
    } else if (text.length > 7) {
      return `15vmin`
    }
    return `20vmin`
  }

  return (
    <Container
      width="100%"
      height="100%"
      paddingRight="4"
      justifyContent="flex-start"
      flexDirection="column"
      {...props}
    >
      {currentAttention ? (
        <Card
          width="100%"
          height="90%"
          backgroundColor="tertiary.4"
          borderRadius="20px"
          justifyContent="center"
          padding="3"
          marginTop="4"
        >
          <Container width="100%" flexDirection="column" alignItems="center">
            <Container
              width="100%"
              alignItems="center"
              justifyContent="space-around"
            >
              <Typography
                width="30%"
                color="white"
                fontSize={{ _: 6, lg: 7 }}
                fontWeight="100"
              >
                Llamado actual
              </Typography>
              <Typography
                color="white"
                fontSize={calcFontSize(currentAttention.longIdentifier)}
                lineHeight="1.1"
                fontWeight="900"
              >
                {currentAttention.longIdentifier}
              </Typography>
            </Container>
          </Container>
          <Container width="80%" border="1px solid white" />
          <Container
            width="100%"
            alignItems="center"
            justifyContent="space-around"
          >
            <Typography
              color="white"
              fontSize={{ _: 6, lg: 7 }}
              fontWeight="100"
            >
              Diríjase a
            </Typography>
            <Typography
              textAlign="center"
              lineHeight="1.2"
              color="white"
              fontSize={calcFontSize(
                currentAttention.moduleLongIdentifier,
                false,
              )}
              fontWeight="900"
            >
              {`${currentAttention.moduleLongIdentifier}`}
            </Typography>
          </Container>
        </Card>
      ) : (
        <Container width="100%" justifyContent="center" alignItems="center">
          <Typography
            color="primary.4"
            fontSize={{ _: 10 }}
            fontWeight="900"
            textAlign="center"
          >
            Esperando llamados
          </Typography>
        </Container>
      )}
    </Container>
  )
}

function TV({
  logo,
  attentionsById,
  lastCalledAttentions,
  currentAttention,
  isNextCallsMode,
}) {
  const time = useTime()
  return (
    <Container backgroundColor="gray.2@0.25" height="100vh">
      <Container
        backgroundColor="white"
        maxHeight="10vh"
        width="100%"
        paddingY="1"
        paddingX={{ _: 4, lg: 5 }}
        withShadow
        justifyContent="space-between"
        alignItems="center"
      >
        <Img width="10%" src={LOGO_KEIRON} />
        <Img width="15%" src={logo} maxHeight="9vh" />
        <Typography color="tertiary.4" fontSize={{ _: 6, lg: 7 }}>
          {time.format(`HH:mm:ss`)}
        </Typography>
      </Container>
      <Container
        width="100%"
        alignItems="flex-start"
        height="90vh"
        paddingX={{ _: 4, lg: 5 }}
        paddingY={{ _: 1, lg: 3 }}
      >
        <ActualTicketPanel width="70%" currentAttention={currentAttention} />
        <LastTicketsPanel
          width="30%"
          lastCalledAttentions={lastCalledAttentions}
          attentionsById={attentionsById}
          isNextCallsMode={isNextCallsMode}
        />
      </Container>
    </Container>
  )
}

export default TV
