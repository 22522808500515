import styled from 'styled-components'
import { compose, system, color, layout, typography } from 'styled-system'
import AntTabs from 'antd/es/tabs'
import 'antd/es/tabs/style/index.css'

const { TabPane: AntTabPane } = AntTabs

const tabColor = system({
  activeTabColor: {
    property: `color`,
    scale: `colors`,
  },
})

const activeTabColor = system({
  selectedTabColor: {
    property: `backgroundColor`,
    scale: `colors`,
  },
})

const fontSize = system({
  fontSize: {
    property: `fontSize`,
    scale: `fontSizes`,
  },
})

const Tabs = styled(AntTabs)`
  ${compose(color, layout, fontSize)}

  & .ant-tabs-ink-bar {
    ${activeTabColor}
  }
  & .ant-tabs-tab.ant-tabs-tab-active {
    ${compose(tabColor, fontSize, typography)}
    font-weight: 900 !important;
  }
  & .ant-tabs-nav .ant-tabs-tab {
    font-weight: 400;
    &:hover {
      ${tabColor}
    }
  }
`
Tabs.defaultProps = {
  width: `100%`,
  activeTabColor: `primary.0`,
  selectedTabColor: `gray.3`,
  fontSize: `4`,
}

const TabPane = styled(AntTabPane)`
  ${color}
`

TabPane.defaultProps = {}

export { Tabs, TabPane }
