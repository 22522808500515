import { CONFIG_BY_UNIT_KEY } from 'config/constants'
import { normalizeString } from './format'

export function getVisibleLines(unitId = null) {
  const localConfigByUnit = JSON.parse(localStorage.getItem(CONFIG_BY_UNIT_KEY))
  if (localConfigByUnit != null) {
    if (unitId != null) {
      return localConfigByUnit[unitId]
        ? localConfigByUnit[unitId].visibleLines
        : []
    } else {
      return Object.values(localConfigByUnit).reduce((prev, current) => {
        if (current.isActive) {
          return [...prev, ...current.visibleLines]
        } else {
          return prev
        }
      }, [])
    }
  } else {
    return []
  }
}

export function filterByVisibleLines({
  array,
  getterFn = null,
  unitId = null,
  config = null,
}) {
  let visibleLines = getVisibleLines(unitId)
  if (config) {
    const { groups } = config
    const lines = groups.reduce((acc, current) => {
      return [...acc, ...current.lines]
    }, [])
    visibleLines = lines
  }
  const filteredArray =
    visibleLines.length === 0
      ? array
      : array.filter((item) =>
          visibleLines.includes(getterFn ? getterFn(item) : item),
        )
  return filteredArray
}

function normalizeUppercase(text) {
  return normalizeString(`${text}`.toUpperCase())
}

export const filterTable = ({ data, filterText, filters }) => {
  if (filterText === ``) {
    return data
  }
  return data.filter((row) => {
    return filters.find((filter) => {
      const nesting = filter.split(`.`)
      let auxRow = row
      nesting.forEach((nest) => {
        if (auxRow[nest]) {
          auxRow = auxRow[nest]
        }
      })
      return normalizeUppercase(auxRow).includes(normalizeUppercase(filterText))
    })
  })
}
