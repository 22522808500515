import styled from 'styled-components'
import Modal from 'antd/es/modal'

export const ModalContainer = styled(Modal)`
  ${({
    theme,
    backgroundColor = `white`,
    titleColor = theme.colors.primary[2],
    closeColor = `white`,
  }) => `
  & .ant-modal-header {
    background-color: ${backgroundColor};
    border-radius: 0;
    padding: 30px 24px;
    & .ant-modal-title {
      color: ${titleColor} !important;
    }
  }
  & .ant-modal-close-x {
    color: ${closeColor} !important;
  }
  width: ${(props) =>
    props.width ? `${props.width} !important` : `60 % !important`};
  `}
`
