import React from 'react'
import { Container, Typography, Button, Modal } from 'components'

function CloseSession({ onClose, logOut }) {
  return (
    <Modal onClose={onClose} closeColor="gray.1" width="50%" isVisible>
      <Typography fontSize="5">Cerrar sesión</Typography>
      <Container border="0.5px solid" borderColor="gray.0" />
      <Typography fontSize="2" marginY="4">
        ¿Está seguro que desea cerrar sesión?
      </Typography>
      <Container border="0.5px solid" borderColor="gray.0" />
      <Container marginTop="2" alignItems="center" justifyContent="flex-end">
        <Button onClick={onClose}>
          <Typography textDecoration="underline">Cancelar</Typography>
        </Button>
        <Button
          onClick={logOut}
          marginLeft="2"
          borderRadius="3px"
          paddingY="1"
          paddingX="2"
          backgroundColor="primary.2"
        >
          Cerrar sesión
        </Button>
      </Container>
    </Modal>
  )
}

export default CloseSession
