import React, { useEffect, useState } from 'react'
import API from 'config/api'
import { Container, Typography, Input } from 'components'

function Unit({ id, config, name, changeUnitConfig }) {
  const [lines, setLines] = useState([])
  useEffect(() => {
    async function getLines() {
      try {
        let { lines } = await API.getTotemLines({
          returnUnfiltered: true,
          unitId: id,
        })
        lines = lines.map((line) => ({ value: line.id, label: line.name }))
        setLines(lines)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }
    getLines()
  }, [])
  function onChangeField(field, value) {
    changeUnitConfig(id, { ...config, [field]: value })
  }
  return (
    <Container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      withShadow
      padding="1em"
      marginY="1em"
    >
      <Typography
        fontWeight="bolder"
        color="primary.4"
        fontSize={5}
        padding="2%"
      >
        {name}
      </Typography>
      <Typography color="primary.4" fontSize={5} fontWeight="100" padding="2%">
        Activar unidad en este equipo
      </Typography>
      <Input
        padding={2}
        fontSize={5}
        value={config.isActive}
        type="checkbox"
        onChange={() => onChangeField(`isActive`, !config.isActive)}
      />
      <Typography color="primary.4" fontSize={5} fontWeight="100" padding="2%">
        Selecciones las filas visibles de esta unidad.
      </Typography>
      <Input
        width="80%"
        padding={2}
        fontSize={5}
        value={config.visibleLines}
        options={lines}
        onChange={(visibleLines) => onChangeField(`visibleLines`, visibleLines)}
        required
        type="select"
        mode="multiple"
      />
      <Typography color="primary.4" fontSize={5} fontWeight="100" padding="2%">
        Mostrar solo ticket virtual en esta unidad
      </Typography>
      <Input
        padding={2}
        fontSize={5}
        value={config.showOnlyVirtualTicket}
        type="checkbox"
        onChange={() =>
          onChangeField(`showOnlyVirtualTicket`, !config.showOnlyVirtualTicket)
        }
      />
    </Container>
  )
}

export default Unit
