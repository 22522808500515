import React, { useEffect, useState } from 'react'
import ServiceButton from './ServiceButton'

function LineButton({
  isLine,
  setCurrentLineCategory,
  name,
  totemName,
  isDisabled,
  handleLineClick,
  order,
  icons,
  ...props
}) {
  const [lineIcons, setLineIcons] = useState(null)
  const styleProps = {
    width: `100%`,
    backgroundColor: `primary.4`,
    color: `white`,
    fontSize: `5`,
    padding: { md: name.length > 30 ? `0px 16px 0px 16px` : 3, _: 3 },
    borderRadius: `15px`,
    maxHeight: `110px`,
    withShadow: true,
    maxWidth: `100%`,
    hoverProps: {
      backgroundColor: `primary.3`,
      withShadow: false,
    },
  }

  useEffect(() => {
    if (icons != null) {
      setLineIcons(icons)
    }
  }, [])

  return isLine ? (
    <ServiceButton
      isDisabled={isDisabled}
      onClick={handleLineClick}
      icons={lineIcons}
      {...styleProps}
      {...props}
    >
      {totemName || name}
    </ServiceButton>
  ) : (
    <ServiceButton
      isDisabled={isDisabled}
      onClick={setCurrentLineCategory}
      icons={lineIcons}
      {...styleProps}
      {...props}
    >
      {name}
    </ServiceButton>
  )
}

export default LineButton
