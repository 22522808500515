import { useRef, useLayoutEffect } from 'react'

function useIsMounted() {
  const isMountedRef = useRef(false)

  useLayoutEffect(() => {
    isMountedRef.current = true

    return () => (isMountedRef.current = false)
  }, [])

  return isMountedRef
}

export default useIsMounted
