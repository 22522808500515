/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import {
  LOGO_BANMEDICA,
  LOGO_CODELCO,
  LOGO_COLMENA,
  LOGO_CONSALUD,
  LOGO_CRUZBLANCA,
  LOGO_FONASA,
  LOGO_FUNDACION,
  LOGO_MASVIDA,
  LOGO_VIDA3,
  LOGO_ESENCIAL,
  LOGO_CRUZDELNORTE,
} from 'assets'
import { Container, MaterialIcon, Typography } from 'components'
import { useHistory } from 'react-router-dom'
import API from 'config/api'
import Icon from 'components/MaterialIcon'
import { useAutopago } from 'context/AutopagoContext'
import { genericErrorRedirect, toExit } from './Redirection'
import Feedbacks from './Feedbacks'

const financers = [
  { logo: LOGO_FONASA, imedId: 1, name: `Fonasa`, order: 7 },
  { logo: LOGO_CODELCO, imedId: 63, name: `Codelco`, order: 1 },
  { logo: LOGO_COLMENA, imedId: 67, name: `Colmena`, order: 2 },
  { logo: LOGO_CONSALUD, imedId: 71, name: `Consalud`, order: 3 },
  { logo: LOGO_FUNDACION, imedId: 76, name: `Isapre Fundación`, order: 8 },
  { logo: LOGO_CRUZBLANCA, imedId: 78, name: `Cruz Blanca`, order: 5 },
  { logo: LOGO_VIDA3, imedId: 80, name: `Vida3`, order: 10 },
  { logo: LOGO_MASVIDA, imedId: 88, name: `Nueva Mas Vida`, order: 9 },
  { logo: LOGO_CRUZDELNORTE, imedId: 94, name: `Cruz del norte`, order: 4 },
  { logo: LOGO_BANMEDICA, imedId: 99, name: `Banmedica`, order: 0 },
  { logo: LOGO_ESENCIAL, imedId: 108, name: `Esencial`, order: 6 },
]

const handleExclusionRuleToPayment = ({ appointment, unitId, history }) =>
  genericErrorRedirect({
    unitId,
    redirect: `/totem/${unitId}/print`,
    timeout: 4000,
    data: appointment,
    hasToExit: false,
    history,
    title: `Pago en caja.`,
    subtitle: `Este servicio requiere ser pagado en caja. Retire su ticket y espere su atención.`,
    iconPaddingTop: `3rem`,
    iconSetting: {
      icon: <MaterialIcon icon="errorOutline" />,
      iconColor: `primary.4`,
    },
  })
const handleExclusionRuleToPrivatePayment = ({
  appointment,
  unitId,
  history,
  posType,
  patientRut,
}) =>
  genericErrorRedirect({
    unitId,
    useOnlyFirstLevel: true,
    data: {
      posType,
      scheduleData: appointment,
      data: {
        financerCode: `privatePayment`,
        patientRut,
        appointment,
      },
    },
    pathToRedirect: `/totem/${unitId}/copago`,
    history,
    title: `Esta prestación sólo admite pago particular`,
    iconPaddingTop: `7rem`,
    iconSetting: {
      icon: <MaterialIcon icon="errorOutline" />,
      iconColor: `primary.4`,
    },
    hasToExit: false,
    textToRetry: `Continuar`,
    styleToRetry: { marginTop: `4vh` },
  })

const handleExclusionRuleByAction = {
  TO_PAYMENT: handleExclusionRuleToPayment,
  TO_PRIVATE_PAYMENT: handleExclusionRuleToPrivatePayment,
}

const handleRedirectIMEDError = ({
  error,
  history,
  scheduleData,
  unitId,
  posType,
}) => {
  if (error === `ErrorCitaId` || error === `ErrorIdExterno`) {
    genericErrorRedirect({
      unitId,
      scheduleData,
      history,
      title: `Lo sentimos`,
      subtitle: `No se puede realizar su solicitud. Retire su ticket y espere su atención en caja.`,
      redirect: `/totem/${unitId}/print`,
      timeout: 4000,
      data: { ...scheduleData, action: `TO_PAYMENT` },
      hasToExit: false,
      iconSetting: {
        icon: <Icon icon="errorOutline" />,
        iconColor: `primary.4`,
      },
    })
  } else {
    genericErrorRedirect({
      unitId,
      title: `Ups!`,
      subtitle: `Tenemos problemas para completar su solicitud`,
      iconSetting: {
        icon: <Icon icon="language" />,
        iconColor: `primary.4`,
      },
      upperIconSetting: {
        upperIcon: <Icon icon="error" />,
        upperIconColor: `error.3`,
      },
      scheduleData,
      useOnlyFirstLevel: true,
      data: { posType },
      pathToRedirect: `/totem/${unitId}/financiador`,
      history,
    })
  }
}

const handleRedirectAfterHookError = ({ scheduleData, history }) => {
  const { unitId } = scheduleData
  genericErrorRedirect({
    unitId,
    scheduleData,
    history,
    title: `Lo sentimos`,
    subtitle: `No se puede realizar su solicitud. Retire su ticket y espere su atención en caja.`,
    redirect: `/totem/${unitId}/print`,
    timeout: 4000,
    data: { ...scheduleData, action: `TO_PAYMENT` },
    hasToExit: false,
    iconSetting: {
      icon: <Icon icon="errorOutline" />,
      iconColor: `primary.4`,
    },
  })
}

const getValorization = async ({
  appointment,
  financerCode,
  isPrivatePaymentByExclusion,
}) => {
  const appointmentWithValorization = await API.afterFinancerHook({
    appointment,
    financerCode,
    isPrivatePaymentByExclusion,
  })
  return appointmentWithValorization
}

const handleValorizationPrivatePayment = async ({
  posType,
  scheduleData: appointment,
  history,
  patientRut,
  financerCode,
  isPrivatePaymentByExclusion = false,
}) => {
  const { unitId } = appointment
  const scheduleData = await getValorization({
    appointment,
    financerCode,
    isPrivatePaymentByExclusion,
  })
  const { action } = scheduleData
  if (action === `TO_PAYMENT`) {
    return handleExclusionRuleByAction[action]({
      appointment,
      unitId,
      history,
    })
  }
  history.push({
    pathname: `/totem/${unitId}/copago`,
    state: {
      posType,
      scheduleData,
      data: {
        financerCode: `privatePayment`,
        patientRut,
        appointment: scheduleData,
      },
    },
  })
}

const handleContinuePaymentExclusion = async ({
  scheduleData,
  patientRut,
  history,
  state,
  setState,
  posType,
}) => {
  try {
    setState({ ...state, loading: true })
    await handleValorizationPrivatePayment({
      scheduleData,
      history,
      patientRut,
      posType,
      financerCode: `privatePayment`,
      isPrivatePaymentByExclusion: true,
    })
    setState({ ...state, loading: false })
  } catch (error) {
    setState({ ...state, loading: false })
    handleRedirectAfterHookError({ scheduleData, history })
  }
}

const initialState = {
  type: `isapre`,
  loading: true,
  data: [],
}

function Financer() {
  const history = useHistory()
  const {
    autopagoRecordId: lastAutopagoRecordId,
    totemIp,
    handleSetAutopagoRecordId,
  } = useAutopago()
  const {
    scheduleData,
    scheduleData: { unitId },
    posType,
  } = history?.location?.state ?? {}
  const patientRut = `${scheduleData?.patientRut}-${scheduleData?.patientRutDv}`
  const [state, setState] = useState(initialState)

  const toPayment = scheduleData?.financersForcedToPayment

  async function confirmFinancer(financer) {
    const { imedId: financerCode = financer, name: financerName } = financer
    if (toPayment?.includes(financerCode)) {
      return toExit({ history, scheduleData })
    }
    if (financerCode === `privatePayment`) {
      try {
        setState({ ...state, loading: true })
        await handleValorizationPrivatePayment({
          scheduleData,
          history,
          unitId,
          patientRut,
          posType,
          financerCode: `privatePayment`,
        })
        setState({ ...state, loading: false })
      } catch (error) {
        setState({ ...state, loading: false })
        handleRedirectAfterHookError({ scheduleData, history })
      }
    } else {
      setState({ ...state, loading: true })
      let scheduleDataWithValorization = {}
      try {
        scheduleDataWithValorization = await getValorization({
          appointment: scheduleData,
          financerCode,
        })
      } catch (error) {
        setState({ ...state, loading: false })
        handleRedirectAfterHookError({ scheduleData, history })
        return false
      }
      const { action } = scheduleDataWithValorization
      if (action === `TO_PRIVATE_PAYMENT` || action === `TO_PAYMENT`) {
        handleExclusionRuleByAction[action]({
          appointment: scheduleDataWithValorization,
          posType,
          patientRut,
          unitId,
          history,
        })
        setState({ ...state, loading: false })
        return false
      }
      try {
        if (scheduleDataWithValorization?.accompanistResponse.code === 0) {
          const state = {
            posType,
            scheduleData,
            data: {
              financerCode,
              patientRut,
            },
            scheduleDataWithValorization,
          }
          history.push({
            pathname: `/totem/${unitId}/redirigiendo`,
            state: {
              ...state,
              props: {
                title: `Paciente requiere acompañante`,
                subtitle: `Ingrese el RUT del acompañante`,
                iconPaddingTop: `6.7rem`,
                iconPaddingBottom: `3.3rem`,
                icon: <MaterialIcon icon="supervisorAccount" />,
                iconColor: `primary.4`,
                toExit: () =>
                  handleAccompanistRutInput({
                    state,
                    scheduleDataWithValorization,
                    unitId,
                  }),
                textToExit: `Continuar`,
                styleToExit: {
                  backgroundColor: `primary.4`,
                  color: `white`,
                },
              },
            },
          })
        } else {
          const response = await API.createImedAppointment({
            ...scheduleDataWithValorization,
            patientRut,
            financerCode,
            paymentType: 1, //1: Pendiente de Pago
            autopagoRecordId: lastAutopagoRecordId,
            totemIp,
          })
          const autopagoRecordId = response?.autopagoRecordId
          handleSetAutopagoRecordId?.(autopagoRecordId)
          history.push({
            pathname: `/totem/${unitId}/huella`,
            state: {
              posType,
              scheduleData,
              data: {
                financerCode,
                financerName,
                patientRut,
                appointment: response.parsedResponse,
              },
            },
          })
        }
      } catch (e) {
        setState({ ...state, loading: false })
        const { error, autopagoRecordId: newAutopagoRecordId } = e
        const autopagoRecordId = newAutopagoRecordId || lastAutopagoRecordId
        handleSetAutopagoRecordId?.(autopagoRecordId)
        handleRedirectIMEDError({ error, history, scheduleData, unitId })
      }
    }
  }

  function handleAccompanistRutInput({
    state,
    scheduleDataWithValorization,
    unitId,
  }) {
    history.push({
      pathname: `/totem/${unitId}/rutAcompanante`,
      state: {
        accompanistResponse: scheduleDataWithValorization.accompanistResponse,
        unitId,
        ...state,
      },
    })
  }

  useEffect(() => {
    let data = financers // data no ordenada
      .sort((a, b) => {
        return a.order - b.order
      })
    setState({ ...state, data, loading: false })
  }, [])

  if (scheduleData.onlyPrivatePayment) {
    return (
      <Container width="100%" isLoading={state?.loading} loaderSize="7">
        <Feedbacks
          title="Esta prestación sólo admite pago particular"
          iconPaddingTop="6.7rem"
          iconPaddingBottom="3.3rem"
          icon={<MaterialIcon icon="errorOutline" />}
          iconColor="primary.4"
          textToExit="Continuar"
          toExit={() =>
            handleContinuePaymentExclusion({
              posType,
              scheduleData,
              patientRut,
              unitId,
              history,
              state,
              setState,
            })
          }
          styleToExit={{
            marginTop: `4rem`,
            backgroundColor: `primary.4`,
            color: `white`,
            shadow: `0px 5px 5px 1px rgba(0, 0, 0, .1)`,
          }}
        />
      </Container>
    )
  }

  return (
    <Container
      marginTop="13vh"
      flexDirection="column"
      alignItems="center"
      height="87vh"
      width="100%"
      loaderSize="7"
      isLoading={state?.loading}
    >
      <Typography fontWeight="500" fontSize="5.5vw">
        Selecciona tu previsión de salud
      </Typography>
      <Container
        display="flex"
        marginY="2.4vh"
        maxHeight="66vh"
        overflowY="auto"
        flexWrap="wrap"
        justifyContent="center"
      >
        {state?.data?.map((financer, index) => {
          return (
            <Container
              data-testid={`imedCode-${financer.imedId}`}
              key={`${financer.imedId}-${index}`}
              backgroundColor="white"
              shadow="0px 5px 10px 1px rgba(0,0,0,0.1)"
              borderRadius="1.3vw"
              justifyContent="center"
              alignItems="center"
              height="8vh"
              paddingX="4vw"
              paddingY="1.65vh"
              width="40vw"
              margin="2vw"
              onClick={() => confirmFinancer(financer)}
            >
              {financer?.logo ? (
                <img src={financer.logo} width="100%" height="100%" />
              ) : null}
            </Container>
          )
        })}
      </Container>
      <Typography
        data-testid="privatePayment"
        color="primary.4"
        fontSize="6"
        textDecoration="underline"
        onClick={() => confirmFinancer(`privatePayment`)}
      >
        Pagar como particular
      </Typography>
    </Container>
  )
}

export default Financer
