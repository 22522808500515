import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Container, Typography, Button, Icon, MaterialIcon } from 'components'
import { capitalizeFirstLetter } from 'utils'
import Error from './Error'

const InfoCard = ({ name, icon, value, ...props }) => (
  <Container width="100%" flexDirection="column" {...props}>
    <Typography color="primary.4" fontSize="5" marginBottom="1">
      <Container alignItems="center">
        {icon}
        {name}
      </Container>
    </Typography>
    {value}
  </Container>
)
function ScheduleCard({ onClick, ...schedule }) {
  const {
    specialty,
    scheduledDate,
    reservationDate,
    messageOnPrintButton,
    totemMessage,
    alertColorChange,
  } = schedule
  return (
    <Container
      background="white"
      borderRadius="1rem"
      shadow="0px 5px 8px 1px RGBA(0, 0, 0, 0.12)"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Container
        flexDirection="column"
        justifyContent="center"
        width="100%"
        padding="3rem 2.25rem 2.25rem"
      >
        {specialty ? (
          <InfoCard
            name="Prestación"
            icon={
              <MaterialIcon
                icon="calendarToday"
                style={{ marginRight: `1rem` }}
              />
            }
            value={
              <Typography fontSize="7" fontWeight="400">
                {capitalizeFirstLetter(specialty.toLowerCase())}
              </Typography>
            }
            marginBottom="2.625rem"
          />
        ) : null}
        {scheduledDate || reservationDate ? (
          <InfoCard
            name="Fecha y hora"
            icon={
              <MaterialIcon icon="accessTime" style={{ marginRight: `1rem` }} />
            }
            value={
              <Typography fontSize="6" fontWeight="400">
                {capitalizeFirstLetter(
                  moment(scheduledDate || reservationDate).format(
                    `dddd D MMMM YYYY, HH:mm`,
                  ),
                )}
              </Typography>
            }
            marginBottom="2.625rem"
          />
        ) : null}
        {totemMessage ? (
          <Container
            alignItems="center"
            backgroundColor={
              alertColorChange ? `error.4@0.15` : `primary.12@0.15`
            }
            width="100%"
            paddingX="1.5vh"
            paddingY="1.3vh"
            borderRadius="0.4vh"
          >
            <MaterialIcon
              icon="errorOutline"
              color={alertColorChange ? `error.4` : `primary.12`}
              fontSize="5"
              style={{ marginRight: `1rem` }}
            />
            <Typography fontSize="5" fontWeight="400">
              {totemMessage}
            </Typography>
          </Container>
        ) : null}
        <Button
          width="100%"
          backgroundColor="primary.4"
          color="white"
          fontSize="6"
          marginTop="3rem"
          padding={3}
          borderRadius="15px"
          withShadow
          hoverProps={{
            backgroundColor: `primary.3`,
          }}
          onClick={() => onClick(schedule)}
        >
          {messageOnPrintButton
            ? messageOnPrintButton?.toUpperCase()
            : `CONFIRMAR CITA`}
        </Button>
      </Container>
    </Container>
  )
}

function Schedules({
  goBack,
  unit,
  goToLines,
  title,
  error,
  schedules,
  handleConfirm,
  disableGoBack,
  flushInterval,
}) {
  const [blockGoBack, setBlockGoBack] = useState(false)
  useEffect(() => {
    if (disableGoBack == true) {
      setBlockGoBack(true)
      setTimeout(() => flushInterval(), 500)
    }
  }, [])
  const areManySchedules = schedules.length > 1
  return (
    <Container flexDirection="column" alignItems="center" width="100%">
      <Container justifyContent="start" width="100%">
        <Button
          backgroundColor="transparent"
          paddingY="3"
          paddingX="4"
          onClick={goBack}
        >
          <Icon icon="arrowLeft" color="gray.3" fontSize="6" />
        </Button>
      </Container>
      {error != null ? (
        <Container
          width={{ _: `40em` }}
          height={{ _: `37em` }}
          backgroundColor="white"
          justifyContent="space-evenly"
          flexWrap="no-wrap"
          alignItems="center"
          flexDirection="column"
          shadow="0px 3px 4px -4px rgba(0, 0, 0, 0.8)"
          paddingTop="5"
          paddingBottom="0"
          paddingX="3"
          position="relative"
        >
          <Error
            unitName={unit.name}
            goToLines={goToLines}
            {...error}
            country={unit.country}
          />
        </Container>
      ) : (
        <>
          <Typography fontSize="6" color="gray.4" fontWeight="1">
            {title}
          </Typography>
          <Container
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(44.3em, 1fr))"
            gridGap={4}
            gridAutoColumns="minmax(44.3em, 1fr)"
            width={areManySchedules ? `90vh` : `80vh`}
            marginY="2"
            overflowX="auto"
            gridAutoFlow="column"
            padding="2rem 0.5rem"
            alignItems="center"
          >
            {schedules?.map((schedule) => (
              <ScheduleCard
                key={schedule.id}
                {...schedule}
                onClick={handleConfirm}
              />
            ))}
          </Container>
          {blockGoBack == false ? (
            <Button backgroundColor="transparent" onClick={goToLines}>
              <Typography
                fontSize="5"
                textDecoration="underline"
                color="primary.4"
              >
                Ir al menú principal
              </Typography>
            </Button>
          ) : null}
        </>
      )}
    </Container>
  )
}

export default Schedules
